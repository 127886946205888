import { action, observable } from 'mobx'
import {
  CompanySize,
  CurrencyUnit,
  ICompanyBase,
  ICompanyMemberBase,
  ICompanyNotificationBase,
  IFetchCompanyForViewerUseCase,
  ILocationBase,
  IMarketBase,
  InvestmentBase,
  IProductBase,
  IPublicCompany,
  IRoundBase,
} from '@/types'

type Dependencies = {
  fetchCompanyForViewerUseCase: IFetchCompanyForViewerUseCase
}

export default class PublicCompany implements IPublicCompany {
  @observable aboutUs = ''

  @observable articles = null

  @observable companyMembers: ICompanyMemberBase[] = []

  @observable companySize: CompanySize

  @observable conceptPitch = ''

  @observable currencyUnit: CurrencyUnit

  @observable denyNewContact = false

  @observable excludeFromSearch = false

  @observable facebookUrl = ''

  @observable followersCount = 0

  @observable hasInvestmentsWithJobs = false

  @observable highlightedJobListings = null

  @observable id = ''

  @observable instagramUrl = ''

  @observable investmentTargetMarkets: IMarketBase[] = []

  @observable investmentTargetRounds: IRoundBase[] = []

  @observable investments: InvestmentBase[] = []

  @observable isFollowing = false

  @observable isVc = false

  @observable jobs = null

  @observable linkedinUrl = ''

  @observable locations: ILocationBase[] = []

  @observable logo = ''

  @observable headerImage = ''

  @observable markets: IMarketBase[] = []

  @observable maxInvestmentAmount = ''

  @observable minInvestmentAmount = ''

  @observable name = ''

  @observable notificationSettings: ICompanyNotificationBase[] = []

  @observable products: IProductBase[] = []

  @observable receivedInvestments: InvestmentBase[] = []

  @observable round: IRoundBase

  @observable slug = ''

  @observable status = ''

  @observable totalFundingAmount = ''

  @observable twitterUrl = ''

  @observable url = ''

  @observable youtubeUrl = ''

  fetchCompanyForViewerUseCase!: IFetchCompanyForViewerUseCase

  _mapFromBase(base: ICompanyBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: ICompanyBase): void {
    this._mapFromBase(base)
  }

  constructor(base: ICompanyBase, deps: Dependencies) {
    this._mapFromBase(base)
    this.fetchCompanyForViewerUseCase = deps.fetchCompanyForViewerUseCase
  }

  async fetch(): Promise<boolean> {
    const output = await this.fetchCompanyForViewerUseCase.handle({ slug: this.slug })

    if (output.data.company) {
      this.update(output.data.company)
      return true
    }

    return false
  }
}
