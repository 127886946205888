import { inject, injectable } from 'inversify'
import PitchAttachment from '@/entities/PitchAttachment'
import symbols from '@/symbols'
import {
  IErrorsStore,
  IPitchAttachment,
  IPitchAttachmentFactory,
  IUpdatePitchAttachmentUseCase,
  PitchAttachmentFactoryInput,
} from '@/types'

@injectable()
export default class PitchAttachmentFactory implements IPitchAttachmentFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  @inject(symbols.IUpdatePitchAttachmentUseCase) private updatePitchAttachmentUseCase: IUpdatePitchAttachmentUseCase

  public create(input: PitchAttachmentFactoryInput): IPitchAttachment {
    const pitchAttachment = new PitchAttachment(input.base)
    pitchAttachment.errorsStore = this.errorStore
    pitchAttachment.updatePitchAttachmentUseCase = this.updatePitchAttachmentUseCase
    return pitchAttachment
  }
}
