import { inject, injectable } from 'inversify'
import {
  FetchMyCompanyArticlesUseCaseInput,
  FetchMyCompanyArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  ICompanyArticle,
  ICompanyArticleFactory,
  IFetchMyCompanyArticlesUseCase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMyCompanyArticlesInteractor implements IFetchMyCompanyArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.ICompanyArticleFactory) private companyArticleFactory: ICompanyArticleFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    articles: null | ICompanyArticle[]
    hasNextPage: null | boolean
    endCursor: string
  }>

  async handle(input: FetchMyCompanyArticlesUseCaseInput): Promise<FetchMyCompanyArticlesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        articles: null,
        hasNextPage: null,
        endCursor: null,
      },
    })
    try {
      const { articles, hasNextPage, endCursor } = await this.articlesService.fetchMyCompanyArticles(input)
      output.data.articles = articles.map((a) => this.companyArticleFactory.create({ base: a }))
      output.data.hasNextPage = hasNextPage
      output.data.endCursor = endCursor
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
