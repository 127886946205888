import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  DeleteJobUseCaseInput,
  DeleteJobUseCaseOutput,
  IAppErrorFactory,
  IDeleteJobUseCase,
  IJobsService,
} from '../../types'

@injectable()
export default class DeleteJobInteractor implements IDeleteJobUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeleteJobUseCaseInput): Promise<DeleteJobUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const job = await this.jobsService.deleteJob(input)
      output.job = job
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '募集の削除に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): DeleteJobUseCaseOutput {
    return {
      job: null,
      error: null,
    }
  }
}
