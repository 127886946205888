import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IUpdateMyReferenceUseCase,
  IUserReferencesService,
  UpdateMyReferenceUseCaseInput,
  UpdateMyReferenceUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateMyReferenceInteractor implements IUpdateMyReferenceUseCase {
  @inject(symbols.IUserReferencesService) private userReferencesService: IUserReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateMyReferenceUseCaseInput): Promise<UpdateMyReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.userReference = await this.userReferencesService.updateMyReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの更新に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateMyReferenceUseCaseOutput {
    return {
      userReference: null,
      error: null,
    }
  }
}
