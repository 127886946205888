import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IUpdateUserReferenceUseCase,
  IUserReferencesService,
  UpdateUserReferenceUseCaseInput,
  UpdateUserReferenceUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateUserReferenceInteractor implements IUpdateUserReferenceUseCase {
  @inject(symbols.IUserReferencesService) private userReferencesService: IUserReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateUserReferenceUseCaseInput): Promise<UpdateUserReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.userReference = await this.userReferencesService.updateUserReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの更新に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateUserReferenceUseCaseOutput {
    return {
      userReference: null,
      error: null,
    }
  }
}
