import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ISearchUserUseCase,
  IUsersService,
  SearchUserUseCaseInput,
  SearchUserUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class SearchUserInteractor implements ISearchUserUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: SearchUserUseCaseInput): Promise<SearchUserUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.users = await this.usersService.searchUser(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): SearchUserUseCaseOutput {
    return {
      users: [],
      error: null,
    }
  }
}
