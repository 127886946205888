import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateRecruitSubscriptionUseCaseInput,
  CreateRecruitSubscriptionUseCaseOutput,
  IAppErrorFactory,
  ICreateRecruitSubscriptionUseCase,
  ISubscriptionsService,
} from '@/types'

@injectable()
export default class CreateRecruitSubscriptionInteractor implements ICreateRecruitSubscriptionUseCase {
  @inject(symbols.ISubscriptionsService) private subscriptionsService: ISubscriptionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateRecruitSubscriptionUseCaseInput): Promise<CreateRecruitSubscriptionUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const paymentIntentBase = await this.subscriptionsService.createRecruitSubscription(input)
      output.paymentIntent = paymentIntentBase
    } catch (e) {
      // エラーハンドリングの方向性決まるまで一旦これで対応
      // `e` に文字列で GraphQL のエラーが突っ込んである...
      const errorCode = 'STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT'
      let messageForUI: string = null
      if (e instanceof Error && e?.message.includes(errorCode)) {
        // TODO: サーバー側のエラーメッセージを使う
        // TODO: もしくは翻訳
        messageForUI = 'このクーポンコードは利用出来ません。初回注文のみ利用可能です。'
      }

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI,
        extra: {
          useCaseInput: {
            companyId: input.companyId,
            attributes: {
              companyName: input.attributes.companyName,
              couponCode: input.attributes?.couponCode,
              priceType: input.attributes.priceType,
            },
          },
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateRecruitSubscriptionUseCaseOutput {
    return {
      paymentIntent: null,
      error: null,
    }
  }
}
