import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateVehicleItemRoundUseCaseInput,
  CreateVehicleItemRoundUseCaseOutput,
  IAppErrorFactory,
  ICreateVehicleItemRoundUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleItemRound,
  IVehicleItemRoundFactory,
} from '@/types'

@injectable()
export default class CreateVehicleItemRoundInteractor implements ICreateVehicleItemRoundUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IVehicleItemRoundFactory) private vehicleItemRoundFactory: IVehicleItemRoundFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItemRound: IVehicleItemRound
  }>

  async handle(input: CreateVehicleItemRoundUseCaseInput): Promise<CreateVehicleItemRoundUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItemRound: null,
      },
    })
    try {
      // TODO: サーバーの返り値変更後に調整(消してよいか確認)
      const base = await this.pmsService.createVehicleItemRound(input)
      output.data.vehicleItemRound = this.vehicleItemRoundFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
