import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IPortfolioBase,
  IUpdateVehicleItemAndPortfolioUseCase,
  IUseCaseOutputFactory,
  IVehicleItemBase,
  UpdateVehicleItemAndPortfolioUseCaseInput,
  UpdateVehicleItemAndPortfolioUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateVehicleItemAndPortfolioInteractor implements IUpdateVehicleItemAndPortfolioUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItem: IVehicleItemBase
    portfolio: IPortfolioBase
  }>

  async handle(input: UpdateVehicleItemAndPortfolioUseCaseInput): Promise<UpdateVehicleItemAndPortfolioUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItem: null,
        portfolio: null,
      },
    })
    try {
      const [vehicleItem, portfolio] = await Promise.all([
        this.pmsService.updateVehicleItem({
          id: input.id,
          vehicleItem: input.vehicleItem,
          captainUserId: input.captainUserId,
          vehicleStatusId: input.vehicleStatusId,
        }),
        this.pmsService.updateVehicleItemPortfolio({
          id: input.portfolioId,
          vehicleItemId: input.id,
          portfolio: input.portfolio,
        }),
      ])

      output.data.vehicleItem = vehicleItem
      output.data.portfolio = portfolio
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
