import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  DeleteVehicleItemRoundAttachmentUseCaseInput,
  DeleteVehicleItemRoundAttachmentUseCaseOutput,
  IAppErrorFactory,
  IDeleteVehicleItemRoundAttachmentUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleItemRoundAttachmentBase,
} from '@/types'

@injectable()
export default class DeleteVehicleItemRoundAttachmentInteractor implements IDeleteVehicleItemRoundAttachmentUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    attachment: IVehicleItemRoundAttachmentBase
  }>

  async handle(
    input: DeleteVehicleItemRoundAttachmentUseCaseInput
  ): Promise<DeleteVehicleItemRoundAttachmentUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        attachment: null,
      },
    })
    try {
      output.data.attachment = await this.pmsService.deleteVehicleItemRoundAttachment(input.id)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
