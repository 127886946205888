import { inject, injectable } from 'inversify'
import {
  CompanyMemberFactoryInput,
  IMyCompanyMember,
  ICompanyMemberFactory,
  IErrorsStore,
  IUpdateCompanyMemberUseCase,
} from '@/types'
import symbols from '@/symbols'
import CompanyMember from '@/entities/CompanyMember'

@injectable()
export default class CompanyMemberFactory implements ICompanyMemberFactory {
  @inject(symbols.IUpdateCompanyMemberUseCase) private updateCompanyMemberUseCase: IUpdateCompanyMemberUseCase

  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: CompanyMemberFactoryInput): IMyCompanyMember {
    return new CompanyMember({
      company: input.company,
      base: input.base,
      useCases: {
        updateCompanyMemberUseCase: this.updateCompanyMemberUseCase,
      },
      errorsStore: this.errorsStore,
    })
  }
}
