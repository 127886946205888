import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  DeleteVehicleUseCaseInput,
  DeleteVehicleUseCaseOutput,
  IAppErrorFactory,
  IDeleteVehicleUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleBase,
} from '@/types'

@injectable()
export default class DeleteVehicleInteractor implements IDeleteVehicleUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicle: IVehicleBase
  }>

  async handle(input: DeleteVehicleUseCaseInput): Promise<DeleteVehicleUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicle: null,
      },
    })
    try {
      output.data.vehicle = await this.pmsService.deleteVehicle(input.id)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
