import { action, observable } from 'mobx'
import {
  IErrorsStore,
  IPitchAttachment,
  IPitchAttachmentBase,
  IPitchAttachmentInputBase,
  IUpdatePitchAttachmentUseCase,
  PitchAttachmentCategoryName,
} from '@/types'

export default class PitchAttachment implements IPitchAttachment {
  @observable id = ''

  @observable categoryName: PitchAttachmentCategoryName

  @observable file = ''

  @observable filename = ''

  @observable url = ''

  errorsStore: IErrorsStore

  updatePitchAttachmentUseCase: IUpdatePitchAttachmentUseCase

  @action
  _mapBase(base: IPitchAttachmentBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IPitchAttachmentBase) {
    this._mapBase(base)
  }

  async save(pitchAttachment: IPitchAttachmentInputBase): Promise<boolean> {
    const output = await this.updatePitchAttachmentUseCase.handle({
      pitchAttachment,
      id: this.id,
    })

    if (output.pitchAttachment) {
      this._mapBase(output.pitchAttachment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
