import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchLocationUseCaseInput,
  FetchLocationUseCaseOutput,
  IAppErrorFactory,
  IFetchLocationUseCase,
  ILocationsService,
} from '../../types'

@injectable()
export default class FetchLocationInteractor implements IFetchLocationUseCase {
  @inject(symbols.ILocationsService) private locationsService: ILocationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchLocationUseCaseInput): Promise<FetchLocationUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.location = await this.locationsService.fetchLocation(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchLocationUseCaseOutput {
    return {
      location: null,
      error: null,
    }
  }
}
