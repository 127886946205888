import { inject, injectable } from 'inversify'
import { IErrorsStore, IProduct, IProductFactory, IUpdateProductUseCase, ProductFactoryInput } from '@/types'
import symbols from '@/symbols'
import Product from '@/entities/Product'

@injectable()
export default class ProductFactory implements IProductFactory {
  @inject(symbols.IUpdateProductUseCase) private updateProductUseCase: IUpdateProductUseCase

  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: ProductFactoryInput): IProduct {
    const product = new Product(input.base)
    product.errorsStore = this.errorsStore
    product.updateProductUseCase = this.updateProductUseCase
    return product
  }
}
