import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  ICompanyReferencesService,
  IUpdateMyCompanyReferenceUseCase,
  UpdateMyCompanyReferenceUseCaseInput,
  UpdateMyCompanyReferenceUseCaseOutput,
} from '../../types'

@injectable()
export default class UpdateMyCompanyReferenceInteractor implements IUpdateMyCompanyReferenceUseCase {
  @inject(symbols.ICompanyReferencesService) private companyReferencesService: ICompanyReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateMyCompanyReferenceUseCaseInput): Promise<UpdateMyCompanyReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companyReference = await this.companyReferencesService.updateMyCompanyReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの更新に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateMyCompanyReferenceUseCaseOutput {
    return {
      companyReference: null,
      error: null,
    }
  }
}
