// Type definitions for @rails/actioncable 6.1
// Project: https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable
// Definitions by: Martin Badin <https://github.com/martin-badin>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped
// Minimum TypeScript Version: 3.6

// ============================================================
// ActionCable が SSR 環境での利用を想定しておらず、
// サーバーサイドでエラー落ちするため必要な型定義をここに移植。
// ============================================================

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */
// export as namespace ActionCable;

export enum ActionCableMessageTypes {
  confirmation = 'confirm_subscription',
  disconnect = 'disconnect',
  ping = 'ping',
  rejection = 'reject_subscription',
  welcome = 'welcome',
}

export enum ActionCableDisconnectReasons {
  invalid_request = 'invalid_request',
  server_restart = 'server_restart',
  unauthorized = 'unauthorized',
}

export interface ActionCableMixin {
  appear?(): void
  away?(): void
  connected?(): void
  disconnected?({ willAttemptReconnect: boolean }): void
  initialized?(): void
  install?(): void
  rejected?(): void
  uninstall?(): void
  update?(): void

  received?(data: any): void

  readonly documentIsActive?: boolean
  readonly appearingOn?: string | null

  [key: string]: any
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/internal.js
 */
// export const INTERNAL: {
//   default_mount_path: '/cable';
//   disconnect_reasons: typeof DisconnectReasons;
//   message_types: typeof MessageTypes;
//   protocols: ['actioncable-v1-json', 'actioncable-unsupported'];
// };

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/connection.js
 */
export interface ActionCableConnection<C = ActionCableConsumer> {
  consumer: C

  subscriptions: ActionCableSubscriptions<C>

  monitor: ConnectionMonitor<C>

  disconnected: boolean

  send(data: object): boolean

  open(this: ActionCableConnection<C>): boolean

  close(options?: { allowReconnect: boolean }): any

  reopen(): void

  getProtocol(): void | string

  isOpen(): boolean

  isActive(): boolean

  reopenDelay: number

  events: {
    message(event: {
      data: {
        identifier: string
        message: string
        reason: ActionCableDisconnectReasons
        reconnect: boolean
        type: ActionCableMessageTypes
      }
    }): any

    open(): void

    close(): void

    error(): void
  }
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/connection_monitor.js
 */
export interface ConnectionMonitor<C = ActionCableConsumer> {
  connection: C

  reconnectAttempts: number

  start(): void

  stop(): void

  isRunning(): boolean

  recordPing(): void

  recordConnect(): void

  recordDisconnect(): void

  visibilityDidChange(this: ConnectionMonitor<C>): void

  staleThreshold: number

  reconnectionBackoffRate: number
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/consumer.js
 */
export interface ActionCableConsumer {
  subscriptions: ActionCableSubscriptions<this>

  connection: ActionCableConnection<this>

  url: string

  send(data: object): boolean

  connect(): boolean

  disconnect(): any

  ensureActiveConnection(): void | boolean
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/subscription.js
 */
export interface ActionCableSubscription<C = ActionCableConsumer> {
  consumer: C

  identifier: string

  perform(action: string, data?: object): boolean

  send(data: object): boolean

  unsubscribe(): this
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/subscriptions.js
 */
export interface ActionCableSubscriptions<C = ActionCableConsumer> {
  consumer: C

  subscriptions: Array<ActionCableSubscription<C>>

  create<M>(
    channelName: string | { channel: string; [key: string]: string }, // PROTOCOL 用調整
    mixin?: ActionCableMixin & M
  ): ActionCableSubscription<C> & ActionCableMixin & M
}

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/adapters.js
 */
// export const adapters: {
//   logger: Console
//   WebSocket: WebSocket
// }

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/consumer.js
 */
// export function createWebSocketURL(url: string): string

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/logger.js
 */
// export const logger: {
//   log(...messages: any[]): void
// }

/**
 * @see https://github.com/rails/rails/blob/main/actioncable/app/javascript/action_cable/index.js
 */
// export function createConsumer(url?: string): ActionCableConsumer
// export function getConfig(name: string): string | void

export interface ActionCable {
  createConsumer(url?: string): ActionCableConsumer
}

/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */
