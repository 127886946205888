import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchLatestForexRateUseCaseInput,
  FetchLatestForexRateUseCaseOutput,
  IAppErrorFactory,
  IFetchLatestForexRateUseCase,
  IForexService,
  IUseCaseOutput,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class FetchLatestForexRateInteractor implements IFetchLatestForexRateUseCase {
  @inject(symbols.IForexService) private forexService: IForexService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<FetchLatestForexRateUseCaseOutput>

  async handle(input: FetchLatestForexRateUseCaseInput): Promise<IUseCaseOutput<FetchLatestForexRateUseCaseOutput>> {
    const output = this.outputFactory.create({
      defaultValue: {
        forexRate: null,
      },
    })
    try {
      output.data.forexRate = await this.forexService.fetchForexRate(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
