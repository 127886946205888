import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IUpdateVehicleItemRoundUseCase,
  IUseCaseOutputFactory,
  IVehicleItemRoundBase,
  UpdateVehicleItemRoundUseCaseInput,
  UpdateVehicleItemRoundUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateVehicleItemRoundInteractor implements IUpdateVehicleItemRoundUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItemRound: IVehicleItemRoundBase
  }>

  async handle(input: UpdateVehicleItemRoundUseCaseInput): Promise<UpdateVehicleItemRoundUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItemRound: null,
      },
    })
    try {
      output.data.vehicleItemRound = await this.pmsService.updateVehicleItemRound(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
