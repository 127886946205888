import { action, computed, observable } from 'mobx'
import {
  ChatMessageBroadCastingType,
  IChatMessage,
  IChatMessageInputBase,
  IChatMessageThread,
  IChatMessageThreadBase,
  IReopenChatConnectionUseCase,
  ICreateChatMessageThreadSubscriptionUseCase,
  IErrorsStore,
  IFetchChatMessagesUseCase,
  IJobApplicationBase,
  IMarkAllMessagesAsReadUseCase,
  IMessageAttachment,
  ISendChatMessageUseCase,
  IUserBase,
  IViewer,
  MessageThreadType,
  QueueForSendingMessage,
  SendChatMessageUseCaseOutput,
} from '@/types'
import { getUUID } from '@/utils'

export default class ChatMessageThread implements IChatMessageThread {
  @observable id = ''

  @observable slug = ''

  @observable name = ''

  @observable allMessages: IChatMessage[] = []

  @observable users: IUserBase[] = []

  @observable hasNextMessagesPage = true

  @observable isInitialized = false

  @observable draftText = ''

  @observable draftAttachments = []

  @observable queueForSendingMessages = []

  @observable messageThreadType: MessageThreadType

  @observable jobApplication: IJobApplicationBase

  // 基本使わないけど Base からマップする用の箱だけ用意しておく
  messages = null

  errorsStore: IErrorsStore

  createChatMessageThreadSubscriptionUseCase: ICreateChatMessageThreadSubscriptionUseCase

  sendChatMessageUseCase: ISendChatMessageUseCase

  fetchChatMessagesUseCase: IFetchChatMessagesUseCase

  markAllMessagesAsReadUseCase: IMarkAllMessagesAsReadUseCase

  reopenChatConnectionUseCase: IReopenChatConnectionUseCase

  viewer: IViewer

  /**
   *スレッド一覧用の最初のメッセージを取得して返す
   */
  @computed
  get latestMessage(): IChatMessage {
    if (this.allMessages.length === 0) {
      return null
    }

    return this.allMessages[0]
  }

  /**
   *自分以外の最初のメッセージを取得して返す
   */
  @computed
  get othersLatestMessage(): IChatMessage {
    const { username } = this.viewer
    const othersMessages = this.allMessages.filter((message) => message.user.username !== username)

    if (othersMessages.length === 0) {
      return null
    }

    return othersMessages[0]
  }

  /**
   * this.messages の最初の要素の isRead を確認してその結果を返す
   */
  @computed
  get hasUnreadMessage(): boolean {
    // 自分以外のメッセージがあって、自分以外のメッセージに既読した人がいれば既読とする
    if (this.othersLatestMessage) {
      return this.othersLatestMessage.readers.length === 0
    }

    // なければ常に false
    return false
  }

  @action
  _mapFromBase(base: IChatMessageThreadBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
      if (key === 'messages') {
        // messages の場合は Relay Connection を変換
        this.allMessages = base.messages.nodes
      }
    })
  }

  /**
   * スレッドへ Subscription を作成
   * @private
   */
  _createSubscription(): void {
    this.createChatMessageThreadSubscriptionUseCase.handle({
      chatMessageThreadSlug: this.slug,
      onDisconnected: () => {
        // 切断時に再接続 or credentials が変わってるなら Consumer ごと作り直し
        this._reconnect()
      },
      onReceived: (input) => {
        if (input.type === ChatMessageBroadCastingType.CREATED) {
          this._removeQueueForSendingMessage(input.deduplicationId)
          this._prependMessage(input.message)
        }
      },
    })
  }

  @action
  _prependMessage(message: IChatMessage): void {
    // 先頭に追加
    this.allMessages.unshift(message)
  }

  @action
  _addMessages(messages: IChatMessage[]): void {
    messages.forEach((m) => {
      // 重複していたら処理をスキップ
      if (this.allMessages.some((allM) => allM.id === m.id)) {
        return
      }

      // 末尾に追加
      this.allMessages.push(m)
    })
  }

  @action
  _updateMessages(messages: IChatMessage[]): void {
    // 全部上書き
    this.allMessages = messages
  }

  /**
   * user を 自分のメッセージ以外の、すべてのメッセージの reader にする
   */
  @action
  _markAllMessagesAsRead(user: IUserBase): void {
    let isChanged = false

    const messages = this.allMessages.map((message) => {
      // 自分のメッセージの場合はなにもしない
      if (message.user.username === user.username) {
        return message
      }
      // すでにメッセージの reader である場合はなにもしない
      if (message.readers.find((reader) => reader.username === user.username)) {
        return message
      }
      // user を reader の末尾に追加
      message.readers.push(user)
      isChanged = true
      return message
    })

    if (isChanged) {
      this._updateMessages(messages)
    } // 変更を加えたときのみ、メッセージを上書き
  }

  @action
  _updateHasNextMessagesPage(hasNextPage: boolean): void {
    this.hasNextMessagesPage = hasNextPage
  }

  @action
  updateDraftText(draftText: string): void {
    this.draftText = draftText
  }

  @action
  resetDraftText(): void {
    this.draftText = ''
  }

  @action
  addDraftAttachment(draftAttachment: IMessageAttachment): void {
    this.draftAttachments = this.draftAttachments.concat(draftAttachment)
  }

  @action
  removeDraftAttachment(draftAttachment: IMessageAttachment): void {
    this.draftAttachments = this.draftAttachments.filter((a: IMessageAttachment) => a.id !== draftAttachment.id)
  }

  @action
  resetDraftAttachments(): void {
    this.draftAttachments = []
  }

  @action
  updateIsInitialized(isInitialized: boolean): void {
    this.isInitialized = isInitialized
  }

  @action
  _addQueueForSendingMessage(queueForSendingMessage: QueueForSendingMessage): void {
    this.queueForSendingMessages = this.queueForSendingMessages.concat(queueForSendingMessage)
  }

  @action
  _removeQueueForSendingMessage(deduplicationId: string): void {
    this.queueForSendingMessages = this.queueForSendingMessages.filter(
      (q: QueueForSendingMessage) => q.deduplicationId !== deduplicationId
    )
  }

  @action
  _reconnect(): void {
    this.reopenChatConnectionUseCase.handle()
    // subscription を再度作成
    this._createSubscription()
  }

  constructor(
    base: IChatMessageThreadBase,
    useCases: {
      createChatMessageThreadSubscriptionUseCase: ICreateChatMessageThreadSubscriptionUseCase
      sendChatMessageUseCase: ISendChatMessageUseCase
      fetchChatMessagesUseCase: IFetchChatMessagesUseCase
      markAllMessagesAsReadUseCase: IMarkAllMessagesAsReadUseCase
      reopenChatConnectionUseCase: IReopenChatConnectionUseCase
    }
  ) {
    this.createChatMessageThreadSubscriptionUseCase = useCases.createChatMessageThreadSubscriptionUseCase
    this.sendChatMessageUseCase = useCases.sendChatMessageUseCase
    this.fetchChatMessagesUseCase = useCases.fetchChatMessagesUseCase
    this.markAllMessagesAsReadUseCase = useCases.markAllMessagesAsReadUseCase
    this.reopenChatConnectionUseCase = useCases.reopenChatConnectionUseCase
    this._mapFromBase(base)
    this._createSubscription()
  }

  /**
   * メッセージを送信
   * @param input
   */
  sendMessage(input: IChatMessageInputBase): SendChatMessageUseCaseOutput {
    const deduplicationId = getUUID()

    // TODO: キューに入れたメッセージのタイムアウトを処理する。
    // いつまでも送信中のステータスでメッセージが残ってしまう不具合あり。
    // cf., https://app.asana.com/0/1200352138063580/1203813586505530/f
    // タイムアウトしたら下書きに戻す or Apple Messages App みたいに"送信できませんでした"の状態で一覧に表示させてコピペや再送できるようにする必要あり
    // 下書きに戻す場合は Twitter の下書きツイートのような複数下書きにできる仕組みを作るか、下書きを一つのままにする場合は送信中に追加の下書きを受け付けないようにする必要あり
    this._addQueueForSendingMessage({
      deduplicationId,
      body: input.messageBody,
      messageAttachments: input.messageFiles,
    })

    const output = this.sendChatMessageUseCase.handle({
      messageBody: input.messageBody,
      chatMessageTreadSlug: this.slug,
      messageFiles: input.messageFiles,
      deduplicationId,
    })

    // TODO: キューから削除せず、テキストエリアに下書きとして戻してあげる
    // 失敗した場合キューからメッセージを削除
    if (!output.isSuccessful) {
      this._removeQueueForSendingMessage(deduplicationId)
    }

    return output
  }

  /**
   * スレッドのメッセージを追加で取得
   * @param input
   */
  async fetchChatMessages(input: { shouldRefresh: boolean } = { shouldRefresh: false }): Promise<boolean> {
    const { shouldRefresh } = input
    const output = await this.fetchChatMessagesUseCase.handle({
      shouldRefresh,
      chatMessageTreadSlug: this.slug,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    // リフレッシュする場合は今の allMessages を全部消して更新
    if (shouldRefresh) {
      this._updateMessages(output.messages)
    } else {
      this._addMessages(output.messages)
    }

    this._updateHasNextMessagesPage(output.hasNextPage)

    return true
  }

  getUserToTalkWith(myUsername: string): IUserBase {
    return this.users.find((u) => u.username !== myUsername)
  }

  async markAllMessagesAsRead(input: { reader: IUserBase }): Promise<boolean> {
    const output = await this.markAllMessagesAsReadUseCase.handle({ slug: this.slug })

    if (output.error) {
      this.errorsStore.handle(output.error)
      // エラーがある場合はなにもしない
      return false
    }

    this._markAllMessagesAsRead(input.reader)

    return true
  }
}
