import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  AddPortfolioAttachmentUseCaseInput,
  AddPortfolioAttachmentUseCaseOutput,
  IAddPortfolioAttachmentUseCase,
  IAppErrorFactory,
  IPMSService,
  IPortfolioAttachmentBase,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class AddPortfolioAttachmentInteractor implements IAddPortfolioAttachmentUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    attachment: IPortfolioAttachmentBase
  }>

  async handle(input: AddPortfolioAttachmentUseCaseInput): Promise<AddPortfolioAttachmentUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        attachment: null,
      },
    })
    try {
      output.data.attachment = await this.pmsService.addVehicleItemPortfolioAttachment(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
