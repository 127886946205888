import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IPortfolioBase,
  IUpdateVehicleItemPortfolioUseCase,
  IUseCaseOutputFactory,
  UpdateVehicleItemPortfolioUseCaseInput,
  UpdateVehicleItemPortfolioUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateVehicleItemPortfolioInteractor implements IUpdateVehicleItemPortfolioUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    portfolio: IPortfolioBase
  }>

  async handle(input: UpdateVehicleItemPortfolioUseCaseInput): Promise<UpdateVehicleItemPortfolioUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        portfolio: null,
      },
    })
    try {
      const portfolio = await this.pmsService.updateVehicleItemPortfolio(input)
      output.data.portfolio = portfolio
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
