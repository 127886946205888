import { inject, injectable } from 'inversify'
import {
  FetchChatMessageThreadsUseCaseInput,
  FetchChatMessageThreadsUseCaseOutput,
  IAppErrorFactory,
  IChatMessageThreadFactory,
  IChatService,
  IFetchChatMessageThreadsUseCase,
  IUseCaseOutput,
  IUseCaseOutputFactory,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchChatMessageThreadsInteractor implements IFetchChatMessageThreadsUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IUseCaseOutputFactory)
  private outputFactory: IUseCaseOutputFactory<FetchChatMessageThreadsUseCaseOutput>

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IChatMessageThreadFactory) private chatMessageThreadFactory: IChatMessageThreadFactory

  async handle(
    input: FetchChatMessageThreadsUseCaseInput
  ): Promise<IUseCaseOutput<FetchChatMessageThreadsUseCaseOutput>> {
    const output = this.outputFactory.create({
      defaultValue: {
        threads: [],
        pageInfo: null,
      },
    })
    try {
      const { threads, pageInfo } = await this.chatService.fetchPaginatedChatMessageThreads({
        limit: input.limit,
        cursor: input.cursor,
        limitOfMessages: 1,
      })
      output.data.threads = threads.map((t) =>
        this.chatMessageThreadFactory.create({
          base: t,
        })
      )
      output.data.pageInfo = pageInfo
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
