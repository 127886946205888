import { inject, injectable } from 'inversify'
import Pitch from '@/entities/Pitch'
import {
  IAddPitchAttachmentUseCase,
  IDeletePitchAttachmentUseCase,
  IErrorsStore,
  IMyPitch,
  IPitchAttachmentFactory,
  IPitchFactory,
  IRemovePitchAttachmentFromPitchUseCase,
  IUpdatePitchUseCase,
  PitchFactoryInput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class PitchFactory implements IPitchFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  @inject(symbols.IUpdatePitchUseCase) private updatePitchUseCase: IUpdatePitchUseCase

  @inject(symbols.IAddPitchAttachmentUseCase) private addPitchAttachmentUseCase: IAddPitchAttachmentUseCase

  @inject(symbols.IDeletePitchAttachmentUseCase) private deletePitchAttachmentUseCase: IDeletePitchAttachmentUseCase

  @inject(symbols.IRemovePitchAttachmentFromPitchUseCase)
  private removePitchAttachmentFromPitchUseCase: IRemovePitchAttachmentFromPitchUseCase

  @inject(symbols.IPitchAttachmentFactory) private pitchAttachmentFactory: IPitchAttachmentFactory

  public create(input: PitchFactoryInput): IMyPitch {
    input.base.pitchAttachments = input.base.pitchAttachments
      ? input.base.pitchAttachments.map((p) => this.pitchAttachmentFactory.create({ base: p }))
      : []
    const pitch = new Pitch(input.base)
    pitch.errorsStore = this.errorStore
    pitch.updatePitchUseCase = this.updatePitchUseCase
    pitch.addPitchAttachmentUseCase = this.addPitchAttachmentUseCase
    pitch.deletePitchAttachmentUseCase = this.deletePitchAttachmentUseCase
    pitch.removePitchAttachmentFromPitchUseCase = this.removePitchAttachmentFromPitchUseCase
    return pitch
  }
}
