import {
  CompanyOrderBase,
  CompanySize,
  CurrencyUnit,
  IArticle,
  IArticleBase,
  IBaseAppError,
  IChatMessageThread,
  ICompanyBase,
  ICompanyInputBase,
  IFollowableEntityBase,
  IInvestor,
  IInvitationBase,
  IJobApplication,
  IJobBase,
  IJobCategoryBase,
  IJobTagBase,
  ILocationBase,
  IMarketBase,
  IMessage,
  IMessageBase,
  IMyCompany,
  InvestorType,
  IPitchAttachmentBase,
  IPitchBase,
  IPublicArticle,
  IPublicCompany,
  IPublicFollowableEntity,
  IPublicUser,
  ISkillBase,
  ISubsidyBase,
  ISubsidyPurposeBase,
  IUseCaseOutput,
  IUser,
  IUserBase,
  IUserProfileInputBase,
  IVehicle,
  IViewer,
  JobTypeOfPosition,
  JobWorkExperience,
  JobWorkingForm,
  Language,
  MagazineArchivePageContents,
  MagazineSinglePageContents,
  MagazineTopPageContents,
  PitchAttachmentCategoryName,
  PrimaryUserType,
  TickerSymbol,
  UserOrderBase,
  VehicleInvestor,
} from './entities'
import {
  AddFootprintToJobUseCaseInput,
  CreateSkillUseCaseInput,
  FetchArticlesUseCaseOutput,
  FetchCompanyArticlesUseCaseInput,
  FetchCompanyArticlesUseCaseOutput,
  FetchCompanyJobsUseCaseInput,
  FetchCompanyUseCaseInput,
  FetchFeaturedStoriesUseCaseOutput,
  FetchJobsUseCaseInput,
  FetchJobUseCaseInput,
  FetchLatestForexRateUseCaseOutput,
  FetchLocationsUseCaseInput,
  FetchLocationUseCaseInput,
  FetchPitchesUseCaseInput,
  FetchPitchUseCaseInput,
  FetchSkillsUseCaseInput,
  FetchSubsidiesUseCaseInput,
  FetchSubsidyPurposeUseCaseInput,
  FetchSubsidyUseCaseInput,
  FetchUserArticlesUseCaseInput,
  FetchUserUseCaseInput,
  LeaveServiceUseCaseOutput,
  MarkPitchAsReadUseCaseInput,
  SearchCompanyUseCaseInput,
  SearchUserUseCaseInput,
} from './useCases'

export interface IStores {
  ui: IUIStore
  errors: IErrorsStore
  messages: IMessagesStore
  viewer: IViewerStore
  users: IUsersStore
  companies: ICompaniesStore
  articles: IArticlesStore
  chat: IChatStore
  jobApplications: IJobApplicationsStore
  jobs: IJobsStore
  pms: IPMSStore
  subsidies: ISubsidiesStore
}

export interface IUIStore {
  // モバイル向けの表示にするか
  isMobile: boolean
  // Drawer
  isRightDrawerOpen: boolean
  // 通知ポップアップ
  isNotificationMenuOpen: boolean
  // 求人応募モーダル
  isJobApplicationModalOpen: boolean
  // 求人決済モーダル
  isRecruitPaymentModalOpen: boolean
  // 求人決済モーダル
  isOnboardingModalOpen: boolean
  // 招待送信モーダル
  isInvitationModalOpen: boolean
  investorType: InvestorType
  // プロフィール入力モーダル
  isProfileInputModalOpen: boolean
  // 求人決済訴求モーダル
  isRecruitPaymentDescriptionModalOpen: boolean
  // サインアップモーダル
  isSignUpModalOpen: boolean
  // DM時プロフィール設定モーダル
  isSetUpProfileToSendDMsModalOpen: boolean
  // ビークルアイテム新規作成モーダル
  isNewVehicleItemModalOpen: boolean
  // ビークルアイテム新規作成フォームの vehicleStatusId初期値
  initialVehicleStatusId: string
  // ビークルアイテムドロワー
  isVehicleItemRightDrawerOpen: boolean

  toggleRightDrawer(): void

  toggleNotificationMenu(): void

  toggleJobApplicationModal(): void

  toggleRecruitPaymentModal(): void

  toggleOnboardingModal(): void

  toggleInvitationModal(): void

  updateInvestorType(investorType: InvestorType): void

  toggleProfileInputModal(): void

  toggleRecruitPaymentDescriptionModal(): void

  toggleSignUpModal(): void

  toggleSetUpProfileToSendDMsModal(): void

  openNewVehicleItemModal(vehicleStatusId?: string): void

  closeNewVehicleItemModal(): void

  toggleVehicleItemRightDrawer(): void
}

export interface IErrorsStore {
  error: IBaseAppError

  handle(error: IBaseAppError): void
  create(e: Error): void
}

export interface IMessagesStore {
  messages: IMessage[]

  add(message: IMessageBase): void

  remove(message: IMessage): void
}

export type OnInitializedHandlerInput = {
  isSignedIn: boolean
  username: string
  primaryUserType: PrimaryUserType
}

export type OnInitializedHandler = (input: OnInitializedHandlerInput) => Promise<void>

export interface IViewerStore {
  isSignedIn: boolean
  isInitialized: boolean
  language: string
  viewer: IViewer

  updateMyProfile(profile: IUserProfileInputBase): Promise<boolean>

  updateNames(name: string, username: string): Promise<boolean>

  updateEmail(newEmail: string): Promise<boolean>

  updatePassword(newPassword: string, newPasswordConfirmation: string, currentPassword: string): Promise<boolean>

  updatePasswordWithToken(resetPasswordToken: string, password: string, passwordConfirmation: string): Promise<boolean>

  sendResetPasswordRequest(email: string): Promise<boolean>

  signIn(email: string, password: string): Promise<boolean>

  signUp(
    name: string,
    username: string,
    email: string,
    password: string,
    passwordConfirmation: string,
    token?: string
  ): Promise<boolean>

  signOut(): Promise<boolean>

  leaveService(): Promise<LeaveServiceUseCaseOutput>

  initialize(language: Language, onInitialized?: OnInitializedHandler): Promise<void>

  changeLanguage(language: Language): Promise<boolean>

  createMyCompany(company: ICompanyInputBase): Promise<IMyCompany>

  applyForJob(jobId: string, message: string): Promise<boolean>

  validInvitationToken(token: string): Promise<IInvitationBase>
}

export type FetchAngelsStoreInput = {
  searchWord?: string
  maxInvestmentAmount?: string
  minInvestmentAmount?: string
  investmentTargetMarketIds?: string[]
  investmentTargetRoundIds?: string[]
  locationIds?: string[]
  currencyUnit?: CurrencyUnit
  orderBy?: UserOrderBase
  limit: number
  shouldRefresh: boolean
}

export type SearchUserStoreInput = SearchUserUseCaseInput
export type FetchUserStoreInput = FetchUserUseCaseInput

export type FetchUserArticlesStoreInput = FetchUserArticlesUseCaseInput

export interface IUsersStore {
  angels: IUser[]
  searchResults: IUserBase[]
  searchWord: string
  following: Record<string, IPublicFollowableEntity[]>
  followers: Record<string, IPublicUser[]>
  publicUsers: Record<string, IPublicUser>

  hasNextAngelsPage: boolean
  hasNextFollowingPage: Record<string, boolean>
  hasNextFollowersPage: Record<string, boolean>
  userArticles: Record<string, IArticleBase[]>
  hasNextUserArticlesPage: Record<string, boolean>

  fetchAngels(input: FetchAngelsStoreInput): Promise<IUser[]>

  fetchFeaturedAngels(limit: number): Promise<IUser[]>

  fetchUser(input: FetchUserStoreInput): Promise<IUser>

  searchUser(input: SearchUserStoreInput): Promise<IUserBase[]>

  resetSearchResults(): void

  updateHasNextAngelsPage(hasNextpage: boolean): void

  fetchFollowing(username: string, limit: number, shouldRefresh: boolean): Promise<IFollowableEntityBase[]>

  updateFollowing(username: string, following: IFollowableEntityBase[]): void

  fetchFollowers(username: string, limit: number, shouldRefresh: boolean): Promise<IUserBase[]>

  updateFollowers(username: string, users: IUserBase[]): void

  updateHasNextFollowingPage(username: string, hasNextPage: boolean): void

  updateHasNextFollowersPage(username: string, hasNextPage: boolean): void

  createUserInstance(base: IUserBase): IPublicUser

  fetchUserArticles(input: FetchUserArticlesStoreInput): Promise<IArticleBase[]>

  updateHasNextUserArticlesPage(username: string, hasNextPage: boolean): void
}

export type SearchCompanyStoreInput = SearchCompanyUseCaseInput

export type FetchCompaniesStoreInput = {
  searchWord?: string
  marketIds?: string[]
  maxInvestmentAmount?: string
  minInvestmentAmount?: string
  companySize?: CompanySize
  roundIds?: string[]
  investmentTargetMarketIds?: string[]
  investmentTargetRoundIds?: string[]
  locationIds?: string[]
  currencyUnit?: CurrencyUnit
  orderBy?: CompanyOrderBase
  limit: number
  shouldRefresh: boolean
}

export type FetchCompanyStoreInput = FetchCompanyUseCaseInput

export type FetchLocationsStoreInput = FetchLocationsUseCaseInput

export type FetchPitchesStoreInput = FetchPitchesUseCaseInput

export type FetchPitchStoreInput = FetchPitchUseCaseInput

export type MarkPitchAsReadStoreInput = MarkPitchAsReadUseCaseInput

export type FetchSkillsStoreInput = FetchSkillsUseCaseInput

export type CreateSkillStoreInput = CreateSkillUseCaseInput

export type FetchCompaniesWithJobsStoreInput = {
  searchWord?: string
  marketIds?: string[]
  maxInvestmentAmount?: string
  minInvestmentAmount?: string
  companySize?: CompanySize
  roundIds?: string[]
  investmentTargetMarketIds?: string[]
  investmentTargetRoundIds?: string[]
  companyLocationIds?: string[]
  currencyUnit?: CurrencyUnit
  vcSlug?: string
  angelUsername?: string
  minMonthlySalary?: string
  maxMonthlySalary?: string
  equityGrant?: boolean[]
  typeOfPosition?: JobTypeOfPosition[]
  workExperience?: JobWorkExperience
  workingForm?: JobWorkingForm[]
  jobCategoryIds?: string[]
  skillIds?: string[]
  jobLocationIds?: string[]
  limit: number
  shouldRefresh: boolean
}

export type FetchCompanyArticlesStoreInput = FetchCompanyArticlesUseCaseInput

export interface ICompaniesStore {
  startups: ICompanyBase[]
  ventureCapitals: ICompanyBase[]
  searchResults: ICompanyBase[]
  searchWord: string
  markets: IMarketBase[]
  pitches: IPitchBase[]
  pitch: IPitchBase
  jobCategories: IJobCategoryBase[]
  jobTags: IJobTagBase[]
  companiesWithJobs: ICompanyBase[]
  investmentsWithJobsOfVc: Record<string, ICompanyBase[]>
  investmentsWithJobsOfAngel: Record<string, ICompanyBase[]>
  companyArticles: Record<string, IArticleBase[]>
  followers: Record<string, IPublicUser[]>
  publicCompanies: Record<string, IPublicCompany>

  hasNextStartupsPage: boolean
  hasNextVentureCapitalsPage: boolean
  hasNextCompaniesWithJobsPage: boolean
  hasNextInvestmentJobsOfVcPages: Record<string, boolean>
  hasNextInvestmentJobsOfAngelPages: Record<string, boolean>
  hasNextCompanyArticlesPage: Record<string, boolean>
  hasNextFollowersPage: Record<string, boolean>

  fetchStartups(input: FetchCompaniesStoreInput): Promise<ICompanyBase[]>

  fetchVentureCapitals(input: FetchCompaniesStoreInput): Promise<ICompanyBase[]>

  fetchFeaturedCompaniesWithJobs(input: number): Promise<ICompanyBase[]>

  fetchFeaturedVentureCapitals(limit: number): Promise<ICompanyBase[]>

  fetchFeaturedStartups(limit: number): Promise<ICompanyBase[]>

  fetchCompany(input: FetchCompanyStoreInput): Promise<ICompanyBase>

  createCompany(companyInputBase: ICompanyInputBase): Promise<ICompanyBase>

  searchCompany(input: SearchCompanyStoreInput): Promise<ICompanyBase[]>

  resetSearchResults(): void

  fetchMarkets(): Promise<void>

  fetchCompanyArticles(input: FetchCompanyArticlesStoreInput): Promise<FetchCompanyArticlesUseCaseOutput>

  updateHasNextCompanyArticlesPage(slug: string, hasNextPage: boolean): void

  updateHasNextStartupsPage(hasNextPage: boolean): void

  updateHasNextVentureCapitalsPage(hasNextPage: boolean): void

  fetchLocations(input: FetchLocationsStoreInput): Promise<ILocationBase[]>

  fetchPitches(input: FetchPitchesStoreInput): Promise<void>

  fetchPitch(input: FetchPitchStoreInput): Promise<boolean>

  markPitchAsRead(input: MarkPitchAsReadStoreInput): Promise<boolean>

  findPitchAttachmentByCategory(categoryName: PitchAttachmentCategoryName): IPitchAttachmentBase[]

  fetchJobCategories(): Promise<void>

  fetchJobTags(): Promise<void>

  fetchSkills(input: FetchSkillsStoreInput): Promise<ISkillBase[]>

  createSkill(input: CreateSkillStoreInput): Promise<ISkillBase>

  fetchCompaniesWithJobs(input: FetchCompaniesWithJobsStoreInput): Promise<ICompanyBase[]>

  updateHasNextCompaniesWithJobsPage(hasNextPage: boolean): void

  fetchInvestmentsWithJobsOfVc(input: FetchCompaniesWithJobsStoreInput): Promise<ICompanyBase[]>

  updateHasNextInvestmentJobsOfVcPages(slug: string, hasNextPage: boolean): void

  fetchInvestmentsWithJobsOfAngel(input: FetchCompaniesWithJobsStoreInput): Promise<ICompanyBase[]>

  updateHasNextInvestmentJobsOfAngelPages(slug: string, hasNextPage: boolean): void

  fetchFollowers(slug: string, limit: number, shouldRefresh: boolean): Promise<IUserBase[]>

  updateFollowers(slug: string, users: IUserBase[]): void

  updateHasNextFollowersPage(slug: string, hasNextPage: boolean): void

  createCompanyInstance(base: ICompanyBase): IPublicCompany
}

export interface IArticlesStore {
  storiesArticles: IArticle[]
  publicArticles: Record<string, IPublicArticle>
  featuresArticles: IArticle[]
  opinionsArticles: IArticle[]
  newsArticles: IArticle[]
  interviewsArticles: IArticle[]

  hasNextStoriesPage: boolean
  hasNextFeaturesPage: boolean
  hasNextOpinionsPage: boolean
  hasNextNewsPage: boolean
  hasNextInterviewsPage: boolean

  // ブラウザ側でページ送りの際に追加記事取得用
  fetchArticles(input: { category?: string; limit: number }): Promise<FetchArticlesUseCaseOutput>

  fetchTopPageContents(): Promise<MagazineTopPageContents>

  fetchCategoryArchivePageContents(category: string, limit: number): Promise<MagazineArchivePageContents>

  fetchSinglePageContents(slug: string): Promise<MagazineSinglePageContents>

  fetchFeaturedStories(limit: number): Promise<FetchFeaturedStoriesUseCaseOutput>

  createArticleInstance(base: IArticleBase): IPublicArticle

  fetchArticle(slug: string): Promise<boolean>

  updatePublicArticle(article: IArticleBase): void

  fetchPickedUpFeatures(limit: number): Promise<IArticle[]>
}

export interface IChatStore {
  threads: IChatMessageThread[]
  latestThread: IChatMessageThread
  isInitialized: boolean
  isInitializing: boolean
  hasNextThreadsPage: boolean

  init(): Promise<boolean>

  findOrCreateThread(username: string): Promise<IChatMessageThread>

  findThreadBySlug(slug: string): IChatMessageThread

  fetchThreads(input: { limit: number; shouldRefresh: boolean }): Promise<IUseCaseOutput>
}

export interface IJobApplicationsStore {
  jobApplications: IJobApplication[]
  latestJobApplication: IJobApplication
  isInitialized: boolean
  isInitializing: boolean
  companySlug: string
  messageThreads: IChatMessageThread[]

  numberOfJobApllications: number

  init(companySlug: string): Promise<boolean>

  findJobApplicationByMessageThreadSlug(slug: string): IJobApplication
}

export type FetchCompanyJobsStoreInput = FetchCompanyJobsUseCaseInput

export type FetchJobsStoreInput = FetchJobsUseCaseInput

export type FetchJobStoreInput = FetchJobUseCaseInput

export type AddFootprintToJobStoreInput = AddFootprintToJobUseCaseInput

export interface IJobsStore {
  jobs: IJobBase[]
  companyJobs: Record<string, IJobBase[]>

  hasNextJobsPage: boolean
  hasNextCompanyJobsPage: Record<string, boolean>

  fetchJobs(input: FetchJobsStoreInput): Promise<IJobBase[]>

  fetchCompanyJobs(input: FetchCompanyJobsStoreInput): Promise<IJobBase[]>

  updateHasNextJobsPage(hasNextPage: boolean): void

  updateHasNextCompanyJobsPage(slug: string, hasNextPage: boolean): void

  fetchJob(input: FetchJobStoreInput): Promise<IJobBase>

  addFootprintToJob(input: AddFootprintToJobStoreInput): Promise<IJobBase>

  fetchFeaturedJobs(limit: number): Promise<IJobBase[]>
}

export enum PMSStatus {
  INITIALIZING,
  INITIALIZED,
  INVESTOR_NOT_FOUND,
  VEHICLE_NOT_FOUND,
}

export type PMSInvestorTypeInPath = 'u' | 'c'

export interface IPMSStore {
  investors: IInvestor[] // 初期化済みの Investor を格納
  status: PMSStatus
  rates: Record<TickerSymbol, number>

  initialize(investorType: PMSInvestorTypeInPath, investorSlug: string, vehicleSlug?: string): Promise<void>
  getInvestor(investorType: PMSInvestorTypeInPath, investorSlug: string): IInvestor
  getVehicle(investorType: PMSInvestorTypeInPath, investorSlug: string, vehicleSlug: string): IVehicle
  getInvestorTypeInPath(investorType: VehicleInvestor): PMSInvestorTypeInPath
  getInvestorType(investorTypeInPath: PMSInvestorTypeInPath): VehicleInvestor
}

export interface IForexStore {
  rates: Record<TickerSymbol, number>

  getRate(tickerSymbol: TickerSymbol): number

  fetchLatestForexRate(tickerSymbol: TickerSymbol): Promise<IUseCaseOutput<FetchLatestForexRateUseCaseOutput>>
}

export type FetchSubsidiesStoreInput = FetchSubsidiesUseCaseInput

export type FetchSubsidyStoreInput = FetchSubsidyUseCaseInput

export type FetchLocationStoreInput = FetchLocationUseCaseInput

export type FetchSubsidyPurposeStoreInput = FetchSubsidyPurposeUseCaseInput

export interface ISubsidiesStore {
  subsidies: ISubsidyBase[]
  hasNextSubsidiesPage: boolean
  subsidyPurposes: ISubsidyPurposeBase[]

  updateHasNextSubsidiesPage(hasNextPage: boolean): void

  fetchSubsidies(input: FetchSubsidiesStoreInput): Promise<ISubsidyBase[]>

  fetchSubsidy(input: FetchSubsidyStoreInput): Promise<ISubsidyBase>

  fetchLocation(input: FetchLocationStoreInput): Promise<ILocationBase>

  fetchSubsidyPurposes(): Promise<ISubsidyPurposeBase[]>

  fetchSubsidyPurpose(input: FetchSubsidyPurposeStoreInput): Promise<ISubsidyPurposeBase>
}
