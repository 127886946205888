import { inject, injectable } from 'inversify'
import {
  IAppCredentials,
  IAuthService,
  IErrorTrackingService,
  IPreferences,
  ISignOutUseCase,
  IStorageService,
  SignOutUseCaseInput,
  SignOutUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import { isBrowser } from '@/utils'
import UnauthenticatedError from '@/errors/UnauthenticatedError'

@injectable()
export default class SignOutInteractor implements ISignOutUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IPreferences) private preferences: IPreferences

  @inject(symbols.IStorageService) private storageService: IStorageService

  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  async handle(input?: SignOutUseCaseInput): Promise<SignOutUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.authService.signOut()
    } catch (e) {
      // トークンが不整合の場合はハンドリングしない
      if (!(e instanceof UnauthenticatedError)) {
        output.isSuccessful = false
        // 循環依存対策で AppError 使わずにそのままエラーレポート投げる
        this.errorTrackingService.reportErrorByOriginalInstance(e)
      }
    }

    this.credentials.clear()

    if (isBrowser()) {
      // ログアウト後、確実にメモリをクリアするためにリロードさせる
      const queryString = input?.isInvalidToken ? 'token_expired=true' : 'signed_out=true'
      window.location.href = `/${this.preferences.language}/signin?${queryString}`
    }

    output.isSuccessful = true

    return output
  }

  private getDefaultOutputData(): SignOutUseCaseOutput {
    return {
      isSuccessful: false,
    }
  }
}
