import { action, observable } from 'mobx'
import {
  IErrorsStore,
  IMyReference,
  IUpdateMyReferenceUseCase,
  IUserBase,
  IUserReferenceBase,
  UserReferenceStatus,
} from '@/types'

export default class UserReference implements IMyReference {
  @observable id = ''

  @observable comment = ''

  @observable status: UserReferenceStatus

  @observable commenter?: IUserBase

  errorsStore: IErrorsStore

  updateMyReferenceUseCase: IUpdateMyReferenceUseCase

  @action
  _mapBase(base: IUserReferenceBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IUserReferenceBase) {
    this._mapBase(base)
  }

  async save(status: UserReferenceStatus): Promise<boolean> {
    const output = await this.updateMyReferenceUseCase.handle({ id: this.id, status })
    if (output.error) {
      this.errorsStore.handle(output.error)
      return false
    }

    this._mapBase(output.userReference)
    return true
  }
}
