import { inject, injectable } from 'inversify'
import {
  FetchMagazineArchivePageContentUseCaseInput,
  FetchMagazineArchivePageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMagazineArchivePageContentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMagazineArchivePageContentInteractor implements IFetchMagazineArchivePageContentUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: FetchMagazineArchivePageContentUseCaseInput
  ): Promise<FetchMagazineArchivePageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // TODO: Gateway を改良して1リクエストでランキング取得処理まで対応できるように変更
      const [{ articles }, features] = await Promise.all([
        this.articlesService.fetchArticles({ categories: input.categories, limit: input.limit }),
        this.articlesService.fetchArticles({ categories: ['feature'], limit: 5 }),
      ])
      output.articles = articles
      output.asideContents.dailyRanking = features.articles
      output.asideContents.weeklyRanking = features.articles
      output.asideContents.monthlyRanking = features.articles
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMagazineArchivePageContentUseCaseOutput {
    return {
      articles: [],
      asideContents: {
        dailyRanking: [],
        weeklyRanking: [],
        monthlyRanking: [],
      },
      error: null,
    }
  }
}
