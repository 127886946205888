import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyPitchesUseCaseInput,
  FetchMyPitchesUseCaseOutput,
  IAppErrorFactory,
  IFetchMyPitchesUseCase,
  IPitchesService,
  IPitchFactory,
} from '../../types'

@injectable()
export default class FetchMyPitchesInteractor implements IFetchMyPitchesUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IPitchFactory) private pitchFactory: IPitchFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchMyPitchesUseCaseInput): Promise<FetchMyPitchesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitches = await this.pitchesService.fetchMyPitches(input.companySlug)
      output.pitches = pitches.map((p) => this.pitchFactory.create({ base: p }))
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ラウンドの取得に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMyPitchesUseCaseOutput {
    return {
      pitches: null,
      error: null,
    }
  }
}
