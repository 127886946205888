import { action, observable } from 'mobx'
import { IMessage, IMessageBase, MessageState, MessageType } from '@/types'
import { sleep } from '@/utils'

export default class Message implements IMessage {
  key: string

  type: MessageType

  body: string

  ttl: number

  @observable state: MessageState

  isTranslated: boolean

  isDismissable: boolean

  _onHide: (message: IMessage) => void

  constructor(input: { message: IMessageBase; onHide?: (message: IMessage) => void }) {
    const { message, onHide } = input
    this.key = message.key
    this.type = message.type
    this.body = message.body
    this.ttl = message.ttl
    this.state = MessageState.Invisible
    this.isTranslated = message.isTranslated
    this.isDismissable = message.isDismissable
    this._onHide = onHide

    void this._start()
  }

  private async _start(): Promise<void> {
    await sleep(10) // アニメーション開始までの待ち時間
    this._display()

    // dismissable の場合は UI で削除ボタンを押すまで非表示にしないのでスキップ
    if (this.isDismissable) {
      return
    }

    await sleep(this.ttl)
    this.hide()
  }

  private _display(): void {
    this._setState(MessageState.Visible)
  }

  hide(): void {
    // メッセージを見た目上非表示に
    this._setState(MessageState.Invisible)

    // Store のコールバック（store の配列から削除）
    if (this._onHide) {
      this._onHide(this)
    }
  }

  @action
  private _setState(state: MessageState) {
    this.state = state
  }
}
