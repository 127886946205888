import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IRemoveUserReferenceUseCase,
  IUserReferencesService,
  RemoveUserReferenceUseCaseInput,
  RemoveUserReferenceUseCaseOutput,
} from '../../types'

@injectable()
export default class RemoveUserReferenceInteractor implements IRemoveUserReferenceUseCase {
  @inject(symbols.IUserReferencesService) private userReferencesService: IUserReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveUserReferenceUseCaseInput): Promise<RemoveUserReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.userReference = await this.userReferencesService.removeUserReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの削除に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveUserReferenceUseCaseOutput {
    return {
      userReference: null,
      error: null,
    }
  }
}
