import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  FetchLatestForexRateUseCaseOutput,
  IFetchLatestForexRateUseCase,
  IForexRate,
  IForexStore,
  IUseCaseOutput,
  TickerSymbol,
} from '@/types'
import symbols from '@/symbols'
import { action, observable } from 'mobx'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ForexStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ForexStore implements IForexStore {
  // TODO: 初期値ハードコードでなくサーバーから取ってくるようにする
  @observable rates: Record<TickerSymbol, number> = {
    JPYUSD: 131.63,
    USDJPY: 0.0076,
  }

  @inject(symbols.IFetchLatestForexRateUseCase) private fetchLatestForexRateUseCase: IFetchLatestForexRateUseCase

  @action
  _updateRate(rate: IForexRate): void {
    this.rates[rate.tickerSymbol] = Number(rate.rate)
  }

  async fetchLatestForexRate(tickerSymbol: TickerSymbol): Promise<IUseCaseOutput<FetchLatestForexRateUseCaseOutput>> {
    const output = await this.fetchLatestForexRateUseCase.handle({
      tickerSymbol,
    })

    if (output.isSuccessful) {
      this._updateRate(output.data.forexRate)
    }

    return output
  }

  getRate(tickerSymbol: TickerSymbol): number {
    if (tickerSymbol in this.rates) {
      return this.rates[tickerSymbol]
    }
    return 0
  }
}
