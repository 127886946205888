import { inject, injectable } from 'inversify'
import {
  IErrorsStore,
  IMyReference,
  IUpdateMyReferenceUseCase,
  IUserReferenceFactory,
  UserReferenceFactoryInput,
} from '@/types'
import symbols from '@/symbols'
import UserReference from '@/entities/UserReference'

@injectable()
export default class UserReferenceFactory implements IUserReferenceFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  @inject(symbols.IUpdateMyReferenceUseCase) private updateMyReferenceUseCase: IUpdateMyReferenceUseCase

  public create(input: UserReferenceFactoryInput): IMyReference {
    const userReference = new UserReference(input.base)
    userReference.errorsStore = this.errorStore
    userReference.updateMyReferenceUseCase = this.updateMyReferenceUseCase
    return userReference
  }
}
