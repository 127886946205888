import { inject, injectable } from 'inversify'
import { IPortfolioNote, IPortfolioNoteFactory, IUpdatePortfolioNoteUseCase, PortfolioNoteFactoryInput } from '@/types'
import PortfolioNote from '@/entities/PortfolioNote'
import symbols from '@/symbols'

@injectable()
export default class PortfolioNoteFactory implements IPortfolioNoteFactory {
  @inject(symbols.IUpdatePortfolioNoteUseCase) updatePortfolioNoteUseCase: IUpdatePortfolioNoteUseCase

  public create(input: PortfolioNoteFactoryInput): IPortfolioNote {
    return new PortfolioNote(input.base, {
      updatePortfolioNoteUseCase: this.updatePortfolioNoteUseCase,
    })
  }
}
