import { action, computed, observable } from 'mobx'
import {
  CurrencyUnit,
  IAddPitchAttachmentUseCase,
  IDeletePitchAttachmentUseCase,
  IErrorsStore,
  IMyPitch,
  IMyPitchBase,
  IPitchAttachment,
  IPitchAttachmentBase,
  IPitchAttachmentInputBase,
  IPitchInputBase,
  IPitchReaderBase,
  IRemovePitchAttachmentFromPitchUseCase,
  IUpdatePitchUseCase,
  IUserBase,
  PitchAttachmentCategoryName,
  PitchInstrumentBase,
  PitchPermission,
  PitchStatus,
  PitchValuationCapBase,
} from '@/types'

export default class Pitch implements IMyPitch {
  @observable id = ''

  @observable roundName = ''

  @observable slug = ''

  @observable status: PitchStatus

  @observable permission: PitchPermission

  @observable pitchAttachments: IPitchAttachment[] = []

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable createdBy: IUserBase = null

  @observable pitchReaders: IPitchReaderBase[] = []

  @observable instrument: PitchInstrumentBase

  @observable roundSize: string

  @observable currencyUnit: CurrencyUnit

  @observable valuationCap: string

  @observable valuationCapType: PitchValuationCapBase

  @observable targetClosingDate: string

  @observable description = ''

  errorsStore: IErrorsStore

  updatePitchUseCase: IUpdatePitchUseCase

  addPitchAttachmentUseCase: IAddPitchAttachmentUseCase

  deletePitchAttachmentUseCase: IDeletePitchAttachmentUseCase

  removePitchAttachmentFromPitchUseCase: IRemovePitchAttachmentFromPitchUseCase

  // オプションなファイルを定義
  private _optionalAttachments = [
    PitchAttachmentCategoryName.ARTICLES_OF_INCORPORATION,
    PitchAttachmentCategoryName.CAPITAL_TABLE,
    PitchAttachmentCategoryName.FINANCIAL_PLAN,
    PitchAttachmentCategoryName.PAST_FINANCIAL_STATEMENT,
    PitchAttachmentCategoryName.TERM_SHEET,
    PitchAttachmentCategoryName.OTHERS,
  ]

  @action
  _mapBase(base: IMyPitchBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  _addPitchAttachment(pitchAttachment: IPitchAttachment): void {
    this.pitchAttachments.push(pitchAttachment)
  }

  @action
  _deletePitchAttachment(pitchAttachment: IPitchAttachmentBase): void {
    this.pitchAttachments = this.pitchAttachments.filter((p) => p.id !== pitchAttachment.id)
  }

  constructor(base: IMyPitchBase) {
    this._mapBase(base)
  }

  async save(pitch: IPitchInputBase): Promise<boolean> {
    const output = await this.updatePitchUseCase.handle({
      pitch,
      id: this.id,
    })

    if (output.pitch) {
      this._mapBase(output.pitch)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addPitchAttachment(pitchAttachment: IPitchAttachmentInputBase): Promise<boolean> {
    const output = await this.addPitchAttachmentUseCase.handle({
      pitchAttachment,
      pitchId: this.id,
    })

    if (output.pitchAttachment) {
      this._addPitchAttachment(output.pitchAttachment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async deletePitchAttachment(pitchAttachment: IPitchAttachment): Promise<boolean> {
    const output = await this.deletePitchAttachmentUseCase.handle({
      id: pitchAttachment.id,
    })

    if (output.pitchAttachment) {
      this._deletePitchAttachment(output.pitchAttachment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removePitchAttachmentFromPitch(pitchAttachment: IPitchAttachment): Promise<boolean> {
    const output = await this.removePitchAttachmentFromPitchUseCase.handle({
      pitchId: this.id,
      pitchAttachmentId: pitchAttachment.id,
    })

    if (output.pitchAttachment) {
      this._deletePitchAttachment(output.pitchAttachment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  @computed
  get hasPitchDeck(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.PITCH_DECK)
  }

  @computed
  get hasCapitalTable(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.CAPITAL_TABLE)
  }

  @computed
  get hasFinancialPlan(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.FINANCIAL_PLAN)
  }

  @computed
  get hasTermSheet(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.TERM_SHEET)
  }

  @computed
  get hasArticlesOfIncorporation(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.ARTICLES_OF_INCORPORATION)
  }

  @computed
  get hasPastFinancialStatement(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.PAST_FINANCIAL_STATEMENT)
  }

  @computed
  get hasOthers(): boolean {
    return this.hasPitchAttachment(PitchAttachmentCategoryName.OTHERS)
  }

  @computed
  get hasAllAttachment(): boolean {
    return Object.keys(PitchAttachmentCategoryName).every((key) => {
      const categoryNameType = key as PitchAttachmentCategoryName
      if (this._optionalAttachments.includes(categoryNameType)) {
        return true
      }
      return this.hasPitchAttachment(key as PitchAttachmentCategoryName)
    })
  }

  @computed
  get isStep1Completed(): boolean {
    if (
      this.roundName &&
      this.instrument &&
      this.currencyUnit &&
      this.roundSize &&
      this.valuationCapType &&
      this.valuationCap &&
      this.targetClosingDate
    ) {
      return true
    }
    return false
  }

  @computed
  get isStep2Completed(): boolean {
    return this.hasAllAttachment
  }

  @computed
  get isPublished(): boolean {
    return this.status === PitchStatus.PUBLISHED
  }

  @computed
  get progressRate(): number {
    let count = Object.keys(PitchAttachmentCategoryName).filter((key) => {
      // term_sheet または others はカウントしない
      if (key === PitchAttachmentCategoryName.TERM_SHEET || key === PitchAttachmentCategoryName.OTHERS) {
        return false
      }
      return this.hasPitchAttachment(key as PitchAttachmentCategoryName)
    }).length
    // ラウンド概要が存在する場合加算
    if (this.isStep1Completed) {
      count++
    }
    // status が published の場合加算
    if (this.isPublished) {
      count++
    }

    const maxCount = Object.keys(PitchAttachmentCategoryName).length - 2 + 2 // ピッチの種類(term_sheet と others 以外) + step1 + ステータス

    return Math.floor((count * 100) / maxCount)
  }

  findPitchAttachments(category: PitchAttachmentCategoryName, isFile: boolean): IPitchAttachment[] {
    if (isFile) {
      return this.pitchAttachments.filter((pa) => pa.categoryName === category && pa.file.length > 0)
    }
    return this.pitchAttachments.filter((pa) => pa.categoryName === category && pa.url.length > 0)
  }

  /**
   * 指定されたカテゴリーの pitchAttachment があるかを判定
   * @param category
   */
  private hasPitchAttachment(category: PitchAttachmentCategoryName): boolean {
    return this.pitchAttachments.some((p) => p.categoryName === category)
  }
}
