import { inject, injectable } from 'inversify'
import {
  ExperienceFactoryInput,
  IErrorsStore,
  IExperience,
  IExperienceFactory,
  IUpdateExperienceUseCase,
} from '@/types'
import symbols from '@/symbols'
import Experience from '@/entities/Experience'

@injectable()
export default class ExperienceFactory implements IExperienceFactory {
  @inject(symbols.IUpdateExperienceUseCase) private updateExperienceUseCase: IUpdateExperienceUseCase

  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: ExperienceFactoryInput): IExperience {
    const experience = new Experience(input.base)
    experience.errorsStore = this.errorsStore
    experience.updateExperienceUseCase = this.updateExperienceUseCase
    return experience
  }
}
