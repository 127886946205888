import { injectable } from 'inversify'
import Message from '@/entities/Message'
import { IMessage, IMessageFactory, MessageFactoryInput } from '@/types'
import { getUUID } from '@/utils'

@injectable()
export default class MessageFactory implements IMessageFactory {
  public create(input: MessageFactoryInput): IMessage {
    const { message } = input
    // key がなければ付与
    if (!Object.prototype.hasOwnProperty.call(message, 'key')) {
      input.message.key = getUUID()
    }
    // isTranslated がなければ false を初期値として設定
    if (!Object.prototype.hasOwnProperty.call(message, 'isTranslated')) {
      input.message.isTranslated = false
    }
    // isDismissable がなければ false を初期値として設定
    if (!Object.prototype.hasOwnProperty.call(message, 'isDismissable')) {
      input.message.isDismissable = false
    }
    // ttl がなければ 5000 を初期値として設定
    if (!Object.prototype.hasOwnProperty.call(message, 'ttl')) {
      input.message.ttl = 5000
    }
    return new Message(input)
  }
}
