import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IInvitationsService,
  IValidInvitationTokenUseCase,
  ValidInvitationTokenUseCaseInput,
  ValidInvitationTokenUseCaseOutput,
} from '../../types'

@injectable()
export default class ValidInvitationTokenInteractor implements IValidInvitationTokenUseCase {
  @inject(symbols.IInvitationsService) private invitationsService: IInvitationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: ValidInvitationTokenUseCaseInput): Promise<ValidInvitationTokenUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.invitation = await this.invitationsService.validInvitationToken(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): ValidInvitationTokenUseCaseOutput {
    return {
      invitation: null,
      error: null,
    }
  }
}
