import { inject, injectable } from 'inversify'
import {
  FetchCompanyArticlesUseCaseInput,
  FetchCompanyArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticle,
  IArticlesService,
  IFetchCompanyArticlesUseCase,
  IUseCaseOutputFactory,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class FetchCompanyArticlesInteractor implements IFetchCompanyArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    articles: IArticle[]
    hasNextPage: boolean
  }>

  async handle(input: FetchCompanyArticlesUseCaseInput): Promise<FetchCompanyArticlesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        articles: [],
        hasNextPage: true,
      },
    })
    try {
      const result = await this.articlesService.fetchCompanyArticles(input)

      output.data.articles = result.articles
      output.data.hasNextPage = result.hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
