import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  ICompanyBase,
  IUpdateCompanyNotificationSettingsUseCase,
  IUseCaseOutputFactory,
  IViewerService,
  UpdateCompanyNotificationSettingsUseCaseInput,
  UpdateCompanyNotificationSettingsUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateCompanyNotificationSettingsInteractor implements IUpdateCompanyNotificationSettingsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    company: null | ICompanyBase
  }>

  async handle(
    input: UpdateCompanyNotificationSettingsUseCaseInput
  ): Promise<UpdateCompanyNotificationSettingsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        company: null,
      },
    })
    try {
      output.data.company = await this.viewerService.updateCompanyNotificationSettings(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
