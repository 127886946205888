import { inject, injectable } from 'inversify'
import { IErrorsStore, IJobFootprint, IJobFootprintFactory, JobFootprintFactoryInput } from '@/types'
import symbols from '@/symbols'
import JobFootprint from '@/entities/JobFootprint'

@injectable()
export default class JobFootprintFactory implements IJobFootprintFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: JobFootprintFactoryInput): IJobFootprint {
    const jobFootprint = new JobFootprint(input.base, input.children)

    jobFootprint.errorsStore = this.errorsStore

    return jobFootprint
  }
}
