import { inject, injectable } from 'inversify'
import {
  FetchMagazineTopPageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMagazineTopPageContentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMagazineTopPageContentInteractor implements IFetchMagazineTopPageContentUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchMagazineTopPageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // TODO: Gateway を改良して1リクエストでランキング取得処理と各カテゴリ取得処理まで対応できるように変更
      // 使ってないカテゴリー記事はコメントアウト
      const [stories, features, interviews] = await Promise.all([
        this.articlesService.fetchArticles({ categories: ['stories'], limit: 6 }),
        // this.articlesService.fetchArticles({ categories: ['opinions'], limit: 6 }),
        this.articlesService.fetchArticles({ categories: ['feature'], limit: 6 }),
        // this.articlesService.fetchArticles({ categories: ['news'], limit: 6 }),
        this.articlesService.fetchArticles({ categories: ['interviews'], limit: 6 }),
      ])
      output.stories = stories.articles
      // output.opinions = opinions.articles
      output.features = features.articles
      // output.news = news.articles
      output.interviews = interviews.articles
      output.asideContents.dailyRanking = features.articles
      output.asideContents.weeklyRanking = features.articles
      output.asideContents.monthlyRanking = features.articles
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMagazineTopPageContentUseCaseOutput {
    return {
      pickups: [],
      stories: [],
      opinions: [],
      features: [],
      news: [],
      interviews: [],
      asideContents: {
        dailyRanking: [],
        weeklyRanking: [],
        monthlyRanking: [],
      },
      error: null,
    }
  }
}
