import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IRemoveVehicleMemberUseCase,
  IUseCaseOutputFactory,
  IVehicleMemberBase,
  RemoveVehicleMemberUseCaseInput,
  RemoveVehicleMemberUseCaseOutput,
} from '@/types'

@injectable()
export default class RemoveVehicleMemberInteractor implements IRemoveVehicleMemberUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleMemberBase: IVehicleMemberBase
  }>

  async handle(input: RemoveVehicleMemberUseCaseInput): Promise<RemoveVehicleMemberUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleMemberBase: null,
      },
    })
    try {
      output.data.vehicleMemberBase = await this.pmsService.removeVehicleMember(input.id)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
