import { GraphQLResponse } from 'graphql-request/dist/types'
import {
  AcquisitionChannelBase,
  ArticleAttachmentBase,
  ArticleAttachmentInputBase,
  ArticleBase,
  ArticleInputBase,
  BillingPortalBase,
  CategoryBase,
  CompanyBase,
  CompanyInputBase,
  CompanyMemberBase,
  CompanyMemberInputBase,
  CompanyNotificationBase,
  CompanyReferenceBase,
  CompanyReferenceStatus,
  CurrencyUnit,
  DashboardBase,
  DashboardItemBase,
  ExperienceBase,
  ExperienceInputBase,
  FollowableEntityBase,
  ForexRateBase,
  FundraisingManualBase,
  InvestmentBase,
  InvestmentCommentBase,
  InvestmentInputBase,
  InvestorBase,
  InvestorInputBase,
  InvestorMemberBase,
  InvitationBase,
  InvitationLinkBase,
  JobApplicationBase,
  JobAttachmentBase,
  JobAttachmentInputBase,
  JobBase,
  JobBookmarkBase,
  JobCategoryBase,
  JobFootprintBase,
  JobInputBase,
  JobTagBase,
  LocationBase,
  MarketBase,
  MeBase,
  MessageAttachmentBase,
  MessageBase,
  MessageThreadBase,
  MyCompanyBase,
  MyCompanyMemberBase,
  MyPitchBase,
  NewMessageNotificationBase,
  NotificationBase,
  PaymentIntentBase,
  PitchAttachmentBase,
  PitchAttachmentCategoryName,
  PitchAttachmentInputBase,
  PitchBase,
  PitchInputBase,
  PitchReaderBase,
  PortfolioAttachmentBase,
  PortfolioAttachmentInputBase,
  PortfolioBase,
  PortfolioInputBase,
  PortfolioMeetingBase,
  PortfolioMeetingInputBase,
  PortfolioNoteBase,
  PortfolioNoteInputBase,
  ProductBase,
  ProductInputBase,
  RecruitSubscriptionInputBase,
  RoundBase,
  SkillBase,
  SubsidyBase,
  SubsidyPurposeBase,
  TopicBase,
  UserBase,
  UserNotificationBase,
  UserProfileBase,
  UserProfileInputBase,
  UserReferenceBase,
  UserReferenceStatus,
  VehicleBase,
  VehicleItemBase,
  VehicleItemRoundAttachmentBase,
  VehicleItemRoundAttachmentInputBase,
  VehicleItemRoundBase,
  VehicleItemRoundInputBase,
  VehicleMemberBase,
  VehicleMemberRole,
  VehicleStatusBase,
  VehicleStatusInputBase,
} from '@/lib/generated/sdk'
import {
  AddPortfolioAttachmentUseCaseInput,
  AddPortfolioAttachmentUseCaseOutput,
  AddVehicleItemRoundAttachmentUseCaseOutput,
  AddVehicleMemberUseCaseOutput,
  CreateJobUseCaseOutput,
  CreatePortfolioMeetingUseCaseInput,
  CreatePortfolioMeetingUseCaseOutput,
  CreatePortfolioNoteUseCaseInput,
  CreatePortfolioNoteUseCaseOutput,
  CreateVehicleItemRoundUseCaseOutput,
  CreateVehicleItemUseCaseInput,
  CreateVehicleItemUseCaseOutput,
  CreateVehicleStatusUseCaseOutput,
  CreateVehicleUseCaseOutput,
  DeleteCompanyArticleUseCaseOutput,
  DeleteMyArticleUseCaseOutput,
  DeletePortfolioAttachmentUseCaseInput,
  DeletePortfolioAttachmentUseCaseOutput,
  DeletePortfolioMeetingUseCaseInput,
  DeletePortfolioMeetingUseCaseOutput,
  DeletePortfolioNoteUseCaseInput,
  DeletePortfolioNoteUseCaseOutput,
  DeleteVehicleItemRoundAttachmentUseCaseOutput,
  DeleteVehicleItemRoundUseCaseOutput,
  DeleteVehicleItemUseCaseOutput,
  DeleteVehicleStatusUseCaseOutput,
  DeleteVehicleUseCaseOutput,
  FetchArticleUseCaseOutput,
  FetchJobFootprintsUseCaseInput,
  FetchJobFootprintsUseCaseOutput,
  FetchMyCompanyArticlesUseCaseOutput,
  FetchMyJobBookmarksUseCaseInput,
  FetchMyJobsUseCaseInput,
  FetchMyJobsUseCaseOutput,
  FetchVehicleItemsUseCaseOutput,
  FetchVehicleItemUseCaseOutput,
  RemoveVehicleMemberUseCaseOutput,
  SendChatMessageUseCaseOutput,
  UpdateJobUseCaseOutput,
  UpdatePortfolioMeetingUseCaseOutput,
  UpdatePortfolioNoteUseCaseOutput,
  UpdateVehicleItemAndPortfolioUseCaseOutput,
  UpdateVehicleItemPortfolioUseCaseOutput,
  UpdateVehicleItemRoundUseCaseOutput,
  UpdateVehicleItemUseCaseOutput,
  UpdateVehicleMemberUseCaseOutput,
  UpdateVehicleStatusUseCaseOutput,
  UpdateVehicleUseCaseOutput,
} from './useCases'

export * from '@/lib/generated/sdk'

export type Primitive = number | string | boolean | bigint | symbol | null | undefined

export type IOriginalErrorInstance = Error & {
  response?: GraphQLResponse
  extra?: Record<string, unknown>
}

export interface IBaseAppError {
  // エラー集計などに使う際のための箱を用意
  originalInstance: null | IOriginalErrorInstance
  // UIに表示したいエラーメッセージがあれば設定する
  messageForUI: null | string
  code?: ErrorCode
  tags?: {
    [key: string]: Primitive
  }
  extra?: Record<string, unknown>
}

export enum ErrorCode {
  DEFAULT = 'DEFAULT',
  NO_SUBSCRIPTION_ERROR = 'NO_SUBSCRIPTION_ERROR',
  STRIPE_INVALID_REQUEST = 'STRIPE_INVALID_REQUEST',
  STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT = 'STRIPE_INVALID_PROMOTION_CODE_NOT_FIRST_TIME_PAYMENT',
  NOT_AUTHORIZED_ERROR = 'NOT_AUTHORIZED_ERROR',
  NOT_SIGNED_IN = 'NOT_SIGNED_IN',
  EXISTING_RECORD_ERROR = 'EXISTING_RECORD_ERROR',
  ACTIVE_RECORD_ERROR = 'ACTIVE_RECORD_ERROR',
}

export interface IAppError extends IBaseAppError {
  code: ErrorCode
  message: string
}

export interface IUseCaseOutput<T = any> {
  data: T
  error: null | IAppError
  isSuccessful: boolean
}

export interface IAsyncUseCase<I, O> {
  handle(input: I): Promise<IUseCaseOutput<O>>
}

export interface IAppCredentialsBase {
  accessToken: string
  client: string
  expiry: number
  tokenType: string
  uid: string
}

export interface IAppCredentials extends IAppCredentialsBase {
  isSignedIn: boolean

  update(base: IAppCredentialsBase): void
  updateAccessToken(accessToken: string): void
  restore(): boolean
  sync(): void
  clear(): void
  getLatestCredentials(): IAppCredentialsBase
}

export enum MessageType {
  Info,
  Error,
}

export enum MessageState {
  Visible,
  Invisible,
}

export interface IMessageBase {
  key?: string
  type: MessageType
  body: string
  ttl?: number // 表示時間（msec）
  isTranslated?: boolean
  isDismissable?: boolean
  state?: MessageState
}

export interface IMessage extends IMessageBase {
  hide(): void
}

export enum Language {
  JA = 'ja',
  EN = 'en',
}

export interface IPreferencesBase {
  language: Language
}

export interface IPreferences extends IPreferencesBase {
  restore(): boolean

  changeLanguage(language: Language): void

  update(base: IPreferencesBase): void
}

export type IViewerBase = MeBase

export type FetchNotificationsInput = {
  shouldRefresh: boolean
  limit: number
}

export enum PrimaryUserType {
  STARTUP_FOUNDER = 'Startup Founder',
  FUND_MANAGER = 'Fund Manager',
  JOB_SEEKER = 'Job Seeker',
  ANGEL_INVESTOR = 'Angel Investor',
  UNKNOWN = 'Unknown',
}

export interface IViewer extends IViewerBase {
  investments: IInvestment[]
  experiences: IExperience[]
  allNotifications: INotificationBase[] // ページ送りして取得してきた全ての要素を貯めるので `all` という接頭辞をつける
  hasAllNotificationsNextPage: boolean
  allNotificationsCursor: string
  allArticles: IUserArticle[]
  myJobBookmarks: IJobBookmarkBase[]
  myReferences: IMyReference[]

  userBase: IUserBase

  hasMyJobBookmarksNextPage: boolean

  shownStatusReferences: IMyReference[]

  hiddenStatusReferences: IMyReference[]

  hasSNSAccount: boolean
  hasDesiredEmploymentStatus: boolean
  basicProfileCompletionRate: number
  investorProfileCompletionRate: number
  investmentsCompletionRate: number
  jobSeekerProfileCompletionRate: number
  myProfileCompletionRate: number
  latestNotifications: INotificationBase[]
  hasActivePmsSubscription: boolean
  hasNextMyArticlesPage: boolean
  articlesEndCursor: string
  primaryUserType: PrimaryUserType

  update(base: IViewerBase): void

  updateProfile(profile: IUserProfile): void

  updateName(name: string): void

  updateUsername(username: string): void

  addMyCompany(companyMember: IMyCompanyMember): void

  addInvestorMember(investorMember: IInvestorMemberBase): void

  getMyCompanyBySlug(slug: string): IMyCompany

  isCompanyMember(slug: string): boolean

  addExperience(experience: IExperienceInputBase): Promise<boolean>

  removeExperience(experience: IExperience): Promise<boolean>

  addMyInvestment(investment: IInvestmentInputBase): Promise<boolean>

  removeInvestment(investment: IInvestment): Promise<boolean>

  markAllNotificationsAsRead(): Promise<boolean>

  fetchMyArticles(input: { shouldRefresh: boolean; limit: number }): Promise<FetchMyCompanyArticlesUseCaseOutput>

  addMyArticle(article: IArticleInputBase): Promise<boolean>

  removeMyArticle(id: string): Promise<DeleteMyArticleUseCaseOutput>

  addArticleAttachment(input: IArticleAttachmentInputBase): Promise<IArticleAttachmentBase>

  fetchMyJobBookmarks(input: FetchMyJobBookmarksInput): Promise<boolean>

  addMyJobBookmark(jobId: string): Promise<boolean>

  removeMyJobBookmark(jobId: string): Promise<boolean>

  isInMyJobBookmarks(jobId: string): boolean

  addUserReference(username: string, comment: string): Promise<IUserReferenceBase>

  updateUserReference(id: string, comment: string): Promise<IUserReferenceBase>

  removeUserReference(id: string): Promise<IUserReferenceBase>

  addCompanyReference(slug: string, comment: string): Promise<ICompanyReferenceBase>

  updateCompanyReference(id: string, comment: string): Promise<ICompanyReferenceBase>

  removeCompanyReference(id: string): Promise<ICompanyReferenceBase>

  fetchMyReferences(limit: number): Promise<boolean>

  addAngelInvestmentInvitation(input: IAngelInvestmentInvitationInputBase): Promise<string>

  toggleFollowCompany(slug: string): Promise<ICompanyBase>

  toggleFollowUser(username: string): Promise<IUserBase>

  updateCompanyNotificationSettings(companySlug: string, notificationIds: string[]): Promise<ICompanyBase>

  updateUserNotificationSettings(username: string, notificationIds: string[]): Promise<IUserBase>

  fetchNotifications(input: FetchNotificationsInput): Promise<boolean>
}

// ============================================================
// User
// ============================================================
export type IUserProfileInputBase = UserProfileInputBase
export type IUserProfileBase = UserProfileBase
export type IUserProfile = IUserProfileBase
export type IUserBase = UserBase
export type IUser = IUserBase

export interface IPublicUser extends IUserBase {
  update(base: IUserBase): void
  fetch(): Promise<boolean>
}

// ============================================================
// Company
// ============================================================
export enum RoundType {
  UNKNOWN = '1',
  PRESEED = '2',
  SEED = '3',
  SERIES_A = '4',
  SERIES_B = '5',
  SERIES_C = '6',
  SERIES_D = '7',
  SERIES_E = '8',
  SERIES_F = '9',
  SERIES_G = '10',
  ACQUIRED = '11',
  IPO = '12',
  CLOSED = '13',
  PRESERIES_A = '14',
}

export type IRoundBase = RoundBase
export type IRound = IRoundBase

export type ICompanyBase = CompanyBase
export type ICompanyInputBase = CompanyInputBase
export type IMyCompanyBase = MyCompanyBase

export interface IPublicCompany extends ICompanyBase {
  update(base: ICompanyBase): void
  fetch(): Promise<boolean>
}

export interface IMyCompany extends IMyCompanyBase {
  products: IProduct[]
  investments: IInvestment[]
  companyMembers: IMyCompanyMember[]
  allArticles: ICompanyArticle[]
  markets: IMarketBase[]
  investmentTargetMarkets: IMarketBase[]
  investmentTargetRounds: IRoundBase[]
  locations: ILocationBase[]
  pitches: IMyPitch[]
  allJobs: IJob[]
  allJobFootprints: IJobFootprintBase[]
  uniqueJobFootprints: IJobFootprint[]
  hasNextJobFootprintsPage: boolean
  receivedInvestments: IInvestment[]
  allJobBookmarks: IJobBookmarkBase[]
  myCompanyReferences: IMyCompanyReference[]
  hasNextMyCompanyArticlesPage: boolean
  articlesEndCursor: string
  hasNextMyJobsPage: boolean

  hasActiveRecruitSubscription: boolean
  hasActivePmsSubscription: boolean

  ownerAndRecruiters: IMyCompanyMember[]

  shownStatusReferences: IMyCompanyReference[]

  hiddenStatusReferences: IMyCompanyReference[]

  hasSNSAccount: boolean
  basicProfileCompletionRate: number
  startupProfileCompletionRate: number
  vcProfileCompletionRate: number
  companyProfileCompletionRate: number

  getNumberOfInvestorsByPitchSlug(slug: string): number

  getInvestorsByPitchSlug(slug: string): IPitchReaderBase[]

  save(company: ICompanyInputBase): Promise<boolean>

  addProduct(productBase: IProductInputBase): Promise<boolean>

  removeProduct(product: IProduct): Promise<boolean>

  addCompanyInvestment(input: IInvestmentInputBase): Promise<boolean>

  addInvestor(input: IInvestorInputBase): Promise<boolean>

  removeInvestment(product: IInvestment): Promise<boolean>

  removeInvestor(product: IInvestment): Promise<boolean>

  addMember(companyMember: ICompanyMemberInputBase): Promise<boolean>

  removeMember(companyMember: IMyCompanyMember): Promise<boolean>

  addCompanyArticle(article: IArticleInputBase): Promise<boolean>

  removeCompanyArticle(id: string): Promise<DeleteCompanyArticleUseCaseOutput>

  addArticleAttachment(input: IArticleAttachmentInputBase): Promise<IArticleAttachmentBase>

  fetchMyPitches(): Promise<boolean>

  createOrUpdatePitch(input: IPitchInputBase, id: string): Promise<IMyPitch>

  createPitch(pitchBase: IPitchInputBase): Promise<boolean>

  createEmptyDraftPitch(): IMyPitch

  deletePitch(pitch: IMyPitch): Promise<boolean>

  fetchMyJobs(input: FetchMyJobsUseCaseInput): Promise<FetchMyJobsUseCaseOutput>

  fetchJobFootprints(input: FetchJobFootprintsUseCaseInput): Promise<FetchJobFootprintsUseCaseOutput>

  createJob(jobBase: IJobInputBase): Promise<CreateJobUseCaseOutput>

  duplicateJob(jobId: string): Promise<boolean>

  deleteJob(jobId: string): Promise<boolean>

  fetchJobBookmarks(): Promise<boolean>

  addJobAttachment(input: IJobAttachmentInputBase): Promise<IJobAttachmentBase>

  createBillingPortalSession(): Promise<BillingPortalBase>

  createRecruitSubscription(input: RecruitSubscriptionInputBase): Promise<PaymentIntentBase>

  addAngelInvitation(input: IAngelInvitationInputBase): Promise<string>

  addVcInvitation(input: IVcInvitationInputBase): Promise<string>

  addVcInvestmentInvitation(input: IVcInvestmentInvitationInputBase): Promise<string>

  addMemberInvitation(input: IMemberInvitationInputBase): Promise<string>

  fetchMyCompanyReferences(limit: number): Promise<boolean>

  fetchArticles(input: { shouldRefresh: boolean; limit: number }): Promise<FetchMyCompanyArticlesUseCaseOutput>
}

export type ICompanyMemberBase = CompanyMemberBase
export type ICompanyMemberInputBase = CompanyMemberInputBase
export type IMyCompanyMemberBase = MyCompanyMemberBase

export interface IMyCompanyMember extends IMyCompanyMemberBase {
  roleName: string

  company: IMyCompany

  save(companyMember: ICompanyMemberInputBase): Promise<boolean>
}

// ============================================================
// Experience
// ============================================================
// TODO: サーバー・フロント両方 status を enum に変更
export type IExperienceInputBase = ExperienceInputBase
export type IExperienceBase = ExperienceBase

export interface IExperience extends IExperienceBase {
  base: IExperienceBase
  inputBase: IExperienceInputBase

  save(experience: IExperienceInputBase): Promise<boolean>
}

// ============================================================
// Product
// ============================================================
export type IProductBase = ProductBase
export type IProductInputBase = ProductInputBase

export interface IProduct extends IProductBase {
  id: string

  save(product: IProductInputBase): Promise<boolean>
}

// ============================================================
// Investment
// ============================================================
export type IInvestmentBase = InvestmentBase
export type IInvestmentInputBase = InvestmentInputBase

export type IInvestorInputBase = InvestorInputBase
export enum InvestorType {
  ANGEL = 'angel',
  VC = 'vc',
}

export interface IInvestment extends IInvestmentBase {
  save(investment: IInvestmentInputBase): Promise<boolean>
  saved_by_startup(investment: IInvestorInputBase): Promise<boolean>
}

// ============================================================
// InvestmentComment
// ============================================================
export type IInvestmentCommentBase = InvestmentCommentBase
export type IInvestmentComment = IInvestmentCommentBase

// ============================================================
// Market
// ============================================================
export type IMarketBase = MarketBase
export type IMarket = IMarketBase

// ============================================================
// Article
// ============================================================
export type IArticleCategoryBase = CategoryBase
export type IArticleTopicBase = TopicBase
export type IFundraisingManualBase = FundraisingManualBase
export type IArticleBase = ArticleBase
export type IArticleInputBase = ArticleInputBase
export type IArticleCategory = IArticleCategoryBase
export type IArticleTopic = IArticleTopicBase
export type IArticle = IArticleBase

export interface IPublicArticle extends IArticleBase {
  isDraftLiked: boolean

  toggleArticleLikedState(): Promise<boolean>
  update(base: IArticleBase): void
  draftToggleArticleLikedState(): void
  fetch(): Promise<FetchArticleUseCaseOutput>
}

export interface ICompanyArticle extends IArticleBase {
  statusName: string
  isPublished: boolean

  save(article: IArticleInputBase): Promise<boolean>
}

export interface IUserArticle extends IArticleBase {
  statusName: string
  isPublished: boolean

  save(article: IArticleInputBase): Promise<boolean>
}

export type MagazineAsideContents = {
  dailyRanking: IArticle[]
  weeklyRanking: IArticle[]
  monthlyRanking: IArticle[]
}

export type MagazineTopPageContents = {
  pickups: IArticle[]
  stories: IArticle[]
  opinions: IArticle[]
  news: IArticle[]
  features: IArticle[]
  interviews: IArticle[]
  asideContents: MagazineAsideContents
}

export type MagazineArchivePageContents = {
  articles: IArticle[]
  asideContents: MagazineAsideContents
}

export type MagazineSinglePageContents = {
  article: IArticle
  asideContents: MagazineAsideContents
  recommendedArticles: IArticle[]
}

export type CompanyArticleSinglePageContents = {
  article: IArticle
  recommendedArticles: IArticle[]
}

export type UserArticleSinglePageContents = {
  article: IArticle
  recommendedArticles: IArticle[]
}

// ============================================================
// ArticleAttachment
// ============================================================
export type IArticleAttachmentBase = ArticleAttachmentBase
export type IArticleAttachmentInputBase = ArticleAttachmentInputBase

// ============================================================
// Chat
// ============================================================
// Message という名前は Toast で使ってしまってるので Chat を接頭辞につける
export type IChatMessageBase = MessageBase
export type IChatMessage = IChatMessageBase

export interface QueueForSendingMessage {
  deduplicationId: string
  body: string
  messageAttachments?: IMessageAttachment[]
}

export type IChatMessageThreadBase = MessageThreadBase

export interface IChatMessageThread extends IChatMessageThreadBase {
  allMessages: IChatMessage[] // ページ送りして取得してきた全ての要素を貯めるので `all` という接頭辞をつける
  hasUnreadMessage: boolean
  latestMessage: IChatMessage

  // 送信するメッセージは一旦ここに格納し、webSocket でデータが返ったときに削除する
  queueForSendingMessages: QueueForSendingMessage[]

  hasNextMessagesPage: boolean
  isInitialized: boolean // 最初の追加読み込みが終わっているか(ページ送りではなく)

  draftText: string
  draftAttachments: IMessageAttachment[]

  viewer: IViewer

  updateDraftText(draftText: string): void
  resetDraftText(): void

  addDraftAttachment(draftAttachment: IMessageAttachment): void
  removeDraftAttachment(draftAttachment: IMessageAttachment): void
  resetDraftAttachments(): void

  updateIsInitialized(isInitialized: boolean): void

  sendMessage(input: IChatMessageInputBase): SendChatMessageUseCaseOutput

  fetchChatMessages(input: { shouldRefresh: boolean }): Promise<boolean>

  getUserToTalkWith(myUsername: string): IUserBase

  markAllMessagesAsRead(input: { reader: IUserBase }): Promise<boolean>
}

export enum ChatMessageBroadCastingType {
  CREATED = 'created',
  UPDATED = 'updated',
}

export type IMessageAttachment = MessageAttachmentBase

export interface IChatMessageInputBase {
  messageBody: string
  messageFiles: IMessageAttachment[]
}

// ============================================================
// Notifications
// ============================================================
export type INotificationBase = NotificationBase
export type INotification = INotificationBase
export type NewMessageNotification = NewMessageNotificationBase

// ============================================================
// Locations
// ============================================================
export type ILocationBase = LocationBase

export type ILocation = ILocationBase

// ============================================================
// Pitches
// ============================================================
export type IPitchReaderBase = PitchReaderBase
export type IPitchBase = PitchBase
export type IPitchInputBase = PitchInputBase
export type IMyPitchBase = MyPitchBase

export interface IMyPitch extends IMyPitchBase {
  pitchAttachments: IPitchAttachment[]
  hasPitchDeck: boolean
  hasCapitalTable: boolean
  hasFinancialPlan: boolean
  hasTermSheet: boolean
  hasArticlesOfIncorporation: boolean
  hasPastFinancialStatement: boolean
  hasOthers: boolean
  hasAllAttachment: boolean
  isStep1Completed: boolean
  isStep2Completed: boolean
  isPublished: boolean
  progressRate: number

  findPitchAttachments(category: PitchAttachmentCategoryName, isFile: boolean): IPitchAttachment[]

  save(pitch: IPitchInputBase): Promise<boolean>

  addPitchAttachment(pitchAttachment: IPitchAttachmentInputBase): Promise<boolean>

  deletePitchAttachment(pitchAttachment: IPitchAttachment): Promise<boolean>

  removePitchAttachmentFromPitch(pitchAttachment: IPitchAttachment): Promise<boolean>
}

export type IPitchAttachmentBase = PitchAttachmentBase
export type IPitchAttachmentInputBase = PitchAttachmentInputBase

export interface IPitchAttachment extends IPitchAttachmentBase {
  save(pitchAttachment: IPitchAttachmentInputBase): Promise<boolean>
}

// ============================================================
// JobCategories
// ============================================================
export type IJobCategoryBase = JobCategoryBase
export type IJobCategory = IJobCategoryBase

// ============================================================
// JobTag
// ============================================================
export type IJobTagBase = JobTagBase
export type IJobTag = IJobTagBase

// ============================================================
// Skills
// ============================================================
export type ISkillBase = SkillBase
export type ISkill = ISkillBase

// ============================================================
// Jobs
// ============================================================
export enum EquityGrant {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type IJobBase = JobBase
export type IJobInputBase = JobInputBase
export interface IJob extends IJobBase {
  statusName: string
  isPublished: boolean

  save(job: IJobInputBase): Promise<UpdateJobUseCaseOutput>
}

// ============================================================
// JobAttachment
// ============================================================
export type IJobAttachmentBase = JobAttachmentBase
export type IJobAttachmentInputBase = JobAttachmentInputBase

// ============================================================
// JobApplication
// ============================================================
export type IJobApplicationBase = JobApplicationBase
export interface IJobApplication extends IJobApplicationBase {
  messageThread: IChatMessageThread
}

export type PaymentInfo = {
  couponCode: string
  companyName: string
  representativeName: string
  representativeRuby: string
  representativePosition: string
  email: string
  phoneNumber: string
}

// ============================================================
// JobBookmark
// ============================================================
export type IJobBookmarkBase = JobBookmarkBase
export type FetchMyJobBookmarksInput = FetchMyJobBookmarksUseCaseInput

// ============================================================
// Invitation
// ============================================================
export type IInvitationBase = InvitationBase
export type IInvitationLinkBase = InvitationLinkBase
export type IAngelInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IVcInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IAngelInvestmentInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IVcInvestmentInvitationInputBase = {
  days?: number
  emails?: string[]
}
export type IMemberInvitationInputBase = {
  days?: number
  emails?: string[]
}

// ============================================================
// JobFootprint
// ============================================================
export type IJobFootprintBase = JobFootprintBase
export interface IJobFootprint extends IJobFootprintBase {
  children: IJobFootprintBase[]
}

// ============================================================
// UserReference
// ============================================================
export type IUserReferenceBase = UserReferenceBase
export interface IMyReference extends IUserReferenceBase {
  save(status: UserReferenceStatus): Promise<boolean>
}

// ============================================================
// CompanyReference
// ============================================================
export type ICompanyReferenceBase = CompanyReferenceBase
export interface IMyCompanyReference extends ICompanyReferenceBase {
  save(status: CompanyReferenceStatus): Promise<boolean>
}

// ============================================================
// Follow, Follower
// ============================================================
export type IFollowableEntityBase = FollowableEntityBase
export interface IPublicFollowableEntity extends IFollowableEntityBase {
  update(base: IFollowableEntityBase): void
  updateFromUser(base: IUserBase): void
  updateFromCompany(base: ICompanyBase): void
}

// ============================================================
// UserNotification
// ============================================================
export type IUserNotificationBase = UserNotificationBase

export enum UserNotificationType {
  JobSeekingStatusUpdated = '1',
}

// ============================================================
// CompanyNotification
// ============================================================
export type ICompanyNotificationBase = CompanyNotificationBase

export enum CompanyNotificationType {
  FundraisingStatusUpdated = '1',
  NewJobOpening = '2',
}

// ============================================================
// PMS
// ============================================================
export type IInvestorMemberBase = InvestorMemberBase
export type IVehicleBase = VehicleBase
export type IInvestorBase = InvestorBase
export type IVehicleItemBase = VehicleItemBase
export type IVehicleStatusBase = VehicleStatusBase
export type IVehicleStatusInputBase = VehicleStatusInputBase
export type IAcquisitionChannelBase = AcquisitionChannelBase
export type IVehicleItemRoundBase = VehicleItemRoundBase
export type IPortfolioBase = PortfolioBase
export type IPortfolioInputBase = PortfolioInputBase
export type IPortfolioNoteBase = PortfolioNoteBase
export type IPortfolioNoteInputBase = PortfolioNoteInputBase
export type IPortfolioAttachmentBase = PortfolioAttachmentBase
export type IPortfolioAttachmentInputBase = PortfolioAttachmentInputBase
export type IPortfolioMeetingBase = PortfolioMeetingBase
export type IPortfolioMeetingInputBase = PortfolioMeetingInputBase
export type IVehicleItemRoundAttachmentBase = VehicleItemRoundAttachmentBase
export type IVehicleDashboardBase = DashboardBase
export type IVehicleDashboard = IVehicleDashboardBase
export type IVehicleDashboardItemBase = DashboardItemBase
export type IVehicleDashboardItem = IVehicleDashboardItemBase
export type IVehicleItemRoundInput = VehicleItemRoundInputBase
export type IVehicleItemRoundAttachmentInputBase = VehicleItemRoundAttachmentInputBase
export type IVehicleMemberBase = VehicleMemberBase

export interface IVehicleStatus extends IVehicleStatusBase {
  isDefault: boolean
  isInvested: boolean
  update(base: IVehicleStatusBase): void
  save(input: IVehicleStatusInputBase): Promise<UpdateVehicleStatusUseCaseOutput>
}

export interface IVehicleMember extends IVehicleMemberBase {
  update(base: IVehicleMemberBase): void
  save(role: VehicleMemberRole): Promise<UpdateVehicleMemberUseCaseOutput>
}

export interface IVehicleItemRound extends IVehicleItemRoundBase {
  base: IVehicleItemRoundBase
  save(input: IVehicleItemRoundInput): Promise<UpdateVehicleItemRoundUseCaseOutput>
  update(base: IVehicleItemRoundBase): void
  addAttachment(input: IVehicleItemRoundAttachmentInputBase): Promise<AddVehicleItemRoundAttachmentUseCaseOutput>
  deleteAttachment(id: string): Promise<DeleteVehicleItemRoundAttachmentUseCaseOutput>
}

export interface IPortfolioMeeting extends IPortfolioMeetingBase {
  shouldScrollTo: boolean
  updateShouldScrollTo(shouldScrollTo: boolean): void
  save(input: {
    vehicleItemId: string
    portfolioMeeting: IPortfolioMeetingInputBase
  }): Promise<UpdatePortfolioMeetingUseCaseOutput>
  update(base: IPortfolioMeetingBase): void
}
export interface IPortfolioNote extends IPortfolioNoteBase {
  save(input: {
    vehicleItemId: string
    portfolioNote: IPortfolioNoteInputBase
  }): Promise<UpdatePortfolioNoteUseCaseOutput>
  update(base: IPortfolioNoteBase): void
}
export interface IPortfolio extends IPortfolioBase {
  base: IPortfolioBase
  allPortfolioNotes: IPortfolioNote[]
  allPortfolioMeetings: IPortfolioMeeting[]
  portfolioTodoMeetings: IPortfolioMeeting[]
  portfolioDoneMeetings: IPortfolioMeeting[]
  save(input: {
    vehicleItemId: string
    portfolio: IPortfolioInputBase
  }): Promise<UpdateVehicleItemPortfolioUseCaseOutput>
  update(base: IPortfolioBase): void
  addPortfolioNote(input: CreatePortfolioNoteUseCaseInput): Promise<CreatePortfolioNoteUseCaseOutput>
  deletePortfolioNote(input: DeletePortfolioNoteUseCaseInput): Promise<DeletePortfolioNoteUseCaseOutput>
  addPortfolioMeeting(input: CreatePortfolioMeetingUseCaseInput): Promise<CreatePortfolioMeetingUseCaseOutput>
  deletePortfolioMeeting(input: DeletePortfolioMeetingUseCaseInput): Promise<DeletePortfolioMeetingUseCaseOutput>
  addPortfolioAttachment(input: AddPortfolioAttachmentUseCaseInput): Promise<AddPortfolioAttachmentUseCaseOutput>
  deletePortfolioAttachment(
    input: DeletePortfolioAttachmentUseCaseInput
  ): Promise<DeletePortfolioAttachmentUseCaseOutput>
}
export interface IVehicleItem extends IVehicleItemBase {
  base: IVehicleItemBase
  companyName: string
  companyUrl: string
  vehicleItemRounds?: IVehicleItemRound[]
  portfolio?: IPortfolio
  save(input: {
    vehicleStatus: IVehicleStatusBase
    weighting: string
    captainUserId: string
  }): Promise<UpdateVehicleItemUseCaseOutput>
  saveWithPortfolio(input: {
    vehicleStatus: IVehicleStatusBase
    weighting: string
    captainUserId: string
    portfolio: IPortfolioInputBase
  }): Promise<UpdateVehicleItemAndPortfolioUseCaseOutput>
  updateBase(base: IVehicleItemBase): void
  addRound(round: IVehicleItemRoundInput): Promise<CreateVehicleItemRoundUseCaseOutput>
  deleteRound(id: string): Promise<DeleteVehicleItemRoundUseCaseOutput>
  fetch(): Promise<FetchVehicleItemUseCaseOutput>
}

export type FetchVehicleItemsInput = {
  vehicleStatusIds: string[]
  shouldRefresh: boolean
  limit?: number
}

export type SearchVehicleItemsInput = {
  vehicleStatusIds: string[]
  cursor: string
  query?: string
  shouldRefresh: boolean
  limit?: number
}

export type SearchVehicleItemsOutput = IUseCaseOutput<{
  vehicleItems: IVehicleItem[]
  cursor: string
  hasNextPage: boolean
}>

export type IVehicleInput = {
  name: string
  currencyUnit: CurrencyUnit
}

export type AddVehicleMemberInput = {
  userId: string
  role: VehicleMemberRole
}

export interface IVehicle extends IVehicleBase {
  isInitialized: boolean
  vehicleItemsNextPageExistingFlags: Record<string, boolean>
  vehicleItemsNextPageCursors: Record<string, string>
  allVehicleItems: IVehicleItem[]
  defaultVehicleItems: IVehicleItem[]
  investedVehicleItems: IVehicleItem[]
  vehicleStatuses: IVehicleStatus[]
  sortedVehicleStatuses: IVehicleStatus[]
  defaultVehicleStatusIds: string[]
  investedVehicleStatusIds: string[]
  vehicleDashboards: IVehicleDashboard[]
  vehicleMembers: IVehicleMember[]
  getVehicleItem(vehicleItemId: string): IVehicleItem
  getVehicleItems(vehicleStatusId: string): IVehicleItem[]
  getVehicleStatus(vehicleStatusId: string): IVehicleStatus
  update(vehicleBase: IVehicleBase): void
  initialize(): Promise<void>
  save(input: IVehicleInput): Promise<UpdateVehicleUseCaseOutput>
  addVehicleItem(input: CreateVehicleItemUseCaseInput): Promise<CreateVehicleItemUseCaseOutput>
  deleteVehicleItem(vehicleItemId: string): Promise<DeleteVehicleItemUseCaseOutput>
  fetchVehicleItems(input: FetchVehicleItemsInput): Promise<FetchVehicleItemsUseCaseOutput>
  searchVehicleItems(input: SearchVehicleItemsInput): Promise<SearchVehicleItemsOutput>
  addVehicleMember(input: AddVehicleMemberInput): Promise<AddVehicleMemberUseCaseOutput>
  removeVehicleMember(id: string): Promise<RemoveVehicleMemberUseCaseOutput>
  fetchVehicleAnalytics(): Promise<boolean>
  createVehicleStatus(input: IVehicleStatusInputBase): Promise<CreateVehicleStatusUseCaseOutput>
  deleteVehicleStatus(id: string): Promise<DeleteVehicleStatusUseCaseOutput>
}

export type IInvestorCreateVehicleInput = {
  name: string
  currencyUnit: CurrencyUnit
}

export interface IInvestor extends InvestorBase {
  name: string
  vehicles: IVehicle[]
  initializeVehicle(vehicleSlug: string): Promise<boolean>
  getVehicle(vehicleSlug: string): IVehicle
  createVehicle(input: IInvestorCreateVehicleInput): Promise<CreateVehicleUseCaseOutput>
  deleteVehicle(id: string): Promise<DeleteVehicleUseCaseOutput>
}

// ============================================================
// Forex
// ============================================================
export type IForexRateBase = ForexRateBase
export type IForexRate = IForexRateBase

// ============================================================
// Subsidies
// ============================================================
export type ISubsidyBase = SubsidyBase
export type ISubsidy = ISubsidyBase

// ============================================================
// SubsidyPurpose
// ============================================================
export type ISubsidyPurposeBase = SubsidyPurposeBase
export type ISubsidyPurpose = ISubsidyPurposeBase
