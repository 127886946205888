import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddVcInvitationUseCaseInput,
  AddVcInvitationUseCaseOutput,
  IAddVcInvitationUseCase,
  IAppErrorFactory,
  IInvitationsService,
} from '../../types'

@injectable()
export default class AddVcInvitationInteractor implements IAddVcInvitationUseCase {
  @inject(symbols.IInvitationsService) private invitationsService: IInvitationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddVcInvitationUseCaseInput): Promise<AddVcInvitationUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.invitationLink = await this.invitationsService.addVcInvitation(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: {
            companyId: input.companyId,
            days: input.days,
          },
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): AddVcInvitationUseCaseOutput {
    return {
      invitationLink: null,
      error: null,
    }
  }
}
