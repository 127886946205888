import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  IRemoveCompanyMemberUseCase,
  RemoveCompanyMemberUseCaseInput,
  RemoveCompanyMemberUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class RemoveCompanyMemberInteractor implements IRemoveCompanyMemberUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveCompanyMemberUseCaseInput): Promise<RemoveCompanyMemberUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companyMember = await this.companiesService.removeCompanyMember(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'メンバーの削除に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveCompanyMemberUseCaseOutput {
    return {
      companyMember: null,
      error: null,
    }
  }
}
