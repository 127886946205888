import { inject, injectable } from 'inversify'
import PublicUser from '@/entities/PublicUser'
import symbols from '@/symbols'
import { IFetchUserForViewerUseCase, IPublicUser, IPublicUserFactory, PublicUserFactoryInput } from '@/types'

@injectable()
export default class PublicUserFactory implements IPublicUserFactory {
  @inject(symbols.IFetchUserForViewerUseCase) private fetchUserForViewerUseCase: IFetchUserForViewerUseCase

  public create(input: PublicUserFactoryInput): IPublicUser {
    const publicUser = new PublicUser(input.base, {
      fetchUserForViewerUseCase: this.fetchUserForViewerUseCase,
    })

    return publicUser
  }
}
