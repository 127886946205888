import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IFetchSubsidyPurposeUseCase,
  FetchSubsidyPurposeUseCaseInput,
  FetchSubsidyPurposeUseCaseOutput,
  IAppErrorFactory,
  ISubsidiesService,
  ISubsidyPurposeBase,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchSubsidyPurposeInteractor implements IFetchSubsidyPurposeUseCase {
  @inject(symbols.ISubsidiesService) private subsidiesService: ISubsidiesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    subsidyPurpose: null | ISubsidyPurposeBase
  }>

  async handle(input: FetchSubsidyPurposeUseCaseInput): Promise<FetchSubsidyPurposeUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        subsidyPurpose: null,
      },
    })
    try {
      output.data.subsidyPurpose = await this.subsidiesService.fetchSubsidyPurpose(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
