import { action, computed, observable } from 'mobx'
import {
  ArticleStatus,
  IArticleBase,
  IArticleCategory,
  IArticleInputBase,
  IArticleTopic,
  ICompanyBase,
  IErrorsStore,
  IFundraisingManualBase,
  IUpdateMyArticleUseCase,
  IUserArticle,
} from '@/types'
import { DEFAULT_ARTICLE_THUMBNAIL_PATH } from '@/constants'

export default class UserArticle implements IUserArticle {
  @observable id = ''

  @observable title = ''

  @observable slug = ''

  @observable content = ''

  @observable description = ''

  @observable _ogImage = ''

  @observable status = null

  @observable _thumbnail = ''

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable originalArticleUrl = ''

  @observable originalSiteName = ''

  @observable category: IArticleCategory

  @observable topics: IArticleTopic[] = []

  @observable company: ICompanyBase

  @observable fundraisingManualFields: IFundraisingManualBase

  errorsStore: IErrorsStore

  updateMyArticleUseCase: IUpdateMyArticleUseCase

  @computed
  get ogImage(): string {
    if (this._ogImage) {
      return this._ogImage
    }

    return DEFAULT_ARTICLE_THUMBNAIL_PATH
  }

  @computed
  get thumbnail(): string {
    if (this._thumbnail) {
      return this._thumbnail
    }

    return DEFAULT_ARTICLE_THUMBNAIL_PATH
  }

  @computed
  get statusName(): string {
    if (this.status === ArticleStatus.DRAFT) {
      return '下書き'
    }
    if (this.status === ArticleStatus.PUBLISHED) {
      return '公開済み'
    }
    if (this.status === ArticleStatus.CLOSED) {
      return '非公開'
    }

    return ''
  }

  @computed
  get isPublished(): boolean {
    if (this.status === ArticleStatus.PUBLISHED) {
      return true
    }

    return false
  }

  @action
  _mapFromBase(base: IArticleBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      if (key === 'ogImage') {
        this._ogImage = base[key]
      } else if (key === 'thumbnail') {
        this._thumbnail = base[key]
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this[key] = base[key]
      }
    })
  }

  constructor(base: IArticleBase) {
    this._mapFromBase(base)
  }

  async save(article: IArticleInputBase): Promise<boolean> {
    const output = await this.updateMyArticleUseCase.handle({ id: this.id, article })

    if (output.article) {
      this._mapFromBase(output.article)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
