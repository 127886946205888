import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  DeletePortfolioNoteUseCaseInput,
  DeletePortfolioNoteUseCaseOutput,
  IAppErrorFactory,
  IDeletePortfolioNoteUseCase,
  IPMSService,
  IPortfolioNoteBase,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class DeletePortfolioNoteInteractor implements IDeletePortfolioNoteUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    portfolioNote: IPortfolioNoteBase
  }>

  async handle(input: DeletePortfolioNoteUseCaseInput): Promise<DeletePortfolioNoteUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        portfolioNote: null,
      },
    })
    try {
      output.data.portfolioNote = await this.pmsService.deleteVehicleItemPortfolioNote(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
