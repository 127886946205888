import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IArticlesService,
  IUpdateMyArticleUseCase,
  UpdateMyArticleUseCaseInput,
  UpdateMyArticleUseCaseOutput,
} from '@/types'
import { filterBase64 } from '@/utils'
import symbols from '@/symbols'

@injectable()
export default class UpdateMyArticleInteractor implements IUpdateMyArticleUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateMyArticleUseCaseInput): Promise<UpdateMyArticleUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.article = await this.articlesService.updateMyArticle(input)
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.article.thumbnail = filterBase64(input.article.thumbnail)
      filteredInput.article.ogImage = filterBase64(input.article.ogImage)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateMyArticleUseCaseOutput {
    return {
      article: null,
      error: null,
    }
  }
}
