import { inject, injectable } from 'inversify'
import PublicCompany from '@/entities/PublicCompany'
import symbols from '@/symbols'
import {
  IFetchCompanyForViewerUseCase,
  IPublicCompany,
  IPublicCompanyFactory,
  PublicCompanyFactoryInput,
} from '@/types'

@injectable()
export default class PublicCompanyFactory implements IPublicCompanyFactory {
  @inject(symbols.IFetchCompanyForViewerUseCase) private fetchCompanyForViewerUseCase: IFetchCompanyForViewerUseCase

  public create(input: PublicCompanyFactoryInput): IPublicCompany {
    const publicCompany = new PublicCompany(input.base, {
      fetchCompanyForViewerUseCase: this.fetchCompanyForViewerUseCase,
    })

    return publicCompany
  }
}
