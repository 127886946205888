import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import { action, observable } from 'mobx'
import symbols from '@/symbols'
import { IAppError, IAppErrorFactory, IBaseAppError, IErrorsStore, IMessagesStore, MessageType } from '@/types'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ErrorsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ErrorsStore implements IErrorsStore {
  @inject(symbols.IMessagesStore) private messagesStore: IMessagesStore

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @observable error: IBaseAppError = {
    originalInstance: null,
    messageForUI: null,
  }

  @action
  handle(error: IAppError): void {
    this._handleMessageForUI(error)
  }

  @action
  create(e: Error): void {
    this.errorFactory.create({
      originalInstance: e,
    })
  }

  _handleMessageForUI(error: IAppError): void {
    if (error.messageForUI === null) {
      return
    }

    this.messagesStore.add({
      type: MessageType.Error,
      body: error.messageForUI,
      isDismissable: true,
      ttl: 5000,
    })
  }
}
