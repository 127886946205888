import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IDeleteVehicleStatusUseCase,
  DeleteVehicleStatusUseCaseInput,
  IAppErrorFactory,
  DeleteVehicleStatusUseCaseOutput,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleStatusBase,
} from '@/types'

@injectable()
export default class DeleteVehicleStatusInteractor implements IDeleteVehicleStatusUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleStatusBase: IVehicleStatusBase
  }>

  async handle(input: DeleteVehicleStatusUseCaseInput): Promise<DeleteVehicleStatusUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleStatusBase: null,
      },
    })
    try {
      output.data.vehicleStatusBase = await this.pmsService.deleteVehicleStatus(input.id)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
