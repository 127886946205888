import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchPitchUseCaseInput,
  FetchPitchUseCaseOutput,
  IAppErrorFactory,
  IFetchPitchUseCase,
  IPitchesService,
} from '../../types'

@injectable()
export default class FetchPitchInteractor implements IFetchPitchUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchPitchUseCaseInput): Promise<FetchPitchUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitch = await this.pitchesService.fetchPitch(input.slug)

      output.pitch = pitch
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchPitchUseCaseOutput {
    return {
      pitch: null,
      error: null,
    }
  }
}
