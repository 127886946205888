import {
  IVehicleStatus,
  VehicleStatusCategory,
  IUpdateVehicleStatusUseCase,
  UpdateVehicleStatusUseCaseOutput,
  IVehicleStatusBase,
  IVehicleStatusInputBase,
} from '@/types'
import { action, observable, computed } from 'mobx'

export type Deps = {
  updateVehicleStatusUseCase: IUpdateVehicleStatusUseCase
}

export default class VehicleStatus implements IVehicleStatus {
  @observable id = ''

  @observable name = ''

  @observable slug = ''

  @observable category: VehicleStatusCategory

  @observable itemsCount: number

  @observable weighting: string

  vehicleItems

  updateVehicleStatusUseCase: IUpdateVehicleStatusUseCase

  constructor(base: IVehicleStatusBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IVehicleStatusBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  update(base: IVehicleStatusBase): void {
    this._mapBase(base)
  }

  @action
  _updateWeighting(weighting: string): void {
    this.weighting = weighting
  }

  @computed
  get isDefault(): boolean {
    return this.category === VehicleStatusCategory.DEFAULT
  }

  @computed
  get isInvested(): boolean {
    return this.category === VehicleStatusCategory.INVESTED
  }

  async save(input: IVehicleStatusInputBase): Promise<UpdateVehicleStatusUseCaseOutput> {
    const initialWeighting = this.weighting
    this._updateWeighting(input.weighting)

    const output = await this.updateVehicleStatusUseCase.handle({
      id: this.id,
      vehicleStatus: input,
    })

    if (output.isSuccessful) {
      this.update(output.data.vehicleStatusBase)
    } else {
      // サーバーでエラーになったら戻す
      this._updateWeighting(initialWeighting)
    }

    return output
  }
}
