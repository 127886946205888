import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  FetchJobCategoriesUseCaseOutput,
  IFetchJobCategoriesUseCase,
  IJobCategoriesService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchJobCategoriesInteractor implements IFetchJobCategoriesUseCase {
  @inject(symbols.IJobCategoriesService) private jobCategoryService: IJobCategoriesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchJobCategoriesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const jobCategories = await this.jobCategoryService.fetchJobCategories()
      output.jobCategories = jobCategories
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchJobCategoriesUseCaseOutput {
    return {
      jobCategories: [],
      error: null,
    }
  }
}
