import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  ICompaniesService,
  IUpdateCompanyMemberUseCase,
  UpdateCompanyMemberUseCaseInput,
  UpdateCompanyMemberUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class UpdateCompanyMemberInteractor implements IUpdateCompanyMemberUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateCompanyMemberUseCaseInput): Promise<UpdateCompanyMemberUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companyMember = await this.companiesService.updateCompanyMember(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'メンバーの更新に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateCompanyMemberUseCaseOutput {
    return {
      companyMember: null,
      error: null,
    }
  }
}
