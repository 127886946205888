import { inject, injectable } from 'inversify'
import {
  ChatMessageThreadFactoryInput,
  IChatMessageThread,
  IChatMessageThreadFactory,
  IReopenChatConnectionUseCase,
  ICreateChatMessageThreadSubscriptionUseCase,
  IErrorsStore,
  IFetchChatMessagesUseCase,
  IMarkAllMessagesAsReadUseCase,
  ISendChatMessageUseCase,
  IViewer,
} from '@/types'
import symbols from '@/symbols'
import ChatMessageThread from '@/entities/ChatMessageThread'

@injectable()
export default class ChatMessageThreadFactory implements IChatMessageThreadFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  @inject(symbols.ICreateChatMessageThreadSubscriptionUseCase)
  private createChatMessageThreadSubscriptionUseCase: ICreateChatMessageThreadSubscriptionUseCase

  @inject(symbols.ISendChatMessageUseCase) private sendChatMessageUseCase: ISendChatMessageUseCase

  @inject(symbols.IFetchChatMessagesUseCase) private fetchChatMessagesUseCase: IFetchChatMessagesUseCase

  @inject(symbols.IMarkAllMessagesAsReadUseCase) private markAllMessagesAsReadUseCase: IMarkAllMessagesAsReadUseCase

  @inject(symbols.IReopenChatConnectionUseCase) private reopenChatConnectionUseCase: IReopenChatConnectionUseCase

  @inject(symbols.IViewer) private viewer: IViewer

  public create(input: ChatMessageThreadFactoryInput): IChatMessageThread {
    const thread = new ChatMessageThread(input.base, {
      createChatMessageThreadSubscriptionUseCase: this.createChatMessageThreadSubscriptionUseCase,
      sendChatMessageUseCase: this.sendChatMessageUseCase,
      fetchChatMessagesUseCase: this.fetchChatMessagesUseCase,
      markAllMessagesAsReadUseCase: this.markAllMessagesAsReadUseCase,
      reopenChatConnectionUseCase: this.reopenChatConnectionUseCase,
    })
    thread.errorsStore = this.errorsStore
    thread.viewer = this.viewer

    return thread
  }
}
