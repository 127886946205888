import {
  CreateVehicleUseCaseOutput,
  DeleteVehicleUseCaseOutput,
  ICompanyBase,
  ICreateVehicleUseCase,
  IDeleteVehicleUseCase,
  IInvestor,
  IInvestorBase,
  IInvestorCreateVehicleInput,
  IUserBase,
  IVehicle,
  VehicleInvestor,
} from '@/types'
import { action, computed, observable } from 'mobx'

type Dependencies = {
  vehicles: IVehicle[]
  createVehicleUseCase: ICreateVehicleUseCase
  deleteVehicleUseCase: IDeleteVehicleUseCase
}

export default class Investor implements IInvestor {
  @observable id: string

  @observable investorType: VehicleInvestor

  @observable investorSlug: string

  @observable user: IUserBase

  @observable company: ICompanyBase

  @observable vehicles: IVehicle[] = []

  createVehicleUseCase: ICreateVehicleUseCase

  deleteVehicleUseCase: IDeleteVehicleUseCase

  constructor(base: IInvestorBase, deps: Dependencies) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IInvestorBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Dependencies): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  _addVehicle(vehicle: IVehicle): void {
    const found = this.vehicles.find((v) => v.slug === vehicle.slug)
    if (found) {
      // 既に同じ vehicle がある場合は追加しない
      return
    }

    // まだメモリ上にない場合は普通に追加
    this.vehicles.push(vehicle)
  }

  @computed
  get name(): string {
    if (this.investorType === VehicleInvestor.USER) {
      return this?.user?.name
    }
    if (this.investorType === VehicleInvestor.COMPANY) {
      return this?.company?.name
    }
    return ''
  }

  async initializeVehicle(vehicleSlug: string): Promise<boolean> {
    const vehicle = this.getVehicle(vehicleSlug)
    if (!vehicle) {
      return false
    }

    if (!vehicle.isInitialized) {
      await vehicle.initialize()
    }

    return vehicle.isInitialized
  }

  getVehicle(vehicleSlug: string): IVehicle {
    return this.vehicles.find((v) => v.slug === vehicleSlug)
  }

  async createVehicle(input: IInvestorCreateVehicleInput): Promise<CreateVehicleUseCaseOutput> {
    const output = await this.createVehicleUseCase.handle({
      name: input.name,
      investorSlug: this.investorSlug,
      investorType: this.investorType,
      currencyUnit: input.currencyUnit,
    })

    if (output.isSuccessful) {
      this._addVehicle(output.data.vehicle)
    }

    return output
  }

  async deleteVehicle(id: string): Promise<DeleteVehicleUseCaseOutput> {
    const output = await this.deleteVehicleUseCase.handle({ id })

    return output
  }
}
