import { inject, injectable } from 'inversify'
import {
  ICreateVehicleItemRoundUseCase,
  IDeleteVehicleItemRoundUseCase,
  IFetchVehicleItemUseCase,
  IPortfolioFactory,
  IUpdateVehicleItemAndPortfolioUseCase,
  IUpdateVehicleItemUseCase,
  IVehicleItem,
  IVehicleItemFactory,
  IVehicleItemRoundFactory,
  VehicleItemFactoryInput,
} from '@/types'
import VehicleItem from '@/entities/VehicleItem'
import symbols from '@/symbols'

@injectable()
export default class VehicleItemFactory implements IVehicleItemFactory {
  @inject(symbols.IPortfolioFactory) portfolioFactory: IPortfolioFactory

  @inject(symbols.IVehicleItemRoundFactory) vehicleItemRoundFactory: IVehicleItemRoundFactory

  @inject(symbols.ICreateVehicleItemRoundUseCase) createVehicleItemRoundUseCase: ICreateVehicleItemRoundUseCase

  @inject(symbols.IDeleteVehicleItemRoundUseCase) deleteVehicleItemRoundUseCase: IDeleteVehicleItemRoundUseCase

  @inject(symbols.IUpdateVehicleItemUseCase) updateVehicleItemUseCase: IUpdateVehicleItemUseCase

  @inject(symbols.IFetchVehicleItemUseCase) fetchVehicleItemUseCase: IFetchVehicleItemUseCase

  @inject(symbols.IUpdateVehicleItemAndPortfolioUseCase)
  updateVehicleItemAndPortfolioUseCase: IUpdateVehicleItemAndPortfolioUseCase

  public create(input: VehicleItemFactoryInput): IVehicleItem {
    const portfolio = this.portfolioFactory.create({ base: input.base.portfolio })
    const vehicleItemRounds = input.base.vehicleItemRounds
      ? input.base.vehicleItemRounds.map((round) => this.vehicleItemRoundFactory.create({ base: round }))
      : []
    return new VehicleItem(input.base, {
      portfolio,
      vehicleItemRounds,
      createVehicleItemRoundUseCase: this.createVehicleItemRoundUseCase,
      deleteVehicleItemRoundUseCase: this.deleteVehicleItemRoundUseCase,
      updateVehicleItemUseCase: this.updateVehicleItemUseCase,
      fetchVehicleItemUseCase: this.fetchVehicleItemUseCase,
      updateVehicleItemAndPortfolioUseCase: this.updateVehicleItemAndPortfolioUseCase,
    })
  }
}
