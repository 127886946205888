import { inject, injectable } from 'inversify'
import {
  IErrorsStore,
  IInvestment,
  IInvestmentFactory,
  InvestmentFactoryInput,
  IUpdateInvestmentUseCase,
  IUpdateInvestorUseCase,
} from '@/types'
import symbols from '@/symbols'
import Investment from '@/entities/Investment'

@injectable()
export default class InvestmentFactory implements IInvestmentFactory {
  @inject(symbols.IUpdateInvestmentUseCase) private updateInvestmentUseCase: IUpdateInvestmentUseCase

  @inject(symbols.IUpdateInvestorUseCase) private updateInvestorUseCase: IUpdateInvestorUseCase

  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  public create(input: InvestmentFactoryInput): IInvestment {
    const investment = new Investment(input.base)
    investment.errorsStore = this.errorsStore
    investment.updateInvestmentUseCase = this.updateInvestmentUseCase
    investment.updateInvestorUseCase = this.updateInvestorUseCase
    return investment
  }
}
