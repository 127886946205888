import 'reflect-metadata'
import { Container } from 'inversify'
import InvestorFactory from '@/factories/InvestorFactory'
import VehicleFactory from '@/factories/VehicleFactory'
import PMSStore from '@/stores/PMSStore'
import InitializeInvestorInteractor from '@/useCases/pms/InitializeInvestorInteractor'
import InitializeVehicleInteractor from '@/useCases/pms/InitializeVehicleInteractor'
import VehicleItemRoundFactory from '@/factories/VehicleItemRoundFactory'
import VehicleItemFactory from '@/factories/VehicleItemFactory'
import PortfolioNoteFactory from '@/factories/PortfolioNoteFactory'
import RemoveVehicleMemberInteractor from '@/useCases/pms/RemoveVehicleMemberInteractor'
import UpdateVehicleMemberInteractor from '@/useCases/pms/UpdateVehicleMemberInteractor'
import AddVehicleMemberInteractor from '@/useCases/pms/AddVehicleMemberInteractor'
import DeletePortfolioAttachmentInteractor from '@/useCases/pms/DeletePortfolioAttachmentInteractor'
import AddPortfolioAttachmentInteractor from '@/useCases/pms/AddPortfolioAttachmentInteractor'
import DeletePortfolioNoteInteractor from '@/useCases/pms/DeletePortfolioNoteInteractor'
import UpdatePortfolioNoteInteractor from '@/useCases/pms/UpdatePortfolioNoteInteractor'
import CreatePortfolioNoteInteractor from '@/useCases/pms/CreatePortfolioNoteInteractor'
import DeleteVehicleItemRoundAttachmentInteractor from '@/useCases/pms/DeleteVehicleItemRoundAttachmentInteractor'
import AddVehicleItemRoundAttachmentInteractor from '@/useCases/pms/AddVehicleItemRoundAttachmentInteractor'
import DeleteVehicleItemRoundInteractor from '@/useCases/pms/DeleteVehicleItemRoundInteractor'
import UpdateVehicleItemRoundInteractor from '@/useCases/pms/UpdateVehicleItemRoundInteractor'
import CreateVehicleItemRoundInteractor from '@/useCases/pms/CreateVehicleItemRoundInteractor'
import DeleteVehicleItemInteractor from '@/useCases/pms/DeleteVehicleItemInteractor'
import UpdateVehicleItemInteractor from '@/useCases/pms/UpdateVehicleItemInteractor'
import UpdateVehicleItemPortfolioInteractor from '@/useCases/pms/UpdateVehicleItemPortfolioInteractor'
import CreateVehicleItemInteractor from '@/useCases/pms/CreateVehicleItemInteractor'
import FetchVehicleItemInteractor from '@/useCases/pms/FetchVehicleItemInteractor'
import FetchVehicleItemsInteractor from '@/useCases/pms/FetchVehicleItemsInteractor'
import CreateVehicleInteractor from '@/useCases/pms/CreateVehicleInteractor'
import UpdateVehicleInteractor from '@/useCases/pms/UpdateVehicleInteractor'
import DeleteVehicleInteractor from '@/useCases/pms/DeleteVehicleInteractor'
import PortfolioFactory from '@/factories/PortfolioFactory'
import FetchChatMessageThreadsInteractor from '@/useCases/chat/FetchChatMessageThreadsInteractor'
import FetchLatestForexRateInteractor from '@/useCases/forex/FetchLatestForexRateInteractor'
import ForexStore from '@/stores/ForexStore'
import FetchAllForexRatesInteractor from '@/useCases/forex/FetchAllForexRatesInteractor'
import LeaveServiceInteractor from '@/useCases/viewer/LeaveServiceInteractor'
import symbols from './symbols'
import {
  IAddAngelInvestmentInvitationUseCase,
  IAddAngelInvitationUseCase,
  IAddCompanyInvestmentUseCase,
  IAddCompanyMemberUseCase,
  IAddCompanyReferenceUseCase,
  IAddExperienceUseCase,
  IAddFootprintToJobUseCase,
  IAddInvestorUseCase,
  IAddMemberInvitationUseCase,
  IAddMyInvestmentUseCase,
  IAddMyJobBookmarkUseCase,
  IAddPitchAttachmentUseCase,
  IAddPortfolioAttachmentUseCase,
  IAddProductUseCase,
  IAddUserReferenceUseCase,
  IAddVcInvestmentInvitationUseCase,
  IAddVcInvitationUseCase,
  IAddVehicleItemRoundAttachmentUseCase,
  IAddVehicleMemberUseCase,
  IAppCredentials,
  IAppErrorFactory,
  IApplyForJobUseCase,
  IArticleAttachmentService,
  IArticlesService,
  IArticlesStore,
  IAuthService,
  IChangeLanguageUseCase,
  IChatMessageThreadFactory,
  IChatService,
  IChatStore,
  ICompaniesService,
  ICompaniesStore,
  ICompanyArticleFactory,
  ICompanyFactory,
  ICompanyMemberFactory,
  ICompanyReferenceFactory,
  ICompanyReferencesService,
  ICreateArticleAttachmentUseCase,
  ICreateBillingPortalSessionUseCase,
  ICreateChatMessageThreadSubscriptionUseCase,
  ICreateCompanyArticleUseCase,
  ICreateCompanyUseCase,
  ICreateEmptyDraftPitchUseCase,
  ICreateJobAttachmentUseCase,
  ICreateJobUseCase,
  ICreateMyArticleUseCase,
  ICreateMyCompanyUseCase,
  ICreatePitchUseCase,
  ICreatePortfolioMeetingUseCase,
  ICreatePortfolioNoteUseCase,
  ICreateRecruitSubscriptionUseCase,
  ICreateSkillUseCase,
  ICreateVehicleItemRoundUseCase,
  ICreateVehicleItemUseCase,
  ICreateVehicleStatusUseCase,
  ICreateVehicleUseCase,
  IDeleteCompanyArticleUseCase,
  IDeleteJobUseCase,
  IDeleteMyArticleUseCase,
  IDeletePitchAttachmentUseCase,
  IDeletePitchUseCase,
  IDeletePortfolioAttachmentUseCase,
  IDeletePortfolioMeetingUseCase,
  IDeletePortfolioNoteUseCase,
  IDeleteVehicleItemRoundAttachmentUseCase,
  IDeleteVehicleItemRoundUseCase,
  IDeleteVehicleItemUseCase,
  IDeleteVehicleStatusUseCase,
  IDeleteVehicleUseCase,
  IDuplicateJobUseCase,
  IErrorsStore,
  IErrorTrackingService,
  IExperienceFactory,
  IFetchAllForexRatesUseCase,
  IFetchAngelsUseCase,
  IFetchArticlesUseCase,
  IFetchArticleUseCase,
  IFetchChatMessagesUseCase,
  IFetchChatMessageThreadsUseCase,
  IFetchCompaniesUseCase,
  IFetchCompaniesWithJobsUseCase,
  IFetchCompanyArticlesUseCase,
  IFetchCompanyForViewerUseCase,
  IFetchCompanyJobsUseCase,
  IFetchCompanyUseCase,
  IFetchFeaturedAngelsUseCase,
  IFetchFeaturedCompaniesWithJobsUseCase,
  IFetchFeaturedJobsUseCase,
  IFetchFeaturedStartupsUseCase,
  IFetchFeaturedStoriesUseCase,
  IFetchFeaturedVentureCapitalsUseCase,
  IFetchFollowersUseCase,
  IFetchFollowingUseCase,
  IFetchJobApplicationsUseCase,
  IFetchJobBookmarksUseCase,
  IFetchJobCategoriesUseCase,
  IFetchJobFootprintsUseCase,
  IFetchJobsUseCase,
  IFetchJobTagsUseCase,
  IFetchJobUseCase,
  IFetchLatestForexRateUseCase,
  IFetchLocationsUseCase,
  IFetchLocationUseCase,
  IFetchMagazineArchivePageContentUseCase,
  IFetchMagazineSinglePageContentUseCase,
  IFetchMagazineTopPageContentUseCase,
  IFetchMarketsUseCase,
  IFetchMyArticlesUseCase,
  IFetchMyCompanyArticlesUseCase,
  IFetchMyCompanyReferencesUseCase,
  IFetchMyJobBookmarksUseCase,
  IFetchMyJobsUseCase,
  IFetchMyPitchesUseCase,
  IFetchMyReferencesUseCase,
  IFetchNotificationsUseCase,
  IFetchPickedUpFeaturesUseCase,
  IFetchPitchesUseCase,
  IFetchPitchUseCase,
  IFetchSkillsUseCase,
  IFetchSubsidiesUseCase,
  IFetchSubsidyPurposesUseCase,
  IFetchSubsidyPurposeUseCase,
  IFetchSubsidyUseCase,
  IFetchUserArticlesUseCase,
  IFetchUserForViewerUseCase,
  IFetchUserUseCase,
  IFetchVehicleAnalyticsUseCase,
  IFetchVehicleItemsUseCase,
  IFetchVehicleItemUseCase,
  IFindOrCreateChatMessageThreadUseCase,
  IForexService,
  IForexStore,
  IInitializeChatUseCase,
  IInitializeInvestorUseCase,
  IInitializeUseCase,
  IInitializeVehicleUseCase,
  IInvestmentFactory,
  IInvestmentsService,
  IInvestorFactory,
  IInvitationsService,
  IJobApplicationFactory,
  IJobApplicationsService,
  IJobApplicationsStore,
  IJobBookmarksService,
  IJobCategoriesService,
  IJobFactory,
  IJobFootprintFactory,
  IJobsService,
  IJobsStore,
  IJobTagsService,
  ILeaveServiceUseCase,
  ILocationsService,
  IMarkAllMessagesAsReadUseCase,
  IMarkAllNotificationsAsReadUseCase,
  IMarketsService,
  IMarkPitchAsReadUseCase,
  IMessageFactory,
  IMessagesStore,
  INotificationsService,
  IPitchAttachmentFactory,
  IPitchAttachmentsService,
  IPitchesService,
  IPitchFactory,
  IPMSService,
  IPMSStore,
  IPortfolioFactory,
  IPortfolioMeetingFactory,
  IPortfolioNoteFactory,
  IPreferences,
  IProductFactory,
  IPublicArticleFactory,
  IPublicCompanyFactory,
  IPublicFollowableEntityFactory,
  IPublicUserFactory,
  IRemoveCompanyMemberUseCase,
  IRemoveCompanyReferenceUseCase,
  IRemoveExperienceUseCase,
  IRemoveInvestmentUseCase,
  IRemoveInvestorUseCase,
  IRemoveMyJobBookmarkUseCase,
  IRemovePitchAttachmentFromPitchUseCase,
  IRemoveProductUseCase,
  IRemoveUserReferenceUseCase,
  IRemoveVehicleMemberUseCase,
  IReopenChatConnectionUseCase,
  ISearchCompanyUseCase,
  ISearchUserUseCase,
  ISendChatMessageUseCase,
  ISendResetPasswordRequestUseCase,
  ISignInUseCase,
  ISignOutUseCase,
  ISignUpUseCase,
  ISkillsService,
  IStorageService,
  ISubscriptionsService,
  ISubsidiesService,
  ISubsidiesStore,
  IToggleArticleLikedStateUseCase,
  IToggleFollowCompanyUseCase,
  IToggleFollowUserUseCase,
  IUIStore,
  IUpdateCompanyArticleUseCase,
  IUpdateCompanyMemberUseCase,
  IUpdateCompanyNotificationSettingsUseCase,
  IUpdateCompanyReferenceUseCase,
  IUpdateCompanyUseCase,
  IUpdateEmailUseCase,
  IUpdateExperienceUseCase,
  IUpdateInvestmentUseCase,
  IUpdateInvestorUseCase,
  IUpdateJobUseCase,
  IUpdateMeUseCase,
  IUpdateMyArticleUseCase,
  IUpdateMyCompanyReferenceUseCase,
  IUpdateMyProfileUseCase,
  IUpdateMyReferenceUseCase,
  IUpdatePasswordUseCase,
  IUpdatePasswordWithTokenUseCase,
  IUpdatePitchAttachmentUseCase,
  IUpdatePitchUseCase,
  IUpdatePortfolioMeetingUseCase,
  IUpdatePortfolioNoteUseCase,
  IUpdateProductUseCase,
  IUpdateUserNotificationSettingsUseCase,
  IUpdateUserReferenceUseCase,
  IUpdateVehicleItemAndPortfolioUseCase,
  IUpdateVehicleItemPortfolioUseCase,
  IUpdateVehicleItemRoundUseCase,
  IUpdateVehicleItemUseCase,
  IUpdateVehicleMemberUseCase,
  IUpdateVehicleStatusUseCase,
  IUpdateVehicleUseCase,
  IUseCaseOutputFactory,
  IUserArticleFactory,
  IUserProfileFactory,
  IUserReferenceFactory,
  IUserReferencesService,
  IUsersService,
  IUsersStore,
  IValidInvitationTokenUseCase,
  IVehicleFactory,
  IVehicleItemFactory,
  IVehicleItemRoundFactory,
  IVehicleMemberFactory,
  IVehicleStatusFactory,
  IViewer,
  IViewerService,
  IViewerStore,
} from './types'
import UIStore from './stores/UIStore'
import MessagesStore from './stores/MessagesStore'
import ErrorsStore from './stores/ErrorsStore'
import MessageFactory from './factories/MessageFactory'
import ViewerStore from './stores/ViewerStore'
import AppErrorFactory from './factories/AppErrorFactory'
import AuthAPIGateway from './gateways/AuthAPIGateway'
import SignInInteractor from './useCases/viewer/SignInInteractor'
import LocalStorageGateway from './gateways/LocalStorageGateway'
import InitializeInteractor from './useCases/viewer/InitializeInteractor'
import AppAPIGateway from './gateways/AppAPIGateway'
import SignOutInteractor from './useCases/viewer/SignOutInteractor'
import SignUpInteractor from './useCases/viewer/SignUpInteractor'
import AppCredentials from './entities/AppCredentials'
import UpdatePasswordInteractor from './useCases/viewer/UpdatePasswordInteractor'
import UpdateEmailInteractor from './useCases/viewer/UpdateEmailInteractor'
import SendResetPasswordRequestInteractor from './useCases/viewer/SendResetPasswordRequestInteractor'
import Preferences from './entities/Preferences'
import ChangeLanguageInteractor from './useCases/viewer/ChangeLanguageInteractor'
import UpdatePasswordWithTokenInteractor from './useCases/viewer/UpdatePasswordWithTokenInteractor'
import UsersStore from './stores/UsersStore'
import FetchAngelsInteractor from './useCases/users/FetchAngelsInteractor'
import FetchUserInteractor from './useCases/users/FetchUserInteractor'
import FetchCompaniesInteractor from './useCases/companies/FetchCompaniesInteractor'
import FetchCompanyInteractor from './useCases/companies/FetchCompanyInteractor'
import ArticlesStore from './stores/ArticlesStore'
import FetchMagazineTopPageContentInteractor from './useCases/articles/FetchMagazineTopPageContentInteractor'
import FetchMagazineArchivePageContentInteractor from './useCases/articles/FetchMagazineArchivePageContentInteractor'
import FetchMagazineSinglePageContentInteractor from './useCases/articles/FetchMagazineSinglePageContentInteractor'
import FetchArticlesInteractor from './useCases/articles/FetchArticlesInteractor'
import UserProfileFactory from './factories/UserProfileFactory'
import CompanyFactory from './factories/CompanyFactory'
import UpdateMyProfilelInteractor from './useCases/viewer/UpdateMyProfilelInteractor'
import UpdateMeInteractor from './useCases/viewer/UpdateMeInteractor'
import CreateMyCompanyInteractor from './useCases/viewer/CreateMyCompanyInteractor'
import UpdateCompanyInteractor from './useCases/viewer/UpdateCompanyInteractor'
import CompaniesStore from './stores/CompaniesStore'
import AddProductInteractor from './useCases/companies/AddProductInteractor'
import UpdateProductInteractor from './useCases/companies/UpdateProductInteractor'
import ProductFactory from './factories/ProductFactory'
import RemoveProductInteractor from './useCases/companies/RemoveProductInteractor'
import SearchCompanyInteractor from './useCases/companies/SearchCompanyInteractor'
import CreateCompanyInteractor from './useCases/viewer/CreateCompanyInteractor'
import AddExperienceInteractor from './useCases/viewer/AddExperienceInteractor'
import UpdateExperienceInteractor from './useCases/viewer/UpdateExperienceInteractor'
import RemoveExperienceInteractor from './useCases/viewer/RemoveExperienceInteractor'
import ExperienceFactory from './factories/ExperienceFactory'
import InvestmentFactory from './factories/InvestmentFactory'
import AddCompanyInvestmentInteractor from './useCases/investments/AddCompanyInvestmentInteractor'
import UpdateInvestmentInteractor from './useCases/investments/UpdateInvestmentInteractor'
import RemoveInvestmentInteractor from './useCases/investments/RemoveInvestmentInteractor'
import AddCompanyMemberInteractor from './useCases/companies/AddCompanyMemberInteractor'
import RemoveCompanyMemberInteractor from './useCases/companies/RemoveCompanyMemberInteractor'
import CompanyMemberFactory from './factories/CompanyMemberFactory'
import UpdateCompanyMemberInteractor from './useCases/companies/UpdateCompanyMemberInteractor'
import SearchUserInteractor from './useCases/users/SearchUserInteractor'
import AddMyInvestmentInteractor from './useCases/investments/AddMyInvestmentInteractor'
import ChatStore from './stores/ChatStore'
import InitializeChatInteractor from './useCases/chat/InitializeChatInteractor'
import ChatMessageThreadFactory from './factories/ChatMessageThreadFactory'
import CreateChatMessageThreadSubscriptionInteractor from './useCases/chat/CreateChatMessageThreadSubscriptionInteractor'
import SendChatMessageInteractor from './useCases/chat/SendChatMessageInteractor'
import FindOrCreateChatMessageThreadInteractor from './useCases/chat/FindOrCreateChatMessageThreadInteractor'
import FetchChatMessagesInteractor from './useCases/chat/FetchChatMessagesInteractor'
import FetchMarketsInteractor from './useCases/markets/FetchMarketsInteractor'
import MarkAllMessagesAsReadInteractor from './useCases/chat/MarkAllMessagesAsReadInteractor'
import FetchCompanyArticlesInteractor from './useCases/articles/FetchCompanyArticlesInteractor'
import UpdateCompanyArticleInteractor from './useCases/articles/UpdateCompanyArticleInteractor'
import CreateCompanyArticleInteractor from './useCases/articles/CreateCompanyArticleInteractor'
import CompanyArticleFactory from './factories/CompanyArticleFactory'
import Viewer from './entities/Viewer'
import CreateArticleAttachmentInteractor from './useCases/articleAttachments/CreateArticleAttachmentInteractor'
import MarkAllNotificationsAsReadInteractor from './useCases/notifications/MarkAllNotificationsAsReadInteractor'
import FetchFeaturedAngelsInteractor from './useCases/users/FetchFeaturedAngelsInteractor'
import FetchFeaturedVentureCapitalsInteractor from './useCases/companies/FetchFeaturedVentureCapitalsInteractor'
import FetchFeaturedStartupsInteractor from './useCases/companies/FetchFeaturedStartupsInteractor'
import FetchFeaturedCompaniesWithJobsInteractor from './useCases/companies/FetchFeaturedCompaniesWithJobsInteractor'
import FetchLocationsInteractor from './useCases/companies/FetchLocationsInteractor'
import SentryNextjsGateway from './gateways/SentryNextjsGateway'
import PitchFactory from './factories/PitchFactory'
import PitchAttachmentFactory from './factories/PitchAttachmentFactory'
import FetchPitchesInteractor from './useCases/pitches/FetchPitchesInteractor'
import FetchPitchInteractor from './useCases/pitches/FetchPitchInteractor'
import CreatePitchInteractor from './useCases/pitches/CreatePitchInteractor'
import DeletePitchInteractor from './useCases/pitches/DeletePitchInteractor'
import UpdatePitchInteractor from './useCases/pitches/UpdatePitchInteractor'
import MarkPitchAsReadInteractor from './useCases/pitches/MarkPitchAsReadInteractor'
import AddPitchAttachmentInteractor from './useCases/pitchAttachments/AddPitchAttachmentInteractor'
import DeletePitchAttachmentInteractor from './useCases/pitchAttachments/DeletePitchAttachmentInteractor'
import RemovePitchAttachmentFromPitchInteractor from './useCases/pitchAttachments/RemovePitchAttachmentFromPitchInteractor'
import UpdatePitchAttachmentInteractor from './useCases/pitchAttachments/UpdatePitchAttachmentInteractor'
import CreateEmptyDraftPitchInteractor from './useCases/pitches/CreateEmptyDraftPitchInteractor'
import FetchMyPitchesInteractor from './useCases/pitches/FetchMyPitchesInteractor'
import FetchJobCategoriesInteractor from './useCases/jobCategories/FetchJobCategoriesInteractor'
import FetchJobTagsInteractor from './useCases/jobTags/FetchJobTagsInteractor'
import FetchSkillsInteractor from './useCases/skills/FetchSkillsInteractor'
import JobFactory from './factories/JobFactory'
import FetchMyJobsInteractor from './useCases/jobs/FetchMyJobsInteractor'
import CreateJobInteractor from './useCases/jobs/CreateJobInteractor'
import DuplicateJobInteractor from './useCases/jobs/DuplicateJobInteractor'
import UpdateJobInteractor from './useCases/jobs/UpdateJobInteractor'
import FetchJobInteractor from './useCases/jobs/FetchJobInteractor'
import DeleteJobInteractor from './useCases/jobs/DeleteJobInteractor'
import CreateJobAttachmentInteractor from './useCases/jobs/CreateJobAttachmentInteractor'
import JobApplicationFactory from './factories/JobApplicationFactory'
import FetchJobApplicationsInteractor from './useCases/jobApplications/FetchJobApplicationsInteractor'
import ApplyForJobInteractor from './useCases/jobApplications/applyForJobInteractor'
import JobApplicationsStore from './stores/JobApplicationsStore'
import CreateSkillInteractor from './useCases/skills/CreateSkillInteractor'
import AddInvestorInteractor from './useCases/investments/AddInvestorInteractor'
import UpdateInvestorInteractor from './useCases/investments/UpdateInvestorInteractor'
import RemoveInvestorInteractor from './useCases/investments/RemoveInvestorInteractor'
import CreateBillingPortalSessionInteractor from './useCases/subscriptions/CreateBillingPortalSessionInteractor'
import CreateRecruitSubscriptionInteractor from './useCases/subscriptions/CreateRecruitSubscriptionInteractor'
import ToggleArticleLikedStateInteractor from './useCases/articles/ToggleArticleLikedStateInteractor'
import PublicArticleFactory from './factories/PublicArticleFactory'
import FetchCompaniesWithJobsInteractor from './useCases/companies/FetchCompaniesWithJobsInteractor'
import FetchJobsInteractor from './useCases/jobs/FetchJobsInteractor'
import JobsStore from './stores/JobsStore'
import FetchCompanyJobsInteractor from './useCases/jobs/FetchCompanyJobsInteractor'
import FetchArticleInteractor from './useCases/articles/FetchArticleInteractor'
import AddFootprintToJobInteractor from './useCases/jobs/AddFootprintToJobInteractor'
import FetchJobFootprintsInteractor from './useCases/jobs/FetchJobFootprintsInteractor'
import FetchUserArticlesInteractor from './useCases/articles/FetchUserArticlesInteractor'
import UserArticleFactory from './factories/UserArticleFactory'
import FetchMyArticlesInteractor from './useCases/articles/FetchMyArticlesInteractor'
import CreateMyArticleInteractor from './useCases/articles/CreateMyArticleInteractor'
import UpdateMyArticleInteractor from './useCases/articles/UpdateMyArticleInteractor'
import DeleteMyArticleInteractor from './useCases/articles/DeleteMyArticleInteractor'
import DeleteCompanyArticleInteractor from './useCases/articles/DeleteCompanyArticleInteractor'
import FetchFeaturedStoriesInteractor from './useCases/articles/FetchFeaturedStoriesInteractor'
import FetchMyJobBookmarksInteractor from './useCases/jobBookmarks/FetchMyJobBookmarksInteractor'
import AddMyJobBookmarkInteractor from './useCases/jobBookmarks/AddMyJobBookmarkInteractor'
import RemoveMyJobBookmarkInteractor from './useCases/jobBookmarks/RemoveMyJobBookmarkInteractor'
import JobFootprintFactory from './factories/JobFootprintFactory'
import FetchJobBookmarksInteractor from './useCases/jobBookmarks/FetchJobBookmarksInteractor'
import AddUserReferenceInteractor from './useCases/userReferences/AddUserReferenceInteractor'
import UpdateUserReferenceInteractor from './useCases/userReferences/UpdateUserReferenceInteractor'
import RemoveUserReferenceInteractor from './useCases/userReferences/RemoveUserReferenceInteractor'
import FetchMyReferencesInteractor from './useCases/userReferences/FetchMyReferencesInteractor'
import UpdateMyReferenceInteractor from './useCases/userReferences/UpdateMyReferenceInteractor'
import AddCompanyReferenceInteractor from './useCases/companyReferences/AddCompanyReferenceInteractor'
import UpdateCompanyReferenceInteractor from './useCases/companyReferences/UpdateCompanyReferenceInteractor'
import RemoveCompanyReferenceInteractor from './useCases/companyReferences/RemoveCompanyReferenceInteractor'
import FetchMyCompanyReferencesInteractor from './useCases/companyReferences/FetchMyCompanyReferencesInteractor'
import UpdateMyCompanyReferenceInteractor from './useCases/companyReferences/UpdateMyCompanyReferenceInteractor'
import UserReferenceFactory from './factories/UserReferenceFactory'
import CompanyReferenceFactory from './factories/CompanyReferenceFactory'
import ValidInvitationTokenInteractor from './useCases/invitations/ValidInvitationTokenInteractor'
import AddAngelInvitationInteractor from './useCases/invitations/AddAngelInvitationInteractor'
import AddVcInvitationInteractor from './useCases/invitations/AddVcInvitationInteractor'
import AddAngelInvestmentInvitationInteractor from './useCases/invitations/AddAngelInvestmentInvitationInteractor'
import AddVcInvestmentInvitationInteractor from './useCases/invitations/AddVcInvestmentInvitationInteractor'
import AddMemberInvitationInteractor from './useCases/invitations/AddMemberInvitationInteractor'
import UseCaseOutputFactory from './factories/UseCaseOutputFactory'
import ReopenChatConnectionInteractor from './useCases/chat/ReopenChatConnectionInteractor'
import FetchMyCompanyArticlesInteractor from './useCases/articles/FetchMyCompanyArticlesInteractor'
import ToggleFollowCompanyInteractor from './useCases/viewer/ToggleFollowCompanyInteractor'
import ToggleFollowUserInteractor from './useCases/viewer/ToggleFollowUserInteractor'
import UpdateCompanyNotificationSettingsInteractor from './useCases/viewer/UpdateCompanyNotificationSettingsInteractor'
import UpdateUserNotificationSettingsInteractor from './useCases/viewer/UpdateUserNotificationSettingsInteractor'
import FetchFollowingInteractor from './useCases/users/FetchFollowingInteractor'
import FetchFollowersInteractor from './useCases/users/FetchFollowersInteractor'
import FetchNotificationsInteractor from './useCases/viewer/FetchNotificationsInteractor'
import PublicUserFactory from './factories/PublicUserFactory'
import FetchUserForViewerInteractor from './useCases/users/FetchUserForViewerInteractor'
import PublicCompanyFactory from './factories/PublicCompanyFactory'
import FetchCompanyForViewerInteractor from './useCases/companies/FetchCompanyForViewerInteractor'
import PublicFollowableEntityFactory from './factories/PublicFollowableEntityFactory'
import FetchPickedUpFeaturesInteractor from './useCases/articles/FetchPickedUpFeaturesInteractor'
import FetchFeaturedJobsInteractor from './useCases/jobs/FetchFeaturedJobsInteractor'
import FetchVehicleAnalyticsInteractor from './useCases/pms/FetchVehicleAnalyticsInteractor'
import VehicleMemberFactory from './factories/VehicleMemberFactory'
import CreateVehicleStatusInteractor from './useCases/pms/CreateVehicleStatusInteractor'
import UpdateVehicleStatusInteractor from './useCases/pms/UpdateVehicleStatusInteractor'
import DeleteVehicleStatusInteractor from './useCases/pms/DeleteVehicleStatusInteractor'
import VehicleStatusFactory from './factories/VehicleStatusFactory'
import UpdateVehicleItemAndPortfolioInteractor from './useCases/pms/UpdateVehicleItemAndPortfolioInteractor'
import PortfolioMeetingFactory from './factories/PortfolioMeetingFactory'
import CreatePortfolioMeetingInteractor from './useCases/pms/CreatePortfolioMeetingInteractor'
import UpdatePortfolioMeetingInteractor from './useCases/pms/UpdatePortfolioMeetingInteractor'
import DeletePortfolioMeetingInteractor from './useCases/pms/DeletePortfolioMeetingInteractor'
import SubsidiesStore from './stores/SubsidiesStore'
import FetchSubsidiesInteractor from './useCases/subsidies/FetchSubsidiesInteractor'
import FetchSubsidyInteractor from './useCases/subsidies/FetchSubsidyInteractor'
import FetchLocationInteractor from './useCases/subsidies/FetchLocationInteractor'
import FetchSubsidyPurposesInteractor from './useCases/subsidies/FetchSubsidyPurposesInteractor'
import FetchSubsidyPurposeInteractor from './useCases/subsidies/FetchSubsidyPurposeInteractor'

const createContainer = (container: Container): Container => {
  // Stores
  container.bind<IUIStore>(symbols.IUIStore).to(UIStore).inSingletonScope()
  container.bind<IMessagesStore>(symbols.IMessagesStore).to(MessagesStore).inSingletonScope()
  container.bind<IErrorsStore>(symbols.IErrorsStore).to(ErrorsStore).inSingletonScope()
  container.bind<IViewerStore>(symbols.IViewerStore).to(ViewerStore).inSingletonScope()
  container.bind<IUsersStore>(symbols.IUsersStore).to(UsersStore).inSingletonScope()
  container.bind<ICompaniesStore>(symbols.ICompaniesStore).to(CompaniesStore).inSingletonScope()
  container.bind<IArticlesStore>(symbols.IArticlesStore).to(ArticlesStore).inSingletonScope()
  container.bind<IChatStore>(symbols.IChatStore).to(ChatStore).inSingletonScope()
  container.bind<IJobApplicationsStore>(symbols.IJobApplicationStore).to(JobApplicationsStore).inSingletonScope()
  container.bind<IJobsStore>(symbols.IJobsStore).to(JobsStore).inSingletonScope()
  container.bind<IPMSStore>(symbols.IPMSStore).to(PMSStore).inSingletonScope()
  container.bind<IForexStore>(symbols.IForexStore).to(ForexStore).inSingletonScope()
  container.bind<ISubsidiesStore>(symbols.ISubsidiesStore).to(SubsidiesStore).inSingletonScope()

  // Entities
  container.bind<IAppCredentials>(symbols.IAppCredentials).to(AppCredentials).inSingletonScope()
  container.bind<IPreferences>(symbols.IPreferences).to(Preferences).inSingletonScope()
  container.bind<IViewer>(symbols.IViewer).to(Viewer).inSingletonScope()

  // Factories
  container.bind<IAppErrorFactory>(symbols.IAppErrorFactory).to(AppErrorFactory).inSingletonScope()
  container
    .bind<IUseCaseOutputFactory<never>>(symbols.IUseCaseOutputFactory)
    .to(UseCaseOutputFactory)
    .inSingletonScope()
  container.bind<IMessageFactory>(symbols.IMessageFactory).to(MessageFactory).inSingletonScope()
  container.bind<IUserProfileFactory>(symbols.IUserProfileFactory).to(UserProfileFactory).inSingletonScope()
  container.bind<ICompanyFactory>(symbols.ICompanyFactory).to(CompanyFactory).inSingletonScope()
  container.bind<IProductFactory>(symbols.IProductFactory).to(ProductFactory).inSingletonScope()
  container.bind<IExperienceFactory>(symbols.IExperienceFactory).to(ExperienceFactory).inSingletonScope()
  container.bind<IInvestmentFactory>(symbols.IInvestmentFactory).to(InvestmentFactory).inSingletonScope()
  container.bind<ICompanyMemberFactory>(symbols.ICompanyMemberFactory).to(CompanyMemberFactory).inSingletonScope()
  container
    .bind<IChatMessageThreadFactory>(symbols.IChatMessageThreadFactory)
    .to(ChatMessageThreadFactory)
    .inSingletonScope()
  container.bind<ICompanyArticleFactory>(symbols.ICompanyArticleFactory).to(CompanyArticleFactory).inSingletonScope()
  container.bind<IPitchFactory>(symbols.IPitchFactory).to(PitchFactory).inSingletonScope()
  container.bind<IPitchAttachmentFactory>(symbols.IPitchAttachmentFactory).to(PitchAttachmentFactory).inSingletonScope()
  container.bind<IJobFactory>(symbols.IJobFactory).to(JobFactory).inSingletonScope()
  container.bind<IJobApplicationFactory>(symbols.IJobApplicationFactory).to(JobApplicationFactory).inSingletonScope()
  container.bind<IUserArticleFactory>(symbols.IUserArticleFactory).to(UserArticleFactory).inSingletonScope()
  container.bind<IJobFootprintFactory>(symbols.IJobFootprintFactory).to(JobFootprintFactory).inSingletonScope()
  container.bind<IPublicArticleFactory>(symbols.IPublicArticleFactory).to(PublicArticleFactory).inSingletonScope()
  container.bind<IUserReferenceFactory>(symbols.IUserReferenceFactory).to(UserReferenceFactory).inSingletonScope()
  container
    .bind<ICompanyReferenceFactory>(symbols.ICompanyReferenceFactory)
    .to(CompanyReferenceFactory)
    .inSingletonScope()
  container.bind<IPublicUserFactory>(symbols.IPublicUserFactory).to(PublicUserFactory).inSingletonScope()
  container.bind<IPublicCompanyFactory>(symbols.IPublicCompanyFactory).to(PublicCompanyFactory).inSingletonScope()
  container
    .bind<IPublicFollowableEntityFactory>(symbols.IPublicFollowableEntityFactory)
    .to(PublicFollowableEntityFactory)
    .inSingletonScope()
  container.bind<IInvestorFactory>(symbols.IInvestorFactory).to(InvestorFactory).inSingletonScope()
  container.bind<IVehicleFactory>(symbols.IVehicleFactory).to(VehicleFactory).inSingletonScope()
  container.bind<IVehicleMemberFactory>(symbols.IVehicleMemberFactory).to(VehicleMemberFactory).inSingletonScope()
  container
    .bind<IVehicleItemRoundFactory>(symbols.IVehicleItemRoundFactory)
    .to(VehicleItemRoundFactory)
    .inSingletonScope()
  container.bind<IVehicleItemFactory>(symbols.IVehicleItemFactory).to(VehicleItemFactory).inSingletonScope()
  container.bind<IPortfolioNoteFactory>(symbols.IPortfolioNoteFactory).to(PortfolioNoteFactory).inSingletonScope()
  container
    .bind<IPortfolioMeetingFactory>(symbols.IPortfolioMeetingFactory)
    .to(PortfolioMeetingFactory)
    .inSingletonScope()
  container.bind<IPortfolioFactory>(symbols.IPortfolioFactory).to(PortfolioFactory).inSingletonScope()
  container.bind<IVehicleStatusFactory>(symbols.IVehicleStatusFactory).to(VehicleStatusFactory).inSingletonScope()

  // Binding Services to Gateways
  container.bind<IStorageService>(symbols.IStorageService).to(LocalStorageGateway).inSingletonScope()
  container.bind<IAuthService>(symbols.IAuthService).to(AuthAPIGateway).inSingletonScope()
  container.bind<IViewerService>(symbols.IViewerService).to(AppAPIGateway).inSingletonScope()
  container.bind<IUsersService>(symbols.IUsersService).to(AppAPIGateway).inSingletonScope()
  container.bind<ICompaniesService>(symbols.ICompaniesService).to(AppAPIGateway).inSingletonScope()
  container.bind<IArticlesService>(symbols.IArticlesService).to(AppAPIGateway).inSingletonScope()
  container.bind<IInvestmentsService>(symbols.IInvestmentsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IChatService>(symbols.IChatService).to(AppAPIGateway).inSingletonScope()
  container.bind<IMarketsService>(symbols.IMarketsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IArticleAttachmentService>(symbols.IArticleAttachmentService).to(AppAPIGateway).inSingletonScope()
  container.bind<INotificationsService>(symbols.INotificationsService).to(AppAPIGateway).inSingletonScope()
  container.bind<ILocationsService>(symbols.ILocationsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IPitchesService>(symbols.IPitchesService).to(AppAPIGateway).inSingletonScope()
  container.bind<IPitchAttachmentsService>(symbols.IPitchAttachmentsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IErrorTrackingService>(symbols.IErrorTrackingService).to(SentryNextjsGateway).inSingletonScope()
  container.bind<IJobCategoriesService>(symbols.IJobCategoriesService).to(AppAPIGateway).inSingletonScope()
  container.bind<IJobTagsService>(symbols.IJobTagsService).to(AppAPIGateway).inSingletonScope()
  container.bind<ISkillsService>(symbols.ISkillsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IJobsService>(symbols.IJobsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IJobApplicationsService>(symbols.IJobApplicationsService).to(AppAPIGateway).inSingletonScope()
  container.bind<ISubscriptionsService>(symbols.ISubscriptionsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IJobBookmarksService>(symbols.IJobBookmarksService).to(AppAPIGateway).inSingletonScope()
  container.bind<IUserReferencesService>(symbols.IUserReferencesService).to(AppAPIGateway).inSingletonScope()
  container.bind<ICompanyReferencesService>(symbols.ICompanyReferencesService).to(AppAPIGateway).inSingletonScope()
  container.bind<IInvitationsService>(symbols.IInvitationsService).to(AppAPIGateway).inSingletonScope()
  container.bind<IPMSService>(symbols.IPMSService).to(AppAPIGateway).inSingletonScope()
  container.bind<IForexService>(symbols.IForexService).to(AppAPIGateway).inSingletonScope()
  container.bind<ISubsidiesService>(symbols.ISubsidiesService).to(AppAPIGateway).inSingletonScope()

  // Binding UseCases to Interactors
  container.bind<IInitializeUseCase>(symbols.IInitializeUseCase).to(InitializeInteractor).inSingletonScope()
  container.bind<ISignInUseCase>(symbols.ISignInUseCase).to(SignInInteractor).inSingletonScope()
  container.bind<ISignUpUseCase>(symbols.ISignUpUseCase).to(SignUpInteractor).inSingletonScope()
  container.bind<ISignOutUseCase>(symbols.ISignOutUseCase).to(SignOutInteractor).inSingletonScope()
  container.bind<ILeaveServiceUseCase>(symbols.ILeaveServiceUseCase).to(LeaveServiceInteractor).inSingletonScope()
  container.bind<IUpdatePasswordUseCase>(symbols.IUpdatePasswordUseCase).to(UpdatePasswordInteractor).inSingletonScope()
  container
    .bind<IUpdatePasswordWithTokenUseCase>(symbols.IUpdatePasswordWithTokenUseCase)
    .to(UpdatePasswordWithTokenInteractor)
    .inSingletonScope()
  container.bind<IUpdateEmailUseCase>(symbols.IUpdateEmailUseCase).to(UpdateEmailInteractor).inSingletonScope()
  container.bind<IUpdateMeUseCase>(symbols.IUpdateMeUseCase).to(UpdateMeInteractor).inSingletonScope()
  container
    .bind<IUpdateMyProfileUseCase>(symbols.IUpdateMyProfileUseCase)
    .to(UpdateMyProfilelInteractor)
    .inSingletonScope()
  container
    .bind<ISendResetPasswordRequestUseCase>(symbols.ISendResetPasswordRequestUseCase)
    .to(SendResetPasswordRequestInteractor)
    .inSingletonScope()
  container.bind<IChangeLanguageUseCase>(symbols.IChangeLanguageUseCase).to(ChangeLanguageInteractor).inSingletonScope()
  container.bind<IFetchAngelsUseCase>(symbols.IFetchAngelsUseCase).to(FetchAngelsInteractor).inSingletonScope()
  container
    .bind<IFetchFeaturedAngelsUseCase>(symbols.IFetchFeaturedAngelsUseCase)
    .to(FetchFeaturedAngelsInteractor)
    .inSingletonScope()
  container.bind<IFetchUserUseCase>(symbols.IFetchUserUseCase).to(FetchUserInteractor).inSingletonScope()
  container.bind<IFetchCompaniesUseCase>(symbols.IFetchCompaniesUseCase).to(FetchCompaniesInteractor).inSingletonScope()
  container
    .bind<IFetchFeaturedVentureCapitalsUseCase>(symbols.IFetchFeaturedVentureCapitalsUseCase)
    .to(FetchFeaturedVentureCapitalsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchFeaturedStartupsUseCase>(symbols.IFetchFeaturedStartupsUseCase)
    .to(FetchFeaturedStartupsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchCompaniesWithJobsUseCase>(symbols.IFetchCompaniesWithJobsUseCase)
    .to(FetchCompaniesWithJobsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchFeaturedCompaniesWithJobsUseCase>(symbols.IFetchFeaturedCompaniesWithJobsUseCase)
    .to(FetchFeaturedCompaniesWithJobsInteractor)
    .inSingletonScope()
  container.bind<IFetchCompanyUseCase>(symbols.IFetchCompanyUseCase).to(FetchCompanyInteractor).inSingletonScope()
  container.bind<IFetchArticlesUseCase>(symbols.IFetchArticlesUseCase).to(FetchArticlesInteractor).inSingletonScope()
  container
    .bind<IFetchMagazineTopPageContentUseCase>(symbols.IFetchMagazineTopPageContentUseCase)
    .to(FetchMagazineTopPageContentInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMagazineArchivePageContentUseCase>(symbols.IFetchMagazineArchivePageContentUseCase)
    .to(FetchMagazineArchivePageContentInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMagazineSinglePageContentUseCase>(symbols.IFetchMagazineSinglePageContentUseCase)
    .to(FetchMagazineSinglePageContentInteractor)
    .inSingletonScope()
  container
    .bind<ICreateMyCompanyUseCase>(symbols.ICreateMyCompanyUseCase)
    .to(CreateMyCompanyInteractor)
    .inSingletonScope()
  container.bind<IUpdateCompanyUseCase>(symbols.IUpdateCompanyUseCase).to(UpdateCompanyInteractor).inSingletonScope()
  container.bind<IAddProductUseCase>(symbols.IAddProductUseCase).to(AddProductInteractor).inSingletonScope()
  container.bind<IUpdateProductUseCase>(symbols.IUpdateProductUseCase).to(UpdateProductInteractor).inSingletonScope()
  container.bind<IRemoveProductUseCase>(symbols.IRemoveProductUseCase).to(RemoveProductInteractor).inSingletonScope()
  container.bind<ISearchCompanyUseCase>(symbols.ISearchCompanyUseCase).to(SearchCompanyInteractor).inSingletonScope()
  container.bind<ICreateCompanyUseCase>(symbols.ICreateCompanyUseCase).to(CreateCompanyInteractor).inSingletonScope()
  container.bind<IAddExperienceUseCase>(symbols.IAddExperienceUseCase).to(AddExperienceInteractor).inSingletonScope()
  container
    .bind<IUpdateExperienceUseCase>(symbols.IUpdateExperienceUseCase)
    .to(UpdateExperienceInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveExperienceUseCase>(symbols.IRemoveExperienceUseCase)
    .to(RemoveExperienceInteractor)
    .inSingletonScope()
  container
    .bind<IAddCompanyInvestmentUseCase>(symbols.IAddCompanyInvestmentUseCase)
    .to(AddCompanyInvestmentInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateInvestmentUseCase>(symbols.IUpdateInvestmentUseCase)
    .to(UpdateInvestmentInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveInvestmentUseCase>(symbols.IRemoveInvestmentUseCase)
    .to(RemoveInvestmentInteractor)
    .inSingletonScope()
  container
    .bind<IAddCompanyMemberUseCase>(symbols.IAddCompanyMemberUseCase)
    .to(AddCompanyMemberInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCompanyMemberUseCase>(symbols.IUpdateCompanyMemberUseCase)
    .to(UpdateCompanyMemberInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveCompanyMemberUseCase>(symbols.IRemoveCompanyMemberUseCase)
    .to(RemoveCompanyMemberInteractor)
    .inSingletonScope()
  container.bind<ISearchUserUseCase>(symbols.ISearchUserUseCase).to(SearchUserInteractor).inSingletonScope()
  container
    .bind<IAddMyInvestmentUseCase>(symbols.IAddMyInvestmentUseCase)
    .to(AddMyInvestmentInteractor)
    .inSingletonScope()
  container.bind<IInitializeChatUseCase>(symbols.IInitializeChatUseCase).to(InitializeChatInteractor).inSingletonScope()
  container
    .bind<ICreateChatMessageThreadSubscriptionUseCase>(symbols.ICreateChatMessageThreadSubscriptionUseCase)
    .to(CreateChatMessageThreadSubscriptionInteractor)
    .inSingletonScope()
  container
    .bind<ISendChatMessageUseCase>(symbols.ISendChatMessageUseCase)
    .to(SendChatMessageInteractor)
    .inSingletonScope()
  container
    .bind<IFindOrCreateChatMessageThreadUseCase>(symbols.IFindOrCreateChatMessageThreadUseCase)
    .to(FindOrCreateChatMessageThreadInteractor)
    .inSingletonScope()
  container
    .bind<IFetchChatMessagesUseCase>(symbols.IFetchChatMessagesUseCase)
    .to(FetchChatMessagesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchChatMessageThreadsUseCase>(symbols.IFetchChatMessageThreadsUseCase)
    .to(FetchChatMessageThreadsInteractor)
    .inSingletonScope()
  container.bind<IFetchMarketsUseCase>(symbols.IFetchMarketsUseCase).to(FetchMarketsInteractor).inSingletonScope()
  container
    .bind<IMarkAllMessagesAsReadUseCase>(symbols.IMarkAllMessagesAsReadUseCase)
    .to(MarkAllMessagesAsReadInteractor)
    .inSingletonScope()
  container
    .bind<IFetchCompanyArticlesUseCase>(symbols.IFetchCompanyArticlesUseCase)
    .to(FetchCompanyArticlesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMyCompanyArticlesUseCase>(symbols.IFetchMyCompanyArticlesUseCase)
    .to(FetchMyCompanyArticlesInteractor)
    .inSingletonScope()
  container
    .bind<ICreateCompanyArticleUseCase>(symbols.ICreateCompanyArticleUseCase)
    .to(CreateCompanyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCompanyArticleUseCase>(symbols.IUpdateCompanyArticleUseCase)
    .to(UpdateCompanyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IToggleArticleLikedStateUseCase>(symbols.IToggleArticleLikedStateUseCase)
    .to(ToggleArticleLikedStateInteractor)
    .inSingletonScope()
  container
    .bind<ICreateArticleAttachmentUseCase>(symbols.ICreateArticleAttachmentUseCase)
    .to(CreateArticleAttachmentInteractor)
    .inSingletonScope()
  container.bind<IFetchArticleUseCase>(symbols.IFetchArticleUseCase).to(FetchArticleInteractor).inSingletonScope()
  container
    .bind<IMarkAllNotificationsAsReadUseCase>(symbols.IMarkAllNotificationsAsReadUseCase)
    .to(MarkAllNotificationsAsReadInteractor)
    .inSingletonScope()
  container.bind<IFetchLocationsUseCase>(symbols.IFetchLocationsUseCase).to(FetchLocationsInteractor).inSingletonScope()
  container.bind<IFetchLocationUseCase>(symbols.IFetchLocationUseCase).to(FetchLocationInteractor).inSingletonScope()
  container.bind<IFetchPitchesUseCase>(symbols.IFetchPitchesUseCase).to(FetchPitchesInteractor).inSingletonScope()
  container.bind<IFetchPitchUseCase>(symbols.IFetchPitchUseCase).to(FetchPitchInteractor).inSingletonScope()
  container.bind<ICreatePitchUseCase>(symbols.ICreatePitchUseCase).to(CreatePitchInteractor).inSingletonScope()
  container.bind<IDeletePitchUseCase>(symbols.IDeletePitchUseCase).to(DeletePitchInteractor).inSingletonScope()
  container.bind<IUpdatePitchUseCase>(symbols.IUpdatePitchUseCase).to(UpdatePitchInteractor).inSingletonScope()
  container
    .bind<IMarkPitchAsReadUseCase>(symbols.IMarkPitchAsReadUseCase)
    .to(MarkPitchAsReadInteractor)
    .inSingletonScope()
  container
    .bind<IAddPitchAttachmentUseCase>(symbols.IAddPitchAttachmentUseCase)
    .to(AddPitchAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IDeletePitchAttachmentUseCase>(symbols.IDeletePitchAttachmentUseCase)
    .to(DeletePitchAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IRemovePitchAttachmentFromPitchUseCase>(symbols.IRemovePitchAttachmentFromPitchUseCase)
    .to(RemovePitchAttachmentFromPitchInteractor)
    .inSingletonScope()
  container
    .bind<IUpdatePitchAttachmentUseCase>(symbols.IUpdatePitchAttachmentUseCase)
    .to(UpdatePitchAttachmentInteractor)
    .inSingletonScope()
  container.bind<IFetchMyPitchesUseCase>(symbols.IFetchMyPitchesUseCase).to(FetchMyPitchesInteractor).inSingletonScope()
  container
    .bind<ICreateEmptyDraftPitchUseCase>(symbols.ICreateEmptyDraftPitchUseCase)
    .to(CreateEmptyDraftPitchInteractor)
    .inSingletonScope()
  container
    .bind<IFetchJobCategoriesUseCase>(symbols.IFetchJobCategoriesUseCase)
    .to(FetchJobCategoriesInteractor)
    .inSingletonScope()
  container.bind<IFetchJobTagsUseCase>(symbols.IFetchJobTagsUseCase).to(FetchJobTagsInteractor).inSingletonScope()
  container.bind<IFetchSkillsUseCase>(symbols.IFetchSkillsUseCase).to(FetchSkillsInteractor).inSingletonScope()
  container.bind<ICreateSkillUseCase>(symbols.ICreateSkillUseCase).to(CreateSkillInteractor).inSingletonScope()
  container.bind<IFetchJobsUseCase>(symbols.IFetchJobsUseCase).to(FetchJobsInteractor).inSingletonScope()
  container
    .bind<IFetchCompanyJobsUseCase>(symbols.IFetchCompanyJobsUseCase)
    .to(FetchCompanyJobsInteractor)
    .inSingletonScope()
  container.bind<IFetchMyJobsUseCase>(symbols.IFetchMyJobsUseCase).to(FetchMyJobsInteractor).inSingletonScope()
  container.bind<IFetchJobUseCase>(symbols.IFetchJobUseCase).to(FetchJobInteractor).inSingletonScope()
  container.bind<ICreateJobUseCase>(symbols.ICreateJobUseCase).to(CreateJobInteractor).inSingletonScope()
  container.bind<IDuplicateJobUseCase>(symbols.IDuplicateJobUseCase).to(DuplicateJobInteractor).inSingletonScope()
  container.bind<IUpdateJobUseCase>(symbols.IUpdateJobUseCase).to(UpdateJobInteractor).inSingletonScope()
  container.bind<IDeleteJobUseCase>(symbols.IDeleteJobUseCase).to(DeleteJobInteractor).inSingletonScope()
  container
    .bind<IFetchJobFootprintsUseCase>(symbols.IFetchJobFootprintsUseCase)
    .to(FetchJobFootprintsInteractor)
    .inSingletonScope()
  container
    .bind<IAddFootprintToJobUseCase>(symbols.IAddFootprintToJobUseCase)
    .to(AddFootprintToJobInteractor)
    .inSingletonScope()
  container
    .bind<ICreateJobAttachmentUseCase>(symbols.ICreateJobAttachmentUseCase)
    .to(CreateJobAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IFetchJobApplicationsUseCase>(symbols.IFetchJobApplicationsUseCase)
    .to(FetchJobApplicationsInteractor)
    .inSingletonScope()
  container.bind<IApplyForJobUseCase>(symbols.IApplyForJobUseCase).to(ApplyForJobInteractor).inSingletonScope()
  container.bind<IUpdateInvestorUseCase>(symbols.IUpdateInvestorUseCase).to(UpdateInvestorInteractor).inSingletonScope()
  container.bind<IAddInvestorUseCase>(symbols.IAddInvestorUseCase).to(AddInvestorInteractor).inSingletonScope()
  container.bind<IRemoveInvestorUseCase>(symbols.IRemoveInvestorUseCase).to(RemoveInvestorInteractor).inSingletonScope()
  container
    .bind<ICreateBillingPortalSessionUseCase>(symbols.ICreateBillingPortalSessionUseCase)
    .to(CreateBillingPortalSessionInteractor)
    .inSingletonScope()
  container
    .bind<ICreateRecruitSubscriptionUseCase>(symbols.ICreateRecruitSubscriptionUseCase)
    .to(CreateRecruitSubscriptionInteractor)
    .inSingletonScope()
  container
    .bind<IFetchUserArticlesUseCase>(symbols.IFetchUserArticlesUseCase)
    .to(FetchUserArticlesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMyArticlesUseCase>(symbols.IFetchMyArticlesUseCase)
    .to(FetchMyArticlesInteractor)
    .inSingletonScope()
  container
    .bind<ICreateMyArticleUseCase>(symbols.ICreateMyArticleUseCase)
    .to(CreateMyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateMyArticleUseCase>(symbols.IUpdateMyArticleUseCase)
    .to(UpdateMyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteMyArticleUseCase>(symbols.IDeleteMyArticleUseCase)
    .to(DeleteMyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteCompanyArticleUseCase>(symbols.IDeleteCompanyArticleUseCase)
    .to(DeleteCompanyArticleInteractor)
    .inSingletonScope()
  container
    .bind<IFetchFeaturedStoriesUseCase>(symbols.IFetchFeaturedStoriesUseCase)
    .to(FetchFeaturedStoriesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMyJobBookmarksUseCase>(symbols.IFetchMyJobBookmarksUseCase)
    .to(FetchMyJobBookmarksInteractor)
    .inSingletonScope()
  container
    .bind<IAddMyJobBookmarkUseCase>(symbols.IAddMyJobBookmarkUseCase)
    .to(AddMyJobBookmarkInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveMyJobBookmarkUseCase>(symbols.IRemoveMyJobBookmarkUseCase)
    .to(RemoveMyJobBookmarkInteractor)
    .inSingletonScope()
  container
    .bind<IFetchJobBookmarksUseCase>(symbols.IFetchJobBookmarksUseCase)
    .to(FetchJobBookmarksInteractor)
    .inSingletonScope()
  container
    .bind<IReopenChatConnectionUseCase>(symbols.IReopenChatConnectionUseCase)
    .to(ReopenChatConnectionInteractor)
    .inSingletonScope()
  container
    .bind<IAddUserReferenceUseCase>(symbols.IAddUserReferenceUseCase)
    .to(AddUserReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateUserReferenceUseCase>(symbols.IUpdateUserReferenceUseCase)
    .to(UpdateUserReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveUserReferenceUseCase>(symbols.IRemoveUserReferenceUseCase)
    .to(RemoveUserReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMyReferencesUseCase>(symbols.IFetchMyReferencesUseCase)
    .to(FetchMyReferencesInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateMyReferenceUseCase>(symbols.IUpdateMyReferenceUseCase)
    .to(UpdateMyReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IAddCompanyReferenceUseCase>(symbols.IAddCompanyReferenceUseCase)
    .to(AddCompanyReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCompanyReferenceUseCase>(symbols.IUpdateCompanyReferenceUseCase)
    .to(UpdateCompanyReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveCompanyReferenceUseCase>(symbols.IRemoveCompanyReferenceUseCase)
    .to(RemoveCompanyReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IFetchMyCompanyReferencesUseCase>(symbols.IFetchMyCompanyReferencesUseCase)
    .to(FetchMyCompanyReferencesInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateMyCompanyReferenceUseCase>(symbols.IUpdateMyCompanyReferenceUseCase)
    .to(UpdateMyCompanyReferenceInteractor)
    .inSingletonScope()
  container
    .bind<IValidInvitationTokenUseCase>(symbols.IValidInvitationTokenUseCase)
    .to(ValidInvitationTokenInteractor)
    .inSingletonScope()
  container
    .bind<IAddAngelInvitationUseCase>(symbols.IAddAngelInvitationUseCase)
    .to(AddAngelInvitationInteractor)
    .inSingletonScope()
  container
    .bind<IAddVcInvitationUseCase>(symbols.IAddVcInvitationUseCase)
    .to(AddVcInvitationInteractor)
    .inSingletonScope()
  container
    .bind<IAddAngelInvestmentInvitationUseCase>(symbols.IAddAngelInvestmentInvitationUseCase)
    .to(AddAngelInvestmentInvitationInteractor)
    .inSingletonScope()
  container
    .bind<IAddVcInvestmentInvitationUseCase>(symbols.IAddVcInvestmentInvitationUseCase)
    .to(AddVcInvestmentInvitationInteractor)
    .inSingletonScope()
  container.bind<IAddMemberInvitationUseCase>(symbols.IAddMemberInvitationUseCase).to(AddMemberInvitationInteractor)
  container
    .bind<IToggleFollowCompanyUseCase>(symbols.IToggleFollowCompanyUseCase)
    .to(ToggleFollowCompanyInteractor)
    .inSingletonScope()
  container
    .bind<IToggleFollowUserUseCase>(symbols.IToggleFollowUserUseCase)
    .to(ToggleFollowUserInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateCompanyNotificationSettingsUseCase>(symbols.IUpdateCompanyNotificationSettingsUseCase)
    .to(UpdateCompanyNotificationSettingsInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateUserNotificationSettingsUseCase>(symbols.IUpdateUserNotificationSettingsUseCase)
    .to(UpdateUserNotificationSettingsInteractor)
    .inSingletonScope()
  container.bind<IFetchFollowingUseCase>(symbols.IFetchFollowingUseCase).to(FetchFollowingInteractor).inSingletonScope()
  container.bind<IFetchFollowersUseCase>(symbols.IFetchFollowersUseCase).to(FetchFollowersInteractor).inSingletonScope()
  container
    .bind<IFetchNotificationsUseCase>(symbols.IFetchNotificationsUseCase)
    .to(FetchNotificationsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchUserForViewerUseCase>(symbols.IFetchUserForViewerUseCase)
    .to(FetchUserForViewerInteractor)
    .inSingletonScope()
  container
    .bind<IFetchCompanyForViewerUseCase>(symbols.IFetchCompanyForViewerUseCase)
    .to(FetchCompanyForViewerInteractor)
    .inSingletonScope()
  container
    .bind<IFetchPickedUpFeaturesUseCase>(symbols.IFetchPickedUpFeaturesUseCase)
    .to(FetchPickedUpFeaturesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchFeaturedJobsUseCase>(symbols.IFetchFeaturedJobsUseCase)
    .to(FetchFeaturedJobsInteractor)
    .inSingletonScope()
  container
    .bind<IInitializeInvestorUseCase>(symbols.IInitializeInvestorUseCase)
    .to(InitializeInvestorInteractor)
    .inSingletonScope()
  container
    .bind<IInitializeVehicleUseCase>(symbols.IInitializeVehicleUseCase)
    .to(InitializeVehicleInteractor)
    .inSingletonScope()
  container
    .bind<IFetchVehicleAnalyticsUseCase>(symbols.IFetchVehicleAnalyticsUseCase)
    .to(FetchVehicleAnalyticsInteractor)
    .inSingletonScope()
  container
    .bind<IFetchVehicleItemUseCase>(symbols.IFetchVehicleItemUseCase)
    .to(FetchVehicleItemInteractor)
    .inSingletonScope()
  container
    .bind<IFetchVehicleItemsUseCase>(symbols.IFetchVehicleItemsUseCase)
    .to(FetchVehicleItemsInteractor)
    .inSingletonScope()
  container.bind<ICreateVehicleUseCase>(symbols.ICreateVehicleUseCase).to(CreateVehicleInteractor).inSingletonScope()
  container.bind<IUpdateVehicleUseCase>(symbols.IUpdateVehicleUseCase).to(UpdateVehicleInteractor).inSingletonScope()
  container.bind<IDeleteVehicleUseCase>(symbols.IDeleteVehicleUseCase).to(DeleteVehicleInteractor).inSingletonScope()
  container
    .bind<ICreateVehicleItemUseCase>(symbols.ICreateVehicleItemUseCase)
    .to(CreateVehicleItemInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleItemPortfolioUseCase>(symbols.IUpdateVehicleItemPortfolioUseCase)
    .to(UpdateVehicleItemPortfolioInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleItemUseCase>(symbols.IUpdateVehicleItemUseCase)
    .to(UpdateVehicleItemInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleItemAndPortfolioUseCase>(symbols.IUpdateVehicleItemAndPortfolioUseCase)
    .to(UpdateVehicleItemAndPortfolioInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteVehicleItemUseCase>(symbols.IDeleteVehicleItemUseCase)
    .to(DeleteVehicleItemInteractor)
    .inSingletonScope()
  container
    .bind<ICreateVehicleItemRoundUseCase>(symbols.ICreateVehicleItemRoundUseCase)
    .to(CreateVehicleItemRoundInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleItemRoundUseCase>(symbols.IUpdateVehicleItemRoundUseCase)
    .to(UpdateVehicleItemRoundInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteVehicleItemRoundUseCase>(symbols.IDeleteVehicleItemRoundUseCase)
    .to(DeleteVehicleItemRoundInteractor)
    .inSingletonScope()
  container
    .bind<IAddVehicleItemRoundAttachmentUseCase>(symbols.IAddVehicleItemRoundAttachmentUseCase)
    .to(AddVehicleItemRoundAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteVehicleItemRoundAttachmentUseCase>(symbols.IDeleteVehicleItemRoundAttachmentUseCase)
    .to(DeleteVehicleItemRoundAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<ICreatePortfolioNoteUseCase>(symbols.ICreatePortfolioNoteUseCase)
    .to(CreatePortfolioNoteInteractor)
    .inSingletonScope()
  container
    .bind<IUpdatePortfolioNoteUseCase>(symbols.IUpdatePortfolioNoteUseCase)
    .to(UpdatePortfolioNoteInteractor)
    .inSingletonScope()
  container
    .bind<IDeletePortfolioNoteUseCase>(symbols.IDeletePortfolioNoteUseCase)
    .to(DeletePortfolioNoteInteractor)
    .inSingletonScope()
  container
    .bind<ICreatePortfolioMeetingUseCase>(symbols.ICreatePortfolioMeetingUseCase)
    .to(CreatePortfolioMeetingInteractor)
    .inSingletonScope()
  container
    .bind<IUpdatePortfolioMeetingUseCase>(symbols.IUpdatePortfolioMeetingUseCase)
    .to(UpdatePortfolioMeetingInteractor)
    .inSingletonScope()
  container
    .bind<IDeletePortfolioMeetingUseCase>(symbols.IDeletePortfolioMeetingUseCase)
    .to(DeletePortfolioMeetingInteractor)
    .inSingletonScope()
  container
    .bind<IAddPortfolioAttachmentUseCase>(symbols.IAddPortfolioAttachmentUseCase)
    .to(AddPortfolioAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IDeletePortfolioAttachmentUseCase>(symbols.IDeletePortfolioAttachmentUseCase)
    .to(DeletePortfolioAttachmentInteractor)
    .inSingletonScope()
  container
    .bind<IAddVehicleMemberUseCase>(symbols.IAddVehicleMemberUseCase)
    .to(AddVehicleMemberInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleMemberUseCase>(symbols.IUpdateVehicleMemberUseCase)
    .to(UpdateVehicleMemberInteractor)
    .inSingletonScope()
  container
    .bind<IRemoveVehicleMemberUseCase>(symbols.IRemoveVehicleMemberUseCase)
    .to(RemoveVehicleMemberInteractor)
    .inSingletonScope()
  container
    .bind<ICreateVehicleStatusUseCase>(symbols.ICreateVehicleStatusUseCase)
    .to(CreateVehicleStatusInteractor)
    .inSingletonScope()
  container
    .bind<IUpdateVehicleStatusUseCase>(symbols.IUpdateVehicleStatusUseCase)
    .to(UpdateVehicleStatusInteractor)
    .inSingletonScope()
  container
    .bind<IDeleteVehicleStatusUseCase>(symbols.IDeleteVehicleStatusUseCase)
    .to(DeleteVehicleStatusInteractor)
    .inSingletonScope()
  container
    .bind<IFetchLatestForexRateUseCase>(symbols.IFetchLatestForexRateUseCase)
    .to(FetchLatestForexRateInteractor)
    .inSingletonScope()
  container
    .bind<IFetchAllForexRatesUseCase>(symbols.IFetchAllForexRatesUseCase)
    .to(FetchAllForexRatesInteractor)
    .inSingletonScope()
  container.bind<IFetchSubsidiesUseCase>(symbols.IFetchSubsidiesUseCase).to(FetchSubsidiesInteractor).inSingletonScope()
  container.bind<IFetchSubsidyUseCase>(symbols.IFetchSubsidyUseCase).to(FetchSubsidyInteractor).inSingletonScope()
  container
    .bind<IFetchSubsidyPurposesUseCase>(symbols.IFetchSubsidyPurposesUseCase)
    .to(FetchSubsidyPurposesInteractor)
    .inSingletonScope()
  container
    .bind<IFetchSubsidyPurposeUseCase>(symbols.IFetchSubsidyPurposeUseCase)
    .to(FetchSubsidyPurposeInteractor)
    .inSingletonScope()
  return container
}

const emptyContainer = new Container()
const container = createContainer(emptyContainer)

export { container, createContainer }
