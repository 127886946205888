import { action, computed, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  IChatMessageThread,
  IErrorsStore,
  IFetchJobApplicationsUseCase,
  IJobApplication,
  IJobApplicationsStore,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'JobApplicationsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class JobApplicationsStore implements IJobApplicationsStore {
  @observable jobApplications: IJobApplication[] = []

  @observable isInitialized = false

  @observable isInitializing = false

  @observable companySlug = ''

  @observable messageThreads: IChatMessageThread[] = []

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchJobApplicationsUseCase)
    private fetchJobApplicationsUseCase: IFetchJobApplicationsUseCase
  ) {
    //
  }

  @computed
  get latestJobApplication(): IJobApplication {
    if (this.jobApplications.length === 0) {
      return null
    }

    return this.jobApplications[0]
  }

  @action
  _updateJobApplications(jobApplications: IJobApplication[]): void {
    this.jobApplications = jobApplications
  }

  @action
  _updateInitializedState(state: boolean): void {
    this.isInitialized = state
  }

  @action
  _updateInitializingState(state: boolean): void {
    this.isInitializing = state
  }

  @action
  _updateCompanySlug(companySlug: string): void {
    this.companySlug = companySlug
  }

  @action
  _updateMessageThreads(jobApplications: IJobApplication[]): void {
    this.messageThreads = jobApplications.map((j) => j.messageThread)
  }

  /**
   * チャット機能の初期化処理
   * 返り値の boolean は正常終了可どうかを返す
   */
  async init(companySlug: string): Promise<boolean> {
    // 重複して呼び出されないように初期化中は false を返す
    if (this.isInitializing) {
      return false
    }
    // すでに初期化済みの場合は処理をスキップ
    if (this.isInitialized && this.companySlug === companySlug) {
      return true
    }

    // 初期化中フラグを立てる
    this._updateInitializingState(true)

    const output = await this.fetchJobApplicationsUseCase.handle({
      companySlug,
      limitOfMessage: 1,
      limitOfJopApplication: 100,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updateCompanySlug(companySlug)
    this._updateJobApplications(output.jobApplications)
    this._updateMessageThreads(output.jobApplications)
    this._updateInitializedState(true)
    this._updateInitializingState(false)

    return true
  }

  findJobApplicationByMessageThreadSlug(slug: string): IJobApplication {
    return this.jobApplications.find((j) => j.messageThread.slug === slug)
  }

  @computed
  get numberOfJobApllications(): number {
    return this.jobApplications.length
  }
}
