import { inject, injectable } from 'inversify'
import {
  FetchCompanyUseCaseInput,
  FetchCompanyUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  IFetchCompanyUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchCompanyInteractor implements IFetchCompanyUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchCompanyUseCaseInput): Promise<FetchCompanyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.company = await this.companiesService.fetchCompany(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCompanyUseCaseOutput {
    return {
      company: null,
      error: null,
    }
  }
}
