import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateBillingPortalSessionUseCaseInput,
  CreateBillingPortalSessionUseCaseOutput,
  IAppErrorFactory,
  ICreateBillingPortalSessionUseCase,
  ISubscriptionsService,
} from '../../types'

@injectable()
export default class CreateBillingPortalSessionInteractor implements ICreateBillingPortalSessionUseCase {
  @inject(symbols.ISubscriptionsService) private subscriptionsService: ISubscriptionsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateBillingPortalSessionUseCaseInput): Promise<CreateBillingPortalSessionUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const billingPortalBase = await this.subscriptionsService.createBillingPortalSession(input)
      output.billingPortal = billingPortalBase
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '請求管理画面への遷移が失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateBillingPortalSessionUseCaseOutput {
    return {
      billingPortal: null,
      error: null,
    }
  }
}
