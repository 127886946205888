type Extra = {
  input: RequestInfo
  init?: RequestInit
}

// request 時の timeout をハンドリングするエラー
export default class RequestTimeoutError extends Error {
  // 追加のデバッグ情報
  private extra: Extra = null

  constructor(message: string, extra?: Extra) {
    super(message)
    this.name = new.target.name
    if (extra) {
      this.extra = this._filterExtra(extra)
    }
    Object.setPrototypeOf(this, new.target.prototype)
  }

  private _filterExtra(extra: Extra): Extra {
    if (extra?.init?.headers?.['access-token']) {
      extra.init.headers['access-token'] = 'FILTERED'
    }
    return extra
  }
}
