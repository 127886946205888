import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreatePitchUseCaseInput,
  CreatePitchUseCaseOutput,
  IAppErrorFactory,
  ICreatePitchUseCase,
  IPitchesService,
  IPitchFactory,
} from '../../types'

@injectable()
export default class CreatePitchInteractor implements ICreatePitchUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IPitchFactory) private pitchFactory: IPitchFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreatePitchUseCaseInput): Promise<CreatePitchUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const base = await this.pitchesService.createPitch(input)
      output.pitch = this.pitchFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ラウンドの追加に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreatePitchUseCaseOutput {
    return {
      pitch: null,
      error: null,
    }
  }
}
