import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import { action, observable } from 'mobx'
import { IMessage, IMessageBase, IMessageFactory, IMessagesStore } from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'MessagesStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class MessagesStore implements IMessagesStore {
  @observable messages = []

  constructor(@inject(symbols.IMessageFactory) private messageFactory: IMessageFactory) {
    // TODO: MobX 6 にアップグレード時にコンストラクタ実装
  }

  @action
  add(message: IMessageBase): void {
    // 追加時に削除処理を登録
    const onHide = (m: IMessage) => {
      // 非表示時のアニメーションの実行時間を待機（削除は別に急がないのでざっくり1秒）
      const animationDuration = 1000
      setTimeout(() => {
        this.remove(m)
      }, animationDuration)
    }
    const m = this.messageFactory.create({
      message,
      onHide,
    })
    this.messages.push(m)
  }

  @action
  remove(message: IMessage): void {
    // key がマッチしないものだけ残す
    this.messages = this.messages.filter((m: IMessage) => {
      return m.key !== message.key
    })
  }
}
