import { action, observable } from 'mobx'
import {
  ICompanyBase,
  IErrorsStore,
  IInvestment,
  IInvestmentBase,
  IInvestmentCommentBase,
  IInvestmentInputBase,
  IInvestorInputBase,
  IRound,
  IUpdateInvestmentUseCase,
  IUpdateInvestorUseCase,
  IUserBase,
} from '@/types'

export default class Investment implements IInvestment {
  @observable id = ''

  @observable investedAt: string

  @observable company: ICompanyBase

  @observable angel: IUserBase

  @observable round: IRound

  @observable isLeadInvestor: boolean

  @observable referenceUrl: string

  @observable startupComment: IInvestmentCommentBase

  @observable investorComment: IInvestmentCommentBase

  errorsStore: IErrorsStore

  updateInvestmentUseCase: IUpdateInvestmentUseCase

  updateInvestorUseCase: IUpdateInvestorUseCase

  @action
  _mapFromBase(base: IInvestmentBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IInvestmentBase) {
    this._mapFromBase(base)
  }

  async save(investment: IInvestmentInputBase): Promise<boolean> {
    const output = await this.updateInvestmentUseCase.handle({
      investment,
      id: this.id,
    })

    if (output.investment) {
      this._mapFromBase(output.investment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async saved_by_startup(investment: IInvestorInputBase): Promise<boolean> {
    const output = await this.updateInvestorUseCase.handle({
      investment,
      id: this.id,
    })

    if (output.investment) {
      this._mapFromBase(output.investment)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
