import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  ICompanyReferencesService,
  IRemoveCompanyReferenceUseCase,
  RemoveCompanyReferenceUseCaseInput,
  RemoveCompanyReferenceUseCaseOutput,
} from '../../types'

@injectable()
export default class RemoveCompanyReferenceInteractor implements IRemoveCompanyReferenceUseCase {
  @inject(symbols.ICompanyReferencesService) private companyReferencesService: ICompanyReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveCompanyReferenceUseCaseInput): Promise<RemoveCompanyReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companyReference = await this.companyReferencesService.removeCompanyReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの削除に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveCompanyReferenceUseCaseOutput {
    return {
      companyReference: null,
      error: null,
    }
  }
}
