import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IAuthService,
  ISendResetPasswordRequestUseCase,
  SendResetPasswordRequestUseCaseInput,
  SendResetPasswordRequestUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class SendResetPasswordRequestInteractor implements ISendResetPasswordRequestUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: SendResetPasswordRequestUseCaseInput): Promise<SendResetPasswordRequestUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.authService.sendPasswordResetWithToken(input.email)

      output.isSuccessful = true
    } catch (e) {
      output.isSuccessful = false
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI:
          'パスワードのリセットに失敗しました。パスワードを再設定するアカウントのメールアドレスをご入力ください。',
      })
    }

    return output
  }

  private getDefaultOutputData(): SendResetPasswordRequestUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
