import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  ArticleBase,
  FetchAngelsStoreInput,
  FetchUserArticlesStoreInput,
  FetchUserStoreInput,
  FollowableEntityEntityType,
  IArticleBase,
  IErrorsStore,
  IFetchAngelsUseCase,
  IFetchFeaturedAngelsUseCase,
  IFetchFollowersUseCase,
  IFetchFollowingUseCase,
  IFetchUserArticlesUseCase,
  IFetchUserUseCase,
  IFollowableEntityBase,
  IPublicFollowableEntity,
  IPublicFollowableEntityFactory,
  IPublicUser,
  IPublicUserFactory,
  ISearchUserUseCase,
  IUser,
  IUserBase,
  IUsersStore,
  SearchUserStoreInput,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'UsersStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class UsersStore implements IUsersStore {
  @observable angels: IUser[] = []

  @observable searchResults: IUserBase[] = []

  @observable searchWord = ''

  @observable following: Record<string, IPublicFollowableEntity[]> = {}

  @observable followers: Record<string, IPublicUser[]> = {}

  @observable publicUsers: Record<string, IPublicUser> = {}

  @observable hasNextAngelsPage = true

  @observable hasNextFollowingPage: Record<string, boolean> = {}

  @observable hasNextFollowersPage: Record<string, boolean> = {}

  @observable userArticles: Record<string, IArticleBase[]> = {}

  @observable hasNextUserArticlesPage: Record<string, boolean> = {}

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchAngelsUseCase) private fetchAngelsUseCase: IFetchAngelsUseCase,
    @inject(symbols.IFetchFeaturedAngelsUseCase) private fetchFeaturedAngelsUseCase: IFetchFeaturedAngelsUseCase,
    @inject(symbols.IFetchUserUseCase) private fetchUserUseCase: IFetchUserUseCase,
    @inject(symbols.ISearchUserUseCase) private searchUserUseCase: ISearchUserUseCase,
    @inject(symbols.IFetchUserArticlesUseCase) private fetchUserArticlesUseCase: IFetchUserArticlesUseCase,
    @inject(symbols.IFetchFollowingUseCase) private fetchFollowingUseCase: IFetchFollowingUseCase,
    @inject(symbols.IFetchFollowersUseCase) private fetchFollowersUseCase: IFetchFollowersUseCase,
    @inject(symbols.IPublicUserFactory) private publicUserFactory: IPublicUserFactory,
    @inject(symbols.IPublicFollowableEntityFactory)
    private publicFollowableEntityFactory: IPublicFollowableEntityFactory
  ) {
    //
  }

  @action
  _updateAngels(angels: IUser[]): void {
    this.angels = angels
  }

  @action
  _addAngels(angels: IUser[]): void {
    angels.forEach((newAngel) => {
      // 重複していたら処理をスキップ
      if (this.angels.some((a) => a.username === newAngel.username)) {
        return
      }

      // 末尾に追加
      this.angels = this.angels.concat(newAngel)
    })
  }

  @action
  updateHasNextAngelsPage(hasNextPage: boolean): void {
    this.hasNextAngelsPage = hasNextPage
  }

  @action
  _updateSearchResults(users: IUserBase[]): void {
    this.searchResults = users
  }

  @action
  _updateSearchWord(searchWord: string): void {
    this.searchWord = searchWord
  }

  @action
  resetSearchResults(): void {
    this.searchResults = []
    this.searchWord = ''
  }

  // Following
  @action
  _addFollowing(username: string, followableEntities: IFollowableEntityBase[]): void {
    followableEntities.forEach((newFollowableEntity) => {
      if (this.following[username].some((f) => f.slug === newFollowableEntity.slug)) {
        return
      }

      const newFollowableInstance = this._createFollowing(newFollowableEntity)
      this.following[username] = this.following[username].concat(newFollowableInstance)
    })
  }

  @action
  updateFollowing(username: string, followableEntities: IFollowableEntityBase[]): void {
    this.following[username] = followableEntities.map((newFollowalbeEntity) => {
      return this._createFollowing(newFollowalbeEntity)
    })
  }

  @action
  _createFollowing(base: IFollowableEntityBase): IPublicFollowableEntity {
    return this.publicFollowableEntityFactory.create({ base })
  }

  @action
  updateHasNextFollowingPage(username: string, hasNextPage: boolean): void {
    this.hasNextFollowingPage[username] = hasNextPage
  }

  // =========== Followers ===========
  @action
  _addFollowers(username: string, users: IUserBase[]): void {
    users.forEach((newUser) => {
      if (this.followers[username].some((f) => f.username === newUser.username)) {
        return
      }

      const newUserInstance = this._createFollower(newUser)
      this.followers[username] = this.followers[username].concat(newUserInstance)
    })
  }

  @action
  updateFollowers(username: string, users: IUserBase[]): void {
    this.followers[username] = users.map((newUser) => {
      return this._createFollower(newUser)
    })
  }

  @action
  _createFollower(base: IUserBase): IPublicUser {
    return this.publicUserFactory.create({ base })
  }

  @action
  updateHasNextFollowersPage(username: string, hasNextPage: boolean): void {
    this.hasNextFollowersPage[username] = hasNextPage
  }
  // =========== Followers ===========

  // =========== userArticles ===========
  @action
  _addUserArticles(username: string, articles: IArticleBase[]): void {
    articles.forEach((newArticle) => {
      if (this.userArticles[username].some((a) => a.slug === newArticle.slug)) {
        return
      }

      this.userArticles[username] = this.userArticles[username].concat(newArticle)
    })
  }

  @action
  _updateUserArticles(username: string, articles: IArticleBase[]): void {
    this.userArticles[username] = articles
  }

  @action
  updateHasNextUserArticlesPage(username: string, hasNextPage: boolean): void {
    this.hasNextUserArticlesPage[username] = hasNextPage
  }
  // =========== userArticles ===========

  async fetchAngels(input: FetchAngelsStoreInput): Promise<IUser[]> {
    const output = await this.fetchAngelsUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateAngels(output.angels)
    } else {
      this._addAngels(output.angels)
    }
    this.updateHasNextAngelsPage(output.hasNextPage)

    return output.angels
  }

  async fetchFeaturedAngels(limit: number): Promise<IUser[]> {
    const output = await this.fetchFeaturedAngelsUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._updateAngels(output.featuredAngels)

    return this.angels
  }

  async fetchUser(input: FetchUserStoreInput): Promise<IUser> {
    const output = await this.fetchUserUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.user
  }

  async searchUser(input: SearchUserStoreInput): Promise<IUserBase[]> {
    this._updateSearchWord(input.searchWord)

    const output = await this.searchUserUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._updateSearchResults(output.users)

    return this.searchResults
  }

  async fetchUserArticles(input: FetchUserArticlesStoreInput): Promise<ArticleBase[]> {
    const output = await this.fetchUserArticlesUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateUserArticles(input.username, output.articles)
    } else {
      this._addUserArticles(input.username, output.articles)
    }

    this.updateHasNextUserArticlesPage(input.username, output.hasNextPage)
    return output.articles
  }

  async fetchFollowing(username: string, limit: number, shouldRefresh: boolean): Promise<IFollowableEntityBase[]> {
    const output = await this.fetchFollowingUseCase.handle({ username, limit, shouldRefresh })

    if (output.error) {
      return []
    }

    if (shouldRefresh) {
      this.updateFollowing(username, output.data.following)
    } else {
      this._addFollowing(username, output.data.following)
    }

    this.updateHasNextFollowingPage(username, output.data.hasNextPage)
    return output.data.following
  }

  async fetchFollowers(username: string, limit: number, shouldRefresh: boolean): Promise<IUserBase[]> {
    const output = await this.fetchFollowersUseCase.handle({
      slug: username,
      entityType: FollowableEntityEntityType.USER,
      limit,
      shouldRefresh,
    })

    if (output.error) {
      return []
    }

    if (shouldRefresh) {
      this.updateFollowers(username, output.data.followers)
    } else {
      this._addFollowers(username, output.data.followers)
    }

    this.updateHasNextFollowersPage(username, output.data.hasNextPage)
    return output.data.followers
  }

  createUserInstance(base: IUserBase): IPublicUser {
    if (!base) {
      return null
    }

    if (this.publicUsers[base?.username]) {
      return this.publicUsers[base?.username]
    }

    const instance = this.publicUserFactory.create({ base })
    this.publicUsers[instance.username] = instance

    return instance
  }
}
