import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyArticlesUseCaseInput,
  FetchMyArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMyArticlesUseCase,
  IUseCaseOutputFactory,
  IUserArticle,
  IUserArticleFactory,
} from '../../types'

@injectable()
export default class FetchMyArticlesInteractor implements IFetchMyArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IUserArticleFactory) private userArticleFactory: IUserArticleFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    articles: null | IUserArticle[]
    hasNextPage: null | boolean
    endCursor: string
  }>

  async handle(input: FetchMyArticlesUseCaseInput): Promise<FetchMyArticlesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        articles: null,
        hasNextPage: null,
        endCursor: null,
      },
    })
    try {
      const { articles, hasNextPage, endCursor } = await this.articlesService.fetchMyArticles(input)

      output.data.articles = articles.map((a) => this.userArticleFactory.create({ base: a }))
      output.data.hasNextPage = hasNextPage
      output.data.endCursor = endCursor
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
