import { useStaticRendering } from 'mobx-react'
import { container } from '@/inversify.config'
import symbols from '@/symbols'
import {
  IArticlesStore,
  IChatStore,
  ICompaniesStore,
  IErrorsStore,
  IJobApplicationsStore,
  IJobsStore,
  IMessagesStore,
  IPMSStore,
  IStores,
  ISubsidiesStore,
  IUIStore,
  IUsersStore,
  IViewerStore,
} from '@/types'

// 開発時のSSRのメモリリーク対策
// cf., https://mobx-react.js.org/recipes-ssr
// eslint-disable-next-line react-hooks/rules-of-hooks
useStaticRendering(typeof window === 'undefined')

// jest setup の際 container が null で処理が実行されないので、三項演算子を追加
const stores: IStores = container
  ? {
      ui: container.get<IUIStore>(symbols.IUIStore),
      messages: container.get<IMessagesStore>(symbols.IMessagesStore),
      errors: container.get<IErrorsStore>(symbols.IErrorsStore),
      viewer: container.get<IViewerStore>(symbols.IViewerStore),
      users: container.get<IUsersStore>(symbols.IUsersStore),
      companies: container.get<ICompaniesStore>(symbols.ICompaniesStore),
      articles: container.get<IArticlesStore>(symbols.IArticlesStore),
      chat: container.get<IChatStore>(symbols.IChatStore),
      jobApplications: container.get<IJobApplicationsStore>(symbols.IJobApplicationStore),
      jobs: container.get<IJobsStore>(symbols.IJobsStore),
      pms: container.get<IPMSStore>(symbols.IPMSStore),
      subsidies: container.get<ISubsidiesStore>(symbols.ISubsidiesStore),
    }
  : null

export default stores
