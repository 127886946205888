import { action, observable } from 'mobx'
import { IChatMessageThread, IErrorsStore, IJobApplication, IJobApplicationBase, IJobBase, IUserBase } from '@/types'

export default class JobApplication implements IJobApplication {
  @observable id = ''

  @observable user: IUserBase

  @observable job: IJobBase

  @observable messageThread: IChatMessageThread

  errorsStore: IErrorsStore

  @action
  _mapBase(base: IJobApplicationBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IJobApplicationBase) {
    this._mapBase(base)
  }
}
