import { inject, injectable } from 'inversify'
import {
  CreateCompanyUseCaseInput,
  CreateCompanyUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  ICompanyFactory,
  ICreateCompanyUseCase,
} from '@/types'
import symbols from '@/symbols'
import { filterBase64, getValidUrl } from '@/utils'

@injectable()
export default class CreateCompanyInteractor implements ICreateCompanyUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.ICompanyFactory) private companyFactory: ICompanyFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateCompanyUseCaseInput): Promise<CreateCompanyUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // URL のプロトコル部分を調整
      input.company.url = getValidUrl(input.company.url)

      output.company = await this.companiesService.createCompany(input.company)
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.company.logo = filterBase64(input.company.logo)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '会社情報作成に失敗しました',
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateCompanyUseCaseOutput {
    return {
      company: null,
      error: null,
    }
  }
}
