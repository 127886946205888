import {
  AddPortfolioAttachmentUseCaseInput,
  AddPortfolioAttachmentUseCaseOutput,
  CompanySize,
  CreatePortfolioMeetingUseCaseInput,
  CreatePortfolioMeetingUseCaseOutput,
  CreatePortfolioNoteUseCaseInput,
  CreatePortfolioNoteUseCaseOutput,
  DeletePortfolioAttachmentUseCaseInput,
  DeletePortfolioAttachmentUseCaseOutput,
  DeletePortfolioMeetingUseCaseInput,
  DeletePortfolioMeetingUseCaseOutput,
  DeletePortfolioNoteUseCaseInput,
  DeletePortfolioNoteUseCaseOutput,
  IAcquisitionChannelBase,
  IAddPortfolioAttachmentUseCase,
  ICompanyBase,
  ICreatePortfolioMeetingUseCase,
  ICreatePortfolioNoteUseCase,
  IDeletePortfolioAttachmentUseCase,
  IDeletePortfolioMeetingUseCase,
  IDeletePortfolioNoteUseCase,
  ILocation,
  IMarket,
  IPortfolio,
  IPortfolioAttachmentBase,
  IPortfolioBase,
  IPortfolioInputBase,
  IPortfolioMeeting,
  IPortfolioNote,
  IRound,
  IUpdateVehicleItemPortfolioUseCase,
  PortfolioMeetingStatusBase,
  UpdateVehicleItemPortfolioUseCaseOutput,
} from '@/types'
import { action, observable, computed } from 'mobx'

type Deps = {
  allPortfolioNotes: IPortfolioNote[]
  allPortfolioMeetings: IPortfolioMeeting[]
  updateVehicleItemPortfolioUseCase: IUpdateVehicleItemPortfolioUseCase
  createPortfolioNoteUseCase: ICreatePortfolioNoteUseCase
  deletePortfolioNoteUseCase: IDeletePortfolioNoteUseCase
  createPortfolioMeetingUseCase: ICreatePortfolioMeetingUseCase
  deletePortfolioMeetingUseCase: IDeletePortfolioMeetingUseCase
  addPortfolioAttachmentUseCase: IAddPortfolioAttachmentUseCase
  deletePortfolioAttachmentUseCase: IDeletePortfolioAttachmentUseCase
}

export default class Portfolio implements IPortfolio {
  @observable aboutUs: string

  @observable acquisitionChannel: IAcquisitionChannelBase

  @observable company: ICompanyBase

  @observable companySize: CompanySize

  @observable id: string

  @observable locations: ILocation[]

  @observable markets: IMarket[]

  @observable portfolioAttachments: IPortfolioAttachmentBase[]

  portfolioNotes

  @observable allPortfolioNotes: IPortfolioNote[]

  portfolioMeetings

  @observable allPortfolioMeetings: IPortfolioMeeting[] = []

  @observable round: IRound

  base: IPortfolioBase

  updateVehicleItemPortfolioUseCase: IUpdateVehicleItemPortfolioUseCase

  createPortfolioNoteUseCase: ICreatePortfolioNoteUseCase

  deletePortfolioNoteUseCase: IDeletePortfolioNoteUseCase

  createPortfolioMeetingUseCase: ICreatePortfolioMeetingUseCase

  deletePortfolioMeetingUseCase: IDeletePortfolioMeetingUseCase

  addPortfolioAttachmentUseCase: IAddPortfolioAttachmentUseCase

  deletePortfolioAttachmentUseCase: IDeletePortfolioAttachmentUseCase

  @computed
  get portfolioTodoMeetings(): IPortfolioMeeting[] {
    return this.allPortfolioMeetings.filter((m) => m.status === PortfolioMeetingStatusBase.TODO)
  }

  @computed
  get portfolioDoneMeetings(): IPortfolioMeeting[] {
    return this.allPortfolioMeetings.filter((m) => m.status === PortfolioMeetingStatusBase.DONE)
  }

  constructor(base: IPortfolioBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IPortfolioBase): void {
    this.base = base
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  _prependPortfolioNote(note: IPortfolioNote): void {
    // 先頭に追加
    this.allPortfolioNotes.unshift(note)
  }

  @action
  _removePortfolioNote(id: string): void {
    this.allPortfolioNotes = this.allPortfolioNotes.filter((n) => n.id !== id)
  }

  @action
  // startAt 降順ソートを考慮して追加
  _addPortfolioMeeting(meeting: IPortfolioMeeting): void {
    const newStartAtDate = new Date(meeting.startAt).getTime()

    const index = this.allPortfolioMeetings.findIndex((m) => {
      const startAtDate = new Date(m.startAt).getTime()
      return startAtDate < newStartAtDate
    })
    if (index === -1) {
      this.allPortfolioMeetings.push(meeting)
    } else {
      this.allPortfolioMeetings.splice(index, 0, meeting)
    }
  }

  @action
  _removePortfolioMeeting(id: string): void {
    this.allPortfolioMeetings = this.allPortfolioMeetings.filter((m) => m.id !== id)
  }

  @action
  _addPortfolioAttachment(attachment: IPortfolioAttachmentBase): void {
    this.portfolioAttachments.push(attachment)
  }

  @action
  _removePortfolioAttachment(id: string): void {
    this.portfolioAttachments = this.portfolioAttachments.filter((a) => a.id !== id)
  }

  update(base: IPortfolioBase): void {
    this._mapBase(base)
  }

  async save(input: {
    vehicleItemId: string
    portfolio: IPortfolioInputBase
  }): Promise<UpdateVehicleItemPortfolioUseCaseOutput> {
    const output = await this.updateVehicleItemPortfolioUseCase.handle({
      id: this.id,
      vehicleItemId: input.vehicleItemId,
      portfolio: input.portfolio,
    })
    if (output.isSuccessful) {
      this._mapBase(output.data.portfolio)
    }
    return output
  }

  async addPortfolioNote(input: CreatePortfolioNoteUseCaseInput): Promise<CreatePortfolioNoteUseCaseOutput> {
    const output = await this.createPortfolioNoteUseCase.handle(input)
    if (output.isSuccessful) {
      this._prependPortfolioNote(output.data.portfolioNote)
    }
    return output
  }

  async deletePortfolioNote(input: DeletePortfolioNoteUseCaseInput): Promise<DeletePortfolioNoteUseCaseOutput> {
    const output = await this.deletePortfolioNoteUseCase.handle(input)
    if (output.isSuccessful) {
      this._removePortfolioNote(output.data.portfolioNote.id)
    }
    return output
  }

  async addPortfolioMeeting(input: CreatePortfolioMeetingUseCaseInput): Promise<CreatePortfolioMeetingUseCaseOutput> {
    const output = await this.createPortfolioMeetingUseCase.handle(input)
    if (output.isSuccessful) {
      // 新しく追加したミーティングが見えるようにスクロールするため
      output.data.portfolioMeeting.shouldScrollTo = true
      this._addPortfolioMeeting(output.data.portfolioMeeting)
    }
    return output
  }

  async deletePortfolioMeeting(
    input: DeletePortfolioMeetingUseCaseInput
  ): Promise<DeletePortfolioMeetingUseCaseOutput> {
    const output = await this.deletePortfolioMeetingUseCase.handle(input)
    if (output.isSuccessful) {
      this._removePortfolioMeeting(output.data.portfolioMeeting.id)
    }
    return output
  }

  async addPortfolioAttachment(
    input: AddPortfolioAttachmentUseCaseInput
  ): Promise<AddPortfolioAttachmentUseCaseOutput> {
    const output = await this.addPortfolioAttachmentUseCase.handle(input)
    if (output.isSuccessful) {
      this._addPortfolioAttachment(output.data.attachment)
    }
    return output
  }

  async deletePortfolioAttachment(
    input: DeletePortfolioAttachmentUseCaseInput
  ): Promise<DeletePortfolioAttachmentUseCaseOutput> {
    const output = await this.deletePortfolioAttachmentUseCase.handle(input)
    if (output.isSuccessful) {
      this._removePortfolioAttachment(output.data.attachment.id)
    }
    return output
  }
}
