import {
  AddVehicleItemRoundAttachmentUseCaseOutput,
  DeleteVehicleItemRoundAttachmentUseCaseOutput,
  IAddVehicleItemRoundAttachmentUseCase,
  IDeleteVehicleItemRoundAttachmentUseCase,
  IRoundBase,
  IUpdateVehicleItemRoundUseCase,
  IVehicleItemRound,
  IVehicleItemRoundAttachmentBase,
  IVehicleItemRoundAttachmentInputBase,
  IVehicleItemRoundBase,
  IVehicleItemRoundInput,
  UpdateVehicleItemRoundUseCaseOutput,
  VehicleItemRoundInstrument,
} from '@/types'
import { action, observable } from 'mobx'

type Deps = {
  updateVehicleItemRoundUseCase: IUpdateVehicleItemRoundUseCase
  addVehicleItemRoundAttachmentUseCase: IAddVehicleItemRoundAttachmentUseCase
  deleteVehicleItemRoundAttachmentUseCase: IDeleteVehicleItemRoundAttachmentUseCase
}

export default class VehicleItemRound implements IVehicleItemRound {
  @observable currentValuation: string

  @observable discount: number

  @observable id: string

  @observable instrument: VehicleItemRoundInstrument

  @observable investedAt: string

  @observable investmentAmount: string

  @observable postValuation: string

  @observable preValuation: string

  @observable ratioAtInvestment: number

  @observable round: IRoundBase

  @observable valuationCap: string

  @observable vehicleItemRoundAttachments: IVehicleItemRoundAttachmentBase[]

  base: IVehicleItemRoundBase

  updateVehicleItemRoundUseCase: IUpdateVehicleItemRoundUseCase

  addVehicleItemRoundAttachmentUseCase: IAddVehicleItemRoundAttachmentUseCase

  deleteVehicleItemRoundAttachmentUseCase: IDeleteVehicleItemRoundAttachmentUseCase

  constructor(base: IVehicleItemRoundBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IVehicleItemRoundBase): void {
    this.base = base
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  _addVehicleItemRoundAttachment(attachment: IVehicleItemRoundAttachmentBase): void {
    this.vehicleItemRoundAttachments.push(attachment)
  }

  @action
  _removeVehicleItemRoundAttachment(id: string): void {
    this.vehicleItemRoundAttachments = this.vehicleItemRoundAttachments.filter((a) => a.id !== id)
  }

  update(base: IVehicleItemRoundBase): void {
    this._mapBase(base)
  }

  async save(input: IVehicleItemRoundInput): Promise<UpdateVehicleItemRoundUseCaseOutput> {
    const output = await this.updateVehicleItemRoundUseCase.handle({
      id: this.id,
      vehicleItemRound: input,
    })
    if (output.isSuccessful) {
      this._mapBase(output.data.vehicleItemRound)
    }
    return output
  }

  async addAttachment(
    input: IVehicleItemRoundAttachmentInputBase
  ): Promise<AddVehicleItemRoundAttachmentUseCaseOutput> {
    const output = await this.addVehicleItemRoundAttachmentUseCase.handle({
      vehicleItemRoundId: this.id,
      attachment: input,
    })
    if (output.isSuccessful) {
      this._addVehicleItemRoundAttachment(output.data.attachment)
    }
    return output
  }

  async deleteAttachment(id: string): Promise<DeleteVehicleItemRoundAttachmentUseCaseOutput> {
    const output = await this.deleteVehicleItemRoundAttachmentUseCase.handle({
      id,
    })
    if (output.isSuccessful) {
      this._removeVehicleItemRoundAttachment(output.data.attachment.id)
    }
    return output
  }
}
