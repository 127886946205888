import { action, computed, observable } from 'mobx'
import {
  CompanyMemberRole,
  IMyCompany,
  IMyCompanyMember,
  ICompanyMemberInputBase,
  IErrorsStore,
  IUpdateCompanyMemberUseCase,
  IUserBase,
  IMyCompanyMemberBase,
} from '@/types'

type Args = {
  base: IMyCompanyMemberBase
  company?: IMyCompany
  useCases: {
    updateCompanyMemberUseCase: IUpdateCompanyMemberUseCase
  }
  errorsStore: IErrorsStore
}

export default class CompanyMember implements IMyCompanyMember {
  @observable id = ''

  @observable company: IMyCompany = null

  @observable invitedByUser: IUserBase

  @observable user: IUserBase

  @observable role: CompanyMemberRole

  errorsStore: IErrorsStore

  updateCompanyMemberUseCase: IUpdateCompanyMemberUseCase

  @action
  _mapFromBase(base: IMyCompanyMemberBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @computed
  get roleName(): string {
    if (this.role === CompanyMemberRole.OWNER) {
      return 'オーナー'
    }
    if (this.role === CompanyMemberRole.EDITOR) {
      return '編集者'
    }
    if (this.role === CompanyMemberRole.RECRUITER) {
      return '採用担当者'
    }

    return ''
  }

  constructor(args: Args) {
    this._mapFromBase(args.base)
    this.updateCompanyMemberUseCase = args.useCases.updateCompanyMemberUseCase
    this.errorsStore = args.errorsStore
    if (args.company) {
      this.company = args.company
    }
  }

  async save(companyMember: ICompanyMemberInputBase): Promise<boolean> {
    const output = await this.updateCompanyMemberUseCase.handle({
      role: companyMember.role,
      id: this.id,
    })

    if (output.companyMember) {
      this._mapFromBase(output.companyMember)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
