import { action, computed, observable } from 'mobx'
import {
  ICompanyBase,
  IErrorsStore,
  IExperience,
  IExperienceBase,
  IExperienceInputBase,
  IUpdateExperienceUseCase,
  IUserBase,
} from '@/types'
import { getDateTimeFromUtc } from '@/utils'

export default class Experience implements IExperience {
  @observable id = ''

  @observable current: boolean

  @observable description = ''

  @observable _endedAt = ''

  @observable _startedAt = ''

  @observable title = ''

  @observable company: ICompanyBase

  @observable addedByUser: IUserBase

  @observable status: string // TODO: サーバー・フロント両方 enum に変更

  errorsStore: IErrorsStore

  updateExperienceUseCase: IUpdateExperienceUseCase

  @action
  _mapFromBase(base: IExperienceBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      if (key === 'endedAt' || key === 'startedAt') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this[`_${key}`] = base[key]
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this[key] = base[key]
      }
    })
  }

  @computed
  get endedAt(): string {
    return getDateTimeFromUtc(this._endedAt, 'MMMM YYYY')
  }

  @computed
  get startedAt(): string {
    return getDateTimeFromUtc(this._startedAt, 'MMMM YYYY')
  }

  @computed
  get inputBase(): IExperienceInputBase {
    return {
      current: this.current,
      description: this.description,
      endedAt: getDateTimeFromUtc(this._endedAt, 'YYYY-MM'),
      startedAt: getDateTimeFromUtc(this._startedAt, 'YYYY-MM'),
      title: this.title,
      companyId: this.company.id,
      userId: null, // TODO: null どうするか考える
    }
  }

  @computed
  get base(): IExperienceBase {
    return {
      id: this.id,
      current: this.current,
      description: this.description,
      endedAt: getDateTimeFromUtc(this._endedAt, 'YYYY-MM'),
      startedAt: getDateTimeFromUtc(this._startedAt, 'YYYY-MM'),
      title: this.title,
      company: this.company,
      addedByUser: this.addedByUser,
      status: this.status,
    }
  }

  constructor(base: IExperienceBase) {
    this._mapFromBase(base)
  }

  async save(experience: IExperienceInputBase): Promise<boolean> {
    const output = await this.updateExperienceUseCase.handle({
      experience,
      id: this.id,
    })

    if (output.experience) {
      this._mapFromBase(output.experience)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
