import { action, computed, observable } from 'mobx'
import {
  CurrencyUnit,
  ICompanyBase,
  IErrorsStore,
  IJob,
  IJobBase,
  IJobCategoryBase,
  IJobInputBase,
  IJobTagBase,
  ILocationBase,
  ISkillBase,
  IUpdateJobUseCase,
  IUserBase,
  JobCategoryBase,
  JobStatus,
  JobTypeOfPosition,
  JobWorkExperience,
  JobWorkingForm,
  UpdateJobUseCaseOutput,
} from '@/types'

export default class Job implements IJob {
  @observable id = ''

  @observable address = ''

  @observable body = ''

  @observable equityGrant = false

  @observable slug = ''

  @observable thumbnail = ''

  @observable title = ''

  @observable createdAt = ''

  @observable updatedAt = ''

  @observable currencyUnit: CurrencyUnit

  @observable maxMonthlySalary: string

  @observable minMonthlySalary: string

  @observable status: JobStatus

  @observable tokenGrant = false

  @observable typeOfPosition: JobTypeOfPosition

  @observable workExperience: JobWorkExperience

  @observable workingForm: JobWorkingForm

  @observable company: ICompanyBase

  @observable primaryRecruitingContact: IUserBase

  @observable jobCategories: IJobCategoryBase[]

  @observable jobTags: IJobTagBase[]

  @observable skills: ISkillBase[]

  @observable locations: ILocationBase[]

  @observable primaryJobCategory: JobCategoryBase

  errorsStore: IErrorsStore

  updateJobUseCase: IUpdateJobUseCase

  @computed
  get statusName(): string {
    if (this.status === JobStatus.DRAFT) {
      return '下書き'
    }
    if (this.status === JobStatus.PUBLISHED) {
      return '公開済み'
    }
    if (this.status === JobStatus.CLOSED) {
      return '非公開'
    }

    return ''
  }

  @computed
  get isPublished(): boolean {
    if (this.status === JobStatus.PUBLISHED) {
      return true
    }

    return false
  }

  @action
  _mapBase(base: IJobBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IJobBase) {
    this._mapBase(base)
  }

  async save(job: IJobInputBase): Promise<UpdateJobUseCaseOutput> {
    const output = await this.updateJobUseCase.handle({
      job,
      id: this.id,
    })

    if (output.data.job) {
      this._mapBase(output.data.job)
    }

    return output
  }
}
