import {
  IPortfolioNote,
  IPortfolioNoteBase,
  IPortfolioNoteInputBase,
  IUpdatePortfolioNoteUseCase,
  IUserBase,
  UpdatePortfolioNoteUseCaseOutput,
} from '@/types'
import { action, observable } from 'mobx'

type Deps = {
  updatePortfolioNoteUseCase: IUpdatePortfolioNoteUseCase
}

export default class PortfolioNote implements IPortfolioNote {
  @observable id: string

  @observable note: string

  @observable user: IUserBase

  updatePortfolioNoteUseCase: IUpdatePortfolioNoteUseCase

  constructor(base: IPortfolioNoteBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IPortfolioNoteBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  async save(input: {
    vehicleItemId: string
    portfolioNote: IPortfolioNoteInputBase
  }): Promise<UpdatePortfolioNoteUseCaseOutput> {
    const output = await this.updatePortfolioNoteUseCase.handle({
      id: this.id,
      vehicleItemId: input.vehicleItemId,
      portfolioNote: input.portfolioNote,
    })

    if (output.isSuccessful) {
      this.update(output.data.portfolioNote)
    }

    return output
  }

  update(base: IPortfolioNoteBase): void {
    this._mapBase(base)
  }
}
