import {
  CreateVehicleItemRoundUseCaseOutput,
  DeleteVehicleItemRoundUseCaseOutput,
  FetchVehicleItemUseCaseOutput,
  ICreateVehicleItemRoundUseCase,
  IDeleteVehicleItemRoundUseCase,
  IFetchVehicleItemUseCase,
  IPortfolio,
  IPortfolioBase,
  IUpdateVehicleItemAndPortfolioUseCase,
  IUpdateVehicleItemUseCase,
  IVehicleItem,
  IVehicleItemBase,
  IVehicleItemRound,
  IVehicleItemRoundBase,
  IVehicleItemRoundInput,
  IVehicleStatusBase,
  PortfolioInputBase,
  UpdateVehicleItemAndPortfolioUseCaseOutput,
  UpdateVehicleItemUseCaseOutput,
} from '@/types'
import { action, computed, observable } from 'mobx'

type Deps = {
  portfolio: IPortfolio
  vehicleItemRounds: IVehicleItemRound[]
  createVehicleItemRoundUseCase: ICreateVehicleItemRoundUseCase
  deleteVehicleItemRoundUseCase: IDeleteVehicleItemRoundUseCase
  updateVehicleItemUseCase: IUpdateVehicleItemUseCase
  fetchVehicleItemUseCase: IFetchVehicleItemUseCase
  updateVehicleItemAndPortfolioUseCase: IUpdateVehicleItemAndPortfolioUseCase
}

export default class VehicleItem implements IVehicleItem {
  @observable id: string

  @observable portfolio: IPortfolio

  @observable vehicleItemRounds: IVehicleItemRound[]

  @observable vehicleStatus: IVehicleStatusBase

  @observable weighting: string

  base: IVehicleItemBase

  updateVehicleItemUseCase: IUpdateVehicleItemUseCase

  createVehicleItemRoundUseCase: ICreateVehicleItemRoundUseCase

  deleteVehicleItemRoundUseCase: IDeleteVehicleItemRoundUseCase

  fetchVehicleItemUseCase: IFetchVehicleItemUseCase

  updateVehicleItemAndPortfolioUseCase: IUpdateVehicleItemAndPortfolioUseCase

  constructor(base: IVehicleItemBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IVehicleItemBase): void {
    this.base = base
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  // portfolio, vehicleItemRounds以外をmap
  @action
  _mapBaseExceptInstances(base: IVehicleItemBase): void {
    this.base = base
    const keys = Object.keys(base)
    keys.forEach((key) => {
      if (key === 'portfolio' || key === 'vehicleItemRounds') {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @computed
  get companyName(): string {
    return this.portfolio.company.name
  }

  @computed
  get companyUrl(): string {
    return this.portfolio.company.url
  }

  @action
  _addRound(round: IVehicleItemRound): void {
    this.vehicleItemRounds.push(round)
  }

  @action
  _removeRound(id: string): void {
    this.vehicleItemRounds = this.vehicleItemRounds.filter((r) => r.id !== id)
  }

  @action
  _updateWeighting(weighting: string): void {
    this.weighting = weighting
  }

  @action
  _updateVehicleStatus(vehicleStatus: IVehicleStatusBase): void {
    this.vehicleStatus = vehicleStatus
  }

  @action
  _updatePortfolio(portfolio: IPortfolio): void {
    this.portfolio = portfolio
  }

  @action
  _updateVehicleItemRounds(vehicleItemRounds: IVehicleItemRound[]): void {
    this.vehicleItemRounds = vehicleItemRounds
  }

  _updatePortfolioBase(base: IPortfolioBase): void {
    this.portfolio.update(base)
  }

  _updateVehicleItemRoundsBases(vehicleItemRounds: IVehicleItemRoundBase[]): void {
    this.vehicleItemRounds.forEach((thisVir) => {
      const foundItem = vehicleItemRounds.find((vir) => vir.id === thisVir.id)
      if (foundItem) {
        thisVir.update(foundItem)
      }
    })
  }

  updateBase(base: IVehicleItemBase): void {
    this._mapBaseExceptInstances(base)

    this._updatePortfolioBase(base.portfolio)
    this._updateVehicleItemRoundsBases(base.vehicleItemRounds)
  }

  _updateAll(base: IVehicleItemBase, portfolio: IPortfolio, vehicleItemRounds: IVehicleItemRound[]): void {
    this._mapBaseExceptInstances(base)

    this._updatePortfolio(portfolio)
    this._updateVehicleItemRounds(vehicleItemRounds)
  }

  // TODO: 一旦 VehicleItem の更新のみ。移動後の情報やサーバー側での再計算を加味するため VehicleItems の再取得が必要になる。
  // 社内リリース後のタイミングで対応
  async save(input: {
    vehicleStatus: IVehicleStatusBase
    weighting: string
    captainUserId: string
  }): Promise<UpdateVehicleItemUseCaseOutput> {
    const initialWeighting = this.weighting
    const initialVehicleStatus = this.vehicleStatus
    this._updateWeighting(input.weighting)
    this._updateVehicleStatus(input.vehicleStatus)

    const output = await this.updateVehicleItemUseCase.handle({
      id: this.id,
      vehicleStatusId: input.vehicleStatus.id,
      captainUserId: input.captainUserId,
      vehicleItem: {
        weighting: input.weighting,
      },
    })

    if (output.isSuccessful) {
      this.updateBase(output.data.vehicleItem)
    } else {
      // サーバーでエラーになったら戻す
      this._updateWeighting(initialWeighting)
      this._updateVehicleStatus(initialVehicleStatus)
    }

    return output
  }

  async saveWithPortfolio(input: {
    vehicleStatus: IVehicleStatusBase
    weighting: string
    captainUserId: string
    portfolio: PortfolioInputBase
  }): Promise<UpdateVehicleItemAndPortfolioUseCaseOutput> {
    const output = await this.updateVehicleItemAndPortfolioUseCase.handle({
      id: this.id,
      vehicleStatusId: input.vehicleStatus.id,
      captainUserId: input.captainUserId,
      vehicleItem: {
        weighting: input.weighting,
      },
      portfolioId: this.portfolio.id,
      portfolio: input.portfolio,
    })

    if (output.isSuccessful) {
      this.updateBase(output.data.vehicleItem)
      this._updatePortfolioBase(output.data.portfolio)
    }

    return output
  }

  async fetch(): Promise<FetchVehicleItemUseCaseOutput> {
    const output = await this.fetchVehicleItemUseCase.handle({ id: this.id })

    const { base, portfolio, vehicleItemRounds } = output.data
    if (output.isSuccessful) {
      this._updateAll(base, portfolio, vehicleItemRounds)
    }

    return output
  }

  async addRound(round: IVehicleItemRoundInput): Promise<CreateVehicleItemRoundUseCaseOutput> {
    const output = await this.createVehicleItemRoundUseCase.handle({
      vehicleItemId: this.id,
      vehicleItemRound: round,
    })
    if (output.isSuccessful) {
      this._addRound(output.data.vehicleItemRound)
    }
    return output
  }

  async deleteRound(id: string): Promise<DeleteVehicleItemRoundUseCaseOutput> {
    const output = await this.deleteVehicleItemRoundUseCase.handle({
      id,
    })
    if (output.isSuccessful) {
      this._removeRound(id)
    }
    return output
  }
}
