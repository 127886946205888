import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddCompanyReferenceUseCaseInput,
  AddCompanyReferenceUseCaseOutput,
  IAddCompanyReferenceUseCase,
  IAppErrorFactory,
  ICompanyReferencesService,
} from '../../types'

@injectable()
export default class AddCompanyReferenceInteractor implements IAddCompanyReferenceUseCase {
  @inject(symbols.ICompanyReferencesService) private companyReferencesService: ICompanyReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddCompanyReferenceUseCaseInput): Promise<AddCompanyReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.companyReference = await this.companyReferencesService.addCompanyReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの追加に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): AddCompanyReferenceUseCaseOutput {
    return {
      companyReference: null,
      error: null,
    }
  }
}
