import { inject, injectable } from 'inversify'
import {
  IAddVehicleItemRoundAttachmentUseCase,
  IDeleteVehicleItemRoundAttachmentUseCase,
  IUpdateVehicleItemRoundUseCase,
  IVehicleItemRound,
  IVehicleItemRoundFactory,
  VehicleItemRoundFactoryInput,
} from '@/types'
import VehicleItemRound from '@/entities/VehicleItemRound'
import symbols from '@/symbols'

@injectable()
export default class VehicleItemRoundFactory implements IVehicleItemRoundFactory {
  @inject(symbols.IAddVehicleItemRoundAttachmentUseCase)
  addVehicleItemRoundAttachmentUseCase: IAddVehicleItemRoundAttachmentUseCase

  @inject(symbols.IDeleteVehicleItemRoundAttachmentUseCase)
  deleteVehicleItemRoundAttachmentUseCase: IDeleteVehicleItemRoundAttachmentUseCase

  @inject(symbols.IUpdateVehicleItemRoundUseCase)
  updateVehicleItemRoundUseCase: IUpdateVehicleItemRoundUseCase

  public create(input: VehicleItemRoundFactoryInput): IVehicleItemRound {
    return new VehicleItemRound(input.base, {
      updateVehicleItemRoundUseCase: this.updateVehicleItemRoundUseCase,
      addVehicleItemRoundAttachmentUseCase: this.addVehicleItemRoundAttachmentUseCase,
      deleteVehicleItemRoundAttachmentUseCase: this.deleteVehicleItemRoundAttachmentUseCase,
    })
  }
}
