import { ClientError } from 'graphql-request'
import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddMyJobBookmarkUseCaseInput,
  AddMyJobBookmarkUseCaseOutput,
  IAddMyJobBookmarkUseCase,
  IAppErrorFactory,
  IJobBookmarksService,
} from '../../types'

@injectable()
export default class AddMyJobBookmarkInteractor implements IAddMyJobBookmarkUseCase {
  @inject(symbols.IJobBookmarksService) private jobBookmarksService: IJobBookmarksService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddMyJobBookmarkUseCaseInput): Promise<AddMyJobBookmarkUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.jobBookmark = await this.jobBookmarksService.addMyJobBookmark(input)
    } catch (e) {
      const error = e as ClientError
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const errorExtension = error?.response?.errors[0]?.extensions?.code

      let message = '応募の保存に失敗しました'
      if (errorExtension === 'EXISTING_RECORD_ERROR') {
        message = 'すでに保存されています'
      }

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: message,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): AddMyJobBookmarkUseCaseOutput {
    return {
      jobBookmark: null,
      error: null,
    }
  }
}
