import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  CompanyBase,
  CreateSkillStoreInput,
  FetchCompaniesStoreInput,
  FetchCompaniesWithJobsStoreInput,
  FetchCompanyArticlesStoreInput,
  FetchCompanyArticlesUseCaseOutput,
  FetchCompanyStoreInput,
  FetchLocationsStoreInput,
  FetchPitchesStoreInput,
  FetchPitchStoreInput,
  FetchSkillsStoreInput,
  FollowableEntityEntityType,
  IArticleBase,
  ICompaniesStore,
  ICompanyBase,
  ICompanyInputBase,
  ICreateCompanyUseCase,
  ICreateSkillUseCase,
  IErrorsStore,
  IFetchCompaniesUseCase,
  IFetchCompaniesWithJobsUseCase,
  IFetchCompanyArticlesUseCase,
  IFetchCompanyUseCase,
  IFetchFeaturedCompaniesWithJobsUseCase,
  IFetchFeaturedStartupsUseCase,
  IFetchFeaturedVentureCapitalsUseCase,
  IFetchFollowersUseCase,
  IFetchJobCategoriesUseCase,
  IFetchJobTagsUseCase,
  IFetchLocationsUseCase,
  IFetchMarketsUseCase,
  IFetchPitchesUseCase,
  IFetchPitchUseCase,
  IFetchSkillsUseCase,
  IJobCategoryBase,
  IJobTagBase,
  ILocationBase,
  IMarketBase,
  IMarkPitchAsReadUseCase,
  IPitchAttachmentBase,
  IPitchBase,
  IPublicCompany,
  IPublicCompanyFactory,
  IPublicUser,
  IPublicUserFactory,
  ISearchCompanyUseCase,
  ISkillBase,
  IUserBase,
  MarkPitchAsReadStoreInput,
  PitchAttachmentCategoryName,
  SearchCompanyStoreInput,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'CompaniesStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class CompaniesStore implements ICompaniesStore {
  @observable startups: ICompanyBase[] = []

  @observable ventureCapitals: ICompanyBase[] = []

  @observable searchResults: ICompanyBase[] = []

  @observable searchWord = ''

  @observable markets: IMarketBase[] = []

  @observable pitches: IPitchBase[] = []

  @observable pitch: IPitchBase = null

  @observable hasNextStartupsPage = true

  @observable hasNextVentureCapitalsPage = true

  @observable jobCategories: IJobCategoryBase[] = []

  @observable jobTags: IJobTagBase[] = []

  @observable companiesWithJobs: ICompanyBase[] = []

  @observable hasNextCompaniesWithJobsPage = true

  @observable investmentsWithJobsOfVc: Record<string, ICompanyBase[]> = {}

  @observable hasNextInvestmentJobsOfVcPages: Record<string, boolean> = {}

  @observable investmentsWithJobsOfAngel: Record<string, ICompanyBase[]> = {}

  @observable hasNextInvestmentJobsOfAngelPages: Record<string, boolean> = {}

  @observable companyArticles: Record<string, IArticleBase[]> = {}

  @observable hasNextCompanyArticlesPage: Record<string, boolean> = {}

  @observable followers: Record<string, IPublicUser[]> = {}

  @observable hasNextFollowersPage: Record<string, boolean> = {}

  @observable publicCompanies: Record<string, IPublicCompany> = {}

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchCompanyUseCase) private fetchCompanyUseCase: IFetchCompanyUseCase,
    @inject(symbols.IFetchCompaniesUseCase) private fetchCompaniesUsecase: IFetchCompaniesUseCase,
    @inject(symbols.IFetchFeaturedCompaniesWithJobsUseCase)
    private fetchFeaturedCompaniesWithJobsUseCase: IFetchFeaturedCompaniesWithJobsUseCase,
    @inject(symbols.IFetchFeaturedVentureCapitalsUseCase)
    private fetchFeaturedVentureCapitalsUseCase: IFetchFeaturedVentureCapitalsUseCase,
    @inject(symbols.IFetchFeaturedStartupsUseCase)
    private fetchFeaturedStartupsUseCase: IFetchFeaturedStartupsUseCase,
    @inject(symbols.ISearchCompanyUseCase) private searchCompanyUseCase: ISearchCompanyUseCase,
    @inject(symbols.ICreateCompanyUseCase) private createCompanyUseCase: ICreateCompanyUseCase,
    @inject(symbols.IFetchMarketsUseCase) private fetchMarketsUseCase: IFetchMarketsUseCase,
    @inject(symbols.IFetchCompanyArticlesUseCase) private fetchCompanyArticlesUseCase: IFetchCompanyArticlesUseCase,
    @inject(symbols.IFetchLocationsUseCase)
    private fetchLocationsUseCase: IFetchLocationsUseCase,
    @inject(symbols.IFetchPitchesUseCase) private fetchPitchesUseCase: IFetchPitchesUseCase,
    @inject(symbols.IFetchPitchUseCase) private fetchPitchUseCase: IFetchPitchUseCase,
    @inject(symbols.IMarkPitchAsReadUseCase) private markPitchAsReadUseCase: IMarkPitchAsReadUseCase,
    @inject(symbols.IFetchSkillsUseCase) private fetchSkillsUseCase: IFetchSkillsUseCase,
    @inject(symbols.ICreateSkillUseCase) private createSkillUseCase: ICreateSkillUseCase,
    @inject(symbols.IFetchJobCategoriesUseCase) private fetchJobCategoriesUseCase: IFetchJobCategoriesUseCase,
    @inject(symbols.IFetchJobTagsUseCase) private fetchJobTagsUseCase: IFetchJobTagsUseCase,
    @inject(symbols.IFetchCompaniesWithJobsUseCase)
    private fetchCompaniesWithJobsUseCase: IFetchCompaniesWithJobsUseCase,
    @inject(symbols.IFetchFollowersUseCase) private fetchFollowersUseCase: IFetchFollowersUseCase,
    @inject(symbols.IPublicCompanyFactory) private publicCompanyFactory: IPublicCompanyFactory,
    @inject(symbols.IPublicUserFactory) private publicUserFactory: IPublicUserFactory
  ) {
    //
  }

  // =========== startups ===========
  @action
  _updateStartups(startups: ICompanyBase[]): void {
    this.startups = startups
  }

  @action
  _addStartups(startups: ICompanyBase[]): void {
    startups.forEach((newStartup) => {
      // 重複していたら処理をスキップ
      if (this.startups.some((s) => s.slug === newStartup.slug)) {
        return
      }

      // 末尾に追加
      this.startups = this.startups.concat(newStartup)
    })
  }

  @action
  updateHasNextStartupsPage(hasNextPage: boolean): void {
    this.hasNextStartupsPage = hasNextPage
  }
  // =========== startups ===========

  // =========== ventureCapitals ===========
  @action
  _updateVentureCapitals(ventureCapitals: ICompanyBase[]): void {
    this.ventureCapitals = ventureCapitals
  }

  @action
  _addVentureCapitals(ventureCapitals: ICompanyBase[]): void {
    ventureCapitals.forEach((newVc) => {
      // 重複していたら処理をスキップ
      if (this.ventureCapitals.some((v) => v.slug === newVc.slug)) {
        return
      }

      // 末尾に追加
      this.ventureCapitals = this.ventureCapitals.concat(newVc)
    })
  }

  @action
  updateHasNextVentureCapitalsPage(hasNextPage: boolean): void {
    this.hasNextVentureCapitalsPage = hasNextPage
  }
  // =========== ventureCapitals ===========

  // =========== companiesWithJobs ===========
  @action
  _updateCompaniesWithJobs(companies: ICompanyBase[]): void {
    this.companiesWithJobs = companies
  }

  @action
  _addCompaniesWithJobs(companies: ICompanyBase[]): void {
    companies.forEach((newCompany) => {
      // 重複していたら処理をスキップ
      if (this.companiesWithJobs.some((j) => j.slug === newCompany.slug)) {
        return
      }

      // 末尾に追加
      this.companiesWithJobs = this.companiesWithJobs.concat(newCompany)
    })
  }

  @action
  updateHasNextCompaniesWithJobsPage(hasNextPage: boolean): void {
    this.hasNextCompaniesWithJobsPage = hasNextPage
  }
  // =========== jobs ===========

  // =========== investmentsWithJobsOfVc ===========
  @action
  _updateInvestmentsWithJobsOfVc(slug: string, companies: ICompanyBase[]): void {
    this.investmentsWithJobsOfVc[slug] = companies
  }

  @action
  _addInvestmentsWithJobsOfVc(slug: string, companies: ICompanyBase[]): void {
    companies.forEach((newJob) => {
      // 重複していたら処理をスキップ
      if (this.investmentsWithJobsOfVc[slug].some((j) => j.slug === newJob.slug)) {
        return
      }

      // 末尾に追加
      this.investmentsWithJobsOfVc[slug] = this.investmentsWithJobsOfVc[slug].concat(newJob)
    })
  }

  @action
  updateHasNextInvestmentJobsOfVcPages(slug: string, hasNextPage: boolean): void {
    this.hasNextInvestmentJobsOfVcPages[slug] = hasNextPage
  }
  // =========== investmentsWithJobsOfVc ===========

  // =========== investmentsWithJobsOfAngel ===========
  @action
  _updateInvestmentsWithJobsOfAngel(slug: string, companies: ICompanyBase[]): void {
    this.investmentsWithJobsOfAngel[slug] = companies
  }

  @action
  _addInvestmentsWithJobsOfAngel(slug: string, companies: ICompanyBase[]): void {
    companies.forEach((newJob) => {
      // 重複していたら処理をスキップ
      if (this.investmentsWithJobsOfAngel[slug].some((j) => j.slug === newJob.slug)) {
        return
      }

      // 末尾に追加
      this.investmentsWithJobsOfAngel[slug] = this.investmentsWithJobsOfAngel[slug].concat(newJob)
    })
  }

  @action
  updateHasNextInvestmentJobsOfAngelPages(slug: string, hasNextPage: boolean): void {
    this.hasNextInvestmentJobsOfAngelPages[slug] = hasNextPage
  }
  // =========== investmentsWithJobsOfAngel ===========

  // =========== companyArticles ===========
  @action
  _addCompanyArticles(slug: string, articles: IArticleBase[]): void {
    articles.forEach((newArticle) => {
      if (this.companyArticles[slug].some((a) => a.slug === newArticle.slug)) {
        return
      }

      this.companyArticles[slug] = this.companyArticles[slug].concat(newArticle)
    })
  }

  @action
  _updateCompanyArticles(slug: string, articles: IArticleBase[]): void {
    this.companyArticles[slug] = articles
  }

  @action
  updateHasNextCompanyArticlesPage(slug: string, hasNextPage: boolean): void {
    this.hasNextCompanyArticlesPage[slug] = hasNextPage
  }

  // =========== companyArticles ===========

  @action
  _updateSearchResults(companies: ICompanyBase[]): void {
    this.searchResults = companies
  }

  @action
  _updateSearchWord(searchWord: string): void {
    this.searchWord = searchWord
  }

  @action
  _updateMarkets(markets: IMarketBase[]): void {
    this.markets = markets
  }

  @action
  _updatePitches(pitches: IPitchBase[]): void {
    this.pitches = pitches
  }

  @action
  _updatePitch(pitch: IPitchBase): void {
    this.pitch = pitch
  }

  @action
  _updateJobCategories(jobCategories: IJobCategoryBase[]): void {
    this.jobCategories = jobCategories
  }

  @action
  _updateJobTags(jobTags: IJobTagBase[]): void {
    this.jobTags = jobTags
  }

  @action
  resetSearchResults(): void {
    this.searchResults = []
    this.searchWord = ''
  }

  // =========== Followers ===========
  @action
  _addFollowers(slug: string, users: IUserBase[]): void {
    users.forEach((newUser) => {
      if (this.followers[slug].some((f) => f.username === newUser.username)) {
        return
      }

      const newUserInstance = this._createFollower(newUser)
      this.followers[slug] = this.followers[slug].concat(newUserInstance)
    })
  }

  @action
  updateFollowers(slug: string, users: IUserBase[]): void {
    this.followers[slug] = users.map((newUser) => {
      return this._createFollower(newUser)
    })
  }

  @action
  _createFollower(base: IUserBase): IPublicUser {
    return this.publicUserFactory.create({ base })
  }

  @action
  updateHasNextFollowersPage(slug: string, hasNextPage: boolean): void {
    this.hasNextFollowersPage[slug] = hasNextPage
  }
  // =========== Followers ===========

  async fetchStartups(input: FetchCompaniesStoreInput): Promise<ICompanyBase[]> {
    const output = await this.fetchCompaniesUsecase.handle({ ...input, ...{ isVc: false } })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateStartups(output.companies)
    } else {
      this._addStartups(output.companies)
    }
    this.updateHasNextStartupsPage(output.hasNextPage)
    // 更新した this.startups ではなく、クエリで取得した配列を返す
    return output.companies
  }

  async fetchFeaturedStartups(limit: number): Promise<ICompanyBase[]> {
    const output = await this.fetchFeaturedStartupsUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._addStartups(output.startups)
    // 更新した this.startups ではなく、クエリで取得した配列を返す
    return output.startups
  }

  async fetchVentureCapitals(input: FetchCompaniesStoreInput): Promise<ICompanyBase[]> {
    const output = await this.fetchCompaniesUsecase.handle({ ...input, ...{ isVc: true } })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateVentureCapitals(output.companies)
    } else {
      this._addVentureCapitals(output.companies)
    }
    this.updateHasNextVentureCapitalsPage(output.hasNextPage)
    // 更新した this.ventureCapitals ではなく、クエリで取得した配列を返す
    return output.companies
  }

  async fetchFeaturedVentureCapitals(limit: number): Promise<ICompanyBase[]> {
    const output = await this.fetchFeaturedVentureCapitalsUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._addVentureCapitals(output.ventureCapitals)
    // 更新した this.ventureCapitals ではなく、クエリで取得した配列を返す
    return output.ventureCapitals
  }

  async fetchFeaturedCompaniesWithJobs(limit: number): Promise<ICompanyBase[]> {
    const output = await this.fetchFeaturedCompaniesWithJobsUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._addCompaniesWithJobs(output.companiesWithJobs)
    // 更新した this.companiesWithJob ではなく、クエリで取得した配列を返す
    return output.companiesWithJobs
  }

  async fetchCompany(input: FetchCompanyStoreInput): Promise<ICompanyBase> {
    const output = await this.fetchCompanyUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.company
  }

  async createCompany(companyInputBase: ICompanyInputBase): Promise<ICompanyBase> {
    const output = await this.createCompanyUseCase.handle({
      company: companyInputBase,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.company
  }

  async searchCompany(input: SearchCompanyStoreInput): Promise<ICompanyBase[]> {
    this._updateSearchWord(input.searchWord)

    const output = await this.searchCompanyUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    this._updateSearchResults(output.companies)

    return this.searchResults
  }

  async fetchMarkets(): Promise<void> {
    const output = await this.fetchMarketsUseCase.handle()

    if (output.error) {
      this.errorsStore.handle(output.error)
      return
    }

    this._updateMarkets(output.markets)
  }

  async fetchCompanyArticles(input: FetchCompanyArticlesStoreInput): Promise<FetchCompanyArticlesUseCaseOutput> {
    const output = await this.fetchCompanyArticlesUseCase.handle(input)

    if (output.error) {
      return output
    }

    if (input.shouldRefresh) {
      this._updateCompanyArticles(input.slug, output.data.articles)
    } else {
      this._addCompanyArticles(input.slug, output.data.articles)
    }

    this.updateHasNextCompanyArticlesPage(input.slug, output.data.hasNextPage)
    return output
  }

  async fetchLocations(input: FetchLocationsStoreInput): Promise<ILocationBase[]> {
    const output = await this.fetchLocationsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    return output.locations
  }

  async fetchPitches(input: FetchPitchesStoreInput): Promise<void> {
    const output = await this.fetchPitchesUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
      return
    }

    this._updatePitches(output.pitches)
  }

  async fetchPitch(input: FetchPitchStoreInput): Promise<boolean> {
    const output = await this.fetchPitchUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updatePitch(output.pitch)

    return true
  }

  async markPitchAsRead(input: MarkPitchAsReadStoreInput): Promise<boolean> {
    const output = await this.markPitchAsReadUseCase.handle(input)

    if (output.pitch) {
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  findPitchAttachmentByCategory(categoryName: PitchAttachmentCategoryName): IPitchAttachmentBase[] {
    if (!this.pitch) {
      return []
    }

    return this.pitch.pitchAttachments.filter((pa) => pa.categoryName === categoryName)
  }

  async fetchSkills(input: FetchSkillsStoreInput): Promise<ISkillBase[]> {
    const output = await this.fetchSkillsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    return output.skills
  }

  async createSkill(input: CreateSkillStoreInput): Promise<ISkillBase> {
    const output = await this.createSkillUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.skill
  }

  async fetchJobCategories(): Promise<void> {
    const output = await this.fetchJobCategoriesUseCase.handle()

    if (output.error) {
      this.errorsStore.handle(output.error)
      return
    }

    this._updateJobCategories(output.jobCategories)
  }

  async fetchJobTags(): Promise<void> {
    const output = await this.fetchJobTagsUseCase.handle()

    if (output.error) {
      this.errorsStore.handle(output.error)
      return
    }

    this._updateJobTags(output.jobTags)
  }

  async fetchCompaniesWithJobs(input: FetchCompaniesWithJobsStoreInput): Promise<CompanyBase[]> {
    const output = await this.fetchCompaniesWithJobsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
      return []
    }

    if (input.shouldRefresh) {
      this._updateCompaniesWithJobs(output.companies)
    } else {
      this._addCompaniesWithJobs(output.companies)
    }

    this.updateHasNextCompaniesWithJobsPage(output.hasNextPage)
    return output.companies
  }

  async fetchInvestmentsWithJobsOfVc(input: FetchCompaniesWithJobsStoreInput): Promise<CompanyBase[]> {
    const output = await this.fetchCompaniesWithJobsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
      return []
    }

    if (input.shouldRefresh) {
      this._updateInvestmentsWithJobsOfVc(input.vcSlug, output.companies)
    } else {
      this._addInvestmentsWithJobsOfVc(input.vcSlug, output.companies)
    }

    this.updateHasNextInvestmentJobsOfVcPages(input.vcSlug, output.hasNextPage)
    return output.companies
  }

  async fetchInvestmentsWithJobsOfAngel(input: FetchCompaniesWithJobsStoreInput): Promise<CompanyBase[]> {
    const output = await this.fetchCompaniesWithJobsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
      return []
    }

    if (input.shouldRefresh) {
      this._updateInvestmentsWithJobsOfAngel(input.angelUsername, output.companies)
    } else {
      this._addInvestmentsWithJobsOfAngel(input.angelUsername, output.companies)
    }

    this.updateHasNextInvestmentJobsOfAngelPages(input.angelUsername, output.hasNextPage)
    return output.companies
  }

  async fetchFollowers(slug: string, limit: number, shouldRefresh: boolean): Promise<IUserBase[]> {
    const output = await this.fetchFollowersUseCase.handle({
      slug,
      entityType: FollowableEntityEntityType.COMPANY,
      limit,
      shouldRefresh,
    })

    if (output.error) {
      return []
    }

    if (shouldRefresh) {
      this.updateFollowers(slug, output.data.followers)
    } else {
      this._addFollowers(slug, output.data.followers)
    }

    this.updateHasNextFollowersPage(slug, output.data.hasNextPage)
    return output.data.followers
  }

  createCompanyInstance(base: ICompanyBase): IPublicCompany {
    if (!base) {
      return null
    }

    if (this.publicCompanies[base?.slug]) {
      return this.publicCompanies[base?.slug]
    }

    const instance = this.publicCompanyFactory.create({ base })
    this.publicCompanies[instance.slug] = instance

    return instance
  }
}
