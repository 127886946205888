import { action, computed, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  IChatMessageThread,
  IChatStore,
  IErrorsStore,
  IFetchChatMessageThreadsUseCase,
  IFindOrCreateChatMessageThreadUseCase,
  IInitializeChatUseCase,
  IUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ChatStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ChatStore implements IChatStore {
  @observable threads: IChatMessageThread[] = []

  @observable isInitialized = false

  @observable isInitializing = false

  @observable hasNextThreadsPage = true

  _threadsPageNextCursor = ''

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IInitializeChatUseCase)
    private initializeChatUseCase: IInitializeChatUseCase,
    @inject(symbols.IFindOrCreateChatMessageThreadUseCase)
    private findOrCreateChatMessageThreadUseCase: IFindOrCreateChatMessageThreadUseCase,
    @inject(symbols.IFetchChatMessageThreadsUseCase)
    private fetchThreadsUseCase: IFetchChatMessageThreadsUseCase
  ) {
    //
  }

  @computed
  get latestThread(): IChatMessageThread {
    if (this.threads.length === 0) {
      return null
    }

    return this.threads[0]
  }

  @action
  _updateThreads(threads: IChatMessageThread[]): void {
    this.threads = threads
  }

  @action
  _prependThread(thread: IChatMessageThread): void {
    const foundThread = this.threads.find((t) => t.slug === thread.slug)
    if (!foundThread) {
      this.threads.unshift(thread)
    }
  }

  @action
  _appendThread(thread: IChatMessageThread): void {
    const foundThread = this.threads.find((t) => t.slug === thread.slug)
    if (!foundThread) {
      this.threads.push(thread)
    }
  }

  @action
  _updateInitializedState(state: boolean): void {
    this.isInitialized = state
  }

  @action
  _updateInitializingState(state: boolean): void {
    this.isInitializing = state
  }

  /**
   * チャット機能の初期化処理
   * 返り値の boolean は正常終了可どうかを返す
   */
  async init(): Promise<boolean> {
    // すでに初期化済みの場合は処理をスキップ
    if (this.isInitialized) {
      return true
    }
    // 初期化中の場合もスキップ
    if (this.isInitializing) {
      return false
    }

    // 初期化中フラグを立てる
    this._updateInitializingState(true)

    const output = await this.initializeChatUseCase.handle()
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updateThreads(output.threads)
    this._updateInitializedState(true)
    this._updateInitializingState(false)

    return true
  }

  /**
   * チャット相手の username を渡してスレッドを取得
   * @param username
   */
  async findOrCreateThread(username: string): Promise<IChatMessageThread> {
    // 手元のスレッドを検索して見つかれば、それを返す
    const foundThread = this._findThreadByUsername(username)
    if (foundThread) {
      return foundThread
    }

    // ない場合はサーバーに問い合わせして、なければ作成してもらう
    const output = await this.findOrCreateChatMessageThreadUseCase.handle({
      username,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    this._prependThread(output.thread)

    return output.thread
  }

  /**
   * ユーザー名で検索
   * @param username
   * @private
   */
  private _findThreadByUsername(username: string): IChatMessageThread {
    return this.threads.find((t) => {
      // メンバーが2人で相手の User の username が渡されたものと一致するものを探す
      if (t.users.length === 2) {
        const foundUser = t.users.find((u) => u.username === username)
        if (foundUser) {
          return true
        }
      }

      return false
    })
  }

  findThreadBySlug(slug: string): IChatMessageThread {
    return this.threads.find((t) => t.slug === slug)
  }

  async fetchThreads(input: { limit: number; shouldRefresh: boolean }): Promise<IUseCaseOutput> {
    if (input.shouldRefresh) {
      this._clearThreadsPageInfo()
    }

    const output = await this.fetchThreadsUseCase.handle({
      limit: input.limit,
      limitOfMessages: 1,
      cursor: this._threadsPageNextCursor,
    })

    if (output.data.threads.length > 0) {
      output.data.threads.forEach((thread) => {
        this._appendThread(thread)
      })
    }
    if (output.data.pageInfo) {
      this._updateThreadsPageInfo(output.data.pageInfo.hasNextPage, output.data.pageInfo?.endCursor || '')
    }

    return output
  }

  @action
  private _clearThreadsPageInfo(): void {
    this.hasNextThreadsPage = true
    this._threadsPageNextCursor = ''
  }

  @action
  private _updateThreadsPageInfo(hasNextPage: boolean, cursor: string): void {
    this.hasNextThreadsPage = hasNextPage
    this._threadsPageNextCursor = cursor
  }
}
