import { inject, injectable } from 'inversify'
import {
  FetchArticlesUseCaseInput,
  FetchArticlesUseCaseOutput,
  IAppErrorFactory,
  IArticle,
  IArticlesService,
  IFetchArticlesUseCase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchArticlesInteractor implements IFetchArticlesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    articles: [] | IArticle[]
    hasNextPage: false | boolean
  }>

  async handle(input: FetchArticlesUseCaseInput): Promise<FetchArticlesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        articles: [],
        hasNextPage: false,
      },
    })
    try {
      // TODO: Gateway を改良して1リクエストでランキング取得処理まで対応できるように変更
      const result = await this.articlesService.fetchArticles({
        categories: input.categories,
        cursorKey: input.categories.join(),
        limit: input.limit,
      })
      output.data.articles = result.articles
      output.data.hasNextPage = result.hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
