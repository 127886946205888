import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IArticlesService,
  IToggleArticleLikedStateUseCase,
  ToggleArticleLikedStateUseCaseInput,
  ToggleArticleLikedStateUseCaseOutput,
} from '../../types'

@injectable()
export default class ToggleArticleLikedStateInteractor implements IToggleArticleLikedStateUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: ToggleArticleLikedStateUseCaseInput): Promise<ToggleArticleLikedStateUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.article = await this.articlesService.toggleArticleLikedState(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): ToggleArticleLikedStateUseCaseOutput {
    return {
      article: null,
      error: null,
    }
  }
}
