import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IAuthService,
  IUpdatePasswordWithTokenUseCase,
  UpdatePasswordWithTokenUseCaseInput,
  UpdatePasswordWithTokenUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class UpdatePasswordWithTokenInteractor implements IUpdatePasswordWithTokenUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdatePasswordWithTokenUseCaseInput): Promise<UpdatePasswordWithTokenUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.authService.updatePasswordWithToken(
        input.resetPasswordToken,
        input.password,
        input.passwordConfirmation
      )

      output.isSuccessful = true
    } catch (e) {
      output.isSuccessful = false
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'パスワードの変更に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdatePasswordWithTokenUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
