import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IInitializeVehicleUseCase,
  InitializeVehicleUseCaseInput,
  InitializeVehicleUseCaseOutput,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleBase,
  IVehicleItem,
  IVehicleItemFactory,
  IVehicleMember,
  IVehicleMemberFactory,
  IVehicleStatus,
  IVehicleStatusFactory,
} from '@/types'

@injectable()
export default class InitializeVehicleInteractor implements IInitializeVehicleUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IVehicleItemFactory) private vehicleItemFactory: IVehicleItemFactory

  @inject(symbols.IVehicleMemberFactory) private vehicleMemberFactory: IVehicleMemberFactory

  @inject(symbols.IVehicleStatusFactory) private vehicleStatusFactory: IVehicleStatusFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicle: IVehicleBase
    allVehicleItems: IVehicleItem[]
    vehicleMembers: IVehicleMember[]
    vehicleStatuses: IVehicleStatus[]
  }>

  async handle(input: InitializeVehicleUseCaseInput): Promise<InitializeVehicleUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicle: null,
        allVehicleItems: [],
        vehicleMembers: [],
        vehicleStatuses: [],
      },
    })
    try {
      const vehicle = await this.pmsService.fetchVehicle(input.vehicleSlug)
      const initialValue: IVehicleItem[] = []
      const allVehicleItems = vehicle.vehicleStatuses.reduce((accumulator, currentValue) => {
        const items = currentValue.vehicleItems.nodes.map((item) => this.vehicleItemFactory.create({ base: item }))
        return accumulator.concat(items)
      }, initialValue)
      const vehicleMembers = vehicle.vehicleMembers.map((m) => this.vehicleMemberFactory.create({ base: m }))
      const vehicleStatuses = vehicle.vehicleStatuses.map((s) => this.vehicleStatusFactory.create({ base: s }))

      output.data = { vehicle, allVehicleItems, vehicleMembers, vehicleStatuses }
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
