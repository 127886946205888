import { inject, injectable } from 'inversify'
import {
  IPortfolioMeeting,
  IPortfolioMeetingFactory,
  IUpdatePortfolioMeetingUseCase,
  PortfolioMeetingFactoryInput,
} from '@/types'
import PortfolioMeeting from '@/entities/PortfolioMeeting'
import symbols from '@/symbols'

@injectable()
export default class PortfolioMeetingFactory implements IPortfolioMeetingFactory {
  @inject(symbols.IUpdatePortfolioMeetingUseCase) updatePortfolioMeetingUseCase: IUpdatePortfolioMeetingUseCase

  public create(input: PortfolioMeetingFactoryInput): IPortfolioMeeting {
    return new PortfolioMeeting(input.base, {
      updatePortfolioMeetingUseCase: this.updatePortfolioMeetingUseCase,
    })
  }
}
