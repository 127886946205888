import {
  IPortfolioMeeting,
  IPortfolioMeetingBase,
  IPortfolioMeetingInputBase,
  IUpdatePortfolioMeetingUseCase,
  IUserBase,
  PortfolioMeetingStatusBase,
  UpdatePortfolioMeetingUseCaseOutput,
} from '@/types'
import { action, observable } from 'mobx'

type Deps = {
  updatePortfolioMeetingUseCase: IUpdatePortfolioMeetingUseCase
}

export default class PortfolioMeeting implements IPortfolioMeeting {
  @observable id: string

  @observable description: string

  @observable participants: IUserBase[]

  @observable startAt = ''

  @observable status: PortfolioMeetingStatusBase

  @observable user: IUserBase

  @observable shouldScrollTo = false

  updatePortfolioMeetingUseCase: IUpdatePortfolioMeetingUseCase

  constructor(base: IPortfolioMeetingBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IPortfolioMeetingBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  updateShouldScrollTo(shouldScrollTo: boolean): void {
    this.shouldScrollTo = shouldScrollTo
  }

  async save(input: {
    vehicleItemId: string
    portfolioMeeting: IPortfolioMeetingInputBase
  }): Promise<UpdatePortfolioMeetingUseCaseOutput> {
    const output = await this.updatePortfolioMeetingUseCase.handle({
      id: this.id,
      vehicleItemId: input.vehicleItemId,
      portfolioMeeting: input.portfolioMeeting,
    })

    if (output.isSuccessful) {
      this.update(output.data.portfolioMeeting)
    }

    return output
  }

  update(base: IPortfolioMeetingBase): void {
    this._mapBase(base)
  }
}
