import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  CreateEmptyDraftPitchUseCaseOutput,
  CurrencyUnit,
  IAppErrorFactory,
  ICreateEmptyDraftPitchUseCase,
  IPitchesService,
  IPitchFactory,
  PitchInstrumentBase,
  PitchPermission,
  PitchStatus,
  PitchValuationCapBase,
} from '../../types'

@injectable()
export default class CreateEmptyDraftPitchInteractor implements ICreateEmptyDraftPitchUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IPitchFactory) private pitchFactory: IPitchFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  handle(): CreateEmptyDraftPitchUseCaseOutput {
    const output = this.getDefaultOutputData()
    try {
      const base = {
        id: '',
        roundName: '',
        slug: '',
        status: PitchStatus.DRAFT,
        permission: PitchPermission.ANYONE_WITH_THE_LINK,
        pitchAttachments: [],
        createdAt: '',
        updatedAt: '',
        createdBy: null,
        pitchReaders: [],
        instrument: PitchInstrumentBase.COMMON_STOCK,
        roundSize: '',
        currencyUnit: CurrencyUnit.USD,
        valuationCap: '',
        valuationCapType: PitchValuationCapBase.PRE,
        targetClosingDate: '',
        description: '',
      }
      output.pitch = this.pitchFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ラウンドの追加に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateEmptyDraftPitchUseCaseOutput {
    return {
      pitch: null,
      error: null,
    }
  }
}
