import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IInitializeInvestorUseCase,
  IInvestor,
  IInvestorFactory,
  InitializeInvestorUseCaseInput,
  InitializeInvestorUseCaseOutput,
  IPMSService,
  IUseCaseOutputFactory,
  IViewer,
  IViewerService,
} from '@/types'

@injectable()
export default class InitializeInvestorInteractor implements IInitializeInvestorUseCase {
  @inject(symbols.IViewer) private viewer: IViewer

  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IInvestorFactory) private investorFactory: IInvestorFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    investor: IInvestor
  }>

  // eslint-disable-next-line @typescript-eslint/require-await
  async handle(input: InitializeInvestorUseCaseInput): Promise<InitializeInvestorUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        investor: null,
      },
    })
    try {
      // 自分の所属する InvestorMember を補完するための情報を取得
      const viewerBase = await this.viewerService.fetchMyInvestorMembers()
      this.viewer.update(viewerBase)
      const investorMember = this.viewer.investorMembers.find(
        (member) =>
          member.investor.investorSlug === input.investorSlug && member.investor.investorType === input.investorType
      )
      if (investorMember) {
        const vehicleBases = investorMember.vehicleMembers.map((vm) => vm.vehicle)
        output.data.investor = this.investorFactory.create({ base: investorMember.investor, vehicleBases })

        return output
      }

      // ベータで無料提供するため subscription チェックをしない
      // 有料提供戻すときに subscription チェックする
      const newInvestorMember = await this.pmsService.initializePms({
        entityType: input.investorType,
        slug: input.investorSlug,
      })

      this.viewer.addInvestorMember(newInvestorMember)

      output.data.investor = this.investorFactory.create({ base: newInvestorMember.investor, vehicleBases: [] })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
