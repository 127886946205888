import { inject, injectable } from 'inversify'
import {
  DeleteMyArticleUseCaseInput,
  DeleteMyArticleUseCaseOutput,
  IAppErrorFactory,
  IArticleBase,
  IArticlesService,
  IDeleteMyArticleUseCase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteMyArticleInteractor implements IDeleteMyArticleUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{ article: null | IArticleBase }>

  async handle(input: DeleteMyArticleUseCaseInput): Promise<DeleteMyArticleUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        article: null,
      },
    })
    try {
      output.data.article = await this.articlesService.deleteMyArticle({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
