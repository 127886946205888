import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreatePortfolioMeetingUseCaseInput,
  CreatePortfolioMeetingUseCaseOutput,
  IAppErrorFactory,
  ICreatePortfolioMeetingUseCase,
  IPMSService,
  IPortfolioMeeting,
  IPortfolioMeetingFactory,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class CreatePortfolioMeetingInteractor implements ICreatePortfolioMeetingUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IPortfolioMeetingFactory) private portfolioMeetingFactory: IPortfolioMeetingFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    portfolioMeeting: IPortfolioMeeting
  }>

  async handle(input: CreatePortfolioMeetingUseCaseInput): Promise<CreatePortfolioMeetingUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        portfolioMeeting: null,
      },
    })
    try {
      const base = await this.pmsService.createVehicleItemPortfolioMeeting(input)
      output.data.portfolioMeeting = this.portfolioMeetingFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
