import { inject, injectable } from 'inversify'
import { action, computed, observable } from 'mobx'
import symbols from '@/symbols'
import {
  ArticleAttachmentBase,
  ArticleAttachmentInputBase,
  ArticleInputBase,
  DeleteMyArticleUseCaseOutput,
  FetchMyArticlesUseCaseOutput,
  FetchMyJobBookmarksInput,
  FetchNotificationsInput,
  IAddAngelInvestmentInvitationUseCase,
  IAddCompanyReferenceUseCase,
  IAddExperienceUseCase,
  IAddMyInvestmentUseCase,
  IAddMyJobBookmarkUseCase,
  IAddUserReferenceUseCase,
  IAngelInvestmentInvitationInputBase,
  IArticleBase,
  ICompanyBase,
  ICompanyFactory,
  ICompanyMemberFactory,
  ICompanyReferenceBase,
  ICreateArticleAttachmentUseCase,
  ICreateMyArticleUseCase,
  IDeleteMyArticleUseCase,
  IErrorsStore,
  IExperience,
  IExperienceFactory,
  IExperienceInputBase,
  IFetchMyArticlesUseCase,
  IFetchMyJobBookmarksUseCase,
  IFetchMyReferencesUseCase,
  IFetchNotificationsUseCase,
  IInvestment,
  IInvestmentFactory,
  IInvestmentInputBase,
  IInvestorMemberBase,
  IJobBookmarkBase,
  IMarkAllNotificationsAsReadUseCase,
  IMyCompany,
  IMyCompanyMember,
  IMyReference,
  INotificationBase,
  InvitationOfBase,
  IPitchFactory,
  IRemoveCompanyReferenceUseCase,
  IRemoveExperienceUseCase,
  IRemoveInvestmentUseCase,
  IRemoveMyJobBookmarkUseCase,
  IRemoveUserReferenceUseCase,
  IToggleFollowCompanyUseCase,
  IToggleFollowUserUseCase,
  IUpdateCompanyNotificationSettingsUseCase,
  IUpdateCompanyReferenceUseCase,
  IUpdateUserNotificationSettingsUseCase,
  IUpdateUserReferenceUseCase,
  IUserArticle,
  IUserBase,
  IUserProfile,
  IUserProfileFactory,
  IUserReferenceBase,
  IViewer,
  IViewerBase,
  NotificationConnection,
  PmsPlanBase,
  PrimaryUserType,
  UserProfileJobHuntingStatus,
  UserReferenceStatus,
  UserRoleBase,
} from '@/types'

@injectable()
export default class Viewer implements IViewer {
  @observable id = ''

  @observable email: string = null

  @observable username: string = null

  @observable name: string = null

  @observable profile: IUserProfile = null

  @observable investments: IInvestment[] = []

  @observable experiences: IExperience[] = []

  @observable companyMembers: IMyCompanyMember[] = []

  @observable hasUnreadNotifications = false

  @observable allNotifications: INotificationBase[] = []

  @observable hasAllNotificationsNextPage = false

  @observable allNotificationsCursor = ''

  @observable myJobBookmarks: IJobBookmarkBase[] = []

  @observable myReferences: IMyReference[] = []

  @observable hasMyJobBookmarksNextPage = true

  @observable invitationOf: InvitationOfBase

  notifications: NotificationConnection & {
    nodes: INotificationBase[]
  }

  @observable isFirstSignIn = false

  @observable allArticles: IUserArticle[] = []

  @observable hasNextMyArticlesPage = true

  @observable articlesEndCursor = ''

  @observable investorMembers: IInvestorMemberBase[] = []

  @observable pmsPlan = PmsPlanBase.NO_CONTRACT

  @observable role: UserRoleBase

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IAddExperienceUseCase) private addExperienceUseCase: IAddExperienceUseCase,
    @inject(symbols.IRemoveExperienceUseCase) private removeExperienceUseCase: IRemoveExperienceUseCase,
    @inject(symbols.IAddMyInvestmentUseCase) private addMyInvestmentUseCase: IAddMyInvestmentUseCase,
    @inject(symbols.IRemoveInvestmentUseCase) private removeInvestmentUseCase: IRemoveInvestmentUseCase,
    @inject(symbols.IMarkAllNotificationsAsReadUseCase)
    private markAllNotificationsAsReadUseCase: IMarkAllNotificationsAsReadUseCase,
    @inject(symbols.IFetchMyArticlesUseCase) private fetchMyArticlesUseCase: IFetchMyArticlesUseCase,
    @inject(symbols.ICreateMyArticleUseCase) private createMyArticleUseCase: ICreateMyArticleUseCase,
    @inject(symbols.IDeleteMyArticleUseCase) private deleteMyArticleUseCase: IDeleteMyArticleUseCase,
    @inject(symbols.ICreateArticleAttachmentUseCase)
    private createArticleAttachmentUseCase: ICreateArticleAttachmentUseCase,
    @inject(symbols.IFetchMyJobBookmarksUseCase)
    private fetchMyJobBookmarksUseCase: IFetchMyJobBookmarksUseCase,
    @inject(symbols.IAddMyJobBookmarkUseCase) private addMyJobBookmarkUseCase: IAddMyJobBookmarkUseCase,
    @inject(symbols.IRemoveMyJobBookmarkUseCase) private removeMyJobBookmarkUseCase: IRemoveMyJobBookmarkUseCase,
    @inject(symbols.IAddUserReferenceUseCase) private addUserReferenceUseCase: IAddUserReferenceUseCase,
    @inject(symbols.IUpdateUserReferenceUseCase) private updateUserReferenceUseCase: IUpdateUserReferenceUseCase,
    @inject(symbols.IRemoveUserReferenceUseCase) private removeUserReferenceUseCase: IRemoveUserReferenceUseCase,
    @inject(symbols.IAddCompanyReferenceUseCase) private addCompanyReferenceUseCase: IAddCompanyReferenceUseCase,
    @inject(symbols.IUpdateCompanyReferenceUseCase)
    private updateCompanyReferenceUseCase: IUpdateCompanyReferenceUseCase,
    @inject(symbols.IRemoveCompanyReferenceUseCase)
    private removeCompanyReferenceUseCase: IRemoveCompanyReferenceUseCase,
    @inject(symbols.IFetchMyReferencesUseCase) private fetchMyReferencesUseCase: IFetchMyReferencesUseCase,
    @inject(symbols.IToggleFollowCompanyUseCase) private toggleFollowCompanyUseCase: IToggleFollowCompanyUseCase,
    @inject(symbols.IToggleFollowUserUseCase) private toggleFollowUserUseCase: IToggleFollowUserUseCase,
    @inject(symbols.IUpdateCompanyNotificationSettingsUseCase)
    private updateCompanyNotificationSettingsUseCase: IUpdateCompanyNotificationSettingsUseCase,
    @inject(symbols.IUpdateUserNotificationSettingsUseCase)
    private updateUserNotificationSettingsUseCase: IUpdateUserNotificationSettingsUseCase,
    @inject(symbols.IFetchNotificationsUseCase) private fetchNotificationsUserUseCase: IFetchNotificationsUseCase,
    @inject(symbols.ICompanyFactory) private companyFactory: ICompanyFactory,
    @inject(symbols.IExperienceFactory) private experienceFactory: IExperienceFactory,
    @inject(symbols.IInvestmentFactory) private investmentFactory: IInvestmentFactory,
    @inject(symbols.IUserProfileFactory) private userProfileFactory: IUserProfileFactory,
    @inject(symbols.ICompanyMemberFactory) private companyMemberFactory: ICompanyMemberFactory,
    @inject(symbols.IPitchFactory) private pitchFactory: IPitchFactory,
    @inject(symbols.IAddAngelInvestmentInvitationUseCase)
    private addAngelInvestmentInvitationUseCase: IAddAngelInvestmentInvitationUseCase
  ) {}

  @action
  update(base: IViewerBase): void {
    // required fields
    this.id = base.id
    this.email = base.email
    this.username = base.username
    this.name = base.name

    // optional fields
    if (base?.hasUnreadNotifications) {
      this.hasUnreadNotifications = base.hasUnreadNotifications
    }
    if (base?.notifications) {
      this.allNotifications = base.notifications?.nodes
      this.hasAllNotificationsNextPage = base.notifications?.pageInfo?.hasNextPage
      this.allNotificationsCursor = base.notifications?.pageInfo?.endCursor
    }
    if (typeof base?.isFirstSignIn === 'boolean') {
      this.isFirstSignIn = base.isFirstSignIn
    }
    if (base?.invitationOf) {
      this.invitationOf = base.invitationOf
    }
    if (base?.investorMembers) {
      this.investorMembers = base.investorMembers
    }
    if (base?.pmsPlan) {
      this.pmsPlan = base.pmsPlan
    }
    if (base?.role) {
      this.role = base.role
    }

    if (base?.profile) {
      // UserProfile に Factory を作成して割り当て
      this.profile = this.userProfileFactory.create({ base: base.profile })
    }
    if (base?.companyMembers) {
      // CompanyMember の各Company に Factory を作成して割り当て
      this.companyMembers = base.companyMembers.map((companyMember) => {
        const company = this.companyFactory.create({
          base: companyMember.company,
        })
        company.pitches = company.pitches.map((p) => this.pitchFactory.create({ base: p }))
        company.receivedInvestments = company.receivedInvestments.map((i) => this.investmentFactory.create({ base: i }))
        return this.companyMemberFactory.create({
          company,
          base: companyMember,
        })
      })
    }
    if (base?.experiences) {
      // Experience
      this.experiences = base.experiences.map((experience) => {
        return this.experienceFactory.create({ base: experience })
      })
    }
    if (base?.investments) {
      // Investment
      this.investments = base.investments.map((investment) => {
        return this.investmentFactory.create({
          base: investment,
        })
      })
    }
  }

  @action
  updateProfile(profile: IUserProfile): void {
    this.profile = profile
  }

  @action
  updateName(name: string): void {
    this.name = name
  }

  @action
  updateUsername(username: string): void {
    this.username = username
  }

  @action
  addMyCompany(companyMember: IMyCompanyMember): void {
    // CompanyMember の Company に Factory を作成して割り当て
    companyMember.company = this.companyFactory.create({
      base: companyMember.company,
    })

    this.companyMembers.push(companyMember)
  }

  @action
  addInvestorMember(investorMember: IInvestorMemberBase): void {
    this.investorMembers.push(investorMember)
  }

  @action
  _addExperience(experience: IExperience): void {
    this.experiences.push(experience)
  }

  @action
  _removeExperience(experience: IExperience): void {
    this.experiences = this.experiences.filter((e) => e.id !== experience.id)
  }

  @action
  _addInvestment(investment: IInvestment): void {
    this.investments.push(investment)
  }

  @action
  _removeInvestment(investment: IInvestment): void {
    this.investments = this.investments.filter((i) => i.id !== investment.id)
  }

  // =========== notifications ===========
  @action
  _updateAllNotifications(notifications: INotificationBase[]): void {
    this.allNotifications = notifications
  }

  @action
  _addAllNotifications(notifications: INotificationBase[]): void {
    notifications.forEach((newNotification) => {
      if (this.allNotifications.some((n) => n.id === newNotification.id)) {
        return
      }

      this.allNotifications = this.allNotifications.concat(newNotification)
    })
  }

  @action
  _updateHasUnreadNotifications(hasUnreadNotifications: boolean): void {
    this.hasUnreadNotifications = hasUnreadNotifications
  }

  // 引数に渡した readNotifications を既読に更新
  @action
  _markNotificationsAsRead(readNotifications: INotificationBase[]): void {
    this.allNotifications.forEach((n) => {
      if (readNotifications.some((readNotification) => readNotification.id === n.id)) {
        n.isRead = true
      }
    })
  }

  @computed
  get latestNotifications(): INotificationBase[] {
    return this.allNotifications.slice(0, 5)
  }

  // =========== articles ===========
  @action
  _updateArticles(articles: IUserArticle[]): void {
    this.allArticles = articles
  }

  @action
  _addArticles(articles: IUserArticle[]): void {
    articles.forEach((newArticle) => {
      // 重複していたら処理をスキップ
      if (this.allArticles.some((a) => a.slug === newArticle.slug)) {
        return
      }

      // 末尾に追加
      this.allArticles = this.allArticles.concat(newArticle)
    })
  }

  @action
  _addArticle(article: IUserArticle): void {
    this.allArticles.push(article)
  }

  @action
  _deleteArticle(article: IArticleBase): void {
    this.allArticles = this.allArticles.filter((a) => a.id !== article.id)
  }

  @action
  updateHasNextMyArticlesPage(hasNextPage: boolean): void {
    this.hasNextMyArticlesPage = hasNextPage
  }

  @action
  updateArticlesEndCursor(endCursor: string): void {
    this.articlesEndCursor = endCursor
  }

  // =========== myJobBookmarks ===========
  @action
  _updateMyJobBookmarks(jobBookmarks: IJobBookmarkBase[]): void {
    this.myJobBookmarks = jobBookmarks
  }

  @action
  _addMyJobBookmarks(jobBookmarks: IJobBookmarkBase[]): void {
    jobBookmarks.forEach((newJobBookmark) => {
      if (this.myJobBookmarks.some((j) => j.id === newJobBookmark.id)) {
        return
      }

      this.myJobBookmarks = this.myJobBookmarks.concat(newJobBookmark)
    })
  }

  @action
  _updateHasNextMyJobBookmarks(hasNextPage: boolean): void {
    this.hasMyJobBookmarksNextPage = hasNextPage
  }

  @action
  _addMyJobBookmark(jobBookmark: IJobBookmarkBase): void {
    this.myJobBookmarks.unshift(jobBookmark)
  }

  @action
  _removeMyJobBookmark(jobBookmark: IJobBookmarkBase): void {
    this.myJobBookmarks = this.myJobBookmarks.filter((j) => j.id !== jobBookmark.id)
  }

  // =========== myReferences ===========
  @action
  _updateMyReferences(myReferences: IMyReference[]): void {
    this.myReferences = myReferences
  }

  @computed
  get shownStatusReferences(): IMyReference[] {
    return this.myReferences.filter((r) => r.status === UserReferenceStatus.SHOWN)
  }

  @computed
  get hiddenStatusReferences(): IMyReference[] {
    return this.myReferences.filter((r) => r.status === UserReferenceStatus.HIDDEN)
  }

  @computed
  get userBase(): IUserBase {
    return {
      id: this.id,
      username: this.username,
      name: this.name,
      profile: this.profile,
      investments: this.investments,
      experiences: this.experiences,
    }
  }

  @computed
  get unreadNotifications(): INotificationBase[] {
    return this.allNotifications.filter((n) => !n.isRead)
  }

  @computed
  get hasSNSAccount(): boolean {
    if (
      this.profile?.facebookUrl ||
      this.profile?.githubUrl ||
      this.profile?.instagramUrl ||
      this.profile?.linkedinUrl ||
      this.profile?.twitterUrl ||
      this.profile?.websiteUrl
    ) {
      return true
    }
    return false
  }

  @computed
  get hasDesiredEmploymentStatus(): boolean {
    if (
      this.profile?.isFulltimeEmployeeDesired ||
      this.profile?.isContractorDesired ||
      this.profile?.isCofounderDesired ||
      this.profile?.isInternDesired
    ) {
      return true
    }
    return false
  }

  @computed
  get basicProfileCompletionRate(): number {
    let count = 0
    if (this.profile?.avatar) {
      count++
    }
    if (this.profile?.jobTitle) {
      count++
    }
    if (this.profile?.bio) {
      count++
    }
    if (this.profile?.locations.length > 0) {
      count++
    }
    if (this.hasSNSAccount) {
      count++
    }
    if (this.experiences.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 6)
  }

  @computed
  get investorProfileCompletionRate(): number {
    let count = 0
    if (this.profile?.minInvestmentAmount && this.profile?.maxInvestmentAmount) {
      count++
    }
    if (this.profile?.investmentTargetRounds.length > 0) {
      count++
    }
    if (this.profile?.investmentTargetMarkets.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 3)
  }

  @computed
  get investmentsCompletionRate(): number {
    let count = 0
    if (this.investments.length > 0) {
      count++
    }
    return Math.floor(count * 100)
  }

  @computed
  get jobSeekerProfileCompletionRate(): number {
    let count = 0
    if (this.hasDesiredEmploymentStatus) {
      count++
    }
    if (!(this.profile?.jobHuntingStatus === UserProfileJobHuntingStatus.UNKNOWN)) {
      count++
    }
    if (!(this.profile?.primaryJobCategory?.slug === 'unknown')) {
      count++
    }
    if (this.profile?.jobCategories.length > 0) {
      count++
    }
    if (this.profile?.skills.length > 0) {
      count++
    }
    return Math.floor((count * 100) / 5)
  }

  @computed
  get myProfileCompletionRate(): number {
    const isInvestor = this.profile?.isInvestor || this.profile?.isAngel
    if (isInvestor && this.profile?.isJobSeeker) {
      return Math.floor(
        (this.basicProfileCompletionRate +
          this.investorProfileCompletionRate +
          this.investmentsCompletionRate +
          this.jobSeekerProfileCompletionRate) /
          4
      )
    }
    if (isInvestor) {
      return Math.floor(
        (this.basicProfileCompletionRate + this.investorProfileCompletionRate + this.investmentsCompletionRate) / 3
      )
    }
    if (this.profile?.isJobSeeker) {
      return Math.floor((this.basicProfileCompletionRate + this.jobSeekerProfileCompletionRate) / 2)
    }
    return this.basicProfileCompletionRate
  }

  @computed
  get hasActivePmsSubscription(): boolean {
    return this.pmsPlan === PmsPlanBase.BASIC_PLAN
  }

  getMyCompanyBySlug(slug: string): IMyCompany {
    const member = this.companyMembers.find((companyMember) => companyMember.company.slug === slug)
    return member?.company
  }

  isCompanyMember(slug: string): boolean {
    return this.companyMembers.some((companyMember) => companyMember.company.slug === slug)
  }

  async addExperience(experience: IExperienceInputBase): Promise<boolean> {
    const output = await this.addExperienceUseCase.handle({
      experience,
    })

    if (output.experience) {
      this._addExperience(output.experience)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeExperience(experience: IExperience): Promise<boolean> {
    const output = await this.removeExperienceUseCase.handle({
      id: experience.id,
    })

    if (output.experience) {
      this._removeExperience(experience)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async addMyInvestment(investment: IInvestmentInputBase): Promise<boolean> {
    const output = await this.addMyInvestmentUseCase.handle({
      investment,
    })
    if (output.investment) {
      this._addInvestment(output.investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeInvestment(investment: IInvestment): Promise<boolean> {
    const output = await this.removeInvestmentUseCase.handle({
      id: investment.id,
    })
    if (output.investment) {
      this._removeInvestment(investment)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async markAllNotificationsAsRead(): Promise<boolean> {
    if (this.unreadNotifications.length === 0) {
      return true
    }

    const output = await this.markAllNotificationsAsReadUseCase.handle({
      unreadNotifications: this.unreadNotifications,
    })

    this._updateHasUnreadNotifications(output.hasUnreadNotifications)

    if (output.notifications) {
      this._markNotificationsAsRead(output.notifications)
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return true
  }

  async fetchMyArticles(input: { shouldRefresh: boolean; limit: number }): Promise<FetchMyArticlesUseCaseOutput> {
    const output = await this.fetchMyArticlesUseCase.handle({
      shouldRefresh: input.shouldRefresh,
      limit: input.limit,
      after: this.articlesEndCursor,
    })

    if (input.shouldRefresh) {
      this._updateArticles(output.data.articles)
    } else if (output.data.articles.length > 0) {
      // 取得した記事の件数が0件のときは追加しない
      this._addArticles(output.data.articles)
    }

    this.updateHasNextMyArticlesPage(output.data.hasNextPage)
    this.updateArticlesEndCursor(output.data.endCursor)

    return output
  }

  async addMyArticle(article: ArticleInputBase): Promise<boolean> {
    const output = await this.createMyArticleUseCase.handle({ article })

    if (output.article) {
      this._addArticle(output.article)

      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }

  async removeMyArticle(id: string): Promise<DeleteMyArticleUseCaseOutput> {
    const output = await this.deleteMyArticleUseCase.handle({ id })

    if (output.isSuccessful) {
      this._deleteArticle(output.data.article)
    }

    return output
  }

  async addArticleAttachment(input: ArticleAttachmentInputBase): Promise<ArticleAttachmentBase> {
    const output = await this.createArticleAttachmentUseCase.handle({ articleAttachment: input })

    // TODO: UseCaseOutput でエラー情報を含めて返すよう改修

    return output.articleAttachment
  }

  async fetchMyJobBookmarks(input: FetchMyJobBookmarksInput): Promise<boolean> {
    const output = await this.fetchMyJobBookmarksUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    if (input.shouldRefresh) {
      this._updateMyJobBookmarks(output.jobBookmarks)
    } else {
      this._addMyJobBookmarks(output.jobBookmarks)
    }

    this._updateHasNextMyJobBookmarks(output.hasNextPage)
    return true
  }

  async addMyJobBookmark(jobId: string): Promise<boolean> {
    const output = await this.addMyJobBookmarkUseCase.handle({ jobId })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._addMyJobBookmark(output.jobBookmark)
    return true
  }

  async removeMyJobBookmark(jobId: string): Promise<boolean> {
    const output = await this.removeMyJobBookmarkUseCase.handle({ jobId })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._removeMyJobBookmark(output.jobBookmark)
    return true
  }

  isInMyJobBookmarks(jobId: string): boolean {
    const found = this.myJobBookmarks.find((bookmark) => bookmark.job.id === jobId)
    if (found) {
      return true
    }

    return false
  }

  async addAngelInvestmentInvitation(input: IAngelInvestmentInvitationInputBase): Promise<string> {
    const output = await this.addAngelInvestmentInvitationUseCase.handle({
      days: input.days || 7,
      emails: input.emails,
    })

    if (output.error) {
      this.errorsStore.handle(output.error)
      return ''
    }

    return output.invitationLink.url
  }

  async toggleFollowCompany(slug: string): Promise<ICompanyBase> {
    const output = await this.toggleFollowCompanyUseCase.handle({ slug })

    if (output.data.company) {
      return output.data.company
    }

    return null
  }

  async toggleFollowUser(username: string): Promise<IUserBase> {
    const output = await this.toggleFollowUserUseCase.handle({ username })

    if (output.data.user) {
      return output.data.user
    }

    return null
  }

  async updateCompanyNotificationSettings(companySlug: string, notificationIds: string[]): Promise<ICompanyBase> {
    const output = await this.updateCompanyNotificationSettingsUseCase.handle({ companySlug, notificationIds })

    if (output.data.company) {
      return output.data.company
    }

    return null
  }

  async updateUserNotificationSettings(username: string, notificationIds: string[]): Promise<IUserBase> {
    const output = await this.updateUserNotificationSettingsUseCase.handle({ username, notificationIds })

    if (output.data.user) {
      return output.data.user
    }

    return null
  }

  async fetchNotifications(input: FetchNotificationsInput): Promise<boolean> {
    const output = await this.fetchNotificationsUserUseCase.handle({
      shouldRefresh: input.shouldRefresh,
      cursor: this.allNotificationsCursor,
      limit: input.limit,
    })

    if (output.error) {
      return false
    }

    if (input.shouldRefresh) {
      this._updateAllNotifications(output.data.me.notifications.nodes)
    } else {
      this._addAllNotifications(output.data.me.notifications.nodes)
    }

    this.hasAllNotificationsNextPage = output.data.me.notifications.pageInfo.hasNextPage
    this.allNotificationsCursor = output.data.me.notifications.pageInfo.endCursor

    return true
  }

  async addUserReference(username: string, comment: string): Promise<IUserReferenceBase> {
    const output = await this.addUserReferenceUseCase.handle({ username, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async updateUserReference(id: string, comment: string): Promise<IUserReferenceBase> {
    const output = await this.updateUserReferenceUseCase.handle({ id, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async removeUserReference(id: string): Promise<IUserReferenceBase> {
    const output = await this.removeUserReferenceUseCase.handle({ id })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.userReference
  }

  async addCompanyReference(slug: string, comment: string): Promise<ICompanyReferenceBase> {
    const output = await this.addCompanyReferenceUseCase.handle({ slug, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async updateCompanyReference(id: string, comment: string): Promise<ICompanyReferenceBase> {
    const output = await this.updateCompanyReferenceUseCase.handle({ id, comment })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async removeCompanyReference(id: string): Promise<ICompanyReferenceBase> {
    const output = await this.removeCompanyReferenceUseCase.handle({ id })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.companyReference
  }

  async fetchMyReferences(limit: number): Promise<boolean> {
    const output = await this.fetchMyReferencesUseCase.handle({ limit })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return false
    }

    this._updateMyReferences(output.userReferences)
    return true
  }

  @computed
  get primaryUserType(): PrimaryUserType {
    if (this.profile?.isAngel) {
      return PrimaryUserType.ANGEL_INVESTOR
    }
    if (this.profile?.isJobSeeker) {
      return PrimaryUserType.JOB_SEEKER
    }
    if (this.companyMembers.length > 0) {
      // 所属会社の中に VC があれば Fund Manager として扱う
      if (this.companyMembers.some((companyMember) => companyMember?.company?.isVc)) {
        return PrimaryUserType.FUND_MANAGER
      }
      // 所属会社の中に VC がなければ Startup Founder として扱う
      return PrimaryUserType.STARTUP_FOUNDER
    }
    return PrimaryUserType.UNKNOWN
  }
}
