import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateVehicleStatusUseCaseInput,
  CreateVehicleStatusUseCaseOutput,
  IAppErrorFactory,
  ICreateVehicleStatusUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleStatus,
  IVehicleStatusFactory,
} from '@/types'

@injectable()
export default class CreateVehicleStatusInteractor implements ICreateVehicleStatusUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IVehicleStatusFactory) private vehicleStatusFactory: IVehicleStatusFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleStatus: IVehicleStatus
  }>

  async handle(input: CreateVehicleStatusUseCaseInput): Promise<CreateVehicleStatusUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleStatus: null,
      },
    })
    try {
      const base = await this.pmsService.createVehicleStatus(input)
      output.data.vehicleStatus = this.vehicleStatusFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
