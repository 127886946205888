import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUpdateExperienceUseCase,
  IViewerService,
  UpdateExperienceUseCaseInput,
  UpdateExperienceUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class UpdateExperienceInteractor implements IUpdateExperienceUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateExperienceUseCaseInput): Promise<UpdateExperienceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // uncontrolled input warning の対応
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      input.experience.endedAt = input.experience.endedAt || null
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      input.experience.startedAt = input.experience.startedAt || null

      output.experience = await this.viewerService.updateExperience(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '経歴情報の更新に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateExperienceUseCaseOutput {
    return {
      experience: null,
      error: null,
    }
  }
}
