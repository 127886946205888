import {
  AddVehicleMemberInput,
  AddVehicleMemberUseCaseOutput,
  CreateVehicleItemUseCaseInput,
  CreateVehicleItemUseCaseOutput,
  CreateVehicleStatusUseCaseOutput,
  CurrencyUnit,
  DeleteVehicleItemUseCaseOutput,
  DeleteVehicleStatusUseCaseOutput,
  FetchVehicleItemsInput,
  FetchVehicleItemsUseCaseOutput,
  IAddVehicleMemberUseCase,
  ICreateVehicleItemUseCase,
  ICreateVehicleStatusUseCase,
  IDeleteVehicleItemUseCase,
  IDeleteVehicleStatusUseCase,
  IFetchVehicleAnalyticsUseCase,
  IFetchVehicleItemsUseCase,
  IInitializeVehicleUseCase,
  IInvestorBase,
  IRemoveVehicleMemberUseCase,
  IUpdateVehicleUseCase,
  IVehicle,
  IVehicleBase,
  IVehicleDashboard,
  IVehicleInput,
  IVehicleItem,
  IVehicleItemBase,
  IVehicleItemFactory,
  IVehicleMember,
  IVehicleMemberBase,
  IVehicleStatus,
  IVehicleStatusBase,
  IVehicleStatusInputBase,
  RemoveVehicleMemberUseCaseOutput,
  SearchVehicleItemsInput,
  SearchVehicleItemsOutput,
  UpdateVehicleUseCaseOutput,
  VehicleStatusCategory,
} from '@/types'
import { action, computed, observable } from 'mobx'
import { getHashedIds } from '@/utils'

export type Deps = {
  allVehicleItems: IVehicleItem[]
  vehicleMembers: IVehicleMember[]
  vehicleStatuses: IVehicleStatus[]
  initializeVehicleUseCase: IInitializeVehicleUseCase
  createVehicleItemUseCase: ICreateVehicleItemUseCase
  deleteVehicleItemUseCase: IDeleteVehicleItemUseCase
  fetchVehicleAnalyticsUseCase: IFetchVehicleAnalyticsUseCase
  fetchVehicleItemsUseCase: IFetchVehicleItemsUseCase
  updateVehicleUseCase: IUpdateVehicleUseCase
  addVehicleMemberUseCase: IAddVehicleMemberUseCase
  removeVehicleMemberUseCase: IRemoveVehicleMemberUseCase
  createVehicleStatusUseCase: ICreateVehicleStatusUseCase
  deleteVehicleStatusUseCase: IDeleteVehicleStatusUseCase
  vehicleItemFactory: IVehicleItemFactory
}
export default class Vehicle implements IVehicle {
  @observable id = ''

  @observable name = ''

  @observable slug = ''

  @observable allVehicleItems: IVehicleItem[] = []

  @observable vehicleStatuses: IVehicleStatus[] = []

  @observable vehicleMembers: IVehicleMember[] = []

  @observable vehicleDashboards: IVehicleDashboard[] = []

  @observable investor: IInvestorBase

  @observable currencyUnit: CurrencyUnit

  @observable isInitialized = false

  // VehicleItem のページ送りで次のページがあるかのフラグを管理する。
  // `vehicleItemsNextPageExistingFlags["1"]` で vehicleStatusId が 1 の Status に所属する VehicleItem の次ページ有無を取得できる。
  // `vehicleItemsNextPageExistingFlags["1,2,3"]` で vehicleStatusId が 1 と 2 と 3 の Status に所属する VehicleItem の次ページの有無を取得できる。
  // "1,2,3" の部分はヘルパーの getHashedIds を利用して取得のこと。
  //
  // e.g.,
  // - 複数の VehicleStatus の場合 `vehicle.vehicleItemsNextPageExistingFlags[getHashedIds(vehicleStatusIds)]`
  //   - `const vehicleStatusIds = [1, 2]` のようなとき
  // - 単一の VehicleStatus の場合 `vehicle.vehicleItemsNextPageExistingFlags[getHashedIds([vehicleStatusId])]`
  //   - `const vehicleStatusId = 1 のようなとき
  @observable vehicleItemsNextPageExistingFlags: Record<string, boolean> = {}

  // vehicleItemsNextPageExistingFlags と同様の仕様
  @observable vehicleItemsNextPageCursors: Record<string, string> = {}

  initializeVehicleUseCase: IInitializeVehicleUseCase

  createVehicleItemUseCase: ICreateVehicleItemUseCase

  deleteVehicleItemUseCase: IDeleteVehicleItemUseCase

  fetchVehicleAnalyticsUseCase: IFetchVehicleAnalyticsUseCase

  fetchVehicleItemsUseCase: IFetchVehicleItemsUseCase

  updateVehicleUseCase: IUpdateVehicleUseCase

  addVehicleMemberUseCase: IAddVehicleMemberUseCase

  removeVehicleMemberUseCase: IRemoveVehicleMemberUseCase

  createVehicleStatusUseCase: ICreateVehicleStatusUseCase

  deleteVehicleStatusUseCase: IDeleteVehicleStatusUseCase

  vehicleItemFactory: IVehicleItemFactory

  constructor(base: IVehicleBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IVehicleBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  // vehicleMembers, vehicleStatuses 以外をmap
  @action
  _mapBaseExceptInstances(base: IVehicleBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      if (key === 'vehicleMembers' || key === 'vehicleStatuses') {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  @action
  update(base: IVehicleBase): void {
    this._mapBase(base)
  }

  @action
  _updateBase(base: IVehicleBase): void {
    this._mapBaseExceptInstances(base)

    // minVehicleFields で取得しなかったりするので、keyがないときはpass
    const VMKey = 'vehicleMembers'
    const VSKey = 'vehicleStatuses'

    if (VMKey in base) {
      this._updateVehicleMembersBases(base.vehicleMembers)
    }
    if (VSKey in base) {
      this._updateVehicleStatusesBases(base.vehicleStatuses)
    }
  }

  @action
  _addAllVehicleItems(items: IVehicleItem[]): void {
    this.allVehicleItems = this.allVehicleItems.concat(items)
  }

  @action
  _addVehicleItem(item: IVehicleItem): void {
    this.allVehicleItems.push(item)
  }

  @action
  _addUniqueVehicleItem(item: IVehicleItem): void {
    const found = this.getVehicleItem(item.id)
    if (!found) {
      // allVehicleItems にまだない場合だけ追加
      this._addVehicleItem(item)
    }
  }

  @action
  _removeVehicleItem(vehicleItemId: string): void {
    this.allVehicleItems = this.allVehicleItems.filter((vi) => vi.id !== vehicleItemId)
  }

  @action
  _updateIsInitialized(isInitialized: boolean): void {
    this.isInitialized = isInitialized
  }

  @action
  _updateVehicleDashboards(vehicleDashboards: IVehicleDashboard[]): void {
    this.vehicleDashboards = vehicleDashboards
  }

  @action
  _updateVehicleItemsNextPageExistingFlags(key: string, value: boolean): void {
    this.vehicleItemsNextPageExistingFlags[key] = value
  }

  @action
  _updateVehicleItemsNextPageCursors(key: string, value: string): void {
    this.vehicleItemsNextPageCursors[key] = value
  }

  @action
  _addVehicleMember(vehicleMember: IVehicleMember): void {
    this.vehicleMembers.push(vehicleMember)
  }

  @action
  _removeVehicleMember(id: string): void {
    this.vehicleMembers = this.vehicleMembers.filter((m) => m.id !== id)
  }

  @action
  _updateVehicleMembers(vehicleMembers: IVehicleMember[]): void {
    this.vehicleMembers = vehicleMembers
  }

  @action
  _addVehicleStatus(vehicleStatus: IVehicleStatus): void {
    this.vehicleStatuses.push(vehicleStatus)
  }

  @action
  _removeVehicleStatus(id: string): void {
    this.vehicleStatuses = this.vehicleStatuses.filter((s) => s.id !== id)
  }

  @action
  _addVehicleStatuses(vehicleStatuses: IVehicleStatus[]): void {
    vehicleStatuses.forEach((vehicleStatus) => {
      const found = this.vehicleStatuses.find((s) => s.id === vehicleStatus.id)
      if (!found) {
        this._addVehicleStatus(vehicleStatus)
      }
    })
  }

  _updateVehicleMembersBases(vehicleMembers: IVehicleMemberBase[]): void {
    this.vehicleMembers.forEach((thisM) => {
      const foundItem = vehicleMembers.find((m) => m.id === thisM.id)
      if (foundItem) {
        thisM.update(foundItem)
      }
    })
  }

  _updateVehicleStatusesBases(vehicleStatuses: IVehicleStatusBase[]): void {
    this.vehicleStatuses.forEach((thisS) => {
      const foundItem = vehicleStatuses.find((s) => s.id === thisS.id)
      if (foundItem) {
        thisS.update(foundItem)
      }
    })
  }

  _upsertAndGetVehicleItem(base: IVehicleItemBase): IVehicleItem {
    const foundItem = this.getVehicleItem(base.id)
    if (foundItem) {
      // allVehicleItems にある場合は既存のものの base を更新
      foundItem.updateBase(base)
      return foundItem
    }
    // allVehicleItems にまだない場合は Factory 作成して追加
    const newVi = this.vehicleItemFactory.create({ base })
    this._addVehicleItem(newVi)
    return newVi
  }

  _upsertVehicleItems(vehicleItemBases: IVehicleItemBase[]): void {
    vehicleItemBases.forEach((base) => this._upsertAndGetVehicleItem(base))
  }

  _upsertAndGetVehicleItems(vehicleItemBases: IVehicleItemBase[]): IVehicleItem[] {
    return vehicleItemBases.map((base) => this._upsertAndGetVehicleItem(base))
  }

  @computed
  get sortedVehicleStatuses(): IVehicleStatus[] {
    return this.vehicleStatuses?.slice().sort((a, b) => parseFloat(a.weighting) - parseFloat(b.weighting))
  }

  @computed
  get defaultVehicleItems(): IVehicleItem[] {
    return this.allVehicleItems.filter((i) => i.vehicleStatus.category === VehicleStatusCategory.DEFAULT)
  }

  @computed
  get investedVehicleItems(): IVehicleItem[] {
    return this.allVehicleItems.filter((i) => i.vehicleStatus.category === VehicleStatusCategory.INVESTED)
  }

  @computed
  get _defaultVehicleStatuses(): IVehicleStatus[] {
    return this.vehicleStatuses?.filter((s) => s.isDefault)
  }

  @computed
  get _investedVehicleStatuses(): IVehicleStatus[] {
    return this.vehicleStatuses?.filter((s) => s.isInvested)
  }

  @computed
  get defaultVehicleStatusIds(): string[] {
    return this._defaultVehicleStatuses.map((s) => s.id)
  }

  @computed
  get investedVehicleStatusIds(): string[] {
    return this._investedVehicleStatuses.map((s) => s.id)
  }

  getVehicleItem(vehicleItemId: string): IVehicleItem {
    return this.allVehicleItems.find((i) => i.id === vehicleItemId)
  }

  getVehicleItems(vehicleStatusId: string): IVehicleItem[] {
    return (
      this.allVehicleItems
        .filter((item) => item.vehicleStatus.id === vehicleStatusId)
        .slice()
        .sort((a, b) => parseFloat(a.weighting) - parseFloat(b.weighting)) || []
    )
  }

  getVehicleStatus(vehicleStatusId: string): IVehicleStatus {
    return this.vehicleStatuses.find((s) => s.id === vehicleStatusId)
  }

  async initialize(): Promise<void> {
    const output = await this.initializeVehicleUseCase.handle({
      vehicleSlug: this.slug,
    })

    if (output.isSuccessful) {
      this._mapBaseExceptInstances(output.data.vehicle)
      this._addAllVehicleItems(output.data.allVehicleItems)
      this._updateVehicleMembers(output.data.vehicleMembers)
      this._addVehicleStatuses(output.data.vehicleStatuses)
      this._updateIsInitialized(true)
      return
    }

    this._updateIsInitialized(false)
  }

  async save(input: IVehicleInput): Promise<UpdateVehicleUseCaseOutput> {
    const output = await this.updateVehicleUseCase.handle({
      id: this.id,
      name: input.name,
      currencyUnit: input.currencyUnit,
    })

    if (output.isSuccessful) {
      this._updateBase(output.data.vehicle)
    }

    return output
  }

  async addVehicleItem(input: CreateVehicleItemUseCaseInput): Promise<CreateVehicleItemUseCaseOutput> {
    const output = await this.createVehicleItemUseCase.handle(input)
    if (output.isSuccessful) {
      this._addUniqueVehicleItem(output.data.vehicleItem)
    }
    return output
  }

  async deleteVehicleItem(vehicleItemId: string): Promise<DeleteVehicleItemUseCaseOutput> {
    const output = await this.deleteVehicleItemUseCase.handle({
      id: vehicleItemId,
    })
    if (output.isSuccessful) {
      this._removeVehicleItem(vehicleItemId)
    }
    return output
  }

  async fetchVehicleItems(input: FetchVehicleItemsInput): Promise<FetchVehicleItemsUseCaseOutput> {
    const hashedIds = getHashedIds(input.vehicleStatusIds, 'all')
    const cursor = input.shouldRefresh ? '' : this.vehicleItemsNextPageCursors[hashedIds]
    const output = await this.fetchVehicleItemsUseCase.handle({
      vehicleSlug: this.slug,
      vehicleStatusIds: input.vehicleStatusIds,
      limit: input?.limit,
      cursor: cursor || '',
    })

    if (output.isSuccessful) {
      this._upsertVehicleItems(output.data.vehicleItemBases)
      this._updateVehicleItemsNextPageExistingFlags(hashedIds, output.data.hasNextPage)
      this._updateVehicleItemsNextPageCursors(hashedIds, output.data.cursor)
    }

    return output
  }

  // * query がある場合に cursor が扱いづらくなるため、fetchVehicleItems とは別で query 付きの fetch を追加
  // * 取得したデータは allVehicleItems になければ allVehicleItems に追加、あれば base 更新。
  // * fetch したデータではなく、allVehicleItems からの参照を返す。
  // * データの格納, カーソルの管理は View 側で行う(query が変わった場合に cursor をリセットするため)
  async searchVehicleItems(input: SearchVehicleItemsInput): Promise<SearchVehicleItemsOutput> {
    const output = await this.fetchVehicleItemsUseCase.handle({
      vehicleSlug: this.slug,
      query: input.query,
      vehicleStatusIds: input.vehicleStatusIds,
      cursor: input?.cursor || '',
      limit: input?.limit,
    })

    const { isSuccessful, error, data } = output
    const { cursor, hasNextPage, vehicleItemBases } = data

    let vehicleItems: IVehicleItem[] = []
    if (isSuccessful) {
      vehicleItems = this._upsertAndGetVehicleItems(vehicleItemBases)
    }

    const resultData = {
      cursor,
      hasNextPage,
      vehicleItems,
    }

    return { isSuccessful, error, data: resultData }
  }

  async addVehicleMember(input: AddVehicleMemberInput): Promise<AddVehicleMemberUseCaseOutput> {
    const output = await this.addVehicleMemberUseCase.handle({
      vehicleId: this.id,
      userId: input.userId,
      role: input.role,
    })

    if (output.isSuccessful) {
      this._addVehicleMember(output.data.vehicleMember)
    }

    return output
  }

  async removeVehicleMember(id: string): Promise<RemoveVehicleMemberUseCaseOutput> {
    const output = await this.removeVehicleMemberUseCase.handle({ id })

    if (output.isSuccessful) {
      this._removeVehicleMember(id)
    }

    return output
  }

  async fetchVehicleAnalytics(): Promise<boolean> {
    const output = await this.fetchVehicleAnalyticsUseCase.handle({ vehicleSlug: this.slug })

    if (output.isSuccessful) {
      this._updateVehicleDashboards(output.data.dashboards)
    } else {
      return false
    }

    return true
  }

  async createVehicleStatus(input: IVehicleStatusInputBase): Promise<CreateVehicleStatusUseCaseOutput> {
    const output = await this.createVehicleStatusUseCase.handle({
      vehicleSlug: this.slug,
      vehicleStatus: input,
    })

    if (output.isSuccessful) {
      this._addVehicleStatus(output.data.vehicleStatus)
    }

    return output
  }

  async deleteVehicleStatus(id: string): Promise<DeleteVehicleStatusUseCaseOutput> {
    const output = await this.deleteVehicleStatusUseCase.handle({ id })

    if (output.isSuccessful) {
      this._removeVehicleStatus(id)
    }

    return output
  }
}
