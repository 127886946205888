import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUpdateMyProfileUseCase,
  IUserProfileFactory,
  IViewerService,
  UpdateMyProfileUseCaseInput,
  UpdateMyProfileUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import { filterBase64 } from '@/utils'

@injectable()
export default class UpdateMyProfilelInteractor implements IUpdateMyProfileUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IUserProfileFactory) private userProfileFactory: IUserProfileFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateMyProfileUseCaseInput): Promise<UpdateMyProfileUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const profile = await this.viewerService.updateMyProfile(input.profile)
      output.profile = this.userProfileFactory.create({
        base: profile,
      })
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.profile.avatar = filterBase64(input.profile.avatar)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'プロフィールの変更に失敗しました',
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateMyProfileUseCaseOutput {
    return {
      profile: null,
      error: null,
    }
  }
}
