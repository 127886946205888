import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IChatService,
  ISendChatMessageUseCase,
  IUseCaseOutputFactory,
  SendChatMessageUseCaseInput,
  SendChatMessageUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import { filterBase64 } from '@/utils'

@injectable()
export default class SendChatMessageInteractor implements ISendChatMessageUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<boolean>

  handle(input: SendChatMessageUseCaseInput): SendChatMessageUseCaseOutput {
    const output = this.outputFactory.create({
      defaultValue: false,
    })
    try {
      const result = this.chatService.sendChatMessage(input)
      output.data = result
      if (!result) {
        output.error = this.errorFactory.create({
          originalInstance: new Error('Failed to send chat message.'),
        })
      }
    } catch (e) {
      const filteredInput = { ...input }
      if (input.messageFiles.length > 0) {
        filteredInput.messageFiles = input.messageFiles.map((messageFile) => {
          return {
            file: filterBase64(messageFile.file),
            filename: messageFile.filename,
          }
        })
      }

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }
}
