import { injectable } from 'inversify'
import { IUseCaseOutput, IUseCaseOutputFactory, UseCaseOutputFactoryInput } from '@/types'
import UseCaseOutput from '@/entities/UseCaseOutput'

@injectable()
export default class UseCaseOutputFactory<T> implements IUseCaseOutputFactory<T> {
  public create(input: UseCaseOutputFactoryInput<T>): IUseCaseOutput<T> {
    return new UseCaseOutput(input?.defaultValue)
  }
}
