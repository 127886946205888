import { inject, injectable } from 'inversify'
import { IAppErrorFactory, FetchMarketsUseCaseOutput, IFetchMarketsUseCase, IMarketsService } from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMarketsInteractor implements IFetchMarketsUseCase {
  @inject(symbols.IMarketsService) private marketService: IMarketsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchMarketsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const markets = await this.marketService.fetchMarkets()
      output.markets = markets
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMarketsUseCaseOutput {
    return {
      markets: [],
      error: null,
    }
  }
}
