import { action, observable } from 'mobx'
import {
  CompanyReferenceStatus,
  ICompanyReferenceBase,
  IErrorsStore,
  IMyCompanyReference,
  IUpdateMyCompanyReferenceUseCase,
  IUserBase,
} from '@/types'

export default class CompanyReference implements IMyCompanyReference {
  @observable id = ''

  @observable comment = ''

  @observable status: CompanyReferenceStatus

  @observable commenter?: IUserBase

  errorsStore: IErrorsStore

  updateMyCompanyReferenceUseCase: IUpdateMyCompanyReferenceUseCase

  @action
  _mapBase(base: ICompanyReferenceBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: ICompanyReferenceBase) {
    this._mapBase(base)
  }

  async save(status: CompanyReferenceStatus): Promise<boolean> {
    const output = await this.updateMyCompanyReferenceUseCase.handle({ id: this.id, status })
    if (output.error) {
      this.errorsStore.handle(output.error)
      return false
    }

    this._mapBase(output.companyReference)
    return true
  }
}
