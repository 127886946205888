import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IMarkAllNotificationsAsReadUseCase,
  INotificationsService,
  MarkAllNotificationsAsReadUseCaseInput,
  MarkAllNotificationsAsReadUseCaseOutput,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class MarkAllNotificationsAsReadInteractor implements IMarkAllNotificationsAsReadUseCase {
  @inject(symbols.INotificationsService) private notificationService: INotificationsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: MarkAllNotificationsAsReadUseCaseInput): Promise<MarkAllNotificationsAsReadUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const ids = input.unreadNotifications.map((n) => n.id)
      const result = await this.notificationService.markNotificationsAsRead({ ids })
      output.hasUnreadNotifications = result.hasUnreadNotifications
      output.notifications = result.notifications
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): MarkAllNotificationsAsReadUseCaseOutput {
    return {
      hasUnreadNotifications: false,
      notifications: [],
      error: null,
    }
  }
}
