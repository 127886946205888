import { inject, injectable } from 'inversify'
import {
  IAddPortfolioAttachmentUseCase,
  ICreatePortfolioMeetingUseCase,
  ICreatePortfolioNoteUseCase,
  IDeletePortfolioAttachmentUseCase,
  IDeletePortfolioMeetingUseCase,
  IDeletePortfolioNoteUseCase,
  IPortfolio,
  IPortfolioFactory,
  IPortfolioMeetingFactory,
  IPortfolioNoteFactory,
  IUpdateVehicleItemPortfolioUseCase,
  PortfolioFactoryInput,
} from '@/types'
import symbols from '@/symbols'
import Portfolio from '@/entities/Portfolio'

@injectable()
export default class PortfolioFactory implements IPortfolioFactory {
  @inject(symbols.IUpdateVehicleItemPortfolioUseCase)
  updateVehicleItemPortfolioUseCase: IUpdateVehicleItemPortfolioUseCase

  @inject(symbols.ICreatePortfolioNoteUseCase)
  createPortfolioNoteUseCase: ICreatePortfolioNoteUseCase

  @inject(symbols.IDeletePortfolioNoteUseCase)
  deletePortfolioNoteUseCase: IDeletePortfolioNoteUseCase

  @inject(symbols.ICreatePortfolioMeetingUseCase)
  createPortfolioMeetingUseCase: ICreatePortfolioMeetingUseCase

  @inject(symbols.IDeletePortfolioMeetingUseCase)
  deletePortfolioMeetingUseCase: IDeletePortfolioMeetingUseCase

  @inject(symbols.IAddPortfolioAttachmentUseCase)
  addPortfolioAttachmentUseCase: IAddPortfolioAttachmentUseCase

  @inject(symbols.IDeletePortfolioAttachmentUseCase)
  deletePortfolioAttachmentUseCase: IDeletePortfolioAttachmentUseCase

  @inject(symbols.IPortfolioNoteFactory)
  portfolioNoteFactory: IPortfolioNoteFactory

  @inject(symbols.IPortfolioMeetingFactory)
  portfolioMeetingFactory: IPortfolioMeetingFactory

  public create(input: PortfolioFactoryInput): IPortfolio {
    const notes = input.base?.portfolioNotes?.nodes?.map((base) => this.portfolioNoteFactory.create({ base })) || []
    const meetings =
      input.base?.portfolioMeetings?.nodes?.map((base) => this.portfolioMeetingFactory.create({ base })) || []

    return new Portfolio(input.base, {
      allPortfolioNotes: notes,
      allPortfolioMeetings: meetings,
      updateVehicleItemPortfolioUseCase: this.updateVehicleItemPortfolioUseCase,
      createPortfolioNoteUseCase: this.createPortfolioNoteUseCase,
      deletePortfolioNoteUseCase: this.deletePortfolioNoteUseCase,
      createPortfolioMeetingUseCase: this.createPortfolioMeetingUseCase,
      deletePortfolioMeetingUseCase: this.deletePortfolioMeetingUseCase,
      addPortfolioAttachmentUseCase: this.addPortfolioAttachmentUseCase,
      deletePortfolioAttachmentUseCase: this.deletePortfolioAttachmentUseCase,
    })
  }
}
