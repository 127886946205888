import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IUpdateMeUseCase,
  IViewerService,
  UpdateMeUseCaseInput,
  UpdateMeUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class UpdateMeInteractor implements IUpdateMeUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdateMeUseCaseInput): Promise<UpdateMeUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const viewerBase = await this.viewerService.updateMe(input)
      output.name = viewerBase.name
      output.username = viewerBase.username
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ユーザー情報の変更に失敗しました',
        extra: {
          useCaseInput: {
            name: input.name,
            username: input.username,
          },
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdateMeUseCaseOutput {
    return {
      name: null,
      username: null,
      error: null,
    }
  }
}
