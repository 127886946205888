import {
  IUpdateVehicleMemberUseCase,
  IVehicleMember,
  IVehicleMemberBase,
  UpdateVehicleMemberUseCaseOutput,
  VehicleMemberRole,
} from '@/types'
import { action, observable } from 'mobx'

type Deps = {
  updateVehicleMemberUseCase: IUpdateVehicleMemberUseCase
}

export default class VehicleMember implements IVehicleMember {
  @observable id: string

  @observable role: VehicleMemberRole

  updateVehicleMemberUseCase: IUpdateVehicleMemberUseCase

  constructor(base: IVehicleMemberBase, deps: Deps) {
    this._mapBase(base)
    this._mapDeps(deps)
  }

  @action
  _mapBase(base: IVehicleMemberBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  _mapDeps(deps: Deps): void {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  update(base: IVehicleMemberBase): void {
    this._mapBase(base)
  }

  async save(role: VehicleMemberRole): Promise<UpdateVehicleMemberUseCaseOutput> {
    const output = await this.updateVehicleMemberUseCase.handle({
      id: this.id,
      vehicleMemberRole: role,
    })

    if (output.isSuccessful) {
      this.update(output.data.vehicleMember)
    }

    return output
  }
}
