import { action, observable } from 'mobx'
import { IErrorsStore, IJobFootprint, IJobFootprintBase, IJobBase, IUserBase } from '@/types'

export default class JobFootprint implements IJobFootprint {
  @observable id = ''

  @observable referer = ''

  @observable user: IUserBase

  @observable job: IJobBase

  @observable createdAt: ''

  @observable children: IJobFootprintBase[]

  errorsStore: IErrorsStore

  @action
  _mapBase(base: IJobFootprintBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // TODO: いい実装考える
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  constructor(base: IJobFootprintBase, children?: IJobFootprintBase[]) {
    this._mapBase(base)
    if (children) {
      this.children = children
    }
  }
}
