import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchVehicleItemUseCaseInput,
  FetchVehicleItemUseCaseOutput,
  IAppErrorFactory,
  IFetchVehicleItemUseCase,
  IPMSService,
  IPortfolio,
  IPortfolioFactory,
  IUseCaseOutputFactory,
  IVehicleItemBase,
  IVehicleItemRound,
  IVehicleItemRoundFactory,
} from '@/types'

@injectable()
export default class FetchVehicleItemInteractor implements IFetchVehicleItemUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IPortfolioFactory) private portfolioFactory: IPortfolioFactory

  @inject(symbols.IVehicleItemRoundFactory) private vehicleItemRoundFactory: IVehicleItemRoundFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    base: IVehicleItemBase
    portfolio: IPortfolio
    vehicleItemRounds: IVehicleItemRound[]
  }>

  async handle(input: FetchVehicleItemUseCaseInput): Promise<FetchVehicleItemUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        base: null,
        portfolio: null,
        vehicleItemRounds: [],
      },
    })
    try {
      const base = await this.pmsService.fetchVehicleItem(input.id)
      const portfolio = this.portfolioFactory.create({ base: base.portfolio })
      const vehicleItemRounds = base.vehicleItemRounds
        ? base.vehicleItemRounds.map((round) => this.vehicleItemRoundFactory.create({ base: round }))
        : []

      output.data.base = base
      output.data.portfolio = portfolio
      output.data.vehicleItemRounds = vehicleItemRounds
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
