import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IPortfolioMeetingBase,
  IUpdatePortfolioMeetingUseCase,
  IUseCaseOutputFactory,
  UpdatePortfolioMeetingUseCaseInput,
  UpdatePortfolioMeetingUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdatePortfolioMeetingInteractor implements IUpdatePortfolioMeetingUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    portfolioMeeting: IPortfolioMeetingBase
  }>

  async handle(input: UpdatePortfolioMeetingUseCaseInput): Promise<UpdatePortfolioMeetingUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        portfolioMeeting: null,
      },
    })
    try {
      output.data.portfolioMeeting = await this.pmsService.updateVehicleItemPortfolioMeeting(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
