import { inject, injectable } from 'inversify'
import {
  CreateCompanyArticleUseCaseInput,
  CreateCompanyArticleUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  ICompanyArticleFactory,
  ICreateCompanyArticleUseCase,
} from '@/types'
import symbols from '@/symbols'
import { filterBase64 } from '@/utils'

@injectable()
export default class CreateCompanyArticleInteractor implements ICreateCompanyArticleUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.ICompanyArticleFactory) private companyArticleFactory: ICompanyArticleFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: CreateCompanyArticleUseCaseInput): Promise<CreateCompanyArticleUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const base = await this.articlesService.createCompanyArticle(input)
      output.article = this.companyArticleFactory.create({ base })
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.article.thumbnail = filterBase64(input.article.thumbnail)
      filteredInput.article.ogImage = filterBase64(input.article.ogImage)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '記事の作成に失敗しました',
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): CreateCompanyArticleUseCaseOutput {
    return {
      article: null,
      error: null,
    }
  }
}
