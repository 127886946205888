import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IUpdateVehicleMemberUseCase,
  IUseCaseOutputFactory,
  IVehicleMemberBase,
  UpdateVehicleMemberUseCaseInput,
  UpdateVehicleMemberUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateVehicleMemberInteractor implements IUpdateVehicleMemberUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleMember: IVehicleMemberBase
  }>

  async handle(input: UpdateVehicleMemberUseCaseInput): Promise<UpdateVehicleMemberUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleMember: null,
      },
    })
    try {
      output.data.vehicleMember = await this.pmsService.updateVehicleMember(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
