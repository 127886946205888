import { inject, injectable } from 'inversify'
import {
  DeleteCompanyArticleUseCaseInput,
  DeleteCompanyArticleUseCaseOutput,
  IAppErrorFactory,
  IArticleBase,
  IArticlesService,
  IDeleteCompanyArticleUseCase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class DeleteCompanyArticleInteractor implements IDeleteCompanyArticleUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{ article: null | IArticleBase }>

  async handle(input: DeleteCompanyArticleUseCaseInput): Promise<DeleteCompanyArticleUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        article: null,
      },
    })
    try {
      output.data.article = await this.articlesService.deleteCompanyArticle({
        id: input.id,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
