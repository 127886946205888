import { inject, injectable } from 'inversify'
import {
  IAppCredentials,
  IAppErrorFactory,
  IAuthService,
  IErrorTrackingService,
  IInitializeUseCase,
  InitializeUseCaseInput,
  InitializeUseCaseOutput,
  IPreferences,
  IStorageService,
  IViewer,
  IViewerService,
} from '@/types'
import symbols from '@/symbols'
import { getGraphQLErrorInfo } from '@/utils'

@injectable()
export default class InitializeInteractor implements IInitializeUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IPreferences) private preferences: IPreferences

  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IStorageService) private storageService: IStorageService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IViewer) private viewer: IViewer

  async handle(input: InitializeUseCaseInput): Promise<InitializeUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 設定を復元
      this.preferences.restore()
      this.preferences.changeLanguage(input.language)

      // credentials を storage から読み込み
      const isSuccessful = this.credentials.restore()
      if (!isSuccessful) {
        // storage になかった場合にアーリーリターン
        return output
      }

      // viewer を取得
      const viewerBase = await this.viewerService.fetchMe()
      if (viewerBase) {
        // viewer の情報取得できたら値を設定し、ログイン済みにする
        this.viewer.update(viewerBase)

        // エラートラッキングサービスに現在のユーザーを設定
        this.errorTrackingService.setUser(viewerBase)
      } else {
        // viewer がない場合は credentials が無効でログインできてないので
        // 保持している credentials を削除
        this.credentials.clear()
      }
      // ログインしてるかは関係なく、エラーがなければ true
      output.isSuccessful = true
    } catch (e) {
      const [, , code] = getGraphQLErrorInfo(e)
      if (code === 'LEAVE_ACCOUNT') {
        // 退会ユーザーの場合は credentials をクリアしてログアウトした状態にさせる
        this.credentials.clear()
      }

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: null,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): InitializeUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
