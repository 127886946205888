import { action, observable } from 'mobx'
import {
  FollowableEntityCategory,
  FollowableEntityEntityType,
  ICompanyBase,
  IFollowableEntityBase,
  IPublicFollowableEntity,
  IUserBase,
} from '@/types'

export default class PublicFollowableEntity implements IPublicFollowableEntity {
  @observable category: FollowableEntityCategory

  @observable description = ''

  @observable entityType: FollowableEntityEntityType

  @observable image = ''

  @observable isFollowing = false

  @observable isVerifiedAccount = false

  @observable name = ''

  @observable shortDescription = ''

  @observable slug = ''

  @action
  _mapFromBase(base: IFollowableEntityBase): void {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
  }

  @action
  update(base: IFollowableEntityBase): void {
    this._mapFromBase(base)
  }

  constructor(base: IFollowableEntityBase) {
    this._mapFromBase(base)
  }

  @action
  updateFromUser(base: IUserBase): void {
    this.isFollowing = base.isFollowing
  }

  @action
  updateFromCompany(base: ICompanyBase): void {
    this.isFollowing = base.isFollowing
  }
}
