import { injectable } from 'inversify'
import { IStorageService } from '@/types'

@injectable()
export default class LocalStorageGateway implements IStorageService {
  setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  getItem(key: string): string {
    return localStorage.getItem(key)
  }

  removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  get isAbleToUse(): boolean {
    // localStorage が定義されていれば利用できる判定とする
    return typeof localStorage !== 'undefined'
  }
}
