import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  FetchArticlesUseCaseOutput,
  FetchFeaturedStoriesUseCaseOutput,
  IArticle,
  IArticleBase,
  IArticlesStore,
  IErrorsStore,
  IFetchArticlesUseCase,
  IFetchArticleUseCase,
  IFetchFeaturedStoriesUseCase,
  IFetchMagazineArchivePageContentUseCase,
  IFetchMagazineSinglePageContentUseCase,
  IFetchMagazineTopPageContentUseCase,
  IFetchPickedUpFeaturesUseCase,
  IPublicArticle,
  IPublicArticleFactory,
  MagazineArchivePageContents,
  MagazineSinglePageContents,
  MagazineTopPageContents,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ArticlesStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class ArticlesStore implements IArticlesStore {
  @observable storiesArticles: IArticle[] = []

  @observable publicArticles: Record<string, IPublicArticle> = {}

  @observable featuresArticles: IArticle[] = []

  @observable opinionsArticles: IArticle[] = []

  @observable newsArticles: IArticle[] = []

  @observable interviewsArticles: IArticle[] = []

  @observable hasNextStoriesPage = true

  @observable hasNextFeaturesPage = true

  @observable hasNextOpinionsPage = true

  @observable hasNextInterviewsPage = true

  @observable hasNextNewsPage = true

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchArticlesUseCase) private fetchArticlesUseCase: IFetchArticlesUseCase,
    @inject(symbols.IFetchFeaturedStoriesUseCase) private fetchFeaturedStoriesUseCase: IFetchFeaturedStoriesUseCase,
    @inject(symbols.IFetchMagazineSinglePageContentUseCase)
    private fetchMagazineSinglePageContentUseCase: IFetchMagazineSinglePageContentUseCase,
    @inject(symbols.IFetchMagazineArchivePageContentUseCase)
    private fetchMagazineArchivePageContentUseCase: IFetchMagazineArchivePageContentUseCase,
    @inject(symbols.IFetchMagazineTopPageContentUseCase)
    private fetchMagazineTopPageContentUseCase: IFetchMagazineTopPageContentUseCase,
    @inject(symbols.IPublicArticleFactory) private publicArticleFactory: IPublicArticleFactory,
    @inject(symbols.IFetchPickedUpFeaturesUseCase) private fetchPickedUpFeaturesUseCase: IFetchPickedUpFeaturesUseCase,
    @inject(symbols.IFetchArticleUseCase) private fetchArticleUseCase: IFetchArticleUseCase
  ) {
    //
  }

  @action
  _addStoriesArticles(articles: IArticle[]): void {
    // 重複を除く
    const newArticles = articles.filter((newArticle) => {
      const isDuplicate = this.storiesArticles.some((a) => a.slug === newArticle.slug)
      return !isDuplicate
    })
    // 末尾に追加
    this.storiesArticles = this.storiesArticles.concat(newArticles)
  }

  @action
  _addFeaturesArticles(articles: IArticle[]): void {
    // 重複を除く
    const newArticles = articles.filter((newArticle) => {
      const isDuplicate = this.featuresArticles.some((a) => a.slug === newArticle.slug)
      return !isDuplicate
    })
    // 末尾に追加
    this.featuresArticles = this.featuresArticles.concat(newArticles)
  }

  @action
  _addOpinionsArticles(articles: IArticle[]): void {
    // 重複を除く
    const newArticles = articles.filter((newArticle) => {
      const isDuplicate = this.opinionsArticles.some((a) => a.slug === newArticle.slug)
      return !isDuplicate
    })
    // 末尾に追加
    this.opinionsArticles = this.opinionsArticles.concat(newArticles)
  }

  @action
  _addNewsArticles(articles: IArticle[]): void {
    // 重複を除く
    const newArticles = articles.filter((newArticle) => {
      const isDuplicate = this.newsArticles.some((a) => a.slug === newArticle.slug)
      return !isDuplicate
    })
    // 末尾に追加
    this.newsArticles = this.newsArticles.concat(newArticles)
  }

  @action
  _addInterviewsArticles(articles: IArticle[]): void {
    // 重複を除く
    const newArticles = articles.filter((newArticle) => {
      const isDuplicate = this.interviewsArticles.some((a) => a.slug === newArticle.slug)
      return !isDuplicate
    })
    // 末尾に追加
    this.interviewsArticles = this.interviewsArticles.concat(newArticles)
  }

  @action
  _updateHasNextStoriesPage(hasNextPage: boolean): void {
    this.hasNextStoriesPage = hasNextPage
  }

  @action
  _updateHasNextFeaturesPage(hasNextPage: boolean): void {
    this.hasNextFeaturesPage = hasNextPage
  }

  @action
  _updateHasNextOpinionsPage(hasNextPage: boolean): void {
    this.hasNextOpinionsPage = hasNextPage
  }

  @action
  _updateHasNextNewsPage(hasNextPage: boolean): void {
    this.hasNextNewsPage = hasNextPage
  }

  @action
  _updateHasNextInterviewsPage(hasNextPage: boolean): void {
    this.hasNextInterviewsPage = hasNextPage
  }

  @action
  updatePublicArticle(article: IArticleBase): void {
    if (this.publicArticles[article.slug]) {
      this.publicArticles[article.slug].update(article)
    } else {
      this.publicArticles[article.slug] = this.publicArticleFactory.create({ base: article })
    }
  }

  async fetchArticles(input: { category?: string; limit: number }): Promise<FetchArticlesUseCaseOutput> {
    let categories: string[] = []
    switch (input.category) {
      case 'stories':
        categories = ['stories']
        break
      case 'features':
        categories = ['feature']
        break
      case 'opinions':
        categories = ['opinions']
        break
      case 'news':
        categories = ['news']
        break
      case 'interviews':
        categories = ['interviews']
        break
      default:
        break
    }

    const output = await this.fetchArticlesUseCase.handle({
      categories,
      limit: input.limit,
    })
    if (!output.isSuccessful) {
      return output
    }

    switch (input.category) {
      case 'stories':
        this._addStoriesArticles(output.data.articles)
        this._updateHasNextStoriesPage(output.data.hasNextPage)
        break
      case 'features':
        this._addFeaturesArticles(output.data.articles)
        this._updateHasNextFeaturesPage(output.data.hasNextPage)
        break
      case 'opinions':
        this._addOpinionsArticles(output.data.articles)
        this._updateHasNextOpinionsPage(output.data.hasNextPage)
        break
      case 'news':
        this._addNewsArticles(output.data.articles)
        this._updateHasNextNewsPage(output.data.hasNextPage)
        break
      case 'interviews':
        this._addInterviewsArticles(output.data.articles)
        this._updateHasNextInterviewsPage(output.data.hasNextPage)
        break
      default:
        break
    }

    return output
  }

  async fetchTopPageContents(): Promise<MagazineTopPageContents> {
    const output = await this.fetchMagazineTopPageContentUseCase.handle()
    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return {
      pickups: output.pickups,
      stories: output.stories,
      opinions: output.opinions,
      features: output.features,
      news: output.news,
      interviews: output.interviews,
      asideContents: output.asideContents,
    }
  }

  async fetchCategoryArchivePageContents(category: string, limit: number): Promise<MagazineArchivePageContents> {
    let categories: string[] = []
    switch (category) {
      case 'stories':
        categories = ['stories']
        break
      case 'features':
        categories = ['feature']
        break
      case 'opinions':
        categories = ['opinions']
        break
      case 'news':
        categories = ['news']
        break
      case 'interviews':
        categories = ['interviews']
        break
      default:
        break
    }

    const output = await this.fetchMagazineArchivePageContentUseCase.handle({ categories, limit })
    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return {
      articles: output.articles,
      asideContents: output.asideContents,
    }
  }

  async fetchSinglePageContents(slug: string): Promise<MagazineSinglePageContents> {
    const output = await this.fetchMagazineSinglePageContentUseCase.handle({ slug })
    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return {
      article: output.article,
      asideContents: output.asideContents,
      recommendedArticles: output.recommendedArticles,
    }
  }

  async fetchFeaturedStories(limit: number): Promise<FetchFeaturedStoriesUseCaseOutput> {
    const output = await this.fetchFeaturedStoriesUseCase.handle({
      limit,
    })
    if (output.isSuccessful) {
      this._addStoriesArticles(output.data.featuredStories)
    }

    return output
  }

  // 記事の Base を受け取って Instance にした上で Store で管理する
  // Store で管理するのはキャッシュの効果と不具合防止
  // Store で管理する場合、キャッシュが効いて一度取得した isLiked の値を保持できる
  // Store で管理しない場合、ブラウザバックで MobX の Observer が効かなくなる
  createArticleInstance(base: IArticleBase): IPublicArticle {
    if (!base) {
      return null
    }

    if (this.publicArticles[base?.slug]) {
      return this.publicArticles[base?.slug]
    }

    const instance = this.publicArticleFactory.create({ base })
    this.publicArticles[instance.slug] = instance

    return instance
  }

  async fetchPickedUpFeatures(limit: number): Promise<IArticle[]> {
    const output = await this.fetchPickedUpFeaturesUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }
    this._addFeaturesArticles(output.pickedUpFeatures)

    return output.pickedUpFeatures
  }

  async fetchArticle(slug: string): Promise<boolean> {
    const output = await this.fetchArticleUseCase.handle({ slug })

    if (output.data.article) {
      this.updatePublicArticle(output.data.article)
      return true
    }

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return false
  }
}
