import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchNotificationsUseCaseInput,
  FetchNotificationsUseCaseOutput,
  IAppErrorFactory,
  IFetchNotificationsUseCase,
  IUseCaseOutputFactory,
  IViewerBase,
  IViewerService,
} from '@/types'

@injectable()
export default class FetchNotificationsInteractor implements IFetchNotificationsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    me: null | IViewerBase
  }>

  async handle(input: FetchNotificationsUseCaseInput): Promise<FetchNotificationsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        me: null,
      },
    })
    try {
      output.data.me = await this.viewerService.fetchNotifications(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
