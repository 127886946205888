import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateVehicleItemUseCaseInput,
  CreateVehicleItemUseCaseOutput,
  IAppErrorFactory,
  ICreateVehicleItemUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleItem,
  IVehicleItemFactory,
} from '@/types'

@injectable()
export default class CreateVehicleItemInteractor implements ICreateVehicleItemUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IVehicleItemFactory) private vehicleItemFactory: IVehicleItemFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItem: IVehicleItem
  }>

  async handle(input: CreateVehicleItemUseCaseInput): Promise<CreateVehicleItemUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItem: null,
      },
    })
    try {
      const base = await this.pmsService.createVehicleItem(input)
      output.data.vehicleItem = this.vehicleItemFactory.create({
        base,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
