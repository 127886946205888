import { inject, injectable } from 'inversify'
import { IAppErrorFactory, IAuthService, ISignUpUseCase, SignUpUseCaseInput, SignUpUseCaseOutput } from '@/types'
import symbols from '@/symbols'
import ExpectedError from '@/errors/ExpectedError'

@injectable()
export default class SignUpInteractor implements ISignUpUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: SignUpUseCaseInput): Promise<SignUpUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.authService.signUp(
        input.name,
        input.username,
        input.email,
        input.password,
        input.passwordConfirmation,
        input.token
      )
      output.isSuccessful = true
    } catch (e) {
      output.isSuccessful = false
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: e instanceof ExpectedError ? e.message : 'サインアップに失敗しました',
        extra: {
          useCaseInput: {
            name: input.name,
            usename: input.username,
          },
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): SignUpUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
