import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyReferencesUseCaseInput,
  FetchMyReferencesUseCaseOutput,
  IAppErrorFactory,
  IFetchMyReferencesUseCase,
  IUserReferenceFactory,
  IUserReferencesService,
} from '../../types'

@injectable()
export default class FetchMyReferencesInteractor implements IFetchMyReferencesUseCase {
  @inject(symbols.IUserReferencesService) private userReferencesService: IUserReferencesService

  @inject(symbols.IUserReferenceFactory) private userReferenceFactory: IUserReferenceFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchMyReferencesUseCaseInput): Promise<FetchMyReferencesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { userReferences, hasNextPage } = await this.userReferencesService.fetchMyReferences(input)
      output.userReferences = userReferences.map((r) => this.userReferenceFactory.create({ base: r }))
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの取得に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMyReferencesUseCaseOutput {
    return {
      userReferences: [],
      hasNextPage: true,
      error: null,
    }
  }
}
