import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchJobsRequestInput,
  FetchJobsUseCaseOutput,
  IAppErrorFactory,
  IFetchJobsUseCase,
  IJobsService,
} from '../../types'

@injectable()
export default class FetchJobsInteractor implements IFetchJobsUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchJobsRequestInput): Promise<FetchJobsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { jobs, hasNextPage } = await this.jobsService.fetchJobs(input)

      output.jobs = jobs
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchJobsUseCaseOutput {
    return {
      jobs: [],
      hasNextPage: true,
      error: null,
    }
  }
}
