import { inject, injectable } from 'inversify'
import { IAppErrorFactory, FetchJobTagsUseCaseOutput, IFetchJobTagsUseCase, IJobTagsService } from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchJobTagsInteractor implements IFetchJobTagsUseCase {
  @inject(symbols.IJobTagsService) private jobTagService: IJobTagsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(): Promise<FetchJobTagsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const jobTags = await this.jobTagService.fetchJobTags()
      output.jobTags = jobTags
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchJobTagsUseCaseOutput {
    return {
      jobTags: [],
      error: null,
    }
  }
}
