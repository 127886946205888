import { inject, injectable } from 'inversify'
import { IUpdateVehicleMemberUseCase, IVehicleMember, IVehicleMemberFactory, VehicleMemberFactoryInput } from '@/types'
import VehicleMember from '@/entities/VehicleMember'
import symbols from '@/symbols'

@injectable()
export default class VehicleMemberFactory implements IVehicleMemberFactory {
  @inject(symbols.IUpdateVehicleMemberUseCase) updateVehicleMemberUseCase: IUpdateVehicleMemberUseCase

  public create(input: VehicleMemberFactoryInput): IVehicleMember {
    return new VehicleMember(input.base, {
      updateVehicleMemberUseCase: this.updateVehicleMemberUseCase,
    })
  }
}
