import { inject, injectable } from 'inversify'
import {
  CompanyReferenceFactoryInput,
  ICompanyReferenceFactory,
  IErrorsStore,
  IMyCompanyReference,
  IUpdateMyCompanyReferenceUseCase,
} from '@/types'
import symbols from '@/symbols'
import CompanyReference from '@/entities/CompanyReference'

@injectable()
export default class CompanyReferenceFactory implements ICompanyReferenceFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  @inject(symbols.IUpdateMyCompanyReferenceUseCase)
  private updateMyCompanyReferenceUseCase: IUpdateMyCompanyReferenceUseCase

  public create(input: CompanyReferenceFactoryInput): IMyCompanyReference {
    const companyReference = new CompanyReference(input.base)
    companyReference.errorsStore = this.errorStore
    companyReference.updateMyCompanyReferenceUseCase = this.updateMyCompanyReferenceUseCase
    return companyReference
  }
}
