import { inject, injectable } from 'inversify'
import {
  IAddVehicleMemberUseCase,
  ICreateVehicleItemUseCase,
  IDeleteVehicleItemUseCase,
  IFetchVehicleItemsUseCase,
  IFetchVehicleAnalyticsUseCase,
  IInitializeVehicleUseCase,
  IUpdateVehicleUseCase,
  IRemoveVehicleMemberUseCase,
  IVehicle,
  IVehicleFactory,
  IVehicleItem,
  IVehicleItemFactory,
  VehicleFactoryInput,
  IVehicleMemberFactory,
  ICreateVehicleStatusUseCase,
  IDeleteVehicleStatusUseCase,
  IVehicleStatusFactory,
} from '@/types'
import Vehicle from '@/entities/Vehicle'
import symbols from '@/symbols'

@injectable()
export default class VehicleFactory implements IVehicleFactory {
  @inject(symbols.IVehicleItemFactory) private vehicleItemFactory: IVehicleItemFactory

  @inject(symbols.IVehicleMemberFactory) private vehicleMemberFactory: IVehicleMemberFactory

  @inject(symbols.IVehicleStatusFactory) private vehicleStatusFactory: IVehicleStatusFactory

  @inject(symbols.IInitializeVehicleUseCase) public initializeVehicleUseCase: IInitializeVehicleUseCase

  @inject(symbols.ICreateVehicleItemUseCase) public createVehicleItemUseCase: ICreateVehicleItemUseCase

  @inject(symbols.IDeleteVehicleItemUseCase) public deleteVehicleItemUseCase: IDeleteVehicleItemUseCase

  @inject(symbols.IFetchVehicleAnalyticsUseCase) public fetchVehicleAnalyticsUseCase: IFetchVehicleAnalyticsUseCase

  @inject(symbols.IFetchVehicleItemsUseCase) public fetchVehicleItemsUseCase: IFetchVehicleItemsUseCase

  @inject(symbols.IUpdateVehicleUseCase) public updateVehicleUseCase: IUpdateVehicleUseCase

  @inject(symbols.IAddVehicleMemberUseCase) public addVehicleMemberUseCase: IAddVehicleMemberUseCase

  @inject(symbols.IRemoveVehicleMemberUseCase) public removeVehicleMemberUseCase: IRemoveVehicleMemberUseCase

  @inject(symbols.ICreateVehicleStatusUseCase) public createVehicleStatusUseCase: ICreateVehicleStatusUseCase

  @inject(symbols.IDeleteVehicleStatusUseCase) public deleteVehicleStatusUseCase: IDeleteVehicleStatusUseCase

  public create(input: VehicleFactoryInput): IVehicle {
    const initialValue: IVehicleItem[] = []

    const vehicleMembers = input.base.vehicleMembers
      ? input.base.vehicleMembers.map((m) => this.vehicleMemberFactory.create({ base: m }))
      : []

    // vehicleStatuses がなければ空配列を渡す
    if (!input.base?.vehicleStatuses) {
      return new Vehicle(input.base, {
        allVehicleItems: initialValue,
        vehicleMembers,
        vehicleStatuses: [],
        initializeVehicleUseCase: this.initializeVehicleUseCase,
        createVehicleItemUseCase: this.createVehicleItemUseCase,
        deleteVehicleItemUseCase: this.deleteVehicleItemUseCase,
        fetchVehicleItemsUseCase: this.fetchVehicleItemsUseCase,
        updateVehicleUseCase: this.updateVehicleUseCase,
        addVehicleMemberUseCase: this.addVehicleMemberUseCase,
        removeVehicleMemberUseCase: this.removeVehicleMemberUseCase,
        fetchVehicleAnalyticsUseCase: this.fetchVehicleAnalyticsUseCase,
        createVehicleStatusUseCase: this.createVehicleStatusUseCase,
        deleteVehicleStatusUseCase: this.deleteVehicleStatusUseCase,
        vehicleItemFactory: this.vehicleItemFactory,
      })
    }

    const allVehicleItems = input.base?.vehicleStatuses?.reduce((accumulator, currentValue) => {
      const items = currentValue?.vehicleItems?.nodes?.map((item) => this.vehicleItemFactory.create({ base: item }))
      if (items) {
        return accumulator.concat(items)
      }
      return accumulator
    }, initialValue)

    const vehicleStatuses = input.base.vehicleStatuses?.map((s) => this.vehicleStatusFactory.create({ base: s }))

    return new Vehicle(input.base, {
      allVehicleItems,
      vehicleMembers,
      vehicleStatuses,
      initializeVehicleUseCase: this.initializeVehicleUseCase,
      createVehicleItemUseCase: this.createVehicleItemUseCase,
      deleteVehicleItemUseCase: this.deleteVehicleItemUseCase,
      fetchVehicleItemsUseCase: this.fetchVehicleItemsUseCase,
      updateVehicleUseCase: this.updateVehicleUseCase,
      fetchVehicleAnalyticsUseCase: this.fetchVehicleAnalyticsUseCase,
      addVehicleMemberUseCase: this.addVehicleMemberUseCase,
      removeVehicleMemberUseCase: this.removeVehicleMemberUseCase,
      createVehicleStatusUseCase: this.createVehicleStatusUseCase,
      deleteVehicleStatusUseCase: this.deleteVehicleStatusUseCase,
      vehicleItemFactory: this.vehicleItemFactory,
    })
  }
}
