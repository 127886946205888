import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateJobUseCaseInput,
  CreateJobUseCaseOutput,
  IAppErrorFactory,
  ICreateJobUseCase,
  IErrorTrackingService,
  IJob,
  IJobFactory,
  IJobsService,
  IUseCaseOutputFactory,
} from '@/types'
import { filterBase64 } from '@/utils'

@injectable()
export default class CreateJobInteractor implements ICreateJobUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IJobFactory) private jobFactory: IJobFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    job: null | IJob
  }>

  async handle(input: CreateJobUseCaseInput): Promise<CreateJobUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        job: null,
      },
    })
    try {
      const base = await this.jobsService.createJob(input)
      output.data.job = this.jobFactory.create({ base })
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.job.thumbnail = filterBase64(input.job.thumbnail)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }
}
