import { inject, injectable } from 'inversify'
import PublicArticle from '@/entities/PublicArticle'
import symbols from '@/symbols'
import {
  IFetchArticleUseCase,
  IPublicArticle,
  IPublicArticleFactory,
  IToggleArticleLikedStateUseCase,
  PublicArticleFactoryInput,
} from '@/types'

@injectable()
export default class PublicArticleFactory implements IPublicArticleFactory {
  @inject(symbols.IToggleArticleLikedStateUseCase)
  private toggleArticleLikedStateUseCase: IToggleArticleLikedStateUseCase

  @inject(symbols.IFetchArticleUseCase) private fetchArticleUseCase: IFetchArticleUseCase

  public create(input: PublicArticleFactoryInput): IPublicArticle {
    const publicArticle = new PublicArticle(input.base, {
      toggleArticleLikedStateUseCase: this.toggleArticleLikedStateUseCase,
      fetchArticleUseCase: this.fetchArticleUseCase,
    })
    return publicArticle
  }
}
