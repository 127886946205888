import { injectable } from 'inversify'
import * as Sentry from '@sentry/nextjs'
import { IAppError, IErrorTrackingService, IOriginalErrorInstance, IViewerBase } from '@/types'

// Next.js 依存にしないために Gateway として切り出し
// プラットフォームや View 側のライブラリが変われば SDK も変わるため
@injectable()
export default class SentryNextjsGateway implements IErrorTrackingService {
  setUser(user: IViewerBase): void {
    // production 以外の環境ではアーリーリターン
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    // Sentry にユーザー情報を設定する
    // email は個人情報なのでセットしない
    // cf., https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/identify-user/
    Sentry.setUser({
      id: user.id,
      username: user.username,
    })
  }

  reportError(e: IAppError): void {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(e.originalInstance, {
        tags: e?.tags,
        extra: e?.extra,
      })
    } else if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  reportErrorByOriginalInstance(e: IOriginalErrorInstance): void {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(e)
    } else if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
