import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  ICompaniesService,
  IFetchCompaniesUseCase,
  IAppErrorFactory,
  FetchCompaniesUseCaseInput,
  FetchCompaniesUseCaseOutput,
} from '../../types'

@injectable()
export default class FetchCompaniesInteractor implements IFetchCompaniesUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchCompaniesUseCaseInput): Promise<FetchCompaniesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { companies, hasNextPage } = await this.companiesService.fetchCompanies(input)

      output.companies = companies
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCompaniesUseCaseOutput {
    return {
      companies: [],
      hasNextPage: true,
      error: null,
    }
  }
}
