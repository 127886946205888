import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchPitchesUseCaseInput,
  FetchPitchesUseCaseOutput,
  IAppErrorFactory,
  IFetchPitchesUseCase,
  IPitchesService,
} from '../../types'

@injectable()
export default class FetchPitchesInteractor implements IFetchPitchesUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchPitchesUseCaseInput): Promise<FetchPitchesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitches = await this.pitchesService.fetchPitches(input.companySlug)

      output.pitches = pitches
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchPitchesUseCaseOutput {
    return {
      pitches: [],
      error: null,
    }
  }
}
