import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  AddPitchAttachmentUseCaseInput,
  AddPitchAttachmentUseCaseOutput,
  IAddPitchAttachmentUseCase,
  IAppErrorFactory,
  IPitchAttachmentFactory,
  IPitchAttachmentsService,
} from '@/types'
import { filterBase64 } from '@/utils'

@injectable()
export default class AddPitchAttachmentInteractor implements IAddPitchAttachmentUseCase {
  @inject(symbols.IPitchAttachmentsService) private pitchAttachmentsService: IPitchAttachmentsService

  @inject(symbols.IPitchAttachmentFactory) private pitchAttachmentFactory: IPitchAttachmentFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddPitchAttachmentUseCaseInput): Promise<AddPitchAttachmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const base = await this.pitchAttachmentsService.addPitchAttachment(input)
      output.pitchAttachment = this.pitchAttachmentFactory.create({ base })
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.pitchAttachment.file = filterBase64(input.pitchAttachment.file)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ファイルの追加に失敗しました',
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): AddPitchAttachmentUseCaseOutput {
    return {
      pitchAttachment: null,
      error: null,
    }
  }
}
