import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  AddUserReferenceUseCaseInput,
  AddUserReferenceUseCaseOutput,
  IAddUserReferenceUseCase,
  IAppErrorFactory,
  IUserReferencesService,
} from '../../types'

@injectable()
export default class AddUserReferenceInteractor implements IAddUserReferenceUseCase {
  @inject(symbols.IUserReferencesService) private userReferencesService: IUserReferencesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: AddUserReferenceUseCaseInput): Promise<AddUserReferenceUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.userReference = await this.userReferencesService.addUserReference(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの追加に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): AddUserReferenceUseCaseOutput {
    return {
      userReference: null,
      error: null,
    }
  }
}
