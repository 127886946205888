import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchFollowersUseCaseInput,
  FetchFollowersUseCaseOuput,
  IAppErrorFactory,
  IFetchFollowersUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IUsersService,
} from '../../types'

@injectable()
export default class FetchFollowersInteractor implements IFetchFollowersUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    followers: null | IUserBase[]
    hasNextPage: boolean
  }>

  async handle(input: FetchFollowersUseCaseInput): Promise<FetchFollowersUseCaseOuput> {
    const output = this.outputFactory.create({
      defaultValue: {
        followers: null,
        hasNextPage: false,
      },
    })
    try {
      const { followers, hasNextPage } = await this.usersService.fetchFollowers(input)
      output.data.followers = followers
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
