import { inject, injectable } from 'inversify'
import CompanyArticle from '@/entities/CompanyArticle'
import symbols from '@/symbols'
import {
  CompanyArticleFactoryInput,
  ICompanyArticle,
  ICompanyArticleFactory,
  IErrorsStore,
  IUpdateCompanyArticleUseCase,
} from '@/types'

@injectable()
export default class CompanyArticleFactory implements ICompanyArticleFactory {
  @inject(symbols.IUpdateCompanyArticleUseCase) private updateCompanyArticleUseCase: IUpdateCompanyArticleUseCase

  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: CompanyArticleFactoryInput): ICompanyArticle {
    const companyArticle = new CompanyArticle(input.base)
    companyArticle.errorsStore = this.errorStore
    companyArticle.updateCompanyArticleUseCase = this.updateCompanyArticleUseCase
    return companyArticle
  }
}
