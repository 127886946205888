import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreateVehicleUseCaseInput,
  CreateVehicleUseCaseOutput,
  IAppErrorFactory,
  ICreateVehicleUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicle,
  IVehicleFactory,
} from '@/types'

@injectable()
export default class CreateVehicleInteractor implements ICreateVehicleUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IVehicleFactory) private vehicleFactory: IVehicleFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicle: IVehicle
  }>

  async handle(input: CreateVehicleUseCaseInput): Promise<CreateVehicleUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicle: null,
      },
    })
    try {
      const base = await this.pmsService.createVehicle({
        name: input.name,
        entityType: input.investorType,
        slug: input.investorSlug,
        currencyUnit: input.currencyUnit,
      })
      output.data.vehicle = this.vehicleFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
