import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPitchAttachmentsService,
  IUpdatePitchAttachmentUseCase,
  UpdatePitchAttachmentUseCaseInput,
  UpdatePitchAttachmentUseCaseOutput,
} from '@/types'
import { filterBase64 } from '@/utils'

@injectable()
export default class UpdatePitchAttachmentInteractor implements IUpdatePitchAttachmentUseCase {
  @inject(symbols.IPitchAttachmentsService) private pitchAttachmentsService: IPitchAttachmentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdatePitchAttachmentUseCaseInput): Promise<UpdatePitchAttachmentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitchAttachment = await this.pitchAttachmentsService.updatePitchAttachment(input)
      output.pitchAttachment = pitchAttachment
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.pitchAttachment.file = filterBase64(input.pitchAttachment.file)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdatePitchAttachmentUseCaseOutput {
    return {
      pitchAttachment: null,
      error: null,
    }
  }
}
