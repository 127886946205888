import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchSkillsUseCaseInput,
  FetchSkillsUseCaseOutput,
  IAppErrorFactory,
  IFetchSkillsUseCase,
  ISkillsService,
} from '../../types'

@injectable()
export default class FetchSkillsInteractor implements IFetchSkillsUseCase {
  @inject(symbols.ISkillsService) private skillService: ISkillsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchSkillsUseCaseInput): Promise<FetchSkillsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.skills = await this.skillService.fetchSkills(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchSkillsUseCaseOutput {
    return {
      skills: [],
      error: null,
    }
  }
}
