import { inject, injectable } from 'inversify'
import { IVehicleStatusFactory, IUpdateVehicleStatusUseCase, VehicleStatusFactoryInput, IVehicleStatus } from '@/types'
import VehicleStatus from '@/entities/VehicleStatus'
import symbols from '@/symbols'

@injectable()
export default class VehicleStatusFactory implements IVehicleStatusFactory {
  @inject(symbols.IUpdateVehicleStatusUseCase) public updateVehicleStatusUseCase: IUpdateVehicleStatusUseCase

  public create(input: VehicleStatusFactoryInput): IVehicleStatus {
    return new VehicleStatus(input.base, {
      updateVehicleStatusUseCase: this.updateVehicleStatusUseCase,
    })
  }
}
