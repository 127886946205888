import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IJobBookmarksService,
  IRemoveMyJobBookmarkUseCase,
  RemoveMyJobBookmarkUseCaseInput,
  RemoveMyJobBookmarkUseCaseOutput,
} from '../../types'

@injectable()
export default class RemoveMyJobBookmarkInteractor implements IRemoveMyJobBookmarkUseCase {
  @inject(symbols.IJobBookmarksService) private jobBookmarksService: IJobBookmarksService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: RemoveMyJobBookmarkUseCaseInput): Promise<RemoveMyJobBookmarkUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      output.jobBookmark = await this.jobBookmarksService.removeMyJobBookmark(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '保存の解除に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): RemoveMyJobBookmarkUseCaseOutput {
    return {
      jobBookmark: null,
      error: null,
    }
  }
}
