import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  ReopenChatConnectionUseCaseOutput,
  IAppErrorFactory,
  IChatService,
  IReopenChatConnectionUseCase,
} from '@/types'

@injectable()
export default class ReopenChatConnectionInteractor implements IReopenChatConnectionUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  handle(): ReopenChatConnectionUseCaseOutput {
    const output = this.getDefaultOutputData()
    try {
      // 再接続
      this.chatService.reopenChatConnection()
      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }

  private getDefaultOutputData(): ReopenChatConnectionUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
