import { injectable } from 'inversify'
import PublicFollowableEntity from '@/entities/PublicFollowableEntity'
import { IPublicFollowableEntity, IPublicFollowableEntityFactory, PublicFollowableEntityFactoryInput } from '@/types'

@injectable()
export default class PublicFollowableEntityFactory implements IPublicFollowableEntityFactory {
  public create(input: PublicFollowableEntityFactoryInput): IPublicFollowableEntity {
    const publicFollowableEntity = new PublicFollowableEntity(input.base)

    return publicFollowableEntity
  }
}
