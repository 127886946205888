import { inject, injectable } from 'inversify'
import Job from '@/entities/Job'
import symbols from '@/symbols'
import { IErrorsStore, IJob, IJobFactory, IUpdateJobUseCase, JobFactoryInput } from '@/types'

@injectable()
export default class JobFactory implements IJobFactory {
  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  @inject(symbols.IUpdateJobUseCase) private updateJobUseCase: IUpdateJobUseCase

  public create(input: JobFactoryInput): IJob {
    const job = new Job(input.base)
    job.errorsStore = this.errorStore
    job.updateJobUseCase = this.updateJobUseCase
    return job
  }
}
