import { inject, injectable } from 'inversify'
import {
  ErrorCode,
  IAppErrorFactory,
  IErrorTrackingService,
  IJobBase,
  IJobsService,
  IUpdateJobUseCase,
  IUseCaseOutputFactory,
  UpdateJobUseCaseInput,
  UpdateJobUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import { filterBase64 } from '@/utils'

@injectable()
export default class UpdateJobInteractor implements IUpdateJobUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    job: null | IJobBase
  }>

  async handle(input: UpdateJobUseCaseInput): Promise<UpdateJobUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        job: null,
      },
    })
    try {
      output.data.job = await this.jobsService.updateJob(input)
    } catch (e) {
      const filteredInput = { ...input }
      filteredInput.job.thumbnail = filterBase64(input.job.thumbnail)

      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: filteredInput,
        },
      })
    }

    if (output.error?.code === ErrorCode.NO_SUBSCRIPTION_ERROR) {
      try {
        // サブスク契約してなくて弾かれた場合、更新後の求人のデータを取得
        output.data.job = await this.jobsService.fetchJob({
          slug: input.job.slug,
        })
      } catch (e) {
        // NO_SUBSCRIPTION_ERROR を上書きしないように、インスタンスだけ作成してエラーレポートだけ飛ばす
        this.errorFactory.create({
          originalInstance: e as Error,
        })
      }
    }

    return output
  }
}
