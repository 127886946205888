import {
  ErrorCode,
  IAppError,
  IBaseAppError,
  IErrorTrackingService,
  IOriginalErrorInstance,
  ISignOutUseCase,
  Primitive,
} from '@/types'
import ExpectedError from '@/errors/ExpectedError'
import ForbiddenError from '@/errors/ForbiddenError'
import UnauthenticatedError from '@/errors/UnauthenticatedError'
import UninitializedError from '@/errors/UninitializedError'
import { isBrowser } from '@/utils'

type Deps = {
  signOutUseCase: ISignOutUseCase
  errorTrackingService: IErrorTrackingService
}

type GraphQLErrorExtensions = {
  code: ErrorCode
}

export default class AppError implements IAppError {
  originalInstance!: IOriginalErrorInstance

  messageForUI!: string

  tags: {
    [key: string]: Primitive
  } = {}

  extra: Record<string, unknown> = {}

  signOutUseCase!: ISignOutUseCase

  errorTrackingService!: IErrorTrackingService

  code!: ErrorCode

  message!: string

  constructor(base: IBaseAppError, dependencies: Deps) {
    this._mapBase(base)
    this._mapDeps(dependencies)
    this._handleOriginalInstance()
  }

  private _mapBase(base: IBaseAppError) {
    const keys = Object.keys(base)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = base[key]
    })
    this._parseOriginalInstance(base.originalInstance)
  }

  private _mapDeps(deps: Deps) {
    const keys = Object.keys(deps)
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this[key] = deps[key]
    })
  }

  // GraphQL の Error から Code と Message を設定
  private _parseOriginalInstance(originalInstance: IOriginalErrorInstance): void {
    if (originalInstance?.response?.errors?.length > 0) {
      const error = originalInstance?.response?.errors[0]
      const extensions = error?.extensions as GraphQLErrorExtensions
      // code も message も上書きされてしまうけどしょうがないことにする
      if (extensions?.code) {
        this.code = extensions.code
      }
      if (error?.message) {
        this.message = error.message
      }
    }
    // originalInstance に extra がある場合は追加
    if (originalInstance?.extra) {
      this.extra = {
        ...this.extra,
        originalInstanceExtra: originalInstance.extra,
      }
    }
  }

  private _handleOriginalInstance(): void {
    // originalInstance が設定されてない場合はアーリーリターン
    if (this.originalInstance === null) {
      return
    }

    if (process.env.NODE_ENV === 'development') {
      // 開発環境ではコンソールにエラー吐いておく
      // eslint-disable-next-line no-console
      console.error(this.originalInstance)
    } else {
      // production 環境で ExpectedError でないエラーの場合は Sentry に送信
      // eslint-disable-next-line no-lonely-if
      if (!(this.originalInstance instanceof ExpectedError)) {
        this.errorTrackingService.reportError(this)
      }
    }

    // サーバーから Forbidden が返ってきた場合や、
    // AccessToken が無効だった場合はログアウトさせる
    if (this.originalInstance instanceof ForbiddenError || this.originalInstance instanceof UnauthenticatedError) {
      void this.signOutUseCase.handle({
        isInvalidToken: true,
      })
    }

    // 別タブでログイン処理をして、現在開いているタブが初期化されてないケースでは強制的にリロードする
    if (this.originalInstance instanceof UninitializedError) {
      // TODO: Interactor に切り出し
      if (isBrowser()) {
        // TODO: セッション切れのときみたいにメッセージ出す（いきなりリロードされたらビビる）
        window.location.reload()
      }
    }
  }
}
