import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchSubsidiesUseCaseInput,
  FetchSubsidiesUseCaseOutput,
  IAppErrorFactory,
  IFetchSubsidiesUseCase,
  ISubsidiesService,
  ISubsidy,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchSubsidyInteractor implements IFetchSubsidiesUseCase {
  @inject(symbols.ISubsidiesService) private subsidiesService: ISubsidiesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    subsidies: [] | ISubsidy[]
    hasNextPage: false | boolean
  }>

  async handle(input: FetchSubsidiesUseCaseInput): Promise<FetchSubsidiesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        subsidies: [],
        hasNextPage: false,
      },
    })
    try {
      const result = await this.subsidiesService.fetchSubsidies({
        searchWord: input.searchWord,
        locationsId: input.locationsId,
        subsidyPurposeIds: input.subsidyPurposeIds,
        limit: input.limit,
        shouldRefresh: input.shouldRefresh,
      })
      output.data.subsidies = result.subsidies
      output.data.hasNextPage = result.hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
