import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IAppErrorFactory,
  IPitchAttachmentsService,
  IRemovePitchAttachmentFromPitchUseCase,
  RemovePitchAttachmentFromPitchUseCaseInput,
  RemovePitchAttachmentFromPitchUseCaseOutput,
} from '../../types'

@injectable()
export default class RemovePitchAttachmentFromPitchInteractor implements IRemovePitchAttachmentFromPitchUseCase {
  @inject(symbols.IPitchAttachmentsService) private pitchAttachmentsService: IPitchAttachmentsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: RemovePitchAttachmentFromPitchUseCaseInput
  ): Promise<RemovePitchAttachmentFromPitchUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitchAttachment = await this.pitchAttachmentsService.removePitchAttachmentFromPitch(input)
      output.pitchAttachment = pitchAttachment
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ファイルの削除に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): RemovePitchAttachmentFromPitchUseCaseOutput {
    return {
      pitchAttachment: null,
      error: null,
    }
  }
}
