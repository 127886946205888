import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  FetchAllForexRatesUseCaseOutput,
  IFetchAllForexRatesUseCase,
  IForexRate,
  IInitializeInvestorUseCase,
  IInitializeVehicleUseCase,
  IInvestor,
  IPMSStore,
  IUseCaseOutput,
  IVehicle,
  PMSInvestorTypeInPath,
  PMSStatus,
  TickerSymbol,
  VehicleInvestor,
} from '@/types'
import symbols from '@/symbols'
import { action, observable } from 'mobx'

const remoteDevConfig: RemoteDevConfig = {
  name: 'PMSStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class PMSStore implements IPMSStore {
  @observable investors: IInvestor[] = [] // 初期化済みの Investor を格納

  @observable status: PMSStatus = PMSStatus.INITIALIZING

  // フォールバック用の初期値をハードコード
  @observable rates: Record<TickerSymbol, number> = {
    JPYUSD: 131.63,
    USDJPY: 0.0076,
  }

  @inject(symbols.IInitializeInvestorUseCase) public initializeInvestorUseCase: IInitializeInvestorUseCase

  @inject(symbols.IInitializeVehicleUseCase) public initializeVehicleUseCase: IInitializeVehicleUseCase

  @inject(symbols.IFetchAllForexRatesUseCase) private fetchAllForexRatesUseCase: IFetchAllForexRatesUseCase

  @action
  _addInvestor(investor: IInvestor): void {
    this.investors.push(investor)
  }

  @action
  _updateStatus(s: PMSStatus): void {
    this.status = s
  }

  getInvestorType(investorTypeInPath: PMSInvestorTypeInPath): VehicleInvestor {
    if (investorTypeInPath === 'u') {
      return VehicleInvestor.USER
    }
    if (investorTypeInPath === 'c') {
      return VehicleInvestor.COMPANY
    }
    return null
  }

  getInvestorTypeInPath(investorType: VehicleInvestor): PMSInvestorTypeInPath {
    if (investorType === VehicleInvestor.USER) {
      return 'u'
    }
    if (investorType === VehicleInvestor.COMPANY) {
      return 'c'
    }
    return null
  }

  getInvestor(investorTypeInPath: PMSInvestorTypeInPath, investorSlug: string): IInvestor {
    const type = this.getInvestorType(investorTypeInPath)
    return this.investors.find((investor) => investor.investorSlug === investorSlug && investor.investorType === type)
  }

  getVehicle(investorTypeInPath: PMSInvestorTypeInPath, investorSlug: string, vehicleSlug: string): IVehicle {
    const investor = this.getInvestor(investorTypeInPath, investorSlug)
    if (!investor) {
      return null
    }

    return investor.getVehicle(vehicleSlug)
  }

  async initialize(
    investorTypeInPath: PMSInvestorTypeInPath,
    investorSlug: string,
    vehicleSlug?: string
  ): Promise<void> {
    this._updateStatus(PMSStatus.INITIALIZING)

    const investor = this.getInvestor(investorTypeInPath, investorSlug)
    // 初期化済みの investor が既にある場合
    if (investor) {
      if (!vehicleSlug) {
        // vehicle の指定がない場合はアーリーリターン
        this._updateStatus(PMSStatus.INITIALIZED)
        return
      }
      // vehicle の指定がある場合
      const vehicle = this.getVehicle(investorTypeInPath, investorSlug, vehicleSlug)
      if (vehicle.isInitialized) {
        // 初期化済みの vehicle が既にある場合
        // 初期化すべきものはもうないのでアーリーリターン
        this._updateStatus(PMSStatus.INITIALIZED)
        return
      }
      // 初期化済みの vehicle がない場合
      const isVehicleInitialized = await investor.initializeVehicle(vehicleSlug)
      if (isVehicleInitialized) {
        this._updateStatus(PMSStatus.INITIALIZED)
      } else {
        this._updateStatus(PMSStatus.VEHICLE_NOT_FOUND)
      }
      return
    }

    // 初期化済みの investor がない場合
    const [newInvestor] = await Promise.all([
      this._initializeInvestor(investorTypeInPath, investorSlug),
      this._initializeForexRates(), // 合わせて為替レートの取得も行う
    ])
    if (!newInvestor) {
      // 初期化も失敗した場合はアーリーリターン
      this._updateStatus(PMSStatus.INVESTOR_NOT_FOUND)
      return
    }

    if (!vehicleSlug) {
      // vehicle の指定がない場合はアーリーリターン
      this._updateStatus(PMSStatus.INITIALIZED)
      return
    }
    // vehicle の指定がある場合
    const isVehicleInitialized = await newInvestor.initializeVehicle(vehicleSlug)
    if (isVehicleInitialized) {
      this._updateStatus(PMSStatus.INITIALIZED)
    } else {
      this._updateStatus(PMSStatus.VEHICLE_NOT_FOUND)
    }
  }

  async _initializeInvestor(investorType: PMSInvestorTypeInPath, investorSlug: string): Promise<IInvestor> {
    const type = this.getInvestorType(investorType)
    const output = await this.initializeInvestorUseCase.handle({
      investorType: type,
      investorSlug,
    })

    if (output.isSuccessful && output.data.investor) {
      this._addInvestor(output.data.investor)
      return output.data.investor
    }

    return null
  }

  @action
  _updateRate(rate: IForexRate): void {
    this.rates[rate.tickerSymbol] = Number(rate.rate)
  }

  // 最新の為替レートをサーバーから取得してくる
  async _initializeForexRates(): Promise<IUseCaseOutput<FetchAllForexRatesUseCaseOutput>> {
    const output = await this.fetchAllForexRatesUseCase.handle({
      tickerSymbols: [TickerSymbol.JPYUSD, TickerSymbol.USDJPY],
    })

    if (output.isSuccessful && output.data.forexRates.length > 0) {
      output.data.forexRates.forEach((item) => {
        this._updateRate(item)
      })
    }

    return output
  }
}
