import { inject, injectable } from 'inversify'
import {
  IAppCredentials,
  IAppErrorFactory,
  IAuthService,
  IErrorTrackingService,
  ISignInUseCase,
  IStorageService,
  IViewer,
  IViewerService,
  SignInUseCaseInput,
  SignInUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'
import ExpectedError from '@/errors/ExpectedError'

@injectable()
export default class SignInInteractor implements ISignInUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IStorageService) private storageService: IStorageService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IViewer) private viewer: IViewer

  async handle(input: SignInUseCaseInput): Promise<SignInUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const credentials = await this.authService.signIn(input.email, input.password)

      // credentials を設定
      this.credentials.update(credentials)

      // viewer を取得
      const viewerBase = await this.viewerService.fetchMe()

      // viewer の値を設定
      this.viewer.update(viewerBase)

      // エラートラッキングサービスに現在のユーザーを設定
      this.errorTrackingService.setUser(viewerBase)

      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: e instanceof ExpectedError ? e.message : 'ログインに失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): SignInUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
