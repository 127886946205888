import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  AddFootprintToJobStoreInput,
  FetchCompanyJobsStoreInput,
  FetchJobsRequestInput,
  FetchJobStoreInput,
  IAddFootprintToJobUseCase,
  IErrorsStore,
  IFetchCompanyJobsUseCase,
  IFetchFeaturedJobsUseCase,
  IFetchJobsUseCase,
  IFetchJobUseCase,
  IJobBase,
  IJobsStore,
} from '@/types'
import symbols from '../symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'JobsStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class JobsStore implements IJobsStore {
  @observable jobs: IJobBase[] = []

  @observable hasNextJobsPage = true

  @observable companyJobs: Record<string, IJobBase[]> = {}

  @observable hasNextCompanyJobsPage: Record<string, boolean> = {}

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchJobsUseCase) private fetchJobsUseCase: IFetchJobsUseCase,
    @inject(symbols.IFetchCompanyJobsUseCase) private fetchCompanyJobsUseCase: IFetchCompanyJobsUseCase,
    @inject(symbols.IFetchJobUseCase) private fetchJobUseCase: IFetchJobUseCase,
    @inject(symbols.IAddFootprintToJobUseCase) private addFootprintToJobUseCase: IAddFootprintToJobUseCase,
    @inject(symbols.IFetchFeaturedJobsUseCase) private fetchFeaturedJobsUseCase: IFetchFeaturedJobsUseCase
  ) {
    //
  }

  // =========== jobs ===========
  @action
  _updateJobs(jobs: IJobBase[]): void {
    this.jobs = jobs
  }

  @action
  _addJobs(jobs: IJobBase[]): void {
    jobs.forEach((newJob) => {
      // 重複していたら処理をスキップ
      if (this.jobs.some((j) => j.slug === newJob.slug)) {
        return
      }

      // 末尾に追加
      this.jobs = this.jobs.concat(newJob)
    })
  }

  @action
  updateHasNextJobsPage(hasNextPage: boolean): void {
    this.hasNextJobsPage = hasNextPage
  }
  // =========== jobs ===========

  // =========== companyJobsIndexPage ===========
  @action
  _addCompanyJobs(slug: string, jobs: IJobBase[]): void {
    jobs.forEach((newJob) => {
      if (this.companyJobs[slug].some((j) => j.slug === newJob.slug)) {
        return
      }

      this.companyJobs[slug] = this.companyJobs[slug].concat(newJob)
    })
  }

  @action
  _updateCompanyJobs(slug: string, jobs: IJobBase[]): void {
    this.companyJobs[slug] = jobs
  }

  @action
  updateHasNextCompanyJobsPage(slug: string, hasNextPage: boolean): void {
    this.hasNextCompanyJobsPage[slug] = hasNextPage
  }

  async fetchJobs(input: FetchJobsRequestInput): Promise<IJobBase[]> {
    const output = await this.fetchJobsUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateJobs(output.jobs)
    } else {
      this._addJobs(output.jobs)
    }
    this.updateHasNextJobsPage(output.hasNextPage)

    return output.jobs
  }

  async fetchCompanyJobs(input: FetchCompanyJobsStoreInput): Promise<IJobBase[]> {
    const output = await this.fetchCompanyJobsUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateCompanyJobs(input.companySlug, output.jobs)
    } else {
      this._addCompanyJobs(input.companySlug, output.jobs)
    }

    this.updateHasNextCompanyJobsPage(input.companySlug, output.hasNextPage)
    return output.jobs
  }

  async fetchJob(input: FetchJobStoreInput): Promise<IJobBase> {
    const output = await this.fetchJobUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.job
  }

  async addFootprintToJob(input: AddFootprintToJobStoreInput): Promise<IJobBase> {
    const output = await this.addFootprintToJobUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return null
    }

    return output.job
  }

  async fetchFeaturedJobs(limit: number): Promise<IJobBase[]> {
    const output = await this.fetchFeaturedJobsUseCase.handle({
      limit,
    })
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }
    this._addJobs(output.featuredJobs)

    return output.featuredJobs
  }
}
