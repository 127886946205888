import { inject, injectable } from 'inversify'
import {
  ChangeLanguageUseCaseInput,
  ChangeLanguageUseCaseOutput,
  IAppErrorFactory,
  IChangeLanguageUseCase,
  IPreferences,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class ChangeLanguageInteractor implements IChangeLanguageUseCase {
  @inject(symbols.IPreferences) private preferences: IPreferences

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  // eslint-disable-next-line @typescript-eslint/require-await
  async handle(input: ChangeLanguageUseCaseInput): Promise<ChangeLanguageUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      this.preferences.changeLanguage(input.language)

      output.isSuccessful = true
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): ChangeLanguageUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
