import { inject, injectable } from 'inversify'
import UserArticle from '@/entities/UserArticle'
import symbols from '@/symbols'
import {
  IErrorsStore,
  IUpdateMyArticleUseCase,
  IUserArticle,
  IUserArticleFactory,
  UserArticleFactoryInput,
} from '@/types'

@injectable()
export default class UserArticleFactory implements IUserArticleFactory {
  @inject(symbols.IUpdateMyArticleUseCase) private updateMyArticleUseCase: IUpdateMyArticleUseCase

  @inject(symbols.IErrorsStore) private errorStore: IErrorsStore

  public create(input: UserArticleFactoryInput): IUserArticle {
    const userArticle = new UserArticle(input.base)
    userArticle.errorsStore = this.errorStore
    userArticle.updateMyArticleUseCase = this.updateMyArticleUseCase
    return userArticle
  }
}
