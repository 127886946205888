import { inject, injectable } from 'inversify'
import AppError from '@/entities/AppError'
import symbols from '@/symbols'
import { AppErrorFactoryInput, IAppError, IAppErrorFactory, IErrorTrackingService, ISignOutUseCase } from '@/types'

@injectable()
export default class AppErrorFactory implements IAppErrorFactory {
  @inject(symbols.IErrorTrackingService) private errorTrackingService: IErrorTrackingService

  @inject(symbols.ISignOutUseCase) private signOutUseCase: ISignOutUseCase

  public create(input: AppErrorFactoryInput): IAppError {
    return new AppError(
      {
        originalInstance: input.originalInstance,
        messageForUI: input?.messageForUI ? input?.messageForUI : null,
        tags: input?.tags,
        extra: input?.extra,
      },
      {
        signOutUseCase: this.signOutUseCase,
        errorTrackingService: this.errorTrackingService,
      }
    )
  }
}
