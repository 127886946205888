import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  IFetchSubsidyPurposesUseCase,
  FetchSubsidyPurposesUseCaseOutput,
  IAppErrorFactory,
  ISubsidiesService,
  ISubsidyPurposeBase,
  IUseCaseOutputFactory,
} from '../../types'

@injectable()
export default class FetchSubsidyPurposesInteractor implements IFetchSubsidyPurposesUseCase {
  @inject(symbols.ISubsidiesService) private subsidiesService: ISubsidiesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    subsidyPurposes: null | ISubsidyPurposeBase[]
  }>

  async handle(): Promise<FetchSubsidyPurposesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        subsidyPurposes: null,
      },
    })
    try {
      output.data.subsidyPurposes = await this.subsidiesService.fetchSubsidyPurposes()
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
