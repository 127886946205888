import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  ICompaniesService,
  IAppErrorFactory,
  IFetchCompaniesWithJobsUseCase,
  FetchCompaniesWithJobsUseCaseInput,
  FetchCompaniesWithJobsUseCaseOutput,
} from '../../types'

@injectable()
export default class FetchCompaniesWithJobsInteractor implements IFetchCompaniesWithJobsUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchCompaniesWithJobsUseCaseInput): Promise<FetchCompaniesWithJobsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { companies, hasNextPage } = await this.companiesService.fetchCompaniesWithJobs(input)

      output.companies = companies
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchCompaniesWithJobsUseCaseOutput {
    return {
      companies: [],
      hasNextPage: true,
      error: null,
    }
  }
}
