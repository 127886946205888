import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyJobBookmarksUseCaseInput,
  FetchMyJobBookmarksUseCaseOutput,
  IAppErrorFactory,
  IFetchMyJobBookmarksUseCase,
  IJobBookmarksService,
} from '../../types'

@injectable()
export default class FetchMyJobBookmarksInteractor implements IFetchMyJobBookmarksUseCase {
  @inject(symbols.IJobBookmarksService) private jobBookmarksService: IJobBookmarksService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchMyJobBookmarksUseCaseInput): Promise<FetchMyJobBookmarksUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { jobBookmarks, hasNextPage } = await this.jobBookmarksService.fetchMyJobBookmarks(input)

      output.jobBookmarks = jobBookmarks
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: '保存した募集の取得に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }
    return output
  }

  private getDefaultOutputData(): FetchMyJobBookmarksUseCaseOutput {
    return {
      jobBookmarks: [],
      hasNextPage: true,
      error: null,
    }
  }
}
