const symbols = {
  // ============================================================
  // Stores
  // ============================================================
  IUIStore: Symbol.for('IUIStore'),
  IErrorsStore: Symbol.for('IErrorsStore'),
  IMessagesStore: Symbol.for('IMessagesStore'),
  IViewerStore: Symbol.for('IViewerStore'),
  IUsersStore: Symbol.for('IUsersStore'),
  ICompaniesStore: Symbol.for('ICompaniesStore'),
  IArticlesStore: Symbol.for('IArticlesStore'),
  IChatStore: Symbol.for('IChatStore'),
  IJobApplicationStore: Symbol.for('IJobApplicationStore'),
  IJobsStore: Symbol.for('IJobsStore'),
  IPMSStore: Symbol.for('IPMSStore'),
  IForexStore: Symbol.for('IForexStore'),
  ISubsidiesStore: Symbol.for('ISubsidiesStore'),

  // ============================================================
  // Entities
  // ============================================================
  IAppCredentials: Symbol.for('IAppCredentials'),
  IPreferences: Symbol.for('IPreferences'),
  IViewer: Symbol.for('IViewer'),

  // ============================================================
  // Factories
  // ============================================================
  IAppErrorFactory: Symbol.for('IAppErrorFactory'),
  IUseCaseOutputFactory: Symbol.for('IUseCaseOutputFactory'),
  IMessageFactory: Symbol.for('IMessageFactory'),
  IUserProfileFactory: Symbol.for('IUserProfileFactory'),
  ICompanyFactory: Symbol.for('ICompanyFactory'),
  IProductFactory: Symbol.for('IProductFactory'),
  IExperienceFactory: Symbol.for('IExperienceFactory'),
  IInvestmentFactory: Symbol.for('IInvestmentFactory'),
  ICompanyMemberFactory: Symbol.for('ICompanyMemberFactory'),
  IChatMessageThreadFactory: Symbol.for('IChatMessageThreadFactory'),
  ICompanyArticleFactory: Symbol.for('ICompanyArticleFactory'),
  IPitchFactory: Symbol.for('IPitchFactory'),
  IPitchAttachmentFactory: Symbol.for('IPitchAttachmentFactory'),
  IJobFactory: Symbol.for('IJobFactory'),
  IJobApplicationFactory: Symbol.for('IJobApplicationFactory'),
  IUserArticleFactory: Symbol.for('IUserArticleFactory'),
  IJobFootprintFactory: Symbol.for('IJobFootprintFactory'),
  IPublicArticleFactory: Symbol.for('IPublicArticleFactory'),
  IUserReferenceFactory: Symbol.for('IUserReferenceFactory'),
  ICompanyReferenceFactory: Symbol.for('ICompanyReferenceFactory'),
  IPublicUserFactory: Symbol.for('IPublicUserFactory'),
  IPublicCompanyFactory: Symbol.for('IPublicCompanyFactory'),
  IPublicFollowableEntityFactory: Symbol.for('IPublicFollowableEntityFactory'),
  IInvestorFactory: Symbol.for('IInvestorFactory'),
  IVehicleFactory: Symbol.for('IVehicleFactory'),
  IVehicleMemberFactory: Symbol.for('IVehicleMemberFactory'),
  IVehicleItemRoundFactory: Symbol.for('IVehicleItemRoundFactory'),
  IPortfolioNoteFactory: Symbol.for('IPortfolioNoteFactory'),
  IPortfolioMeetingFactory: Symbol.for('IPortfolioMeetingFactory'),
  IVehicleItemFactory: Symbol.for('IVehicleItemFactory'),
  IPortfolioFactory: Symbol.for('IPortfolioFactory'),
  IVehicleStatusFactory: Symbol.for('IVehicleStatusFactory'),

  // ============================================================
  // Services
  // ============================================================
  IStorageService: Symbol.for('IStorageService'),
  IAuthService: Symbol.for('IAuthService'),
  IViewerService: Symbol.for('IViewerService'),
  IUsersService: Symbol.for('IUsersService'),
  ICompaniesService: Symbol.for('ICompaniesService'),
  IInvestmentsService: Symbol.for('IInvestmentsService'),
  IArticlesService: Symbol.for('IArticlesService'),
  IChatService: Symbol.for('IChatService'),
  IMarketsService: Symbol.for('IMarketsService'),
  IArticleAttachmentService: Symbol.for('IArticleAttachmentService'),
  INotificationsService: Symbol.for('INotificationsService'),
  ILocationsService: Symbol.for('ILocationsService'),
  IPitchesService: Symbol.for('IPitchesService'),
  IPitchAttachmentsService: Symbol.for('IPitchAttachmentsService'),
  IErrorTrackingService: Symbol.for('IErrorTrackingService'),
  IJobCategoriesService: Symbol.for('IJobCategoriesService'),
  IJobTagsService: Symbol.for('IJobTagsService'),
  ISkillsService: Symbol.for('ISkillsService'),
  IJobsService: Symbol.for('IJobsService'),
  IJobApplicationsService: Symbol.for('IJobApplicationsService'),
  ISubscriptionsService: Symbol.for('ISubscriptionsService'),
  IJobBookmarksService: Symbol.for('IJobBookmarksService'),
  IUserReferencesService: Symbol.for('IUserReferencesService'),
  ICompanyReferencesService: Symbol.for('ICompanyReferencesService'),
  IInvitationsService: Symbol.for('IInvitationsService'),
  IPMSService: Symbol.for('IPMSService'),
  IForexService: Symbol.for('IForexService'),
  ISubsidiesService: Symbol.for('ISubsidiesService'),

  // ============================================================
  // UseCases
  // ============================================================
  // Viewer
  ISignInUseCase: Symbol.for('ISignInUseCase'),
  ISignUpUseCase: Symbol.for('ISignUpUseCase'),
  ISignOutUseCase: Symbol.for('ISignOutUseCase'),
  ILeaveServiceUseCase: Symbol.for('ILeaveServiceUseCase'),
  ISendResetPasswordRequestUseCase: Symbol.for('ISendResetPasswordRequestUseCase'),
  IUpdatePasswordUseCase: Symbol.for('IUpdatePasswordUseCase'),
  IUpdatePasswordWithTokenUseCase: Symbol.for('IUpdatePasswordWithTokenUseCase'),
  IUpdateEmailUseCase: Symbol.for('IUpdateEmailUseCase'),
  IUpdateMeUseCase: Symbol.for('IUpdateMeUseCase'),
  IInitializeUseCase: Symbol.for('IInitializeUseCase'),
  IFetchMeUseCase: Symbol.for('IFetchMeUseCase'),
  IChangeLanguageUseCase: Symbol.for('IChangeLanguageUseCase'),
  IUpdateMyProfileUseCase: Symbol.for('IUpdateMyProfileUseCase'),
  IAddExperienceUseCase: Symbol.for('IAddExperienceUseCase'),
  IUpdateExperienceUseCase: Symbol.for('IUpdateExperienceUseCase'),
  IRemoveExperienceUseCase: Symbol.for('IRemoveExperienceUseCase'),
  IFetchNotificationsUseCase: Symbol.for('IFetchNotificationsUseCase'),
  // Users
  IFetchAngelsUseCase: Symbol.for('IFetchAngelsUseCase'),
  IFetchFeaturedAngelsUseCase: Symbol.for('IFetchFeaturedAngelsUseCase'),
  IFetchUserUseCase: Symbol.for('IFetchUserUseCase'),
  ISearchUserUseCase: Symbol.for('ISearchUserUseCase'),
  IFetchUserForViewerUseCase: Symbol.for('IFetchUserForViewerUseCase'),
  // Companies
  IFetchCompaniesUseCase: Symbol.for('IFetchCompaniesUseCase'),
  IFetchStartupsUseCase: Symbol.for('IFetchStartupsUseCase'),
  IFetchVentureCapitalsUseCase: Symbol.for('IFetchVentureCapitalsUseCase'),
  IFetchFeaturedVentureCapitalsUseCase: Symbol.for('IFetchFeaturedVentureCapitalsUseCase'),
  IFetchFeaturedStartupsUseCase: Symbol.for('IFetchFeaturedStartupsUseCase'),
  IFetchFeaturedCompaniesWithJobsUseCase: Symbol.for('IFetchFeaturedCompaniesWithJobsUseCase'),
  IFetchCompanyUseCase: Symbol.for('IFetchCompanyUseCase'),
  IUpdateCompanyUseCase: Symbol.for('IUpdateCompanyUseCase'),
  ICreateMyCompanyUseCase: Symbol.for('ICreateMyCompanyUseCase'),
  ICreateCompanyUseCase: Symbol.for('ICreateCompanyUseCase'),
  IAddProductUseCase: Symbol.for('IAddProductUseCase'),
  IUpdateProductUseCase: Symbol.for('IUpdateProductUseCase'),
  IRemoveProductUseCase: Symbol.for('IRemoveProductUseCase'),
  ISearchCompanyUseCase: Symbol.for('ISearchCompanyUseCase'),
  IAddCompanyMemberUseCase: Symbol.for('IAddCompanyMemberUseCase'),
  IUpdateCompanyMemberUseCase: Symbol.for('IUpdateCompanyMemberUseCase'),
  IRemoveCompanyMemberUseCase: Symbol.for('IRemoveCompanyMemberUseCase'),
  IFetchCompaniesWithJobsUseCase: Symbol.for('IFetchCompaniesWithJobsUseCase'),
  IFetchCompanyForViewerUseCase: Symbol.for('IFetchCompanyForViewerUseCase'),
  // Investments
  IAddMyInvestmentUseCase: Symbol.for('IAddMyInvestmentUseCase'),
  IAddInvestorUseCase: Symbol.for('IAddMyInvestorUseCase'),
  IAddCompanyInvestmentUseCase: Symbol.for('IAddCompanyInvestmentUseCase'),
  IUpdateInvestmentUseCase: Symbol.for('IUpdateInvestmentUseCase'),
  IUpdateInvestorUseCase: Symbol.for('IUpdateInvestorUseCase'),
  IRemoveInvestmentUseCase: Symbol.for('IRemoveInvestmentUseCase'),
  IRemoveInvestorUseCase: Symbol.for('IRemoveInvestorUseCase'),
  // Articles
  IFetchMagazineTopPageContentUseCase: Symbol.for('IFetchMagazineTopPageContentUseCase'),
  IFetchMagazineArchivePageContentUseCase: Symbol.for('IFetchMagazineArchivePageContentUseCase'),
  IFetchMagazineSinglePageContentUseCase: Symbol.for('IFetchMagazineSinglePageContentUseCase'),
  IFetchArticlesUseCase: Symbol.for('IFetchArticlesUseCase'),
  IFetchCompanyArticlesUseCase: Symbol.for('IFetchCompanyArticlesUseCase'),
  IFetchMyCompanyArticlesUseCase: Symbol.for('IFetchMyCompanyArticlesUseCase'),
  IFetchCompanyArticleSinglePageContentUseCase: Symbol.for('IFetchCompanyArticleSinglePageContentUseCase'),
  ICreateCompanyArticleUseCase: Symbol.for('ICreateCompanyArticleUseCase'),
  IUpdateCompanyArticleUseCase: Symbol.for('IUpdateCompanyArticleUseCase'),
  IFetchUserArticlesUseCase: Symbol.for('IFetchUserArticlesUseCase'),
  IFetchMyArticlesUseCase: Symbol.for('IFetchMyArticlesUseCase'),
  ICreateMyArticleUseCase: Symbol.for('ICreateMyArticleUseCase'),
  IUpdateMyArticleUseCase: Symbol.for('IUpdateMyArticleUseCase'),
  IDeleteMyArticleUseCase: Symbol.for('IDeleteMyArticleUseCase'),
  IDeleteCompanyArticleUseCase: Symbol.for('IDeleteCompanyArticleUseCase'),
  IFetchFeaturedStoriesUseCase: Symbol.for('IFetchFeaturedStoriesUseCase'),
  IToggleArticleLikedStateUseCase: Symbol.for('IToggleArticleLikedStateUseCase'),
  IFetchArticleUseCase: Symbol.for('IFetchArticleUseCase'),
  IFetchPickedUpFeaturesUseCase: Symbol.for('IFetchPickedUpFeaturesUseCase'),
  // Chat
  IInitializeChatUseCase: Symbol.for('IInitializeChatUseCase'),
  ICreateChatMessageThreadSubscriptionUseCase: Symbol.for('ICreateChatMessageThreadSubscriptionUseCase'),
  ISendChatMessageUseCase: Symbol.for('ISendChatMessageUseCase'),
  IFindOrCreateChatMessageThreadUseCase: Symbol.for('IFindOrCreateChatMessageThreadUseCase'),
  IFetchChatMessagesUseCase: Symbol.for('IFetchChatMessagesUseCase'),
  IFetchChatMessageThreadsUseCase: Symbol.for('IFetchChatMessageThreadsUseCase'),
  IMarkAllMessagesAsReadUseCase: Symbol.for('IMarkAllMessagesAsReadUseCase'),
  IReopenChatConnectionUseCase: Symbol.for('IReopenChatConnectionUseCase'),
  // markets
  IFetchMarketsUseCase: Symbol.for('IFetchMarketsUseCase'),
  // ArticleAttachment
  ICreateArticleAttachmentUseCase: Symbol.for('ICreateArticleAttachmentUseCase'),
  // notifications
  IMarkAllNotificationsAsReadUseCase: Symbol.for('IMarkAllNotificationsAsReadUseCase'),
  // Location
  IFetchLocationsUseCase: Symbol.for('IFetchLocationsUseCase'),
  IFetchLocationUseCase: Symbol.for('IFetchLocationUseCase'),
  // Pitches
  IFetchPitchesUseCase: Symbol.for('IFetchPitchesUseCase'),
  IFetchMyPitchesUseCase: Symbol.for('IFetchMyPitchesUseCase'),
  IFetchPitchUseCase: Symbol.for('IFetchPitchUseCase'),
  ICreatePitchUseCase: Symbol.for('ICreatePitchUseCase'),
  ICreateEmptyDraftPitchUseCase: Symbol.for('ICreateEmptyDraftPitchUseCase'),
  IDeletePitchUseCase: Symbol.for('IDeletePitchUseCase'),
  IUpdatePitchUseCase: Symbol.for('IUpdatePitchUseCase'),
  IMarkPitchAsReadUseCase: Symbol.for('IMarkPitchAsReadUseCase'),
  // PitchAttachments
  IAddPitchAttachmentUseCase: Symbol.for('IAddPitchAttachmentUseCase'),
  IDeletePitchAttachmentUseCase: Symbol.for('IDeletePitchAttachmentUseCase'),
  IRemovePitchAttachmentFromPitchUseCase: Symbol.for('IRemovePitchAttachmentFromPitchUseCase'),
  IUpdatePitchAttachmentUseCase: Symbol.for('IUpdatePitchAttachmentUseCase'),
  // JobCategories
  IFetchJobCategoriesUseCase: Symbol.for('IFetchJobCategoriesUseCase'),
  // JobTags
  IFetchJobTagsUseCase: Symbol.for('IFetchJobTagsUseCase'),
  // Skills
  IFetchSkillsUseCase: Symbol.for('IFetchSkillsUseCase'),
  ICreateSkillUseCase: Symbol.for('ICreateSkillUseCase'),
  // Jobs
  IFetchJobsUseCase: Symbol.for('IFetchJobsUseCase'),
  IFetchCompanyJobsUseCase: Symbol.for('IFetchCompanyJobsUseCase'),
  IFetchMyJobsUseCase: Symbol.for('IFetchMyJobsUseCase'),
  IFetchJobUseCase: Symbol.for('IFetchJobUseCase'),
  ICreateJobUseCase: Symbol.for('ICreateJobUseCase'),
  IDuplicateJobUseCase: Symbol.for('IDuplicateJobUseCase'),
  IUpdateJobUseCase: Symbol.for('IUpdateJobUseCase'),
  IDeleteJobUseCase: Symbol.for('IDeleteJobUseCase'),
  IAddFootprintToJobUseCase: Symbol.for('IAddFootprintToJobUseCase'),
  IFetchJobFootprintsUseCase: Symbol.for('IFetchJobFootprintsUseCase'),
  ICreateJobAttachmentUseCase: Symbol.for('ICreateJobAttachmentUseCase'),
  IFetchFeaturedJobsUseCase: Symbol.for('IFetchFeaturedJobsUseCase'),
  // JobApplications
  IFetchJobApplicationsUseCase: Symbol.for('IFetchJobApplicationsUseCase'),
  IApplyForJobUseCase: Symbol.for('IApplyForJobUseCase'),
  // Subscriptions
  ICreateBillingPortalSessionUseCase: Symbol.for('ICreateBillingPortalSessionUseCase'),
  ICreateRecruitSubscriptionUseCase: Symbol.for('ICreateRecruitSubscriptionUseCase'),
  // JobBookmarks
  IFetchMyJobBookmarksUseCase: Symbol.for('IFetchMyJobBookmarksUseCase'),
  IAddMyJobBookmarkUseCase: Symbol.for('IAddMyJobBookmarkUseCase'),
  IRemoveMyJobBookmarkUseCase: Symbol.for('IRemoveMyJobBookmarkUseCase'),
  IFetchJobBookmarksUseCase: Symbol.for('IFetchJobBookmarksUseCase'),
  // UserReferences
  IAddUserReferenceUseCase: Symbol.for('IAddUserReferenceUseCase'),
  IUpdateUserReferenceUseCase: Symbol.for('IUpdateUserReferenceUseCase'),
  IRemoveUserReferenceUseCase: Symbol.for('IRemoveUserReferenceUseCase'),
  IFetchMyReferencesUseCase: Symbol.for('IFetchMyReferencesUseCase'),
  IUpdateMyReferenceUseCase: Symbol.for('IUpdateMyReferenceUseCase'),
  // CompanyReferences
  IAddCompanyReferenceUseCase: Symbol.for('IAddCompanyReferenceUseCase'),
  IUpdateCompanyReferenceUseCase: Symbol.for('IUpdateCompanyReferenceUseCase'),
  IRemoveCompanyReferenceUseCase: Symbol.for('IRemoveCompanyReferenceUseCase'),
  IFetchMyCompanyReferencesUseCase: Symbol.for('IFetchMyCompanyReferencesUseCase'),
  IUpdateMyCompanyReferenceUseCase: Symbol.for('IUpdateMyCompanyReferenceUseCase'),
  // Invitations
  IValidInvitationTokenUseCase: Symbol.for('IValidInvitationTokenUseCase'),
  IAddAngelInvitationUseCase: Symbol.for('IAddAngelInvitationUseCase'),
  IAddVcInvitationUseCase: Symbol.for('IAddVcInvitationUseCase'),
  IAddAngelInvestmentInvitationUseCase: Symbol.for('IAddAngelInvestmentInvitationUseCase'),
  IAddVcInvestmentInvitationUseCase: Symbol.for('IAddVcInvestmentInvitationUseCase'),
  IAddMemberInvitationUseCase: Symbol.for('IAddMemberInvitationUseCase'),
  // Follow, Follower
  IToggleFollowCompanyUseCase: Symbol.for('IToggleFollowCompanyUseCase'),
  IToggleFollowUserUseCase: Symbol.for('IToggleFollowUserUseCase'),
  IFetchFollowingUseCase: Symbol.for('IFetchFollowingUseCase'),
  IFetchFollowersUseCase: Symbol.for('IFetchFollowersUseCase'),
  // NotificationSettings
  IUpdateCompanyNotificationSettingsUseCase: Symbol.for('IUpdateCompanyNotificationSettingsUseCase'),
  IUpdateUserNotificationSettingsUseCase: Symbol.for('IUpdateUserNotificationSettingsUseCase'),
  // PMS
  IInitializeInvestorUseCase: Symbol.for('IInitializeInvestorUseCase'),
  IInitializeVehicleUseCase: Symbol.for('IInitializeVehicleUseCase'),
  IFetchVehicleAnalyticsUseCase: Symbol.for('IFetchVehicleAnalyticsUseCase'),
  IFetchVehicleItemUseCase: Symbol.for('IFetchVehicleItemUseCase'),
  IFetchVehicleItemsUseCase: Symbol.for('IFetchVehicleItemsUseCase'),
  ICreateVehicleUseCase: Symbol.for('ICreateVehicleUseCase'),
  IUpdateVehicleUseCase: Symbol.for('IUpdateVehicleUseCase'),
  IDeleteVehicleUseCase: Symbol.for('IDeleteVehicleUseCase'),
  ICreateVehicleItemUseCase: Symbol.for('ICreateVehicleItemUseCase'),
  IUpdateVehicleItemPortfolioUseCase: Symbol.for('IUpdateVehicleItemPortfolioUseCase'),
  IUpdateVehicleItemUseCase: Symbol.for('IUpdateVehicleItemUseCase'),
  IUpdateVehicleItemAndPortfolioUseCase: Symbol.for('IUpdateVehicleItemAndPortfolioUseCase'),
  IDeleteVehicleItemUseCase: Symbol.for('IDeleteVehicleItemUseCase'),
  ICreateVehicleItemRoundUseCase: Symbol.for('ICreateVehicleItemRoundUseCase'),
  IUpdateVehicleItemRoundUseCase: Symbol.for('IUpdateVehicleItemRoundUseCase'),
  IDeleteVehicleItemRoundUseCase: Symbol.for('IDeleteVehicleItemRoundUseCase'),
  IAddVehicleItemRoundAttachmentUseCase: Symbol.for('IAddVehicleItemRoundAttachmentUseCase'),
  IDeleteVehicleItemRoundAttachmentUseCase: Symbol.for('IDeleteVehicleItemRoundAttachmentUseCase'),
  ICreatePortfolioNoteUseCase: Symbol.for('ICreatePortfolioNoteUseCase'),
  IUpdatePortfolioNoteUseCase: Symbol.for('IUpdatePortfolioNoteUseCase'),
  IDeletePortfolioNoteUseCase: Symbol.for('IDeletePortfolioNoteUseCase'),
  ICreatePortfolioMeetingUseCase: Symbol.for('ICreatePortfolioMeetingUseCase'),
  IUpdatePortfolioMeetingUseCase: Symbol.for('IUpdatePortfolioMeetingUseCase'),
  IDeletePortfolioMeetingUseCase: Symbol.for('IDeletePortfolioMeetingUseCase'),
  IAddPortfolioAttachmentUseCase: Symbol.for('IAddPortfolioAttachmentUseCase'),
  IDeletePortfolioAttachmentUseCase: Symbol.for('IDeletePortfolioAttachmentUseCase'),
  IAddVehicleMemberUseCase: Symbol.for('IAddVehicleMemberUseCase'),
  IUpdateVehicleMemberUseCase: Symbol.for('IUpdateVehicleMemberUseCase'),
  IRemoveVehicleMemberUseCase: Symbol.for('IRemoveVehicleMemberUseCase'),
  ICreateVehicleStatusUseCase: Symbol.for('ICreateVehicleStatusUseCase'),
  IUpdateVehicleStatusUseCase: Symbol.for('IUpdateVehicleStatusUseCase'),
  IDeleteVehicleStatusUseCase: Symbol.for('IDeleteVehicleStatusUseCase'),
  IFetchLatestForexRateUseCase: Symbol.for('IFetchLatestForexRateUseCase'),
  IFetchAllForexRatesUseCase: Symbol.for('IFetchAllForexRatesUseCase'),
  // Subsidies
  IFetchSubsidiesUseCase: Symbol.for('IFetchSubsidiesUseCase'),
  IFetchSubsidyUseCase: Symbol.for('IFetchSubsidyUseCase'),
  // SubsidyPurposes
  IFetchSubsidyPurposesUseCase: Symbol.for('IFetchSubsidyPurposesUseCase'),
  IFetchSubsidyPurposeUseCase: Symbol.for('IFetchSubsidyPurposeUseCase'),
}

export default symbols
