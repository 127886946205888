import { inject, injectable } from 'inversify'
import {
  IAppCredentials,
  IAppErrorFactory,
  IAuthService,
  ILeaveServiceUseCase,
  IUseCaseOutputFactory,
  IViewerService,
  LeaveServiceUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class LeaveServiceInteractor implements ILeaveServiceUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppCredentials) private credentials: IAppCredentials

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<null>

  async handle(): Promise<LeaveServiceUseCaseOutput> {
    const output = this.outputFactory.create(null)
    try {
      await this.viewerService.leaveService()
      this.credentials.clear()
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
