import { inject, injectable } from 'inversify'
import {
  FetchFeaturedVentureCapitalsUseCaseInput,
  FetchFeaturedVentureCapitalsUseCaseOutput,
  IAppErrorFactory,
  ICompaniesService,
  IFetchFeaturedVentureCapitalsUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedVentureCapitalsInteractor implements IFetchFeaturedVentureCapitalsUseCase {
  @inject(symbols.ICompaniesService) private companiesService: ICompaniesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchFeaturedVentureCapitalsUseCaseInput): Promise<FetchFeaturedVentureCapitalsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 現在、fetchFeaturedVentureCapitals メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.ventureCapitals = await this.companiesService.fetchFeaturedVentureCapitals(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchFeaturedVentureCapitalsUseCaseOutput {
    return {
      ventureCapitals: [],
      error: null,
    }
  }
}
