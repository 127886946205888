import { inject, injectable } from 'inversify'
import {
  IAppErrorFactory,
  IAuthService,
  IUpdatePasswordUseCase,
  UpdatePasswordUseCaseInput,
  UpdatePasswordUseCaseOutput,
} from '@/types'
import symbols from '@/symbols'

@injectable()
export default class UpdatePasswordInteractor implements IUpdatePasswordUseCase {
  @inject(symbols.IAuthService) private authService: IAuthService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: UpdatePasswordUseCaseInput): Promise<UpdatePasswordUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      await this.authService.updatePassword(input.password, input.passwordConfirmation, input.currentPassword)

      output.isSuccessful = true
    } catch (e) {
      output.isSuccessful = false
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'パスワードの変更に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): UpdatePasswordUseCaseOutput {
    return {
      isSuccessful: false,
      error: null,
    }
  }
}
