import { inject, injectable } from 'inversify'
import {
  FetchMagazineSinglePageContentUseCaseInput,
  FetchMagazineSinglePageContentUseCaseOutput,
  IAppErrorFactory,
  IArticlesService,
  IFetchMagazineSinglePageContentUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchMagazineSinglePageContentInteractor implements IFetchMagazineSinglePageContentUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(
    input: FetchMagazineSinglePageContentUseCaseInput
  ): Promise<FetchMagazineSinglePageContentUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // TODO: Gateway を改良して1リクエストでランキング取得処理まで対応できるように変更
      const [article, features] = await Promise.all([
        this.articlesService.fetchArticle(input.slug),
        this.articlesService.fetchArticles({ categories: ['feature'], limit: 5 }),
      ])
      output.article = article
      output.recommendedArticles = features.articles
      output.asideContents.dailyRanking = features.articles
      output.asideContents.weeklyRanking = features.articles
      output.asideContents.monthlyRanking = features.articles
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMagazineSinglePageContentUseCaseOutput {
    return {
      article: null,
      asideContents: {
        dailyRanking: [],
        weeklyRanking: [],
        monthlyRanking: [],
      },
      recommendedArticles: [],
      error: null,
    }
  }
}
