import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchFollowingUseCaseInput,
  FetchFollowingUseCaseOuput,
  IAppErrorFactory,
  IFetchFollowingUseCase,
  IFollowableEntityBase,
  IUseCaseOutputFactory,
  IUsersService,
} from '../../types'

@injectable()
export default class FetchFollowingInteractor implements IFetchFollowingUseCase {
  @inject(symbols.IUsersService) private usersService: IUsersService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    following: null | IFollowableEntityBase[]
    hasNextPage: boolean
  }>

  async handle(input: FetchFollowingUseCaseInput): Promise<FetchFollowingUseCaseOuput> {
    const output = this.outputFactory.create({
      defaultValue: {
        following: null,
        hasNextPage: false,
      },
    })
    try {
      const { following, hasNextPage } = await this.usersService.fetchFollowing(input)
      output.data.following = following
      output.data.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
