import { inject, injectable } from 'inversify'
import {
  FetchFeaturedStoriesUseCaseInput,
  FetchFeaturedStoriesUseCaseOutput,
  IAppErrorFactory,
  IArticleBase,
  IArticlesService,
  IFetchFeaturedStoriesUseCase,
  IUseCaseOutputFactory,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedStoriesInteractor implements IFetchFeaturedStoriesUseCase {
  @inject(symbols.IArticlesService) private articlesService: IArticlesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    featuredStories: [] | IArticleBase[]
  }>

  async handle(input: FetchFeaturedStoriesUseCaseInput): Promise<FetchFeaturedStoriesUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        featuredStories: [],
      },
    })
    try {
      // 現在、fetchFeaturedStories メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.data.featuredStories = await this.articlesService.fetchFeaturedStories(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
