import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  AddVehicleMemberUseCaseInput,
  AddVehicleMemberUseCaseOutput,
  IAddVehicleMemberUseCase,
  IAppErrorFactory,
  InvestorMemberRole,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleMember,
  IVehicleMemberFactory,
  VehicleMemberRole,
} from '@/types'

@injectable()
export default class AddVehicleMemberInteractor implements IAddVehicleMemberUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IVehicleMemberFactory) private vehicleMemberFactory: IVehicleMemberFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleMember: IVehicleMember
  }>

  async handle(input: AddVehicleMemberUseCaseInput): Promise<AddVehicleMemberUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleMember: null,
      },
    })
    try {
      const investorMemberRole =
        input.role === VehicleMemberRole.ADMIN ? InvestorMemberRole.ADMIN : InvestorMemberRole.MEMBER

      const base = await this.pmsService.addVehicleMember({
        userId: input.userId,
        vehicleId: input.vehicleId,
        vehicleMemberRole: input.role,
        investorMemberRole,
      })

      output.data.vehicleMember = this.vehicleMemberFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
