import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  FetchMyCompanyReferencesUseCaseInput,
  FetchMyCompanyReferencesUseCaseOutput,
  IAppErrorFactory,
  ICompanyReferenceFactory,
  ICompanyReferencesService,
  IFetchMyCompanyReferencesUseCase,
} from '../../types'

@injectable()
export default class FetchMyCompanyReferencesInteractor implements IFetchMyCompanyReferencesUseCase {
  @inject(symbols.ICompanyReferencesService) private companyReferencesService: ICompanyReferencesService

  @inject(symbols.ICompanyReferenceFactory) private companyReferenceFactory: ICompanyReferenceFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchMyCompanyReferencesUseCaseInput): Promise<FetchMyCompanyReferencesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { companyReferences, hasNextPage } = await this.companyReferencesService.fetchMyCompanyReferences(input)
      output.companyReferences = companyReferences.map((r) => this.companyReferenceFactory.create({ base: r }))
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'リファレンスの取得に失敗しました',
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchMyCompanyReferencesUseCaseOutput {
    return {
      companyReferences: [],
      hasNextPage: true,
      error: null,
    }
  }
}
