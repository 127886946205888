import { action, observable } from 'mobx'
import remotedev, { RemoteDevConfig } from 'mobx-remotedev'
import { inject, injectable } from 'inversify'
import {
  FetchLocationStoreInput,
  FetchSubsidiesStoreInput,
  FetchSubsidyPurposeStoreInput,
  FetchSubsidyStoreInput,
  IErrorsStore,
  IFetchLocationUseCase,
  IFetchSubsidiesUseCase,
  IFetchSubsidyPurposeUseCase,
  IFetchSubsidyPurposesUseCase,
  IFetchSubsidyUseCase,
  ILocationBase,
  ISubsidiesStore,
  ISubsidy,
  ISubsidyBase,
  ISubsidyPurposeBase,
} from '@/types'
import symbols from '@/symbols'

const remoteDevConfig: RemoteDevConfig = {
  name: 'ArticlesStore',
  global: true,
  remote: false,
}

@remotedev(remoteDevConfig)
@injectable()
export default class SubsidiesStore implements ISubsidiesStore {
  @observable subsidies: ISubsidy[] = []

  @observable hasNextSubsidiesPage = true

  @observable subsidyPurposes: ISubsidyPurposeBase[] = []

  constructor(
    @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore,
    @inject(symbols.IFetchSubsidiesUseCase) private fetchSubsidiesUseCase: IFetchSubsidiesUseCase,
    @inject(symbols.IFetchSubsidyUseCase) private fetchSubsidyUseCase: IFetchSubsidyUseCase,
    @inject(symbols.IFetchSubsidyPurposesUseCase) private fetchSubsidyPurposesUseCase: IFetchSubsidyPurposesUseCase,
    @inject(symbols.IFetchSubsidyPurposeUseCase) private fetchSubsidyPurposeUseCase: IFetchSubsidyPurposeUseCase,
    @inject(symbols.IFetchLocationUseCase)
    private fetchLocationUseCase: IFetchLocationUseCase
  ) {
    //
  }

  @action
  _updateSubsidies(subsidies: ISubsidyBase[]): void {
    this.subsidies = subsidies
  }

  @action
  _addSubsidies(subsidies: ISubsidyBase[]): void {
    subsidies.forEach((newSubsidy) => {
      // 重複していたら処理をスキップ
      if (this.subsidies.some((s) => s.id === newSubsidy.id)) {
        return
      }

      // 末尾に追加
      this.subsidies = this.subsidies.concat(newSubsidy)
    })
  }

  @action
  updateHasNextSubsidiesPage(hasNextPage: boolean): void {
    this.hasNextSubsidiesPage = hasNextPage
  }

  @action
  _updateSubsidyPurposes(subsidyPurposes: ISubsidyPurposeBase[]): void {
    this.subsidyPurposes = subsidyPurposes
  }

  async fetchSubsidies(input: FetchSubsidiesStoreInput): Promise<ISubsidyBase[]> {
    const output = await this.fetchSubsidiesUseCase.handle(input)
    if (output.error) {
      this.errorsStore.handle(output.error)

      return []
    }

    if (input.shouldRefresh) {
      this._updateSubsidies(output.data.subsidies)
    } else {
      this._addSubsidies(output.data.subsidies)
    }
    this.updateHasNextSubsidiesPage(output.data.hasNextPage)

    return output.data.subsidies
  }

  async fetchSubsidy(input: FetchSubsidyStoreInput): Promise<ISubsidyBase> {
    const output = await this.fetchSubsidyUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.data.subsidy
  }

  async fetchLocation(input: FetchLocationStoreInput): Promise<ILocationBase> {
    const output = await this.fetchLocationUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.location
  }

  async fetchSubsidyPurposes(): Promise<ISubsidyPurposeBase[]> {
    const output = await this.fetchSubsidyPurposesUseCase.handle()

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    this._updateSubsidyPurposes(output.data.subsidyPurposes)

    return output.data.subsidyPurposes
  }

  async fetchSubsidyPurpose(input: FetchSubsidyPurposeStoreInput): Promise<ISubsidyPurposeBase> {
    const output = await this.fetchSubsidyPurposeUseCase.handle(input)

    if (output.error) {
      this.errorsStore.handle(output.error)
    }

    return output.data.subsidyPurpose
  }
}
