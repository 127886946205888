import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IPMSService,
  IUpdateVehicleItemUseCase,
  IUseCaseOutputFactory,
  IVehicleItemBase,
  UpdateVehicleItemUseCaseInput,
  UpdateVehicleItemUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateVehicleItemInteractor implements IUpdateVehicleItemUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItem: IVehicleItemBase
  }>

  async handle(input: UpdateVehicleItemUseCaseInput): Promise<UpdateVehicleItemUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItem: null,
      },
    })
    try {
      output.data.vehicleItem = await this.pmsService.updateVehicleItem({
        id: input.id,
        vehicleItem: input.vehicleItem,
        captainUserId: input.captainUserId,
        vehicleStatusId: input.vehicleStatusId,
      })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
