import { inject, injectable } from 'inversify'
import {
  IChatMessageThreadFactory,
  IErrorsStore,
  IJobApplication,
  IJobApplicationFactory,
  JobApplicationFactoryInput,
} from '@/types'
import symbols from '@/symbols'
import JobApplication from '@/entities/JobApplication'

@injectable()
export default class JobApplicationFactory implements IJobApplicationFactory {
  @inject(symbols.IErrorsStore) private errorsStore: IErrorsStore

  @inject(symbols.IChatMessageThreadFactory) private chatMessageThreadFactory: IChatMessageThreadFactory

  public create(input: JobApplicationFactoryInput): IJobApplication {
    input.base.messageThread = this.chatMessageThreadFactory.create({ base: input.base.messageThread })
    const jobApplication = new JobApplication(input.base)

    jobApplication.errorsStore = this.errorsStore

    return jobApplication
  }
}
