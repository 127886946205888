import { inject, injectable } from 'inversify'
import symbols from '../../symbols'
import {
  DeletePitchUseCaseInput,
  DeletePitchUseCaseOutput,
  IAppErrorFactory,
  IDeletePitchUseCase,
  IPitchesService,
} from '../../types'

@injectable()
export default class DeletePitchInteractor implements IDeletePitchUseCase {
  @inject(symbols.IPitchesService) private pitchesService: IPitchesService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: DeletePitchUseCaseInput): Promise<DeletePitchUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const pitch = await this.pitchesService.deletePitch(input)
      output.pitch = pitch
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        messageForUI: 'ラウンドの削除に失敗しました',
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): DeletePitchUseCaseOutput {
    return {
      pitch: null,
      error: null,
    }
  }
}
