import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  FetchVehicleItemsUseCaseInput,
  FetchVehicleItemsUseCaseOutput,
  IAppErrorFactory,
  IFetchVehicleItemsUseCase,
  IPMSService,
  IUseCaseOutputFactory,
  IVehicleItemBase,
} from '@/types'
import { getUniqueArray } from '@/utils'

@injectable()
export default class FetchVehicleItemsInteractor implements IFetchVehicleItemsUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    vehicleItemBases: IVehicleItemBase[]
    cursor: string
    hasNextPage: boolean
  }>

  async handle(input: FetchVehicleItemsUseCaseInput): Promise<FetchVehicleItemsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        vehicleItemBases: [],
        cursor: '',
        hasNextPage: true,
      },
    })
    try {
      const uniqueIds = getUniqueArray<string>(input.vehicleStatusIds) // 重複削除
      const response = await this.pmsService.fetchVehicleItems({
        vehicleSlug: input.vehicleSlug,
        vehicleStatusIds: uniqueIds,
        query: input.query || '',
        limit: input.limit || 20,
        cursor: input.cursor || '',
      })

      // Factory は作成しない
      const { vehicleItems, cursor, hasNextPage } = response
      output.data = { vehicleItemBases: vehicleItems, cursor, hasNextPage }
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
