import { inject, injectable } from 'inversify'
import {
  ICreateVehicleUseCase,
  IDeleteVehicleUseCase,
  IInvestor,
  IInvestorFactory,
  InvestorFactoryInput,
  IVehicleFactory,
} from '@/types'
import Investor from '@/entities/Investor'
import symbols from '@/symbols'

@injectable()
export default class InvestorFactory implements IInvestorFactory {
  @inject(symbols.IVehicleFactory) public vehicleFactory: IVehicleFactory

  @inject(symbols.ICreateVehicleUseCase) public createVehicleUseCase: ICreateVehicleUseCase

  @inject(symbols.IDeleteVehicleUseCase) public deleteVehicleUseCase: IDeleteVehicleUseCase

  public create(input: InvestorFactoryInput): IInvestor {
    const vehicles = input.vehicleBases.map((v) => this.vehicleFactory.create({ base: v }))
    return new Investor(input.base, {
      vehicles,
      createVehicleUseCase: this.createVehicleUseCase,
      deleteVehicleUseCase: this.deleteVehicleUseCase,
    })
  }
}
