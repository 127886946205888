import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  CreatePortfolioNoteUseCaseInput,
  CreatePortfolioNoteUseCaseOutput,
  IAppErrorFactory,
  ICreatePortfolioNoteUseCase,
  IPMSService,
  IPortfolioNote,
  IPortfolioNoteFactory,
  IUseCaseOutputFactory,
} from '@/types'

@injectable()
export default class CreatePortfolioNoteInteractor implements ICreatePortfolioNoteUseCase {
  @inject(symbols.IPMSService) private pmsService: IPMSService

  @inject(symbols.IPortfolioNoteFactory) private portfolioNoteFactory: IPortfolioNoteFactory

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    portfolioNote: IPortfolioNote
  }>

  async handle(input: CreatePortfolioNoteUseCaseInput): Promise<CreatePortfolioNoteUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        portfolioNote: null,
      },
    })
    try {
      const base = await this.pmsService.createVehicleItemPortfolioNote(input)
      output.data.portfolioNote = this.portfolioNoteFactory.create({ base })
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
      })
    }

    return output
  }
}
