import { inject, injectable } from 'inversify'
import symbols from '@/symbols'
import {
  IAppErrorFactory,
  IUpdateUserNotificationSettingsUseCase,
  IUseCaseOutputFactory,
  IUserBase,
  IViewerService,
  UpdateUserNotificationSettingsUseCaseInput,
  UpdateUserNotificationSettingsUseCaseOutput,
} from '@/types'

@injectable()
export default class UpdateUserNotificationSettingsInteractor implements IUpdateUserNotificationSettingsUseCase {
  @inject(symbols.IViewerService) private viewerService: IViewerService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  @inject(symbols.IUseCaseOutputFactory) private outputFactory: IUseCaseOutputFactory<{
    user: null | IUserBase
  }>

  async handle(
    input: UpdateUserNotificationSettingsUseCaseInput
  ): Promise<UpdateUserNotificationSettingsUseCaseOutput> {
    const output = this.outputFactory.create({
      defaultValue: {
        user: null,
      },
    })
    try {
      output.data.user = await this.viewerService.updateUserNotificationSettings(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }
}
