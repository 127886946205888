import { inject, injectable } from 'inversify'
import {
  FetchFeaturedJobsUseCaseInput,
  FetchFeaturedJobsUseCaseOutput,
  IAppErrorFactory,
  IFetchFeaturedJobsUseCase,
  IJobsService,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchFeaturedJobsInteractor implements IFetchFeaturedJobsUseCase {
  @inject(symbols.IJobsService) private jobsService: IJobsService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchFeaturedJobsUseCaseInput): Promise<FetchFeaturedJobsUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      // 現在、fetchFeaturedJobs メソッドは引数不要。しかし今後、データの取得数を指定することを考慮して、引数（input）を記述しておく。
      output.featuredJobs = await this.jobsService.fetchFeaturedJobs(input)
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchFeaturedJobsUseCaseOutput {
    return {
      featuredJobs: [],
      error: null,
    }
  }
}
