import { inject, injectable } from 'inversify'
import {
  FetchChatMessagesUseCaseInput,
  FetchChatMessagesUseCaseOutput,
  IAppErrorFactory,
  IChatService,
  IFetchChatMessagesUseCase,
} from '../../types'
import symbols from '../../symbols'

@injectable()
export default class FetchChatMessagesInteractor implements IFetchChatMessagesUseCase {
  @inject(symbols.IChatService) private chatService: IChatService

  @inject(symbols.IAppErrorFactory) private errorFactory: IAppErrorFactory

  async handle(input: FetchChatMessagesUseCaseInput): Promise<FetchChatMessagesUseCaseOutput> {
    const output = this.getDefaultOutputData()
    try {
      const { messages, hasNextPage } = await this.chatService.fetchChatMessages(input)
      output.messages = messages
      output.hasNextPage = hasNextPage
    } catch (e) {
      output.error = this.errorFactory.create({
        originalInstance: e as Error,
        extra: {
          useCaseInput: input,
        },
      })
    }

    return output
  }

  private getDefaultOutputData(): FetchChatMessagesUseCaseOutput {
    return {
      messages: [],
      hasNextPage: true,
      error: null,
    }
  }
}
